import React, { memo } from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      minHeight: '30px',

      '& > div': {
        position: 'absolute',
        top: 0,
        transform: 'translateX(-50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '&::before': {
          content: '""',
          background: '#777',
          height: '10px',
          width: '1px',
          marginBottom: '4px',
        },
      },
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  width: number;
}
//#endregion

const UnrolledPositionMarkers: React.FunctionComponent<Props> = ({
  classes,
  width,
}) => {
  const spacing = width / 4;

  return (
    <div className={classes.root} style={{ width }}>
      <div style={{ left: spacing * 0 }}>180</div>
      <div style={{ left: spacing * 1 }}>270</div>
      <div style={{ left: spacing * 2 }}>0</div>
      <div style={{ left: spacing * 3 }}>90</div>
    </div>
  );
};

export default memo(withStyles(styles)(UnrolledPositionMarkers));
