import React, { memo } from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import classNames from 'classnames';

//#region Styles
const styles = () =>
  createStyles({
    leftScale: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      color: '#aaa',
      fontSize: 14,
      userSelect: 'none',

      '& > div': {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        transform: 'translateY(-50%) translateX(-100%)',
        flexDirection: 'row',
      },

      '& .scaleMarker': {
        background: '#aaa',
        width: 10,
        height: 1,
      },
    },
    bottomScale: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      color: '#aaa',
      fontSize: 14,
      userSelect: 'none',

      '& > div': {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        transform: 'translateX(-50%)',
        flexDirection: 'column',
      },

      '& .scaleMarker': {
        background: '#aaa',
        width: 1,
        height: 10,
      },
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  nativeSize: number;
  nativeOffset: number;
  isBottom?: boolean;
}
//#endregion Types

const ImageScale: React.FunctionComponent<Props> = ({
  classes,
  nativeSize,
  nativeOffset,
  isBottom,
}) => {
  const scaleHeights = nativeSize > 400 ? [0, 1, 2, 3, 4] : [0, 1, 2];

  const elements = scaleHeights.map(item => {
    const fraction = item / (scaleHeights.length - 1); // 0, 0.25, ... , 1
    const percent = fraction * 100;
    const markerValue = Math.round(fraction * nativeSize + nativeOffset);

    if (isBottom) {
      return (
        <div style={{ left: `${percent}%` }} key={item}>
          <div className="scaleMarker" />
          <div>{markerValue}</div>
        </div>
      );
    }

    return (
      <div style={{ top: `${percent}%` }} key={item}>
        <div>{markerValue}</div>
        <div className="scaleMarker" />
      </div>
    );
  });

  return (
    <div
      className={classNames({
        [classes.bottomScale]: isBottom,
        [classes.leftScale]: !isBottom,
      })}
    >
      {elements}
    </div>
  );
};

export default memo(withStyles(styles)(ImageScale));
