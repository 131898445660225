import selectedFilesStore from '../stores/files/selectedFilesStore';
import filesStore from '../stores/files/filesStore';

export const isDirectory = (file: Common.File) => file.type === 'directory';
export const isFile = (file: Common.File) => !isDirectory(file);
export const isCTViewerSet = (file: Common.File) =>
  file.path.endsWith('.ctimagesset');
export const isHighEnergySet = (file: Common.File) =>
  file.path.endsWith('.high');
export const isLowEnergySet = (file: Common.File) => file.path.endsWith('.low');
export const isDicomFile = (name: string) => name.endsWith('.dcm');
export const isSpecialDirectory = (file: Common.File) =>
  isCTViewerSet(file) || isLowEnergySet(file) || isHighEnergySet(file);
export const getPathWithoutExtension = (path: string) =>
  path.substring(0, path.lastIndexOf('.'));

export const getFileExtension = (file: Common.File) =>
  (file.path.split('.').pop() || '').toLowerCase();

export function areAllChecked(ommitSpecialDirectories = false) {
  const { files } = filesStore;
  let newFiles;

  if (ommitSpecialDirectories) {
    newFiles = files.filter(
      (f: Common.File) => !isLowEnergySet(f) && !isHighEnergySet(f),
    );
  } else {
    newFiles = files;
  }

  if (newFiles.length > 0) {
    return newFiles.every((f: Common.File) => selectedFilesStore.isChecked(f));
  }

  return false;
}

export function checkAll(ommitSpecialDirectories = false) {
  const allChecked = areAllChecked(ommitSpecialDirectories);
  const action = allChecked
    ? selectedFilesStore.uncheckFile
    : selectedFilesStore.checkFile;

  filesStore.files.forEach((f: Common.File) => {
    if (
      !(ommitSpecialDirectories && (isLowEnergySet(f) || isHighEnergySet(f)))
    ) {
      return action(f);
    }
  });
}
