import React from 'react';
import { lighten } from 'polished';
import classNames from 'classnames';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import {
  MY_FILES_CARD_COLOR,
  SHARED_WITH_ME_COLOR,
} from '../../labs/dashboard/DashboardCard';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    menuOption: {
      height: 38,
      minWidth: 180,
      borderRadius: 19,
      cursor: 'pointer',
      display: 'inline-flex',
      fontSize: theme.typography.pxToRem(14),
      alignItems: 'center',

      '&:hover, &.active': {
        boxShadow: '-1px 3px 6px 0 rgba(183,190,198,0.36)',

        '& $iconWrapper': {
          backgroundColor: theme.palette.primary.light,
        },

        '& svg': {
          color: theme.palette.primary.main,
        },
      },
    },
    iconWrapper: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      borderRadius: '50%',
      justifyContent: 'center',
      width: 38,
      height: 38,

      '& > svg': {
        width: 19,
        height: 19,
        color: theme.palette.primary.dark,
      },
    },
    menuLabel: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 16px 12px 8px',
    },
    sharedWithMe: {
      '&:hover, &.active': {
        '& $iconWrapper': {
          backgroundColor: `${lighten(0.4, SHARED_WITH_ME_COLOR)}`,

          '& > svg': {
            color: SHARED_WITH_ME_COLOR,
          },
        },
      },
    },
    myFiles: {
      '&:hover, &.active': {
        '& $iconWrapper': {
          backgroundColor: `${lighten(0.4, MY_FILES_CARD_COLOR)}`,

          '& > svg': {
            color: MY_FILES_CARD_COLOR,
          },
        },
      },
    },
  });
//#endregion

//#region Props
interface Props extends WithStyles<typeof styles> {
  variant?: 'SHARED' | 'MY_FILES';
  icon: React.ReactNode;
  isFirst?: boolean;
  isActive?: boolean;
  onClicked?: () => void;
}
//#endregion

const MenuOption: React.FunctionComponent<Props> = ({
  children,
  classes,
  icon,
  isFirst,
  isActive,
  onClicked,
  variant,
}) => {
  return (
    <div
      className={classNames(classes.menuOption, {
        active: isActive,
        [classes.myFiles]: variant === 'MY_FILES',
        [classes.sharedWithMe]: variant === 'SHARED',
      })}
      onClick={onClicked}
      role="button"
      style={{ marginBottom: isFirst ? 30 : 12 }}
    >
      <div className={classes.iconWrapper}>{icon}</div>

      <Typography className={classes.menuLabel} component="span">
        {children}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(MenuOption);
