import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import find from 'lodash-es/find';
import is from 'is_js';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import MuiButton from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AutoCompleter from '../../../components/ui/AutoCompleter';

import Button from '../../../components/ui/Button';
import { UsersStore } from '../../../stores/usersStore';
import { AuthStore } from '../../../stores/authStore';
import UserEditRemoveDialog from './UserEditRemoveDialog';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '40px 20px 0 32px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    form: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      marginBottom: 16,
    },
    secondaryHeader: {
      fontSize: theme.typography.pxToRem(14),
      color: '#8A8B8D',
    },
    baseInfoSection: {
      marginBottom: 40,
    },
    permissionsSection: {
      marginTop: 20,
      backgroundColor: '#E8EEF0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 160,
      width: '100%',
      borderRadius: 10,
    },
    removeUserButton: {
      alignSelf: 'flex-start',
      color: '#D15151',
    },
    tabsRoot: {
      borderBottom: '1px solid #e8e8e8',
      marginBottom: '1rem',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
    bottomActions: {
      paddingTop: 52,
      paddingBottom: 52,
      borderTop: `1px dashed ${theme.palette.primary.main}`,
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  onSave: (userPayload: Common.UserPayload) => void;
  onDeleteFromLab: () => void;
  onCancel: () => void;
  pending: boolean;
}

interface InjectedProps extends Props {
  usersStore: UsersStore;
  authStore: AuthStore;
}

interface State {
  confirmationOpened: boolean;
  tabValue: number;
}
//#endregion

@inject('usersStore', 'authStore')
@observer
class UserEdit extends React.Component<Props, State> {
  state = {
    confirmationOpened: false,
    tabValue: 0,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    const { usersStore } = this.injected;

    if (usersStore.users.length === 0) {
      await usersStore.getUsersList();
    }
  }

  setTabValue = (event: React.ChangeEvent<{}>, tabValue: number) => {
    this.setState({ tabValue });
  };

  handleCancel = () => {
    this.setState({
      confirmationOpened: false,
    });
  };

  handleConfirm = () => {
    this.props.onDeleteFromLab();
  };

  openRemovalDialog = () => {
    this.setState({
      confirmationOpened: true,
    });
  };

  getButtonText = () => {
    const { usersStore } = this.injected;
    const { pending } = this.props;

    if (usersStore.selectedUser) {
      if (usersStore.saving) {
        return 'Saving...';
      } else if (pending) {
        return 'Resend invitation';
      } else {
        return 'Save';
      }
    } else {
      if (usersStore.saving) {
        return 'Inviting...';
      } else {
        return 'Invite';
      }
    }
  };

  render() {
    const { classes, onSave, onCancel, pending } = this.props;
    const { confirmationOpened, tabValue } = this.state;

    const {
      selectedUser,
      departmentNames: departmentsList,
      saving,
      labName,
      listOfUsers,
      getUserByProperty,
      getUsersForLab,
      currentLab,
    } = this.injected.usersStore;

    const { userData, user, isAdmin } = this.injected.authStore;

    const userLabAdmins = selectedUser ? toJS(selectedUser.lab_admins) : [];
    const isSelectedUserLabAdmin = Boolean(
      find(userLabAdmins, ['full_lab_name', labName]),
    );
    const isIE = is.ie();

    const pmList = selectedUser ? toJS(selectedUser.pm_for_labs) : [];
    const isPMForCurrentProject = pmList && pmList.includes(labName);

    const isCurrentUserLabAdmin = (user.lab_admin_labs_full || []).includes(
      labName,
    );

    const usersAlreadyInLab = getUsersForLab(currentLab).map(
      user => user.full_name,
    );

    const users = listOfUsers
      .map(user => ({
        value: user.email,
        label: user.full_name,
      }))
      .filter(user => user.value !== userData.email)
      .filter(user => !usersAlreadyInLab.includes(user.label));

    const isFormEditable = isAdmin || isCurrentUserLabAdmin;

    let role = 'user';
    if (selectedUser && isSelectedUserLabAdmin) {
      role = 'lab_admin';
    } else if (isPMForCurrentProject) {
      role = 'pm';
    }

    if (pending && selectedUser) {
      role = selectedUser.role!;
    }

    //#region form config
    const initialValues = {
      fullName: selectedUser ? selectedUser.full_name : '',
      email: selectedUser ? selectedUser.email : '',
      role: selectedUser ? role : 'user',
      departments: selectedUser ? toJS(selectedUser.departments) : [],
    };

    const validationSchema = Yup.object().shape({
      fullName: Yup.string().required('Field is required'),
      email: Yup.string()
        .email()
        .required('Field is required'),
      departments: Yup.array(Yup.string()),
      role: Yup.string().required('Field is required'),
    });
    //#endregion

    const canChangeUserDetails =
      isAdmin || (isCurrentUserLabAdmin && initialValues.role === 'user');
    const canSaveUser = isAdmin || isCurrentUserLabAdmin;
    const canRemoveUser =
      isAdmin ||
      (!isSelectedUserLabAdmin &&
        selectedUser &&
        selectedUser.email !== user.email);

    return (
      <div className={classes.root}>
        <UserEditRemoveDialog
          isOpen={confirmationOpened}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />

        {isFormEditable && (
          <Typography className={classes.header}>
            {selectedUser
              ? pending
                ? `Edit user's invitation`
                : `Edit user's profile`
              : `Invite user`}
          </Typography>
        )}

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values: Common.UserPayload) => {
            if (values.role === 'lab_admin') {
              // admin cannot have any department
              values.departments = [];
            }
            return onSave(values);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
            resetForm,
          }) => {
            const user = getUserByProperty('email', values.email)[0];

            return (
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                {selectedUser ? (
                  <Grid
                    container
                    spacing={24}
                    className={classes.baseInfoSection}
                  >
                    <Grid item xs={6}>
                      <TextField
                        autoFocus
                        required
                        fullWidth
                        id="fullName"
                        label="Full Name"
                        margin="normal"
                        disabled={!canChangeUserDetails}
                        error={!!(errors.fullName && touched.fullName)}
                        helperText={
                          errors.fullName && touched.fullName
                            ? errors.fullName
                            : ''
                        }
                        value={values.fullName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        margin="normal"
                        disabled={Boolean(selectedUser) || !isFormEditable}
                        error={!!(errors.email && touched.email)}
                        helperText={
                          errors.email && touched.email ? errors.email : ''
                        }
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} />

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="role"
                        name="role"
                        select
                        label="Role"
                        margin="normal"
                        value={values.role}
                        disabled={
                          !isFormEditable ||
                          (!isAdmin &&
                            isSelectedUserLabAdmin &&
                            values.role === 'lab_admin')
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => handleChange(event)}
                      >
                        {isAdmin ||
                        (isSelectedUserLabAdmin &&
                          values.role === 'lab_admin') ? (
                          <MenuItem value="lab_admin">Lab admin</MenuItem>
                        ) : null}
                        <MenuItem value="user">Lab user</MenuItem>
                        <MenuItem value="pm">Project Manager</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={6}>
                      {isIE === false &&
                      (values.role === 'user' || values.role === 'pm') ? (
                        <FormControl
                          fullWidth
                          margin="normal"
                          error={!!(errors.departments && touched.departments)}
                        >
                          <InputLabel htmlFor="select-departments">
                            Departments
                          </InputLabel>
                          <Select
                            multiple
                            fullWidth
                            value={values.departments}
                            disabled={
                              !isFormEditable || !departmentsList.length
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>,
                            ) => handleChange(event)}
                            input={
                              <Input
                                name="departments"
                                id="select-departments"
                              />
                            }
                          >
                            {departmentsList.map((department, index) => (
                              <MenuItem key={index} value={department}>
                                {department}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.departments && (
                            <FormHelperText>
                              {errors.departments}
                            </FormHelperText>
                          )}
                        </FormControl>
                      ) : (
                        <>
                          {isIE && (
                            <Typography
                              variant="body1"
                              style={{ paddingTop: 16 }}
                            >
                              Departments assign not supported for IE 11
                            </Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <div>
                    <Tabs
                      value={tabValue}
                      onChange={(event, tabValue) => {
                        this.setTabValue(event, tabValue);
                        resetForm(initialValues);
                      }}
                      classes={{ indicator: classes.indicator }}
                      className={classes.tabsRoot}
                    >
                      <Tab label="Existing user" />
                      <Tab label="New user" />
                    </Tabs>

                    {tabValue === 0 && (
                      <Grid
                        container
                        spacing={24}
                        className={classes.baseInfoSection}
                      >
                        <Grid item xs={6}>
                          <AutoCompleter
                            autoFocus
                            options={users}
                            label="Choose existing user"
                            error={!!errors.email}
                            helperText={errors.email || ''}
                            value={{
                              value: values.email,
                              label: user ? user.full_name : '',
                            }}
                            onChange={(e, email) => {
                              if (!email) {
                                setFieldValue('fullName', '');
                              } else {
                                setFieldValue(
                                  'fullName',
                                  // @ts-ignore
                                  users.find(user => user.value === email)
                                    .label,
                                );
                              }

                              return setFieldValue('email', email);
                            }}
                            onBlur={() => {}}
                            id="email"
                            name="email"
                            noOptionsMessage="No users found"
                            isClearable
                          />

                          <Typography
                            className={classes.header}
                            style={{
                              marginTop: '2rem',
                              marginBottom: '2rem',
                            }}
                          >
                            Profile Information
                          </Typography>

                          <TextField
                            autoFocus
                            required
                            fullWidth
                            id="fullName"
                            label="Full Name"
                            margin="normal"
                            disabled={true}
                            error={!!(errors.fullName && touched.fullName)}
                            helperText={
                              errors.fullName && touched.fullName
                                ? errors.fullName
                                : ''
                            }
                            value={values.fullName}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            margin="normal"
                            disabled={true}
                            error={!!(errors.email && touched.email)}
                            helperText={
                              errors.email && touched.email ? errors.email : ''
                            }
                            value={values.email}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6} />

                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="role"
                            name="role"
                            select
                            label="Role"
                            margin="normal"
                            value={values.role}
                            disabled={!isFormEditable}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => handleChange(event)}
                          >
                            {isAdmin && (
                              <MenuItem value="lab_admin">Lab admin</MenuItem>
                            )}
                            <MenuItem value="user">Lab user</MenuItem>
                            <MenuItem value="pm">Project Manager</MenuItem>
                          </TextField>
                        </Grid>

                        <Grid item xs={6}>
                          {isIE === false &&
                          (values.role === 'user' || values.role === 'pm') ? (
                            <FormControl
                              fullWidth
                              margin="normal"
                              error={
                                !!(errors.departments && touched.departments)
                              }
                            >
                              <InputLabel htmlFor="select-departments">
                                Departments
                              </InputLabel>
                              <Select
                                multiple
                                fullWidth
                                value={values.departments}
                                disabled={
                                  !isFormEditable || !departmentsList.length
                                }
                                onChange={(
                                  event: React.ChangeEvent<HTMLSelectElement>,
                                ) => handleChange(event)}
                                input={
                                  <Input
                                    name="departments"
                                    id="select-departments"
                                  />
                                }
                              >
                                {departmentsList.map((department, index) => (
                                  <MenuItem key={index} value={department}>
                                    {department}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.departments && (
                                <FormHelperText>
                                  {errors.departments}
                                </FormHelperText>
                              )}
                            </FormControl>
                          ) : (
                            <>
                              {isIE && (
                                <Typography
                                  variant="body1"
                                  style={{ paddingTop: 16 }}
                                >
                                  Departments assign not supported for IE 11
                                </Typography>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    {tabValue === 1 && (
                      <Grid
                        container
                        spacing={24}
                        className={classes.baseInfoSection}
                      >
                        <Grid item xs={6}>
                          <TextField
                            autoFocus
                            required
                            fullWidth
                            id="fullName"
                            label="Full Name"
                            margin="normal"
                            disabled={!canChangeUserDetails}
                            error={!!(errors.fullName && touched.fullName)}
                            helperText={
                              errors.fullName && touched.fullName
                                ? errors.fullName
                                : ''
                            }
                            value={values.fullName}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            margin="normal"
                            disabled={Boolean(selectedUser) || !isFormEditable}
                            error={!!(errors.email && touched.email)}
                            helperText={
                              errors.email && touched.email ? errors.email : ''
                            }
                            value={values.email}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6} />

                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="role"
                            name="role"
                            select
                            label="Role"
                            margin="normal"
                            value={values.role}
                            disabled={!isFormEditable}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => handleChange(event)}
                          >
                            {isAdmin && (
                              <MenuItem value="lab_admin">Lab admin</MenuItem>
                            )}
                            <MenuItem value="user">Lab user</MenuItem>
                            <MenuItem value="pm">Project Manager</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          {isIE === false &&
                          (values.role === 'user' || values.role === 'pm') ? (
                            <FormControl
                              fullWidth
                              margin="normal"
                              error={
                                !!(errors.departments && touched.departments)
                              }
                            >
                              <InputLabel htmlFor="select-departments">
                                Departments
                              </InputLabel>
                              <Select
                                multiple
                                fullWidth
                                value={values.departments}
                                disabled={
                                  !isFormEditable || !departmentsList.length
                                }
                                onChange={(
                                  event: React.ChangeEvent<HTMLSelectElement>,
                                ) => handleChange(event)}
                                input={
                                  <Input
                                    name="departments"
                                    id="select-departments"
                                  />
                                }
                              >
                                {departmentsList.map((department, index) => (
                                  <MenuItem key={index} value={department}>
                                    {department}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.departments && (
                                <FormHelperText>
                                  {errors.departments}
                                </FormHelperText>
                              )}
                            </FormControl>
                          ) : (
                            <>
                              {isIE && (
                                <Typography
                                  variant="body1"
                                  style={{ paddingTop: 16 }}
                                >
                                  Departments assign not supported for IE 11
                                </Typography>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </div>
                )}

                {canSaveUser && (
                  <>
                    <div style={{ flex: 1 }} />
                    <section className={classes.bottomActions}>
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          {canRemoveUser && selectedUser && !pending && (
                            <MuiButton
                              disabled={saving}
                              onClick={this.openRemovalDialog}
                              className={classes.removeUserButton}
                            >
                              <DeleteIcon fontSize="small" /> Remove From Lab
                            </MuiButton>
                          )}
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="center" spacing={24}>
                            <Grid item>
                              <MuiButton
                                tabIndex={-1}
                                disabled={saving}
                                onClick={onCancel}
                              >
                                Cancel
                              </MuiButton>
                            </Grid>
                            <Grid item>
                              <Button
                                disabled={this.injected.usersStore.saving}
                                type="submit"
                                width={170}
                              >
                                {this.getButtonText()}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </section>
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default withStyles(styles)(UserEdit);
