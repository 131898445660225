import {
  supportedImagesExtensions,
  supportedTextExtensions,
} from './fileTypesSupport';
import { fileReaderPromise } from 'utils/fileReaderPromiseWrapper';
import { FileToDisplay } from './model';
import { getFileExtension } from 'utils/directoryUtils';

export async function getFileToDisplay(
  file: Common.File,
  blob: Blob,
): Promise<FileToDisplay> {
  const fileExtension = getFileExtension(file);
  let data: string = '';

  if (supportedTextExtensions.includes(fileExtension)) {
    data = await fileReaderPromise(blob);
  } else if (supportedImagesExtensions.includes(fileExtension)) {
    data = URL.createObjectURL(blob);
  }

  return {
    data,
    name: file.path,
    type: fileExtension,
  };
}
