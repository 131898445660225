import React, { memo, useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import withDrag from '../drag/withDrag';

import BeamHardeningRadiusControl from './beamHardeningSelector/BeamHardeningRadiusControl';

import { XY_SLICE_WIDTH_SCREEN_PX } from '../../../../../utils/sizingConstants';
import { CTViewerStore, CTImage } from '../../../../../stores/ctViewerStore';

//#region Styles
const styles = () =>
  createStyles({
    root: {
      position: 'absolute',
      borderRadius: '50%',
      border: '1px solid rgb(30, 30, 255)',
      background: 'rgba(30, 30, 255, 0.2)',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
  image: CTImage;
  registerOnDrag: any;
  registerSetDragInitialState: any;
  onMouseDown: ((e: React.MouseEvent) => void) | undefined;
}
//#endregion

const BeamHardeningSelector: React.FunctionComponent<Props> = ({
  ctViewerStore,
  registerOnDrag,
  registerSetDragInitialState,
  onMouseDown,
  image,
  classes,
}) => {
  const newPosition = useRef<any>();

  const handleMouseMove = (
    unscaledXDiff: number,
    unscaledYDiff: number,
    initialState: any,
  ) => {
    const { initialCenterX, initialCenterY } = initialState;
    const scaling = XY_SLICE_WIDTH_SCREEN_PX / ctViewerStore!.axialImage!.width;

    let newX = initialCenterX + unscaledXDiff / scaling;
    let newY = initialCenterY + unscaledYDiff / scaling;

    newX = Math.round(newX);
    newY = Math.round(newY);

    newX = Math.max(newX, 0);
    newY = Math.max(newY, 0);

    newX = Math.min(newX, ctViewerStore!.axialImage!.width);
    newY = Math.min(newY, ctViewerStore!.axialImage!.height);

    ctViewerStore!.setBeamHardeningX(newX);
    ctViewerStore!.setBeamHardeningY(newY);
  };

  const handleChangeRadius = (newRadius: number) => {
    ctViewerStore!.setBeamHardeningRadius(newRadius);
  };

  const setDragInitialState = () => newPosition.current;

  useEffect(() => {
    registerOnDrag(handleMouseMove);
    registerSetDragInitialState(setDragInitialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    newPosition.current = {
      initialCenterX: ctViewerStore!.beamHardeningX,
      initialCenterY: ctViewerStore!.beamHardeningY,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  if (!ctViewerStore || !image) {
    return null;
  }

  const {
    beamHardeningX,
    beamHardeningY,
    beamHardeningRadius,
  } = ctViewerStore!;

  if (beamHardeningX === undefined) return null;
  if (beamHardeningY === undefined) return null;
  if (beamHardeningRadius === undefined) return null;

  const maxRadius = Math.round(Math.min(image.width, image.height) / 2);
  const scaling = XY_SLICE_WIDTH_SCREEN_PX / image.width;

  const circleStyle = {
    left: `${(beamHardeningX - beamHardeningRadius) * scaling}px`,
    top: `${(beamHardeningY - beamHardeningRadius) * scaling}px`,
    width: `${beamHardeningRadius * scaling * 2}px`,
    height: `${beamHardeningRadius * scaling * 2}px`,
  };

  return (
    <div className={classes.root} onMouseDown={onMouseDown} style={circleStyle}>
      <BeamHardeningRadiusControl
        scaling={scaling}
        radius={beamHardeningRadius}
        onChange={handleChangeRadius}
        maxRadius={maxRadius}
      />
    </div>
  );
};

export default memo(
  withStyles(styles)(
    withDrag(inject('ctViewerStore')(observer(BeamHardeningSelector))),
  ),
);
