import React, { memo } from 'react';
import { inject, observer } from 'mobx-react';

import CustomSliceHolder from './customSliceHolders/CustomSliceHolder';

import { CTViewerStore } from '../../../../../stores/ctViewerStore';

//#region Types
interface Props {
  maxX: number;
  maxY: number;
  scaling: number;
  ctViewerStore?: CTViewerStore;
}
//#endregion

const CustomSliceHolders: React.FunctionComponent<Props> = ({
  maxX,
  maxY,
  scaling,
  ctViewerStore,
}) => {
  if (
    typeof maxX === 'undefined' ||
    typeof maxY === 'undefined' ||
    typeof scaling === 'undefined'
  ) {
    return null;
  }

  const onDragHolder = (newValues: any) => {
    if (
      newValues.x1 < 0 ||
      newValues.x2 < 0 ||
      newValues.y1 < 0 ||
      newValues.y2 < 0
    )
      return;
    if (newValues.x1 > maxX || newValues.x2 > maxX) return;
    if (newValues.y1 > maxY || newValues.y2 > maxY) return;

    const x1 = Math.round(newValues.x1);
    const y1 = Math.round(newValues.y1);
    const x2 = Math.round(newValues.x2);
    const y2 = Math.round(newValues.y2);

    ctViewerStore!.singleSetCustomSlice({ x1, y1, x2, y2 });
  };

  return (
    <>
      <CustomSliceHolder
        customSlicePosition={ctViewerStore!.customSlice}
        position="x1"
        scaling={scaling}
        onDragHolder={onDragHolder}
      />
      <CustomSliceHolder
        customSlicePosition={ctViewerStore!.customSlice}
        position="center"
        scaling={scaling}
        onDragHolder={onDragHolder}
      />
      <CustomSliceHolder
        customSlicePosition={ctViewerStore!.customSlice}
        position="x2"
        scaling={scaling}
        onDragHolder={onDragHolder}
      />
    </>
  );
};

export default memo(inject('ctViewerStore')(observer(CustomSliceHolders)));
