import React, { memo } from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RetryUploadIcon from '@material-ui/icons/Replay';
import ErrorIcon from '@material-ui/icons/Error';
import FolderIcon from '@material-ui/icons/Folder';
import IconButton from '@material-ui/core/IconButton';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import classNames from 'classnames';

import { styles } from './styles';

interface Props extends WithStyles<typeof styles> {
  finished: boolean;
  datasetName: string;
  cancelled?: boolean;
  failed?: boolean;
  icon?: any;
  onCancel?: () => void;
  onRetry?: () => void;
}

const FileProcessStatus: React.FunctionComponent<Props> = ({
  finished,
  datasetName,
  failed,
  cancelled,
  icon,
  onCancel,
  onRetry,
  classes,
  children,
}) => {
  return (
    <div
      className={classNames(classes.uploadInfo, {
        [classes.cancelled]: cancelled,
        [classes.canCancel]: onCancel,
      })}
    >
      <Grid container alignItems="center">
        <Grid item xs>
          <div className={classes.item}>
            <div className={classes.fileElement}>
              <div className={classes.fileTypeIcon}>
                {icon ? (
                  <>{icon}</>
                ) : datasetName ? (
                  <FolderIcon className={classes.folderIcon} />
                ) : (
                  <DescriptionIcon className={classes.fileIcon} />
                )}
              </div>

              <Typography className={classes.fileName}>
                {datasetName || 'Files'}
              </Typography>
              <Typography className={classes.uploadedFiles}>
                {children}
              </Typography>
            </div>
            <div className={classes.statusContainer}>
              {failed ? (
                <>
                  {onRetry && (
                    <IconButton
                      classes={{ root: classes.reUploadButton }}
                      onClick={onRetry}
                    >
                      <RetryUploadIcon classes={{ root: classes.icon }} />
                    </IconButton>
                  )}
                  {onCancel && (
                    <IconButton
                      classes={{ root: classes.cancelButton }}
                      onClick={onCancel}
                    >
                      <CancelOutlinedIcon classes={{ root: classes.icon }} />
                    </IconButton>
                  )}
                  <ErrorIcon classes={{ root: classes.errorIcon }} />
                </>
              ) : finished ? (
                <CheckCircleIcon classes={{ root: classes.markIcon }} />
              ) : (
                <>
                  {onCancel && (
                    <IconButton
                      classes={{ root: classes.cancelButton }}
                      onClick={onCancel}
                    >
                      <CancelOutlinedIcon classes={{ root: classes.icon }} />
                    </IconButton>
                  )}
                  <div className={classes.progressContainer}>
                    <CircularProgress disableShrink size={16} thickness={6} />
                  </div>
                </>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(withStyles(styles)(FileProcessStatus));
