import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';

import NavLink from './NavLink';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 100,
    },
    menuContainer: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      display: 'block',
      position: 'relative',
      top: '-1px',
    },
    menuItem: {
      display: 'inline-block',
      position: 'relative',
    },
    menuLinkText: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(65),
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  isAuthorized?: boolean;
}
//#endregion

const Navigation: React.FunctionComponent<Props> = ({
  classes,
  isAuthorized,
}) => (
  <nav className={classes.root}>
    <ul className={classes.menuContainer}>
      <li className={classes.menuItem}>
        <NavLink to="/labs">
          <Typography className={classes.menuLinkText} color="inherit">
            Dashboard
          </Typography>
        </NavLink>
      </li>
      {isAuthorized && (
        <li className={classes.menuItem}>
          <NavLink to="/users">
            <Typography className={classes.menuLinkText} color="inherit">
              Users
            </Typography>
          </NavLink>
        </li>
      )}
    </ul>
  </nav>
);

export default withStyles(styles)(Navigation);
