import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Typography, CircularProgress } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import {
  isQueryMatchUser,
  GroupObject,
  GroupObjectUser,
  sortGroupByCreator,
} from '../Groups';
import { GroupsStore } from '../../../stores/groupsStore';
import { AppStore } from './../../../stores/appStore';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: 6,
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 15,
      minHeight: 150,
      transition: '0.4s',
      width: '100%',
    },
    name: {
      borderBottom: `1px solid ${theme.palette.primary.light}`,
      padding: '10px 20px',
      fontSize: theme.typography.pxToRem(18),
    },
    groupNameHeader: {
      fontWeight: 'bold',
    },
    userRow: {
      padding: '10px 20px',
    },
    userTitle: {
      color: '#ccc',
      fontWeight: 100,
    },
    username: {
      fontSize: 17,
      fontWeight: 100,
    },
    removeEmailIcon: {
      cursor: 'pointer',
      fontSize: 16,
      float: 'right',
      marginTop: 5,

      '&:hover': {
        color: theme.palette.primary.main,
      },

      '&:active': {
        color: theme.palette.primary.light,
      },
    },
    editIcon: {
      float: 'right',
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.primary.main,
      },

      '&:active': {
        color: theme.palette.primary.light,
      },
    },
    activeGroup: {
      backgroundColor: '#566063',
      boxShadow: '0px 0px 10px 3px rgba(181,179,181,1)',
      color: 'white !important',
      transition: '0.4s',

      '& $name': {
        borderColor: 'white',
      },

      '& $groupNameHeader': {
        color: 'white !important',
      },

      '& $username': {
        color: 'white !important',
      },

      '& $removeEmailIcon': {
        color: 'white !important',
      },
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  active: boolean;
  query: string;
  labName: string;
  group: GroupObject;
  editable: boolean;
  onClick: () => void;
  onEdit: (event: any) => void;
}

interface InjectedProps extends Props {
  groupsStore: GroupsStore;
  appStore: AppStore;
}

//#endregion

@inject('groupsStore', 'appStore')
@observer
class Groups extends Component<Props> {
  state = {
    removingUserId: -1,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  setRemovingUserId = (id: number) => {
    this.setState({ removingUserId: id });
  };

  onRemove = async (event: any, user: GroupObjectUser) => {
    event.stopPropagation();

    const { groupsStore, appStore } = this.injected;
    const { group, labName } = this.props;

    const prefixedGroupName =
      group.group_type === 'department'
        ? group.name
        : `${labName}:${group.name}`;

    this.setRemovingUserId(user.id);

    try {
      await groupsStore.removeUser(user.username, prefixedGroupName);
      group.users = group.users.filter(
        (u: GroupObjectUser) => u.id !== user.id,
      );
    } catch (error) {
      appStore.showErrorToaster();
    }

    this.setRemovingUserId(-1);
  };

  render() {
    const {
      classes,
      query,
      group,
      active,
      editable,
      onEdit,
      onClick,
    } = this.props;
    const { removingUserId } = this.state;
    const groupName =
      group.group_type === 'department' ? group.department_name : group.name;

    const sortedGroup = sortGroupByCreator(
      group.users.filter((user: GroupObjectUser) =>
        isQueryMatchUser(user, query),
      ),
      group.creator,
    );

    return (
      <div
        className={classNames(classes.root, { [classes.activeGroup]: active })}
        onClick={onClick}
      >
        <div className={classes.name}>
          <Typography className={classes.groupNameHeader}>
            {groupName}
            {editable && (
              <PersonAddIcon className={classes.editIcon} onClick={onEdit} />
            )}
          </Typography>
        </div>

        {sortedGroup.map((user: GroupObjectUser) => (
          <div className={classes.userRow} key={user.id}>
            {user.email === group.creator && (
              <Typography className={classes.userTitle} variant="subtitle2">
                Group Owner
              </Typography>
            )}
            <Typography className={classes.username} variant="h6">
              {user.full_name}

              {removingUserId === user.id && (
                <CircularProgress
                  className={classes.removeEmailIcon}
                  size={18}
                  thickness={5}
                />
              )}

              {removingUserId !== user.id && editable && (
                <CancelIcon
                  className={classes.removeEmailIcon}
                  onClick={event => this.onRemove(event, user)}
                />
              )}
            </Typography>
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(Groups);
