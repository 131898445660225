import { action, computed, observable } from 'mobx';
import NProgress from 'nprogress';

import getFileStream, { downloadURI, getStream } from 'utils/downloadFile';
import { isFile } from 'utils/directoryUtils';
import httpClient from 'utils/api/httpClient';

export interface CompressFileProcess extends CompressFileMeta {
  paths: string[];
  interval?: number;
}

export interface CompressFileMeta {
  id: number;
  progress: string;
  status: CompressFileStatus;
  ttl: string;
  url: string;
  error: string | null;
}
export enum CompressFileStatus {
  Initialized = 'initialized',
  Preparing = 'preparing',
  Ready = 'ready',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

export class DownloadStore {
  private pollInterval = 5000;
  private endpoints = {
    downloadFile: 'api/v1/resources/download',
    compressFiles: 'api/v1/resources/files/downloads',
  };

  @observable
  showCompressionPopup: boolean = false;

  @observable
  limitExceed: boolean = false;

  @observable
  cancelling: boolean = false;

  @observable
  compressedFiles: Map<number, CompressFileProcess> = new Map();

  @computed
  get compressFilesArray() {
    return [...this.compressedFiles.values()].reverse();
  }

  @computed
  get compressFilesAmount() {
    return this.compressFilesArray.length;
  }

  @computed
  get compressingFiles() {
    return this.compressFilesArray.filter(
      file =>
        file.status === CompressFileStatus.Initialized ||
        file.status === CompressFileStatus.Preparing,
    );
  }
  @computed
  get compressingFilesAmount() {
    return this.compressingFiles.length;
  }

  @computed
  get compressionInProgress() {
    return !!this.compressingFilesAmount;
  }

  @computed
  get compressProgress() {
    const total = this.compressFilesAmount * 100;
    const sum = this.compressFilesArray.reduce((curr, acc) => {
      let progress = parseInt(acc.progress, 10);
      const isReady = acc.status === CompressFileStatus.Ready;
      if (progress === 100 && !isReady) {
        progress = 99;
      }
      return curr + progress;
    }, 0);

    return total && Math.floor((sum / total) * 100);
  }

  @computed
  get compressionDone() {
    const allReady = !this.compressFilesArray.find(
      file => file.status !== CompressFileStatus.Ready,
    );

    return this.compressProgress === 100 && allReady;
  }

  @action
  download = async (entities: Common.File[]) => {
    const singleFile = entities[0];

    if (entities.length === 1 && isFile(singleFile)) {
      await this.streamFile(singleFile);
    } else {
      const paths = entities.map(file => file.absolute_path);
      await this.compressFiles(paths);
    }
  };

  @action
  compressFiles = async (paths: string[]) => {
    try {
      const { data } = await httpClient.post(this.endpoints.compressFiles, {
        paths,
      });
      const compressionId = data.download_id;

      this.pollCompressFile(compressionId, paths);
    } catch (e) {
      if (e.status === 429) {
        this.setLimitExceed(true);
      }
    }
  };

  @action
  pollCompressFile = async (compressionId: number, paths: string[]) => {
    const endpoint = `${this.endpoints.compressFiles}/${compressionId}`;
    const { data } = await this.getCompressionStatus(endpoint);
    this.showCompressionPopup = true;
    this.compressedFiles.set(compressionId, { ...data, paths });

    const interval = window.setInterval(async () => {
      if (
        this.compressedFiles.has(compressionId) &&
        this.showCompressionPopup
      ) {
        const { data } = await this.getCompressionStatus(endpoint);
        this.compressedFiles.set(compressionId, {
          ...data,
          interval,
          paths,
        });

        if (data.status === CompressFileStatus.Cancelled) {
          this.cancelCompression(compressionId);
        }

        if (data.status === CompressFileStatus.Ready) {
          downloadURI(data.url);
        }

        if (
          data.status === CompressFileStatus.Failed ||
          data.status === CompressFileStatus.Ready
        ) {
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, this.pollInterval);
  };

  @action
  getCompressionStatus = (endpoint: string) => {
    return httpClient.get<CompressFileMeta>(endpoint);
  };

  @action
  async streamFile(file: Common.File) {
    const fileName = file.path || 'file';
    const encodeUri = encodeURIComponent(file.uri);
    const streamUrl = `${this.endpoints.downloadFile}?path=${encodeUri}`;

    this.stream(streamUrl, fileName);
  }

  async stream(url: string, fileName: string) {
    NProgress.start();
    const streamUrl = `${process.env.REACT_APP_BASE_URL}/${url}`;
    const response = await getStream(streamUrl);
    NProgress.done();
    await getFileStream(response, fileName);
  }

  async downloadFile({ size, uri: path }: Common.File) {
    const onDownloadProgress = (progress: any) =>
      NProgress.set(progress.loaded / (progress.total || size));
    const params = {
      responseType: 'blob',
      onDownloadProgress,
      params: { path },
    };

    return httpClient
      .get(this.endpoints.downloadFile, params)
      .then(response => {
        const fileType = { type: response.headers['content-type'] };
        return new Blob([response.data], fileType);
      });
  }

  @action
  retryCompression = async (id: number) => {
    const file = this.compressedFiles.get(id);

    if (file) {
      const paths = file.paths;

      const { data } = await httpClient.post(this.endpoints.compressFiles, {
        paths,
      });
      this.compressedFiles.delete(id);
      const compressionId = data.download_id;

      this.pollCompressFile(compressionId, paths);
    }
  };

  @action
  cancelCompression = async (id: number) => {
    const file = this.compressedFiles.get(id);

    if (file) {
      clearInterval(file.interval);
      file.status = CompressFileStatus.Cancelled;
      await httpClient.delete(`${this.endpoints.compressFiles}/${id}`);
      this.compressedFiles.delete(id);
    }

    if (this.compressedFiles.size === 0) {
      this.showCompressionPopup = false;
    }
  };

  @action
  closeCompression = async () => {
    this.cancelling = true;

    for (const file of this.compressFilesArray) {
      await this.cancelCompression(file.id);
    }

    this.showCompressionPopup = false;
    this.cancelling = false;
    this.compressedFiles.clear();
  };

  @action
  setLimitExceed = (value: boolean) => {
    this.limitExceed = value;
  };
}

export default new DownloadStore();
