// Polyfills
import 'ts-polyfill';
import 'url-search-params-polyfill';

import * as React from 'react';
import ReactDOM from 'react-dom';
import NProgress from 'nprogress';
// import { configure } from 'mobx';

import * as serviceWorker from './serviceWorker';
import App from './App';

// Nprogress config
NProgress.configure({ showSpinner: false });

// configure({ enforceActions: 'observed' });

ReactDOM.render(<App />, document.querySelector('#root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
