import { observable, action, computed, flow, toJS, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';

import httpClient from '../utils/api/httpClient';
import appStore from './appStore';

export interface LoginPayLoad {
  email: string;
  password: string;
}

export interface SetPasswordPayload {
  email: string;
  token: string;
  password: string;
}

export class AuthStore {
  @observable user: Common.User = {};
  @observable isLoading: boolean = false;
  @observable hasError: boolean = false;
  @observable basicAuthToken: string = '';
  @observable passwordResetSent: boolean = false;
  @observable passwordResetInProgress: boolean = false;
  @observable isInvited: boolean = false;

  private ENDPOINTS = {
    auth: {
      token: 'oauth/token',
      revoke: 'oauth/revoke',
      passwordReset: '/api/v1/password_resets',
      invitationExist: '/api/v1/invitations/existance_checks',
    },
  };

  constructor() {
    const lsUser = localStorage.getItem('user');
    const user = lsUser ? JSON.parse(lsUser) : null;

    if (user) {
      this.setUserData(
        user.token,
        user.role,
        user.email,
        user.basicAuthToken,
        user.fullName,
        user.lab_admin_for,
        user.lab_admin_labs_full,
        user.pm_for_labs,
        user.client,
      );
    }
  }

  @computed
  get userData() {
    return toJS(this.user);
  }

  @computed
  get isLoggedIn() {
    return !!this.user.token;
  }

  @computed
  get token() {
    return this.user.token || '';
  }

  @computed
  get isAdmin() {
    if (this.user.role) {
      return this.user.role >= 2;
    }

    return false;
  }

  @computed
  get isLabAdmin() {
    return !!(this.user.lab_admin_for || []).length;
  }

  @computed
  get userLabs() {
    return toJS(this.user.lab_admin_labs_full);
  }

  @action
  login = ({ email, password }: LoginPayLoad) => {
    this.isLoading = true;
    this.hasError = false;

    const formData = new FormData();

    formData.append('grant_type', 'password');
    formData.append('email', email);
    formData.append('password', password);

    const basicAuthToken = btoa(`user:LRhuq3D4LmKz`);

    return httpClient
      .post(this.ENDPOINTS.auth.token, formData)
      .then(
        (response: AxiosResponse) =>
          this.setUserData(
            response.data.access_token,
            response.data.role,
            email,
            basicAuthToken,
            response.data.full_name,
            response.data.lab_admin_for,
            response.data.lab_admin_labs_full,
            response.data.pm_for_labs,
            response.data.client,
          ),
        this.onError,
      );
  };

  @action
  logOut = async (callback?: Function) => {
    if (this.user && this.user.token) {
      const token = this.user.token;
      await httpClient.post(this.ENDPOINTS.auth.revoke, { token });
      callback && callback();
    }

    this.user = {};
    localStorage.removeItem('user');
  };

  @action
  invitationExist = async (token: string) => {
    try {
      await httpClient.get(`${this.ENDPOINTS.auth.invitationExist}/${token}`);
      runInAction(() => (this.isInvited = true));
    } catch (e) {
      appStore.showErrorToaster('The invitation is no longer active');
      throw e;
    }
  };

  @action
  setUserData = (
    access_token: string,
    role: number,
    email: string,
    basicAuthToken: string,
    fullName: string,
    lab_admin_for: string[],
    lab_admin_labs_full: string[],
    pm_for_labs: string[],
    client: boolean,
  ) => {
    this.isLoading = false;
    this.hasError = false;

    const userData: Common.User = {
      role,
      email,
      token: access_token,
      basicAuthToken,
      fullName,
      lab_admin_for,
      lab_admin_labs_full,
      pm_for_labs,
      client,
    };

    localStorage.setItem('user', JSON.stringify(userData));

    this.user = userData;
  };

  @action
  onError = () => {
    this.isLoading = false;
    this.hasError = true;
  };

  @action
  clearError = () => {
    this.hasError = false;
  };

  sendPasswordRest = flow(function*(this: AuthStore, email: string) {
    this.passwordResetInProgress = true;

    try {
      yield httpClient.post(this.ENDPOINTS.auth.passwordReset, {
        email,
      });

      this.passwordResetSent = true;
      this.passwordResetInProgress = false;
    } catch ({ data, status }) {
      if (status === 422 && data.message === 'User not found') {
        appStore.showErrorToaster(
          'You have to accept invitation to Petricloud first and set up a password. Please check your mailbox or contact admin',
        );
      } else {
        appStore.showErrorToaster('Failed to send password reset link');
      }
      this.passwordResetInProgress = false;
    }
  });

  setNewPassword = flow(function*(
    this: AuthStore,
    payload: SetPasswordPayload,
  ) {
    this.passwordResetInProgress = true;

    try {
      yield httpClient.put(this.ENDPOINTS.auth.passwordReset, {
        ...payload,
      });

      this.login({
        email: payload.email,
        password: payload.password,
      });

      this.passwordResetInProgress = false;
    } catch {
      appStore.showErrorToaster('Failed to set new password');
      this.passwordResetInProgress = false;
    }
  });
}

export default new AuthStore();
