import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';

import Button from '../Button';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    button: {
      marginTop: 40,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  presentFiles: Common.File[];
  inProgress: boolean;
  onCreateFolder: (values: { folderName: string }) => void;
}
//#endregion

const AddFolder: React.FunctionComponent<Props> = ({
  classes,
  presentFiles,
  inProgress,
  onCreateFolder,
}) => {
  const fileNames = presentFiles.map((file: Common.File) =>
    file.path
      .split('/')
      .pop()!
      .trim()
      .toLowerCase(),
  );

  return (
    <Formik
      initialValues={{
        folderName: '',
      }}
      onSubmit={values => {
        onCreateFolder(values);
      }}
      validationSchema={Yup.object().shape({
        folderName: Yup.string()
          .trim()
          .required('Field is required')
          .matches(/^[0-9a-zA-Z -]+$/, 'Name contains invalid characters')
          .lowercase()
          .notOneOf(fileNames, 'File / folder already exist'),
      })}
    >
      {({ values, handleChange, handleSubmit, errors }) => (
        <div className={classes.root}>
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              id="folderName"
              name="folderName"
              autoFocus
              required
              margin="normal"
              fullWidth
              label="Folder Name"
              error={!!errors.folderName}
              helperText={errors.folderName || ''}
              onChange={handleChange}
              value={values.folderName}
            />

            <Button
              type="submit"
              disabled={inProgress}
              className={classes.button}
            >
              {inProgress ? 'Adding folder...' : 'Add folder'}
            </Button>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default withStyles(styles)(AddFolder);
