import convertToHexElement from './convertToHexElement';
import extractGradientTable from './extractGradientTable';

const convertToLinearGradient = (name: string) => {
  const table = extractGradientTable(name);
  if (table.length === 0) return;

  const joinedEntries = table
    .map((color: any) => {
      const r = convertToHexElement(color[0]);
      const g = convertToHexElement(color[1]);
      const b = convertToHexElement(color[2]);
      return `#${r}${g}${b}`;
    })
    .join(', ');

  return `linear-gradient(90deg, ${joinedEntries})`;
};

export default convertToLinearGradient;
