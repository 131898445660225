import React, { useState, useEffect, memo } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import io from 'socket.io-client';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { CTViewerStore } from '../../../../stores/ctViewerStore';
import { AuthStore } from '../../../../stores/authStore';
import SingleViewer from './SingleViewer';
import GeneratedResults from './singleViewer/GeneratedResults';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    rootBox: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      overflowY: 'auto',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      padding: '32px 20px',
      '-webkit-overflow-scrolling': 'touch',
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      paddingTop: 100,
    },
    processing: {
      marginTop: '20px',
      borderRadius: '6px',
      padding: '4px 14px',
      background: '#e8e8e8',
    },
    processingText: {
      fontSize: '1rem',
    },
    processingError: {
      background: '#da6060',
    },
    processingTextError: {
      color: '#fff',
    },
    button: {
      marginTop: 12,
    },
    resultsButtonBox: {
      width: '95%',
      margin: '0 auto',
    },
    resultsButton: {
      marginBottom: 10,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
  authStore?: AuthStore;
}

interface ProcessingProps extends WithStyles<typeof styles> {}

interface ProcessingErrorProps extends WithStyles<typeof styles> {
  tryAgainHandler: () => void;
}
//#endregion

const ProcessingView = withStyles(styles)((props: ProcessingProps) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.loadingContainer}>
        <CircularProgress size={50} />
        <div className={classes.processing}>
          <Typography
            variant="subtitle1"
            component="strong"
            className={classes.processingText}
          >
            Processing...
          </Typography>
        </div>
      </div>
    </div>
  );
});

const ProcessingErrorView = withStyles(styles)(
  (props: ProcessingErrorProps) => {
    const { classes, tryAgainHandler } = props;
    return (
      <div className={classes.root}>
        <div className={classes.loadingContainer}>
          <div
            className={classNames(classes.processing, classes.processingError)}
          >
            <Typography
              variant="subtitle1"
              component="strong"
              className={classNames(
                classes.processingText,
                classes.processingTextError,
              )}
            >
              We are facing an issue with processing assets
            </Typography>
          </div>
          <Button
            onClick={tryAgainHandler}
            color="primary"
            classes={{ root: classes.button }}
          >
            Try again
          </Button>
        </div>
      </div>
    );
  },
);

const SingleViewerWrapper: React.FunctionComponent<Props> = ({
  classes,
  ctViewerStore,
  authStore,
}) => {
  const { user } = authStore!;
  const [socketConnected, setSocketConnected] = useState<boolean>(false);
  const [slicesGenerated, setSlicesGenerated] = useState<boolean>(false);

  const getAssets = async () => {
    if (ctViewerStore && !ctViewerStore.generatingInProgress) {
      ctViewerStore.generateAssets();
    }
  };

  const tryAgainHandler = () => {
    getAssets();
  };

  const goToResults = () => {
    ctViewerStore!.toggleResults();
  };

  useEffect(() => {
    const uri = process.env.REACT_APP_SINGLE_VIEWER_MICROSERVICE_URI || '';
    const socket = io(uri, {
      transports: ['websocket'],
    });

    ctViewerStore!.setSocket(socket);

    socket.on('connect', () => {
      console.log('Microservice connected');
      socket.emit('joinRoomRequest', user.email);
      setSocketConnected(true);
      ctViewerStore!.setServiceConnection(true);
    });

    socket.on('convertSlicesSuccess', (data: any) => {
      console.log('Microservice: Assets generated successfully!');
      ctViewerStore!.finishGeneratingInProgress(data);
      setSlicesGenerated(true);
    });

    socket.on('convertSlicesError', () => {
      console.log('Microservice: Failed to generate assets!');
      ctViewerStore!.stopGeneratingSlices();
      ctViewerStore!.setServiceConnection(false);
      ctViewerStore!.setProcessingError(true);
    });

    socket.on(
      'singleEnergyCalculationProgress',
      (progress: number, totalNumber: number) => {
        console.log(
          `Microservice: calculation progress: ${progress}, total: ${totalNumber}`,
        );
        const progressValue = Math.round((progress / totalNumber) * 100);

        if (progressValue < 100) {
          if (!ctViewerStore!.processSingleViewerLoading) {
            ctViewerStore!.setSingleViewerDataLoader(true);
          }
          ctViewerStore!.setSingleViewerDataProgress(progressValue);
        }
      },
    );

    socket.on(
      'singleEnergyCalculationComplete',
      (path: string, uuid: string) => {
        console.log('Microservice: SingleViewer calculation complete', path);
        ctViewerStore!.setSingleViewerDataLoader(false);
        ctViewerStore!.setSingleViewerResultsId(uuid);
        ctViewerStore!.getRawResultsSingle();
      },
    );

    socket.on('singleEnergyCalculationError', (error: any) => {
      console.log(
        'Microservice: SingleViewer calculation error',
        JSON.stringify(error),
      );
      ctViewerStore!.setSingleViewerDataLoader(false);
      ctViewerStore!.showSingleViewerCalculationError(
        'Single Viewer calculation error',
      );
    });

    socket.on('disconnect', (reason: any) => {
      console.log('Microservice disconnected: ', reason);
      ctViewerStore!.setServiceConnection(false);
    });

    socket.on('error', (error: any) => {
      console.log('Microservice error: ', error);
      ctViewerStore!.setServiceConnection(false);
    });

    socket.on('connect_error', (error: any) => {
      console.log('Microservice connection error: ', error);
      ctViewerStore!.setServiceConnection(false);
    });

    socket.on('connect_timeout', (error: any) => {
      console.log('Microservice connection timeout error: ', error);
      ctViewerStore!.setServiceConnection(false);
    });

    socket.on('reconnect_error', (error: any) => {
      console.log('Microservice reconnection error: ', error);
      ctViewerStore!.setServiceConnection(false);
    });

    socket.on('reconnect_failed', (error: any) => {
      console.log('Microservice reconnection failed error: ', error);
      ctViewerStore!.setServiceConnection(false);
    });

    return () => {
      ctViewerStore!.setServiceInitialData(false);
      ctViewerStore!.setServiceConnection(false);
      ctViewerStore!.setSocket(null);
      ctViewerStore!.clearData();
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socketConnected) {
      getAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected]);

  if (ctViewerStore && ctViewerStore.generatingInProgress) {
    return <ProcessingView />;
  }

  if (ctViewerStore && ctViewerStore.generatingError) {
    return <ProcessingErrorView tryAgainHandler={tryAgainHandler} />;
  }

  return (
    <>
      <div className={classNames(classes.root, classes.rootBox)}>
        {ctViewerStore!.singleViewerResults && (
          <div className={classes.resultsButtonBox}>
            <Button
              onClick={goToResults}
              classes={{ root: classes.resultsButton }}
            >
              Back to results >>
            </Button>
          </div>
        )}
        <SingleViewer slicesGenerated={slicesGenerated} />
      </div>
      {ctViewerStore!.singleViewerResults && <GeneratedResults />}
    </>
  );
};

export default memo(
  withStyles(styles)(
    inject('ctViewerStore', 'authStore')(observer(SingleViewerWrapper)),
  ),
);
