import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';

import Button from '../../components/ui/Button';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginBottom: 40,
      paddingTop: 10,
    },
    submitButton: {
      marginTop: 40,
    },
    linkSent: {
      padding: '30px 0',
      color: '#69C3AC',
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  isLoading: boolean;
  onSend: (email: string) => void;
}
//#endregion

const Step2: React.FunctionComponent<Props> = ({
  classes,
  isLoading,
  onSend,
}) => {
  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirm: '',
      }}
      onSubmit={values => {
        onSend(values.password);
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(6, 'Minimum password length is 6')
          .required('Password is required'),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Password confirm is required'),
      })}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <TextField
            disabled={isLoading}
            required
            fullWidth
            margin="normal"
            label="Password"
            id="password"
            type="password"
            error={!!(errors.password && touched.password)}
            helperText={
              errors.password && touched.password ? errors.password : ''
            }
            onChange={handleChange}
            value={values.password}
          />

          <TextField
            disabled={isLoading}
            required
            fullWidth
            margin="normal"
            label="Re-type password"
            id="passwordConfirm"
            type="password"
            error={!!(errors.passwordConfirm && touched.passwordConfirm)}
            helperText={
              errors.passwordConfirm && touched.passwordConfirm
                ? errors.passwordConfirm
                : ''
            }
            onChange={handleChange}
            value={values.passwordConfirm}
          />

          <Button
            type="submit"
            disabled={isLoading}
            className={classes.submitButton}
            width="100%"
          >
            {isLoading ? 'Setting...' : 'Set'}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(Step2);
