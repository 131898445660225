import {
  leftMarkerWidth,
  orientationMarkerHeight,
  markerTextOffset,
} from './spacings';

export default (
  context: CanvasRenderingContext2D,
  footerVerticalOffset: number,
  imageWidth: number,
) => {
  const spacingBetweenLines = imageWidth / 4;

  const markerSpecs = [
    { x: 0, label: '180' }, // b
    { x: spacingBetweenLines, label: '270' }, // r
    { x: spacingBetweenLines * 2, label: '0' }, // t
    { x: spacingBetweenLines * 3, label: '90' }, // l
    { x: spacingBetweenLines * 4, label: '180', textOnLeftSide: true }, // b
  ];

  markerSpecs.forEach(spec => {
    context.strokeStyle = '#fff';
    context.lineWidth = 1;

    context.beginPath();
    context.moveTo(spec.x + leftMarkerWidth, footerVerticalOffset);
    context.lineTo(
      spec.x + leftMarkerWidth,
      footerVerticalOffset + orientationMarkerHeight,
    );
    context.stroke();

    context.textAlign = spec.textOnLeftSide ? 'right' : 'left';
    context.textBaseline = 'middle';
    context.fillStyle = '#fff';
    context.font = '16px';

    const x = spec.textOnLeftSide
      ? spec.x + leftMarkerWidth - markerTextOffset
      : spec.x + leftMarkerWidth + markerTextOffset;

    context.fillText(
      spec.label,
      x,
      footerVerticalOffset + orientationMarkerHeight / 2,
    );
  });
};
