import { GENERATED_SLICE_NUMBER } from './sizingConstants';

// Finds XZ index (Coronal) and ZY index (Sagittal)

function findViewerSliceImages(
  coronalWidth: number,
  cropCenterX: number,
  cropCenterY: number,
) {
  const globalWidth = coronalWidth;

  const xIndex = Math.round(
    (cropCenterX / globalWidth) * GENERATED_SLICE_NUMBER,
  );
  const yIndex = Math.round(
    (cropCenterY / globalWidth) * GENERATED_SLICE_NUMBER,
  );

  return { xIndex, yIndex };
}

export default findViewerSliceImages;
