import extractGradientTable from './extractGradientTable';
import convertSingleColorToHex from './convertSingleColorToHex';

const extractFirstLastGradientColor = (name: any, isLast: any) => {
  const table = extractGradientTable(name);
  if (table.length < 4) return isLast ? '#fff' : '#000';

  const colorAsArray = isLast ? table[table.length - 4] : table[0];
  return convertSingleColorToHex(colorAsArray);
};

export default extractFirstLastGradientColor;
