import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { styles } from './styles';

interface FileStatusFloatBoxProps extends WithStyles<typeof styles> {
  header: any;
  progress: number;
  aborted?: boolean;
  onCloseFloatBox: () => void;
}

const FileProcessPopup: React.FC<FileStatusFloatBoxProps> = ({
  aborted,
  header,
  progress,
  onCloseFloatBox,
  classes,
  children,
}) => {
  const [minimized, setMinimized] = useState<boolean>(false);

  const toggleMinimize = () => setMinimized(!minimized);

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootMinimized]: minimized,
      })}
    >
      <div className={classes.header}>
        <div className={classes.headerText}>
          <Typography
            variant="subtitle1"
            color="inherit"
            component="span"
            classes={{ root: classes.uploadingText }}
          >
            {header}
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <button className={classes.button} onClick={toggleMinimize}>
            {minimized ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </button>
          <button className={classes.button} onClick={onCloseFloatBox}>
            <CloseIcon fontSize="small" />
          </button>
        </div>
      </div>
      <LinearProgress
        variant="determinate"
        value={progress}
        classes={{ colorPrimary: classes.progress }}
      />
      <div className={classes.container}>
        {aborted && (
          <div className={classes.abortOverlay}>
            <Typography
              variant="subtitle1"
              color="inherit"
              component="span"
              classes={{ root: classes.abortingText }}
            >
              Canceling...
            </Typography>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default withStyles(styles)(FileProcessPopup);
