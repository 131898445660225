import React, { memo, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
//@ts-ignore
import ReactSlider from 'react-slider';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Typography from '@material-ui/core/Typography';

import { CTViewerStore, CTImage } from '../../../../stores/ctViewerStore';

//#region Styles
const styles = () =>
  createStyles({
    root: {
      marginTop: '10px',
    },
    slider: {
      width: '300px',
      height: '20px',
    },
    thumb: {
      position: 'relative',
      width: '12px',
      height: '17px',
      background: '#505f88',
      transform: 'translateY(-6px)',

      '&:focus': {
        outline: 'none',
      },

      '&-0, &-1': {
        '&:after': {
          content: '""',
          position: 'absolute',
          left: 0,
          width: 0,
          height: 0,
          bottom: '-6px',
          borderTop: '6px solid #505f88',
          borderLeft: '6px solid transparent',
          borderRight: '6px solid transparent',
        },
      },
    },
    track: {
      height: '10px',
      borderRadius: '5px',

      '&-0': {
        border: '1px solid #aaa',
        background: '#000',
      },
      '&-1': {
        border: '1px solid #aaa',
        background: 'linear-gradient(90deg, #000, #fff)',
      },
      '&-2': {
        border: '1px solid #aaa',
        background: '#fff',
      },
    },
    title: {
      display: 'block',
      marginBottom: 10,
    },
    inputBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    inputNumber: {
      fontSize: 16,
      textAlign: 'center',
      borderRadius: 7,
      border: '1px solid #dfe3ed',
      height: 33,
      minWidth: 60,
      maxWidth: 80,
      marginTop: '-7px',
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
}
//#endregion

const colorStep = 1;

const ContrastSlider: React.FunctionComponent<Props> = ({
  classes,
  ctViewerStore,
}) => {
  const [localMinValue, setLocalMinValue] = useState<string | undefined>(
    undefined,
  );
  const [localMaxValue, setLocalMaxValue] = useState<string | undefined>(
    undefined,
  );

  const roundToStep = (input: number) => {
    return Math.round(input / colorStep) * colorStep;
  };

  const handleChangeMinMax = (newMin: number, newMax: number) => {
    const newCenter = (newMin + newMax) / 2;
    const newWidth = newMax - newMin;

    ctViewerStore!.setViewportSettings({
      width: newWidth,
      center: newCenter,
    });
  };

  const handleChangeMin = (newMin: number) => {
    const { viewportSettings } = ctViewerStore!;
    const max = viewportSettings.center + viewportSettings.width / 2;
    if (newMin > max) {
      newMin = max;
    }
    newMin = roundToStep(newMin);

    handleChangeMinMax(newMin, max);
  };

  const handleChangeMax = (newMax: number) => {
    const { viewportSettings } = ctViewerStore!;
    const min = viewportSettings.center - viewportSettings.width / 2;
    if (newMax < min) {
      newMax = min;
    }
    newMax = roundToStep(newMax);

    handleChangeMinMax(min, newMax);
  };

  const handleChangeMinEv = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const isEmpty = !ev.target.value.trim();
    const isNaN = Number.isNaN(parseFloat(ev.target.value));

    if (!isEmpty && !isNaN) {
      handleChangeMin(parseFloat(ev.target.value));
      setLocalMinValue(undefined);
    } else {
      setLocalMinValue(ev.target.value);
    }
  };

  const handleChangeMaxEv = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const isEmpty = !ev.target.value.trim();
    const isNaN = Number.isNaN(parseFloat(ev.target.value));

    if (!isEmpty && !isNaN) {
      handleChangeMax(parseFloat(ev.target.value));
      setLocalMaxValue(undefined);
    } else {
      setLocalMaxValue(ev.target.value);
    }
  };

  const handleChangeMinMaxArray = (newValues: number[]) => {
    const { viewportSettings } = ctViewerStore!;
    const min = viewportSettings.center - viewportSettings.width / 2;
    const max = viewportSettings.center + viewportSettings.width / 2;

    if (newValues[0] !== min) {
      handleChangeMin(newValues[0]);
      setLocalMinValue(undefined);
    }
    if (newValues[1] !== max) {
      handleChangeMax(newValues[1]);
      setLocalMaxValue(undefined);
    }
  };

  const { axialImage, sagittalImage, coronalImage } = ctViewerStore!;

  useEffect(() => {
    if (
      !ctViewerStore!.brightnessRange &&
      axialImage &&
      sagittalImage &&
      coronalImage
    ) {
      let minBrightness = ctViewerStore!.axialImage!.minPixelValue!;
      let maxBrightness = ctViewerStore!.axialImage!.maxPixelValue!;

      [ctViewerStore!.sagittalImage, ctViewerStore!.coronalImage].forEach(
        (image: CTImage | null) => {
          if (image) {
            if (image.minPixelValue < minBrightness) {
              minBrightness = image.minPixelValue;
            }
            if (image.maxPixelValue > maxBrightness) {
              maxBrightness = image.maxPixelValue;
            }
          }
        },
      );

      ctViewerStore!.setBrightnessRange(minBrightness, maxBrightness);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axialImage, sagittalImage, coronalImage]);

  const { viewportSettings, brightnessRange } = ctViewerStore!;
  let minValue = null;
  let maxValue = null;

  if (!brightnessRange) return null;

  if (brightnessRange.min !== undefined) minValue = brightnessRange.min;
  if (brightnessRange.max !== undefined) maxValue = brightnessRange.max;

  if (minValue === null || maxValue === null) return null;

  const windowMin = viewportSettings.center - viewportSettings.width / 2;
  const windowMax = viewportSettings.center + viewportSettings.width / 2;

  return (
    <div className={classes.root}>
      <Typography
        variant="subtitle1"
        component="span"
        className={classes.title}
      >
        Adjust contrast
      </Typography>
      <ReactSlider
        min={minValue}
        max={maxValue}
        value={[windowMin, windowMax]}
        onChange={handleChangeMinMaxArray}
        withBars
        className={classes.slider}
        thumbClassName={classes.thumb}
        trackClassName={classes.track}
      />
      <div className={classes.inputBox}>
        <input
          type="number"
          min={minValue}
          max={maxValue}
          value={localMinValue !== undefined ? localMinValue : windowMin}
          step={colorStep}
          onChange={handleChangeMinEv}
          className={classes.inputNumber}
        />
        <input
          type="number"
          min={minValue}
          max={maxValue}
          value={localMaxValue !== undefined ? localMaxValue : windowMax}
          step={colorStep}
          onChange={handleChangeMaxEv}
          className={classes.inputNumber}
        />
      </div>
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(ContrastSlider))),
);
