import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';

import Button from '../../components/ui/Button';

//#region Styles
const styles = () =>
  createStyles({
    root: {},
    createLabButton: {
      marginTop: 40,
    },
  });
//#endregion

//#region Types
export interface CreateLabData {
  labName: string;
}

interface Props extends WithStyles<typeof styles> {
  allLabs: Common.Lab[];
  inProgress: boolean;
  onCreateLab: (data: CreateLabData) => void;
}
//#endregion

// Component
const CreateLab: React.FunctionComponent<Props> = ({
  allLabs,
  inProgress,
  classes,
  onCreateLab,
}) => {
  const takenLabNames = allLabs.map((lab: Common.Lab) => lab.path);

  return (
    <Formik
      initialValues={{
        labName: '',
      }}
      onSubmit={values => {
        onCreateLab(values);
      }}
      validationSchema={Yup.object().shape({
        labName: Yup.string()
          .matches(/^[0-9a-zA-Z ]+$/, 'Name contains invalid characters')
          .notOneOf(takenLabNames, 'File / folder already exist')
          .required('Field is required'),
      })}
    >
      {({ values, handleChange, handleSubmit, errors }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            margin="normal"
            fullWidth
            label="Lab Name"
            id="labName"
            error={!!errors.labName}
            helperText={errors.labName || ''}
            onChange={handleChange}
            value={values.labName}
          />

          <Button
            disabled={inProgress}
            type="submit"
            className={classes.createLabButton}
          >
            {inProgress ? 'Creating...' : 'Create'}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(CreateLab);
