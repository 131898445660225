import React, { memo } from 'react';
import { inject, observer } from 'mobx-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Loader from './results/Loader';

import { CTViewerStore } from '../../../../../stores/ctViewerStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    resultRow: {
      display: 'flex',
      width: '100%',
      alignItems: 'baseline',
      justifyContent: 'center',
    },
    field: {
      display: 'inline-flex',
      flexDirection: 'column',
    },
    fieldError: {
      display: 'inline-block',
      color: '#de3535',
      padding: '4px 0',
      fontWeight: 400,
    },
    subtitle: {
      marginRight: 16,
      fontWeight: 'normal',
      fontSize: '1rem',
      width: '170px',
    },
    subtitle2: {
      marginLeft: 10,
      fontWeight: 'normal',
      fontSize: '1rem',
    },
    input: {
      height: '2.8rem',
      width: '14rem',
      border: '1px solid #dfe3ed',
      borderRadius: '3px',
      backgroundColor: '#fefefe',
      padding: '0.6rem 1rem',
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.2rem',
      color: '#2c2e32',
    },
    inputError: {
      borderColor: '#de3535',
    },
    button: {
      color: '#fff',
      boxShadow: 'none',
      backgroundColor: '#69C3AC',
      padding: '8px 24px',
      lineHeight: 1.75,
      minWidth: '64px',
      fontSize: '0.9375rem',
      fontFamily: 'acumin-pro, sans-serif',
      fontWeight: 'bold',
      borderRadius: '10px',
      textTransform: 'initial',
      border: 0,
      margin: 0,
      cursor: 'pointer',
      display: 'inline-flex',
      position: 'relative',
      alignItems: 'center',
      userSelect: 'none',
      verticalAlign: 'middle',
      justifyContent: 'center',
      outline: 'none',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&[disabled]': {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
}
//#endregion

const Results: React.FunctionComponent<Props> = ({
  classes,
  ctViewerStore,
}) => {
  const handleChangeDepthUnit = (e: any) => {
    ctViewerStore!.setDepthUnit(e.target.value);
  };

  const getDepthInSelectedUnit = (value: number) => {
    if (ctViewerStore!.depthUnit === 'ft') {
      return value / 0.3048;
    }
    return value;
  };

  const handleDepthChange = (
    ev: any,
    setFieldValue: any,
    setFieldError: any,
  ) => {
    let valueInMeters = parseFloat(ev.target.value);

    if (!Number.isNaN(valueInMeters)) {
      valueInMeters = valueInMeters < 0 ? 0 : valueInMeters;
      if (ctViewerStore!.depthUnit === 'ft') {
        valueInMeters = valueInMeters * 0.3048;
      }
      setFieldValue('coreDepth', valueInMeters, true);
    } else {
      setFieldValue('coreDepth', ev.target.value, false);
      setFieldError('coreDepth', 'Core depth must be a non-negative number');
    }
  };

  const handleSlicesToGenerate = (value: number) => {
    ctViewerStore!.setSlicesToGenerate(value);
    return value;
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          sampleName: ctViewerStore!.sampleName
            ? ctViewerStore!.sampleName
            : ctViewerStore!.projectName,
          coreDepth: getDepthInSelectedUnit(ctViewerStore!.depth),
          axialSlices: ctViewerStore!.axialSlicesToGenerate,
        }}
        onSubmit={values => {
          ctViewerStore!.clearProcessingData();
          ctViewerStore!.setDepth(values.coreDepth);
          ctViewerStore!.setSampleName(values.sampleName);

          ctViewerStore!.processData();
        }}
        validationSchema={Yup.object().shape({
          sampleName: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z -]+$/, 'Name contains invalid characters')
            .required('Field is required'),
          coreDepth: Yup.number()
            .min(0)
            .required('Field is required'),
          axialSlices: Yup.number()
            .min(1, 'Axial slices must be greater than or equal to 1')
            .max(30, 'Axial slices must be less than or equal to 30')
            .integer()
            .required('Field is required'),
        })}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldError,
          errors,
          touched,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="center"
              style={{
                padding: '32px',
              }}
            >
              <Grid
                item
                style={{
                  width: '100%',
                  maxWidth: '1200px',
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    style={{
                      padding: '16px',
                    }}
                  >
                    <div className={classes.resultRow}>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className={classes.subtitle}
                      >
                        Sample name
                      </Typography>
                      <div className={classes.field}>
                        <input
                          className={classNames(classes.input, {
                            [classes.inputError]:
                              touched.sampleName && errors.sampleName,
                          })}
                          type="text"
                          name="sampleName"
                          value={values.sampleName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.sampleName && errors.sampleName && (
                          <Typography
                            variant="subtitle2"
                            component="span"
                            className={classes.fieldError}
                          >
                            {errors.sampleName}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  width: '100%',
                  maxWidth: '1200px',
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    style={{
                      padding: '16px',
                    }}
                  >
                    <div className={classes.resultRow}>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className={classes.subtitle}
                      >
                        Core Top Depth
                      </Typography>
                      <div className={classes.field}>
                        <input
                          className={classNames(classes.input, {
                            [classes.inputError]:
                              touched.coreDepth && errors.coreDepth,
                          })}
                          type="number"
                          name="coreDepth"
                          min={0}
                          step={1}
                          value={getDepthInSelectedUnit(values.coreDepth)}
                          onChange={ev =>
                            handleDepthChange(ev, setFieldValue, setFieldError)
                          }
                          onBlur={handleBlur}
                        />
                        {touched.coreDepth && errors.coreDepth && (
                          <Typography
                            variant="subtitle2"
                            component="span"
                            className={classes.fieldError}
                          >
                            {errors.coreDepth}
                          </Typography>
                        )}
                      </div>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className={classes.subtitle2}
                      >
                        {ctViewerStore!.depthUnit === 'm' ? (
                          <>
                            {`m = ${(
                              getDepthInSelectedUnit(values.coreDepth) / 0.3048
                            ).toFixed(2)} ft`}
                          </>
                        ) : (
                          <>
                            {`ft = ${(
                              getDepthInSelectedUnit(values.coreDepth) * 0.3048
                            ).toFixed(2)} m`}
                          </>
                        )}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    style={{
                      padding: '16px',
                    }}
                  >
                    <div className={classes.resultRow}>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className={classes.subtitle}
                      >
                        Depth Unit
                      </Typography>
                      <select
                        value={ctViewerStore!.depthUnit}
                        className={classes.input}
                        onChange={handleChangeDepthUnit}
                      >
                        <option value="ft">ft</option>
                        <option value="m">m</option>
                      </select>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  width: '100%',
                  maxWidth: '1200px',
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    style={{
                      padding: '16px',
                    }}
                  >
                    <div className={classes.resultRow}>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className={classes.subtitle}
                      >
                        Axial slices to generate
                      </Typography>
                      <div className={classes.field}>
                        <input
                          className={classNames(classes.input, {
                            [classes.inputError]:
                              touched.axialSlices && errors.axialSlices,
                          })}
                          type="number"
                          name="axialSlices"
                          min={1}
                          step={1}
                          max={30}
                          value={handleSlicesToGenerate(values.axialSlices)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.axialSlices && errors.axialSlices && (
                          <Typography
                            variant="subtitle2"
                            component="span"
                            className={classes.fieldError}
                          >
                            {errors.axialSlices}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  width: '100%',
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    style={{
                      padding: '16px',
                    }}
                  >
                    <button
                      className={classes.button}
                      type="submit"
                      disabled={
                        errors.sampleName ||
                        errors.coreDepth ||
                        errors.axialSlices
                          ? true
                          : undefined
                      }
                    >
                      Process
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      {ctViewerStore!.processSingleViewerLoading && (
        <Loader value={ctViewerStore!.processSingleViewerProgress}>
          Processing images - might take several minutes for larger data sets
        </Loader>
      )}
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(Results))),
);
