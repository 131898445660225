import './addCustomPalettes';
import extractFirstLastGradientColor from './extractFirstLastGradientColor';

const gradientMaxLookup = {
  gray: '#fff',
  hot: '#fff',
  hotIron: extractFirstLastGradientColor('hotIron', true),
  pet: extractFirstLastGradientColor('pet', true),
  hotMetalBlue: extractFirstLastGradientColor('hotMetalBlue', true),
  pet20Step: extractFirstLastGradientColor('pet20Step', true),
  customSpectral: extractFirstLastGradientColor('customSpectral', true),
};

export default gradientMaxLookup;
