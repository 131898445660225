export default (
  context: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  colorStr?: string,
) => {
  context.strokeStyle = colorStr ? colorStr : '#fff';
  context.lineWidth = 1;

  context.beginPath();
  context.moveTo(x1, y1);
  context.lineTo(x2, y2);

  context.stroke();
};
