import React, { memo, useRef, useEffect } from 'react';

import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import ArrowSVG from '../../icons/ArrowIcon';
import withDrag from '../../drag/withDrag';

//#region Styles
const styles = () =>
  createStyles({
    root: {
      position: 'absolute',
      top: '-12px',
      left: 'calc(50% - 12px)',
      width: '24px',
      height: '24px',
      background: '#fff',
      borderRadius: '50%',
      border: '1px solid #000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  registerOnDrag: any;
  registerSetDragInitialState: any;
  onMouseDown: ((e: React.MouseEvent) => void) | undefined;
  scaling: number;
  radius: number;
  maxRadius: number;
  onChange: (value: number) => void;
}
//#endregion

const BeamHardeningRadiusControl: React.FunctionComponent<Props> = ({
  registerOnDrag,
  registerSetDragInitialState,
  onMouseDown,
  scaling,
  radius,
  maxRadius,
  onChange,
  classes,
}) => {
  const newPosition = useRef<any>();

  const handleMouseMove = (
    unscaledXDiff: number,
    unscaledYDiff: number,
    initialState: any,
  ) => {
    const { initialRadius } = initialState;
    let newRadius = initialRadius - unscaledYDiff / scaling;

    newRadius = Math.round(newRadius);
    newRadius = Math.max(newRadius, 0);
    newRadius = Math.min(newRadius, maxRadius);

    onChange(newRadius);
  };

  const setDragInitialState = () => newPosition.current;

  useEffect(() => {
    registerOnDrag(handleMouseMove);
    registerSetDragInitialState(setDragInitialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    newPosition.current = {
      initialRadius: radius,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <div className={classes.root} onMouseDown={onMouseDown}>
      <ArrowSVG />
    </div>
  );
};

export default memo(withStyles(styles)(withDrag(BeamHardeningRadiusControl)));
