import { observable } from 'mobx';
import { action } from 'mobx';

interface Toasters {
  message: string;
  opened: boolean;
  variang: 'success' | 'error' | 'info' | 'warning';
}

interface Modals {
  conflictedPath: boolean;
  createFolderOpened: boolean;
  createLabOpened: boolean;
  deleteFolderOpened: boolean;
  inviteUserOpened: boolean;
  inviteUserToLabOpened: boolean;
  reInviteUserOpened: boolean;
  removeUserFromAppOpened: boolean;
  removeUserInvitationOpened: boolean;
  removeUserFromLabOpened: boolean;
  showSingleViewerOpened: boolean;
  createDatasetOpened: boolean;
  lightboxOpened: boolean;
  [key: string]: boolean;
}

export class AppStore {
  private defaultSuccessMsg = 'Success!';
  private defaultErrorMsg = 'Error occured';
  private defaultInfoMsg = 'The data has not changed';

  @observable
  toaster: Toasters = {
    opened: false,
    message: '',
    variang: 'success',
  };

  @observable
  modals: Modals = {
    createProjectOpened: false,
    createLabOpened: false,
    conflictedPath: false,
    createFolderOpened: false,
    deleteFolderOpened: false,
    inviteUserOpened: false,
    inviteUserToLabOpened: false,
    reInviteUserOpened: false,
    removeUserFromAppOpened: false,
    removeUserInvitationOpened: false,
    removeUserFromLabOpened: false,
    showSingleViewerOpened: false,
    createDatasetOpened: false,
    lightboxOpened: false,
  };

  @observable
  isLoading: boolean = false;

  @action
  hideToaster = () => {
    this.toaster.opened = false;
  };

  @action
  showSuccessToaster = (msg: string = this.defaultSuccessMsg) => {
    this.toaster.opened = true;
    this.toaster.variang = 'success';
    this.toaster.message = msg;
  };

  @action
  showErrorToaster = (msg: string = this.defaultErrorMsg) => {
    this.toaster.opened = true;
    this.toaster.variang = 'error';
    this.toaster.message = msg;
  };

  @action
  showInfoToaster = (msg: string = this.defaultInfoMsg) => {
    this.toaster.opened = true;
    this.toaster.variang = 'info';
    this.toaster.message = msg;
  };

  @action
  setLoading = (value: boolean) => {
    this.isLoading = value;
  };

  @action
  toggleModal = (modal: string, opened: boolean) => {
    this.modals[modal] = opened;
  };
}

export default new AppStore();
