import React, { memo } from 'react';
import { inject, observer } from 'mobx-react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Typography from '@material-ui/core/Typography';

import { CTViewerStore } from '../../../../stores/ctViewerStore';

//#region Styles
const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '16px',
    },
    crop: {
      fontWeight: 'bold',
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
}
//#endregion

const CropDiameter: React.FunctionComponent<Props> = ({
  classes,
  ctViewerStore,
}) => {
  if (
    !ctViewerStore ||
    !ctViewerStore.axialImage ||
    !ctViewerStore.cropRadius
  ) {
    return null;
  }

  const cropDiameter =
    (ctViewerStore.cropRadius *
      2 *
      ctViewerStore.axialImage.columnPixelSpacing) /
    10;

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" component="span">
        Crop diameter:{' '}
        <span className={classes.crop}>{`${cropDiameter.toFixed(2)} cm`}</span>
      </Typography>
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(CropDiameter))),
);
