import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { navigate } from '@reach/router';

import DashboardCard from './dashboard/DashboardCard';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 20,
      justifyContent: 'space-between',
    },
    sectionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      paddingBottom: 30,
    },
  });

interface Props extends WithStyles<typeof styles> {
  labs: (Common.Lab | Common.Project)[];
  type: string;
}

const Dashboard: FC<Props> = ({ labs, classes, type }) => {
  return (
    <div className={classes.root}>
      <Grid container spacing={32}>
        <Grid item xs>
          <Grid container spacing={24}>
            {labs.map((lab, index) => (
              <Grid key={index} item>
                {type === 'lab' ? (
                  <DashboardCard
                    name={(lab as Common.Lab).path}
                    size={(lab as Common.Lab).size}
                    onClick={() =>
                      navigate(`/labs/${(lab as Common.Lab).path}`)
                    }
                  />
                ) : (
                  <DashboardCard
                    name={(lab as Common.Project).name}
                    size={(lab as Common.Project).size}
                    subName={(lab as Common.Project).lab.lab_name}
                    onClick={() =>
                      navigate(`/projects/${(lab as Common.Project).id}`)
                    }
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Dashboard);
