import React, { Component } from 'react';
import { Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import * as Yup from 'yup';
import { Link } from '@reach/router';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '../components/ui/Button';
import SnackbarMessage from '../components/ui/SnackbarMessage';

import { AuthStore } from '../stores/authStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 68px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loginCard: {
      height: '100%',
      width: 498,
      boxShadow: theme.shadows[1],
    },
    cardContent: {
      padding: '34px 90px 0 !important',
    },
    loginForm: {
      marginBottom: 40,
      paddingTop: 10,
    },
    loginButton: {
      marginTop: 40,
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    errorIcon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing.unit,
    },
    errorMessage: {
      display: 'flex',
      alignItems: 'center',
    },
    forgotPassword: {
      paddingBottom: 20,
    },
  });
//#endregion

//#region Types
interface LoginPayload {
  email: string;
  password: string;
}

interface Props extends WithStyles<typeof styles> {}

interface InjectedProps extends Props {
  authStore: AuthStore;
}
//#endregion

//#region Component
@inject('authStore')
@observer
class Login extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleLogin = async (payload: LoginPayload) => {
    this.injected.authStore.login(payload);
  };

  render() {
    const { classes } = this.props;
    const { isLoading, hasError } = this.injected.authStore;

    return (
      <div className={classes.root}>
        <SnackbarMessage
          opened={hasError}
          variant="error"
          message="Wrong credentials"
          onClose={this.injected.authStore.clearError}
        />

        <Card className={classes.loginCard}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Typography variant="h1" align="center" gutterBottom>
              Log into your cloud
            </Typography>

            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={values => {
                this.handleLogin(values);
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required(),
                password: Yup.string().required(),
              })}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <form
                  className={classes.loginForm}
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    disabled={isLoading}
                    autoFocus
                    required
                    fullWidth
                    margin="normal"
                    label="Email"
                    id="email"
                    type="email"
                    error={!!(errors.email && touched.email)}
                    helperText={
                      errors.email && touched.email ? errors.email : ''
                    }
                    onChange={handleChange}
                    value={values.email}
                  />

                  <TextField
                    disabled={isLoading}
                    required
                    fullWidth
                    margin="normal"
                    label="Password"
                    id="password"
                    type="password"
                    error={!!(errors.password && touched.password)}
                    helperText={
                      errors.password && touched.password ? errors.password : ''
                    }
                    onChange={handleChange}
                    value={values.password}
                  />

                  <Button
                    type="submit"
                    disabled={isLoading}
                    className={classes.loginButton}
                    width="100%"
                  >
                    {isLoading ? 'Logging in...' : 'Log in'}
                  </Button>
                </form>
              )}
            </Formik>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.forgotPassword}
            >
              <Grid item>
                <Link to="/password-reset">
                  <Typography>Forgot password?</Typography>
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}
//#endregion

export default withStyles(styles)(Login);
