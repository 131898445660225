import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import { inject, observer } from 'mobx-react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { UsersStore } from '../../stores/usersStore';
import { AuthStore } from '../../stores/authStore';
import Navigation from './users/Navigation';
import UsersAndDepartments from './users/UsersAndDepartments';
import PendingUsers from './users/PendingUsers';
import DepartmentEdit from './users/DepartmentEdit';
import UserEdit from './users/UserEdit';

//#region styles
const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  labName: string;
}

interface State {
  showPending: boolean;
}

interface InjectedProps extends Props {
  usersStore: UsersStore;
  authStore: AuthStore;
}
//#endregion

@inject('usersStore', 'authStore')
@observer
class Users extends Component<Props, State> {
  state = {
    showPending: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    const { usersStore } = this.injected;
    usersStore.getLabDepartments(this.props.labName);
    await usersStore.getPendingUsersList();
  }

  componentWillUnmount() {
    this.injected.usersStore.cleanUp();
  }

  handleCreateUser = () => {
    this.setState({
      showPending: false,
    });
    this.injected.usersStore.setUserEditOrCreate();
  };

  handleSelectUser = (id?: number) => {
    if (id) {
      this.injected.usersStore.setUserEditOrCreate(id);
    }
  };

  handleSelectPendingUser = (id?: number) => {
    if (id) {
      this.injected.usersStore.setUserPendingInvitation(id);
    }
  };

  handleDeleteUserFromLab = () => {
    this.injected.usersStore.deleteUserFromLab();
  };

  handleCreateDepartment = () => {
    this.setState({
      showPending: false,
    });
    this.injected.usersStore.setDepartmentEditOrCreate();
  };

  handleSelectDepartment = (name?: string) => {
    if (name) {
      this.injected.usersStore.setDepartmentEditOrCreate(name);
    }
  };

  handleDeleteDepartment = () => {
    this.injected.usersStore.deleteDepartment();
  };

  handleSaveDepartment = (name: string) => {
    this.injected.usersStore.saveDepartment(name);
  };

  handleSubmitUser = (payload: Common.UserPayload) => {
    if (this.state.showPending) {
      this.injected.usersStore.sendInvitation(payload, true);
    } else {
      this.injected.usersStore.saveUser(payload);
    }
  };

  handleCancelEdit = () => {
    this.injected.usersStore.resetEditData();
  };

  handleToggleSearch = () => {
    this.injected.usersStore.toggleSearchActive();
  };

  handlePendingUsers = () => {
    this.handleCancelEdit();
    this.setState({
      showPending: true,
    });
  };

  getEditSectionComponent = (type: string) => {
    if (type === 'user') {
      return (
        <UserEdit
          onSave={this.handleSubmitUser}
          onDeleteFromLab={this.handleDeleteUserFromLab}
          onCancel={this.handleCancelEdit}
          pending={this.state.showPending}
        />
      );
    }

    if (type === 'department') {
      return (
        <DepartmentEdit
          onSave={this.handleSaveDepartment}
          onDelete={this.handleDeleteDepartment}
          onCancel={this.handleCancelEdit}
        />
      );
    }

    return null;
  };

  render() {
    const { classes, labName } = this.props;
    const { showPending } = this.state;
    const selectedEditType = this.injected.usersStore.selectedOption;
    const { isAdmin, user } = this.injected.authStore;
    const isCurrentLabAdmin = (user.lab_admin_labs_full || []).includes(
      labName,
    );

    return (
      <div className={classes.root}>
        <Navigation
          onCreateDepartment={this.handleCreateDepartment}
          onCreateUser={this.handleCreateUser}
          onPendingUsers={this.handlePendingUsers}
          onToggleSearch={this.handleToggleSearch}
          canEdit={isAdmin || isCurrentLabAdmin}
        />

        {!showPending ? (
          <UsersAndDepartments
            onSelectDepartment={this.handleSelectDepartment}
            onSelectUser={this.handleSelectUser}
            canEdit={isAdmin || isCurrentLabAdmin}
          />
        ) : (
          <PendingUsers onSelectUser={this.handleSelectPendingUser} />
        )}

        {selectedEditType
          ? this.getEditSectionComponent(selectedEditType)
          : null}
      </div>
    );
  }
}

export default withStyles(styles)(Users);
