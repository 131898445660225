import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Tab from '@material-ui/core/Tab';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: '16px',
      minHeight: '56px',
      backgroundColor: '#EFF1F6',
      fontWeight: 'normal',
      border: '1px solid rgba(224, 224, 224, 0.5)',
      borderBottom: 'none',
    },
    selected: {
      backgroundColor: theme.palette.common.white,
      fontWeight: 'bold',
      border: '1px solid rgb(224, 224, 224)',
      borderBottom: 'none',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  label: string;
  value: string;
}
//#endregion

// Component
const CTViewerTab: React.FunctionComponent<Props> = (props: Props) => (
  <Tab label={props.label} value={props.value} {...props} />
);

export default withStyles(styles)(CTViewerTab);
