import { AxiosResponse } from 'axios';
import get from 'lodash-es/get';

import labsStore from '../../../stores/labsStore';
import appStore from '../../../stores/appStore';

async function removeAllAdmins(labName: string, removedAdmins: string[]) {
  const removeAdminEmailsPromises = removedAdmins.map((email: string) =>
    labsStore.removeSubAdmins(labName, email),
  );

  return Promise.all(removeAdminEmailsPromises);
}

export async function saveSettings(
  labName: string,
  adminEmails: string[],
  removedAdminEmails: string[],
  callback: () => void,
) {
  await removeAllAdmins(labName, removedAdminEmails);

  const adminEmailsPromises = adminEmails.map((email: string) =>
    labsStore.addAdminToGroup({ groupName: labName, userName: email }),
  );

  const onFailure = (msg: string) => {
    callback();
    appStore.showErrorToaster(msg || 'Action failed');
  };

  return Promise.all([...adminEmailsPromises])
    .then((responses: AxiosResponse[]) => {
      const failures = responses.filter(
        (response: AxiosResponse) =>
          get(response, 'data.ocs.meta.status', false) === 'failure' ||
          get(response, 'data.meta.status', false) === 'failure',
      );

      if (failures.length) {
        const errorMessages = responses
          .map(
            (response: AxiosResponse) =>
              get(response, 'data.ocs.meta.message') ||
              get(response, 'data.meta.message'),
          )
          .filter((msg: string) => !!msg);

        errorMessages.forEach((msg: string) => onFailure(msg));
        return;
      }

      callback();
      appStore.showSuccessToaster();
    })
    .catch((response: AxiosResponse) => {
      const msg = response.data.message;
      const username = JSON.parse(response.config.data).username;

      if (msg) {
        onFailure(`${msg} (${username})`);
        return;
      }

      onFailure('');
    });
}
