import convertToHexElement from './convertToHexElement';

const convertSingleColorToHex = (color: any) => {
  const r = convertToHexElement(color[0]);
  const g = convertToHexElement(color[1]);
  const b = convertToHexElement(color[2]);

  return `#${r}${g}${b}`;
};

export default convertSingleColorToHex;
