import React, { Component } from 'react';
import { navigate } from '@reach/router';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import FolderIcon from '@material-ui/icons/Folder';
import Grid from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { inject, observer } from 'mobx-react';

import { LabsStore } from 'stores/labsStore';
import DashboardCard, { CustomCardType } from './dashboard/DashboardCard';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 20,
      justifyContent: 'space-between',
    },
    sectionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      paddingBottom: 30,
    },
  });

interface InjectedProps extends Props {
  labsStore: LabsStore;
}

interface Props extends WithStyles<typeof styles> {
  // labs: Common.Lab[];
  // shared: (Common.Lab | Common.File)[];
}

@inject('labsStore')
@observer
class CustomLabs extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { classes } = this.props;
    const { labsStore } = this.injected;

    const sharedWithMeLab: Common.Lab = {
      lab: false,
      path: 'Shared with me',
      size: 0,
      country: undefined,
      users: 0,
    };

    const MyFilesLab: Common.Lab = {
      lab: false,
      path: 'My Files',
      size: labsStore.myFilesSize,
      country: undefined,
      users: 0,
    };

    return (
      <div className={classes.root}>
        <Grid container spacing={32}>
          <Grid item xs>
            <Grid container spacing={24}>
              <Grid item>
                <DashboardCard
                  name={MyFilesLab.path}
                  size={MyFilesLab.size}
                  icon={<FolderIcon />}
                  custom={CustomCardType.MY_FILES}
                  onClick={() => navigate(`/labs/${MyFilesLab.path}`)}
                />
              </Grid>

              <Grid item>
                <DashboardCard
                  name={sharedWithMeLab.path}
                  icon={<ReplyAllIcon />}
                  custom={CustomCardType.SHARED_WITH_ME}
                  onClick={() => navigate(`/labs/${sharedWithMeLab.path}`)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(CustomLabs);
