import React, { memo } from 'react';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import { CTViewerStore } from '../../../../../../../stores/ctViewerStore';
import gradientLookup from '../../../../../../../utils/gradientLookup';

const availableGradients = [
  { key: 'pet', name: 'PET' },
  { key: 'gray', name: 'Gray' },
  { key: 'hot', name: 'Hot' },
  { key: 'customSpectral', name: 'Spectral' },
];

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    selectorTitle: {
      marginBottom: '8px',
      textAlign: 'center',
    },
    outputColorMarker: {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      marginBottom: '3px',
    },
    outputColorSelector: {
      display: 'flex',

      '& > div': {
        marginRight: '10px',
        minWidth: '80px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '5px 0',
        fontSize: '11px',
        border: '1px solid #ddd',
        borderRadius: '3px',
        cursor: 'pointer',
        userSelect: 'none',

        '&:last-child': {
          marginRight: 0,
        },

        '&.selected': {
          border: '1px solid #6eceb2',
        },

        '&:hover': {
          opacity: 0.8,
        },
      },
    },
    markerText: {
      fontSize: '.9rem',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
}
//#endregion

const ColorSettings: React.FunctionComponent<Props> = ({
  classes,
  ctViewerStore,
}) => {
  const currentMap = ctViewerStore!.colormap || 'gray';

  const options = availableGradients.map(gradientSpec => {
    const key = gradientSpec.key;
    // @ts-ignore
    const gradient = gradientLookup[key];

    return (
      <div
        onClick={() => ctViewerStore!.selectColorMap(key)}
        className={currentMap === key ? 'selected' : undefined}
        key={key}
      >
        <div
          className={classes.outputColorMarker}
          style={{ background: gradient }}
        />
        <Typography className={classes.markerText}>
          {gradientSpec.name}
        </Typography>
      </div>
    );
  });

  return (
    <div className={classes.root}>
      <div className={classes.selectorTitle}>
        <Typography variant="subtitle1" component="strong">
          Intensity Color
        </Typography>
      </div>
      <div className={classes.outputColorSelector}>{options}</div>
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(ColorSettings))),
);
