import React, { memo } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import NumericInput from '../../../../../components/ui/NumericInput';
import { CTViewerStore } from '../../../../../stores/ctViewerStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    subtitle: {
      marginBottom: 10,
      fontSize: '1.2rem',
    },
    cropPicker: {
      background: '#fafbfc',
      minWidth: '340px',
      borderRadius: '5px',
      border: '1px solid #dfe3ed',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '12px 24px',
    },
    cropPickerBig: {
      minWidth: '650px',
    },
    cropPickerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '10px 0',
      width: '100%',
    },
    pickerSection: {
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '&:nth-of-type(2n)': {
        marginLeft: '24px',
      },
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
}
//#endregion

const CropDetails: React.FunctionComponent<Props> = ({
  classes,
  ctViewerStore,
}) => {
  const handleChange = (value: number, name: string) => {
    const {
      cropTop,
      cropBottom,
      displayedSliceHeight,
      coronalImage,
      sagittalImage,
      axialImage,
    } = ctViewerStore!;
    let newValue: any = value;

    if (!coronalImage || !sagittalImage || !axialImage) return;

    switch (name) {
      case 'cropTop':
        if (newValue < 0) {
          newValue = 0;
        } else if (newValue >= cropBottom!) {
          newValue = cropBottom;
        }
        break;
      case 'cropBottom':
        if (value <= cropTop!) {
          newValue = cropTop;
        } else if (value >= displayedSliceHeight!) {
          newValue = displayedSliceHeight;
        }
        break;
      case 'cropRadius':
        if (value <= 0) {
          value = 0;
        } else if (value >= coronalImage.width / 2) {
          value = coronalImage.width / 2;
        }
        break;
      default:
        break;
    }

    ctViewerStore!.setCropParameters({ [name]: Number(value) });
  };

  const pickerSection = (text: string, value: number, key: string) => (
    <div className={classes.pickerSection}>
      <Typography variant="subtitle1" component="strong">
        {text}
      </Typography>
      <NumericInput
        value={value}
        onChange={(value: number) => handleChange(value, key)}
      />
    </div>
  );

  return (
    <Grid
      container
      direction="column"
      justify="center"
      style={{
        padding: '24px 24px 48px 24px',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <Grid
        item
        style={{
          padding: '16px 0 0 0',
        }}
      >
        <Typography
          variant="subtitle2"
          component="p"
          className={classes.subtitle}
        >
          Crop position details
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          padding: '16px 0',
        }}
      >
        <Grid container direction="row" justify="space-evenly">
          <Grid
            item
            style={{
              padding: '16px 0',
            }}
          >
            <div
              className={classNames(classes.cropPicker, classes.cropPickerBig)}
            >
              <div className={classes.cropPickerContainer}>
                {pickerSection(
                  'X center position',
                  ctViewerStore!.cropCenterX!,
                  'cropCenterX',
                )}
                {pickerSection(
                  'Crop radius',
                  ctViewerStore!.cropRadius!,
                  'cropRadius',
                )}
              </div>
              <div className={classes.cropPickerContainer}>
                {pickerSection(
                  'Y center position',
                  ctViewerStore!.cropCenterY!,
                  'cropCenterY',
                )}
                <div className={classes.pickerSection} />
              </div>
            </div>
          </Grid>
          <Grid
            item
            style={{
              padding: '16px 0',
            }}
          >
            <div className={classes.cropPicker}>
              <div className={classes.cropPickerContainer}>
                {pickerSection(
                  'Top crop position',
                  ctViewerStore!.cropTop!,
                  'cropTop',
                )}
              </div>
              <div className={classes.cropPickerContainer}>
                {pickerSection(
                  'Bottom crop position',
                  ctViewerStore!.cropBottom!,
                  'cropBottom',
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(CropDetails))),
);
