import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';

interface SelectedFilesActionsProps {
  sharedFilesContext: boolean;
  isClipboardEmpty: boolean;
  disableDownload?: boolean;
  onDownload: () => void;
  onMove: () => void;
  onPaste: () => void;
  onDelete: () => void;
}

const SelectedFilesActions: React.FC<SelectedFilesActionsProps> = ({
  sharedFilesContext,
  isClipboardEmpty,
  disableDownload,
  onDownload,
  onMove,
  onPaste,
  onDelete,
}) => {
  return (
    <Grid container>
      <Grid item>
        {/*div inside Tooltip is required*/}
        <Tooltip title="Download">
          <div>
            <IconButton
              aria-label="Download selected files or folders"
              disabled={disableDownload}
              onClick={onDownload}
            >
              <DownloadIcon>Download</DownloadIcon>
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete selected files or folders"
            onClick={onDelete}
          >
            <DeleteIcon>Delete</DeleteIcon>
          </IconButton>
        </Tooltip>
      </Grid>
      {!sharedFilesContext && isClipboardEmpty && (
        <Grid item>
          <Tooltip title="Move">
            <IconButton
              aria-label="Move selected files or folders"
              onClick={onMove}
            >
              <FileCopyIcon>Move</FileCopyIcon>
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      {!sharedFilesContext && !isClipboardEmpty && (
        <Grid item>
          <Tooltip title="Paste">
            <IconButton
              aria-label="paste selected files or folders"
              onClick={onPaste}
            >
              <GetAppIcon>Paste</GetAppIcon>
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectedFilesActions;
