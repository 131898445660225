import React from 'react';
import { inject, observer } from 'mobx-react';
import find from 'lodash-es/find';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import ButtonWithIcon from './ButtonWithIcon';
import ConfirmationModal from './ConfirmationModal';
import { FilesStore, TempFile } from '../../stores/files/filesStore';
import { DownloadStore } from '../../stores/files/downloadStore';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: 160,
    },
    fileInput: {
      height: 0,
      width: 0,
      visibility: 'hidden',
      position: 'absolute',
    },
    buttonProgress: {
      position: 'absolute',
      left: -2,
      top: -2,
      color: theme.palette.primary.dark,
    },
  });

interface Props extends WithStyles<typeof styles> {
  folderPath: string;
  context: string;
  disabled?: boolean;
  onUploadSuccess?: (param?: boolean) => void;
  onUploadError?: () => void;
}

interface InjectedProps extends Props {
  filesStore: FilesStore;
  downloadStore: DownloadStore;
}

interface State {
  files: TempFile[] | [];
  readonly overwriteModalVisible: boolean;
}

@inject('filesStore', 'downloadStore')
@observer
class FileUpload extends React.Component<Props, State> {
  state = {
    files: [],
    overwriteModalVisible: false,
  };

  mounted: boolean = false;
  input = React.createRef<HTMLInputElement>();

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleUpload = (event: React.FormEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files === null) {
      return;
    }

    const filesArray: TempFile[] = this.prepareFiles(files);

    this.setState(
      {
        files: filesArray,
      },
      () => {
        if (filesArray.find((file: any) => file.exist)) {
          this.setState({
            overwriteModalVisible: true,
          });
        } else {
          this.processFilesUpload();
        }
      },
    );
    this.clearFileInput();
  };

  prepareFiles = (files: FileList) => {
    return Array.from(files).map((file: File) => ({
      file,
      name: file.name,
      exist: this.checkIfFileExist(file),
      uploadProgress: 0,
      uploaded: false,
    }));
  };

  checkIfFileExist = (file: File) =>
    !!find(this.injected.filesStore.files, ['path', file.name]);

  processFilesUpload = async () => {
    const { filesStore } = this.injected;
    const { onUploadSuccess, onUploadError, folderPath, context } = this.props;
    const { files } = this.state;
    const path = context === 'project' ? 'Projects/' : '';

    this.setState({
      overwriteModalVisible: false,
    });

    filesStore.setUploadPath(`${path}${folderPath}`);
    await filesStore.uploadFiles(files, onUploadSuccess, onUploadError);
  };

  clearFileInput = () => {
    if (this.input && this.input.current) {
      this.input.current.value = '';
      this.input.current.files = null;
    }
  };

  closeOverwriteModal = () => {
    if (!this.mounted) {
      return;
    }
    this.setState({
      overwriteModalVisible: false,
      files: [],
    });
  };

  render() {
    const { classes, disabled } = this.props;
    const {
      filesStore: { uploadingFiles },
      downloadStore: { showCompressionPopup },
    } = this.injected;
    const shouldBeDisabled = disabled || uploadingFiles || showCompressionPopup;

    return (
      <>
        <div className={classes.root}>
          <input
            disabled={shouldBeDisabled}
            type="file"
            onChange={this.handleUpload}
            className={classes.fileInput}
            multiple
            id="fileUpload"
            ref={this.input}
          />
          <label htmlFor="fileUpload">
            <ButtonWithIcon
              disabled={shouldBeDisabled}
              icon={<CloudUploadIcon />}
            >
              {uploadingFiles ? 'Uploading...' : 'Upload File'}
            </ButtonWithIcon>
          </label>
        </div>

        <ConfirmationModal
          opened={this.state.overwriteModalVisible}
          title="File overwrite"
          question="Following action will overwrite file(s) with the same name(s)"
          onCancel={this.closeOverwriteModal}
          onConfirm={this.processFilesUpload}
        />
      </>
    );
  }
}

export default withStyles(styles)(FileUpload);
