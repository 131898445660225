import React, { memo, useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { CTViewerStore } from '../../../../../stores/ctViewerStore';

const defaultSize = 333;
const radius = 1.5 * defaultSize; // sqrt(2) * defaultSize will be enough

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
}
//#endregion

const RadialMarkerCrossLineCanvas: React.FunctionComponent<Props> = ({
  ctViewerStore,
  classes,
}) => {
  const canvasElement = useRef<any>();

  const drawLine = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    width: number,
    color: string,
  ) => {
    const canvas = canvasElement.current;
    const context = canvas.getContext('2d');

    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);

    context.strokeStyle = color;
    context.lineWidth = width;
    context.stroke();
  };

  const renderLinesOnCanvas = () => {
    const canvas = canvasElement.current;

    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);

    const scale = 333 / 512;

    const cx = ctViewerStore!.cropCenterX! * scale;
    const cy = ctViewerStore!.cropCenterY! * scale;

    const angleInRadians = (ctViewerStore!.tiltAngle * Math.PI) / 180;

    const rSinAlpha = radius * Math.sin(angleInRadians);
    const rCosAlpha = radius * Math.cos(angleInRadians);

    const upperLeftX = cx - rSinAlpha;
    const upperLeftY = cy - rCosAlpha;

    const bottomLeftX = cx - rCosAlpha;
    const bottomLeftY = cy + rSinAlpha;

    const upperRightX = cx + rCosAlpha;
    const upperRightY = cy - rSinAlpha;

    const bottomRightX = cx + rSinAlpha;
    const bottomRightY = cy + rCosAlpha;

    // non-tilted
    drawLine(0, cy, defaultSize, cy, 2, 'rgba(74, 144, 226, 0.5)');
    drawLine(cx, 0, cx, defaultSize, 2, 'rgba(203, 83, 226, 0.5)');

    // tilted
    drawLine(
      upperLeftX,
      upperLeftY,
      bottomRightX,
      bottomRightY,
      2,
      'rgb(203, 83, 226)',
    );
    drawLine(
      upperRightX,
      upperRightY,
      bottomLeftX,
      bottomLeftY,
      2,
      'rgb(74, 144, 226)',
    );
  };

  const { cropCenterX, cropCenterY, tiltAngle } = ctViewerStore!;

  useEffect(() => {
    if (
      cropCenterX !== undefined &&
      cropCenterY !== undefined &&
      tiltAngle !== undefined
    ) {
      renderLinesOnCanvas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropCenterX, cropCenterY, tiltAngle]);

  return (
    <canvas
      className={classes.root}
      width={defaultSize}
      height={defaultSize}
      ref={canvasElement}
    />
  );
};

export default memo(
  withStyles(styles)(
    inject('ctViewerStore')(observer(RadialMarkerCrossLineCanvas)),
  ),
);
