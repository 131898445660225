import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import fileSize from 'filesize';
import { navigate } from '@reach/router';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import RateReviewIcon from '@material-ui/icons/RateReview';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '@material-ui/icons/GetApp';

import { AppStore } from 'stores/appStore';
import { FilesStore } from 'stores/files/filesStore';
import { SelectedFilesStore } from 'stores/files/selectedFilesStore';
import { CTViewerStore } from 'stores/ctViewerStore';
import ConfirmationModal from 'components/ui/ConfirmationModal';
import RenameFileModal from 'components/ui/RenameFileModal';
import EnergyChoserModal from 'components/ui/EnergyChoserModal';

import {
  isDirectory,
  isFile,
  isCTViewerSet,
  isHighEnergySet,
  isLowEnergySet,
  isSpecialDirectory,
  getPathWithoutExtension,
} from '../../../utils/directoryUtils';
import { DownloadStore } from '../../../stores/files/downloadStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    tableCell: {
      alignItems: 'center',
      paddingRight: 24,
    },
    assetTypeIcon: {
      color: theme.palette.primary.main,
      marginTop: 2,
      height: 36,
      width: 36,
      cursor: 'pointer',
    },
    ctViewerSetFolder: {
      color: '#1880FF',
    },
    cutRow: {
      color: theme.palette.primary.light,
    },
    assetName: {
      fontWeight: 700,
      cursor: 'pointer',
    },
    fileLink: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      border: 'none',
    },
    iconContainer: {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
    },
    fileName: {
      paddingLeft: '54px !important',
    },
    assetContainer: {
      position: 'relative',
    },
    disabledRow: {
      'pointer-events': 'none',
      opacity: 0.3,
    },
    moreIcon: {
      cursor: 'pointer',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  entity: Common.File;
  currentPath: string;
  newCurrentPath: string;
  isRootPath: boolean;
  isCut: boolean;
  isChecked: boolean;
  readonly: boolean;
  onCheck: () => void;
  onToggleLightBox: (file: Common.File) => void;
}

interface InjectedProps extends Props {
  appStore: AppStore;
  filesStore: FilesStore;
  downloadStore: DownloadStore;
  selectedFilesStore: SelectedFilesStore;
  ctViewerStore: CTViewerStore;
}

interface Email {
  value: string;
  id: string;
}

interface State {
  anchorEl: HTMLElement | null;
  shareModalOpened: boolean;
  emails: Email[];
  entities: Array<any>;
  shareInProgress: boolean;
  confirmModalOpened: boolean;
  renameModalOpened: boolean;
  energyModalOpened: boolean;
}
//#endregion

@inject(
  'appStore',
  'filesStore',
  'selectedFilesStore',
  'ctViewerStore',
  'downloadStore',
)
@observer
class CustomTableRow extends Component<Props, State> {
  state: State = {
    anchorEl: null,
    shareModalOpened: false,
    emails: [],
    entities: [],
    shareInProgress: false,
    confirmModalOpened: false,
    renameModalOpened: false,
    energyModalOpened: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  mounted = false;
  singleViewerModalCallback: ((e: any) => void) | undefined = undefined;

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    this.singleViewerModalCallback = undefined;
  }

  onRecordClick = () => {
    const { appStore } = this.injected;
    if (appStore.isLoading) {
      return;
    }

    const { entity, newCurrentPath, onToggleLightBox } = this.props;
    if (isFile(entity)) {
      onToggleLightBox(entity);
      return;
    }

    navigate(`/projects/${newCurrentPath}/${entity.path}`);
  };

  onDownload = () => {
    const { downloadStore } = this.injected;
    const { entity } = this.props;
    downloadStore.download([entity]);
  };

  handleOpenMenu = (event: React.MouseEvent<any>) =>
    this.setState({ anchorEl: event.currentTarget });
  handleCloseMenu = () => this.setState({ anchorEl: null });

  handleOpenRenameModal = () => {
    this.setState({
      renameModalOpened: true,
    });
  };

  handleCloseRenameModal = () => {
    this.setState({
      renameModalOpened: false,
    });
  };

  handleRenameSuccess = () => {
    this.injected.appStore.showSuccessToaster('The name has been changed');
  };

  handleOpenSingleViewer = () => {
    const opened = this.injected.appStore.modals.showSingleViewerOpened;
    this.injected.appStore.modals.showSingleViewerOpened = !opened;
  };

  handleOpenEnergyChoserModal = () => {
    this.setState({
      energyModalOpened: true,
    });
  };

  handleCloseEnergyChoserModal = () => {
    this.setState({
      energyModalOpened: false,
    });
  };

  handlePrepareToOpenSingleViewer = (
    value: string | undefined,
    direct = false,
  ) => {
    const { entity, currentPath } = this.props;
    const { ctViewerStore } = this.injected;
    this.handleCloseEnergyChoserModal();

    if (value) {
      const name = getPathWithoutExtension(entity.path);
      ctViewerStore.setEnergy(value);

      if (direct) {
        const projectName = getPathWithoutExtension(currentPath)
          .split('/')
          .pop();
        ctViewerStore.setProjectName(projectName!);
        ctViewerStore.setPathToProject(`Projects/${currentPath}`);
        ctViewerStore.setPathToImages(
          `Projects/${currentPath}/${name}.${value}`,
        );
      } else {
        ctViewerStore.setProjectName(name);
        ctViewerStore.setPathToProject(
          `Projects/${currentPath}/${name}.ctimagesset`,
        );
        if (value === 'low') {
          ctViewerStore.setPathToImages(
            `Projects/${currentPath}/${name}.ctimagesset/Low Energy.low`,
          );
        } else {
          ctViewerStore.setPathToImages(
            `Projects/${currentPath}/${name}.ctimagesset/High Energy.high`,
          );
        }
      }

      this.handleOpenSingleViewer();
    }
  };

  handleDelete = async () => {
    const { uri } = this.props.entity;

    try {
      await this.injected.selectedFilesStore.deleteFile(uri);
      this.injected.filesStore.refresh();
    } catch (error) {
      this.injected.appStore.showErrorToaster(error.data.message);
      this.handleCloseConfirmModal();
    }
  };

  handleOpenConfirmModal = () => {
    this.setState({
      confirmModalOpened: true,
    });
  };

  handleCloseConfirmModal = () => {
    this.setState({
      confirmModalOpened: false,
    });
  };

  render() {
    const {
      readonly,
      classes,
      entity,
      isCut,
      isChecked,
      onCheck,
      currentPath,
      isRootPath,
    } = this.props;

    const {
      anchorEl,
      renameModalOpened,
      confirmModalOpened,
      energyModalOpened,
    } = this.state;

    const {
      filesStore: { fetchingResources, uploadingFiles },
    } = this.injected;
    const { path } = entity;

    const size = fileSize(entity.size);

    const { sharedFilesContext, myFilesContext } = this.injected.filesStore;

    const isEntityFile = isFile(entity);
    const isEntityDirectory = isDirectory(entity);
    const isEntitySpecialDirectory = isSpecialDirectory(entity);
    const isEntityLowEnergy = isLowEnergySet(entity);
    const isEntityHighEnergy = isHighEnergySet(entity);
    const isEntityCTViewerSet = isCTViewerSet(entity);
    const isRowDisabled =
      isRootPath && isEntityFile && !myFilesContext && !sharedFilesContext;

    if (isEntityCTViewerSet) {
      this.singleViewerModalCallback = (e: any) =>
        this.handleOpenEnergyChoserModal();
    } else if (isEntityLowEnergy) {
      this.singleViewerModalCallback = (e: any) =>
        this.handlePrepareToOpenSingleViewer('low', true);
    } else if (isEntityHighEnergy) {
      this.singleViewerModalCallback = (e: any) =>
        this.handlePrepareToOpenSingleViewer('high', true);
    }

    return (
      <>
        {fetchingResources ? (
          <TableRow>
            <TableCell className={classes.loadingContainer}>
              <CircularProgress size={25} />
            </TableCell>
          </TableRow>
        ) : (
          <TableRow
            hover={isEntityDirectory || (isEntityFile && !isRootPath)}
            selected={isChecked}
            className={classNames({
              [classes.disabledRow]: isRowDisabled,
            })}
          >
            {!readonly && (
              <TableCell padding="checkbox">
                {!isEntityLowEnergy && !isEntityHighEnergy && (
                  <Checkbox checked={isChecked} onChange={onCheck} />
                )}
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              <Grid
                container
                alignItems="flex-start"
                spacing={16}
                className={classes.assetContainer}
              >
                <Grid
                  item
                  onClick={this.onRecordClick}
                  className={classes.iconContainer}
                >
                  {isEntityDirectory && (
                    <FolderIcon
                      className={classNames(classes.assetTypeIcon, {
                        [classes.cutRow]: isCut,
                        [classes.ctViewerSetFolder]: isEntitySpecialDirectory,
                      })}
                    />
                  )}

                  {isEntityFile && (
                    <DescriptionIcon
                      className={classNames(classes.assetTypeIcon, {
                        [classes.cutRow]: isCut,
                      })}
                    />
                  )}
                </Grid>

                <Grid
                  item
                  onClick={this.onRecordClick}
                  className={classes.fileName}
                >
                  <Typography className={classes.assetName} component="span">
                    {isEntityFile && (
                      <span className={classes.fileLink}>{entity.path}</span>
                    )}
                    {isEntitySpecialDirectory &&
                      getPathWithoutExtension(entity.path)}
                    {isEntityDirectory &&
                      !isEntitySpecialDirectory &&
                      entity.path}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <strong>{size}</strong>
            </TableCell>
            <TableCell className={classes.tableCell}>
              {entity.last_modified}
            </TableCell>

            {!readonly && (
              <TableCell className={classes.tableCell}>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={16}
                >
                  <Grid item />
                  <Grid item>
                    <MoreVertIcon
                      onClick={this.handleOpenMenu}
                      className={classes.moreIcon}
                    />

                    <ConfirmationModal
                      opened={confirmModalOpened}
                      title="Delete confirmation"
                      question={`Following action will delete: ${path}, are you sure?`}
                      onCancel={this.handleCloseConfirmModal}
                      onConfirm={this.handleDelete}
                    />

                    <RenameFileModal
                      opened={renameModalOpened}
                      title="Rename"
                      name={entity.path}
                      currentPath={currentPath}
                      isDirectory={isEntityDirectory}
                      onCancel={this.handleCloseRenameModal}
                      onConfirm={this.handleRenameSuccess}
                      isSpecialDirectory={isEntitySpecialDirectory}
                      isProjectListing
                    />

                    {energyModalOpened && (
                      <EnergyChoserModal
                        opened={energyModalOpened}
                        title="Select images"
                        name={getPathWithoutExtension(entity.path)}
                        onCancel={this.handleCloseEnergyChoserModal}
                        onConfirm={this.handlePrepareToOpenSingleViewer}
                      />
                    )}

                    <Menu
                      id="project-file-menu"
                      anchorEl={anchorEl}
                      open={!!anchorEl}
                      onClose={this.handleCloseMenu}
                    >
                      {entity.size !== 0 &&
                        (isEntityCTViewerSet ||
                          isEntityLowEnergy ||
                          isEntityHighEnergy) && (
                          <MenuItem onClick={this.singleViewerModalCallback}>
                            <ListItemIcon>
                              <VisibilityIcon />
                            </ListItemIcon>
                            <ListItemText>Open in SingleViewer</ListItemText>
                          </MenuItem>
                        )}

                      <MenuItem
                        disabled={uploadingFiles}
                        onClick={this.onDownload}
                      >
                        <ListItemIcon>
                          <DownloadIcon />
                        </ListItemIcon>
                        <ListItemText>Download</ListItemText>
                      </MenuItem>

                      {!sharedFilesContext &&
                        !isEntityLowEnergy &&
                        !isEntityHighEnergy && (
                          <MenuItem onClick={this.handleOpenRenameModal}>
                            <ListItemIcon>
                              <RateReviewIcon />
                            </ListItemIcon>
                            <ListItemText>Rename</ListItemText>
                          </MenuItem>
                        )}

                      <MenuItem onClick={this.handleOpenConfirmModal}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </TableCell>
            )}
          </TableRow>
        )}
      </>
    );
  }
}

export default withStyles(styles)(CustomTableRow);
