import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps } from '@reach/router';

import Login from '../pages/Login';
import { AuthStore } from '../stores/authStore';

//#region Types
interface Props {
  as: any; //TODO: Figure out proper type for this
  adminOnly?: boolean;
}

interface InjectedProps extends Props {
  authStore: AuthStore;
}
//#endregion

@inject('authStore')
@observer
class PrivateRoute extends Component<Props & RouteComponentProps> {
  get injected() {
    return this.props as InjectedProps;
  }
  render() {
    const { adminOnly, as: Comp, ...props } = this.props;
    const { isLoggedIn, isAdmin, isLabAdmin } = this.injected.authStore;
    const shouldSee = adminOnly
      ? isLoggedIn && (isAdmin || isLabAdmin)
      : isLoggedIn;

    return shouldSee ? <Comp {...props} /> : <Login />;
  }
}

export default PrivateRoute;
