import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import fileSize from 'filesize';
import produce from 'immer';
import { navigate } from '@reach/router';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

import RateReviewIcon from '@material-ui/icons/RateReview';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DescriptionIcon from '@material-ui/icons/Description';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';

import ShareEntityDialog from './tableRow/ShareEntityDialog';
import { AppStore } from 'stores/appStore';
import { LabsStore } from 'stores/labsStore';
import { UsersStore } from 'stores/usersStore';
import { FilesStore } from 'stores/files/filesStore';
import { SelectedFilesStore } from 'stores/files/selectedFilesStore';
import { GroupObject } from '../Groups';
import ConfirmationModal from 'components/ui/ConfirmationModal';
import RenameFileModal from 'components/ui/RenameFileModal';
import DownloadIcon from '@material-ui/icons/GetApp';
import { isFile, isDirectory } from 'utils/directoryUtils';
import { DownloadStore } from 'stores/files/downloadStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    tableCell: {
      alignItems: 'center',
      paddingRight: 24,
    },
    assetTypeIcon: {
      color: theme.palette.primary.main,
      marginTop: 2,
      height: 36,
      width: 36,
      cursor: 'pointer',
    },
    cutRow: {
      color: theme.palette.primary.light,
    },
    assetName: {
      fontWeight: 700,
      cursor: 'pointer',
    },
    fileLink: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    sharedLabel: {
      marginTop: 2,
      marginRight: 2,
    },
    sharedLabelSpan: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 240,
      display: 'inline-block',
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      border: 'none',
    },
    iconContainer: {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
    },
    fileName: {
      paddingLeft: '54px !important',
    },
    assetContainer: {
      position: 'relative',
    },
    disabledRow: {
      'pointer-events': 'none',
      opacity: 0.3,
    },
    moreIcon: {
      cursor: 'pointer',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  entity: Common.File;
  currentPath: string;
  isRootPath: boolean;
  isCut: boolean;
  isChecked: boolean;
  readonly: boolean;
  onCheck: () => void;
  onToggleLightBox: (file: Common.File) => void;
}

interface InjectedProps extends Props {
  appStore: AppStore;
  labsStore: LabsStore;
  usersStore: UsersStore;
  selectedFilesStore: SelectedFilesStore;
  filesStore: FilesStore;
  downloadStore: DownloadStore;
}

interface Email {
  value: string;
  id: string;
}

interface State {
  anchorEl: HTMLElement | null;
  shareModalOpened: boolean;
  emails: Email[];
  groups: GroupObject[];
  entities: Array<any>;
  shareInProgress: boolean;
  confirmModalOpened: boolean;
  renameModalOpened: boolean;
}
//#endregion

@inject(
  'appStore',
  'labsStore',
  'usersStore',
  'selectedFilesStore',
  'groupsStore',
  'filesStore',
  'downloadStore',
)
@observer
class CustomTableRow extends Component<Props, State> {
  state: State = {
    anchorEl: null,
    shareModalOpened: false,
    emails: [],
    groups: [],
    entities: [],
    shareInProgress: false,
    confirmModalOpened: false,
    renameModalOpened: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  get resourcePath() {
    return `${this.props.currentPath}/${this.props.entity.path}`;
  }

  mounted = false;

  componentDidMount() {
    this.mounted = true;
    this.updateSharesInfo();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onRecordClick = () => {
    const { appStore } = this.injected;
    if (appStore.isLoading) {
      return;
    }

    const { entity, currentPath, onToggleLightBox } = this.props;

    if (isFile(entity)) {
      onToggleLightBox(entity);
      return;
    }

    navigate(`/labs/${currentPath}/${entity.path}`);
  };

  onDownloadFile = () => {
    const { downloadStore } = this.injected;
    const { entity } = this.props;
    downloadStore.download([entity]);
  };

  handleOpenMenu = (event: React.MouseEvent<any>) =>
    this.setState({ anchorEl: event.currentTarget });
  handleCloseMenu = () => this.setState({ anchorEl: null });

  handleDelete = async () => {
    const { uri } = this.props.entity;

    try {
      await this.injected.selectedFilesStore.deleteFile(uri);
      this.injected.filesStore.refresh();
    } catch (error) {
      this.injected.appStore.showErrorToaster(error.data.message);
      this.handleCloseConfirmModal();
    }
  };

  handleOpenConfirmModal = () => {
    this.setState({
      confirmModalOpened: true,
    });
  };

  handleCloseConfirmModal = () => {
    this.setState({
      confirmModalOpened: false,
    });
  };

  handleOpenRenameModal = () => {
    this.setState({
      renameModalOpened: true,
    });
  };

  handleCloseRenameModal = () => {
    this.setState({
      renameModalOpened: false,
    });
  };

  handleRenameSuccess = () => {
    this.injected.appStore.showSuccessToaster('The name has been changed');
  };

  handleOpenSharing = () => {
    this.setState({
      shareModalOpened: true,
    });
  };

  handleCloseSharing = () => {
    this.setState({
      shareModalOpened: false,
    });
  };

  handleAddShare = async (email: string, group: boolean) => {
    this.setState({
      shareInProgress: true,
    });

    this.injected.filesStore
      .setResourceShare(this.resourcePath, email, group)
      .then(({ success }: { success: boolean }) =>
        this.onAddShareSuccess(success),
      )
      .catch(() => {
        this.onAddShareError();
      });
  };

  handleRemoveShare = (value: string, group: boolean) => {
    this.setState({
      shareInProgress: true,
    });

    this.injected.filesStore
      .removeResourceShare(this.resourcePath, value, group)
      .then(() => {
        this.updateSharesInfo();
        this.setState({
          shareInProgress: false,
        });
      })
      .catch(() => {
        this.injected.appStore.showErrorToaster(`Failed to remove ${value}`);
        this.setState({
          shareInProgress: false,
        });
      });
  };

  updateSharesInfo = () => {
    const {
      filesStore,
      usersStore: { getUserByProperty },
    } = this.injected;
    const path = `${this.props.currentPath}/${this.props.entity.path}`;

    filesStore.getResourceShares(path).then(({ data }) => {
      if (!this.mounted || !data) {
        return;
      }

      const entities = data.map((item: any) => {
        let sharedBy = item.share_with;
        const sharedByUser =
          item.share_type === 0
            ? getUserByProperty('email', item.share_with)
            : [];
        if (sharedByUser.length) {
          sharedBy = sharedByUser[0].full_name;
        }

        return {
          id: item.share_with_displayname,
          value: item.share_with,
          share_type: item.share_type,
          full_name: sharedBy,
        };
      });

      const emails = entities.filter((item: any) => item.share_type === 0);

      const groups = entities.filter((item: any) => item.share_type === 1);

      this.setState(
        produce<State>(draft => {
          draft.emails = emails;
          draft.groups = groups;
          draft.entities = entities.map((item: any) => item.full_name);
        }),
      );
    });
  };

  private onAddShareSuccess = (success: boolean) => {
    if (success) {
      this.updateSharesInfo();
      this.setState({
        shareInProgress: false,
      });
    } else {
      this.onAddShareError();
    }
  };

  private onAddShareError() {
    this.injected.appStore.showErrorToaster(
      `File/Directory already shared with this user`,
    );
    this.setState({
      shareInProgress: false,
    });
  }

  render() {
    const {
      readonly,
      classes,
      entity,
      isCut,
      isChecked,
      onCheck,
      currentPath,
      isRootPath,
    } = this.props;

    const {
      anchorEl,
      emails,
      groups,
      confirmModalOpened,
      renameModalOpened,
      entities,
      shareInProgress,
      shareModalOpened,
    } = this.state;

    const {
      usersStore: { getUserByProperty },
      filesStore: { uploadingFiles },
      labsStore: { fetchingResources },
    } = this.injected;
    const { path } = entity;

    const size = fileSize(entity.size);
    const resourcePath = `${currentPath}/${entity.path}`;

    const { sharedFilesContext, myFilesContext } = this.injected.filesStore;
    const sharedWith = entities.join(', ');

    const isEntityFile = isFile(entity);
    const isEntityDirectory = isDirectory(entity);
    const isRowDisabled =
      isRootPath && isEntityFile && !myFilesContext && !sharedFilesContext;

    let sharedBy = null;
    const sharedByUser = entity.sharedBy
      ? getUserByProperty('email', entity.sharedBy)
      : [];
    if (sharedByUser.length) {
      sharedBy = sharedByUser[0].full_name;
    }

    return (
      <>
        {fetchingResources ? (
          <TableRow>
            <TableCell className={classes.loadingContainer}>
              <CircularProgress size={25} />
            </TableCell>
          </TableRow>
        ) : (
          <TableRow
            hover={isEntityDirectory || (isEntityFile && !isRootPath)}
            selected={isChecked}
            className={classNames({
              [classes.disabledRow]: isRowDisabled,
            })}
          >
            {!readonly && (
              <TableCell padding="checkbox">
                <Checkbox checked={isChecked} onChange={onCheck} />
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              <Grid
                container
                alignItems="center"
                spacing={16}
                className={classes.assetContainer}
              >
                <Grid
                  item
                  onClick={this.onRecordClick}
                  className={classes.iconContainer}
                >
                  {isEntityDirectory && (
                    <FolderIcon
                      className={classNames(classes.assetTypeIcon, {
                        [classes.cutRow]: isCut,
                      })}
                    />
                  )}

                  {isEntityFile && (
                    <DescriptionIcon
                      className={classNames(classes.assetTypeIcon, {
                        [classes.cutRow]: isCut,
                      })}
                    />
                  )}
                </Grid>

                <Grid
                  item
                  onClick={this.onRecordClick}
                  className={classes.fileName}
                >
                  <Typography className={classes.assetName} component="span">
                    {entity.type === 'file' && (
                      <span className={classes.fileLink}>{entity.path}</span>
                    )}
                    {entity.type === 'directory' && entity.path}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell className={classes.tableCell}>
              {sharedWith && (
                <Chip
                  className={classes.sharedLabel}
                  label={sharedWith}
                  classes={{
                    label: classes.sharedLabelSpan,
                  }}
                />
              )}
              {isRowDisabled && (
                <Chip
                  className={classes.sharedLabel}
                  label="Created in ownCloud"
                  classes={{
                    label: classes.sharedLabelSpan,
                  }}
                />
              )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <strong>{size}</strong>
            </TableCell>
            {sharedFilesContext && (
              <TableCell className={classes.tableCell}>
                {sharedBy && (
                  <Chip className={classes.sharedLabel} label={sharedBy} />
                )}
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              {entity.last_modified}
            </TableCell>

            {!readonly && (
              <TableCell className={classes.tableCell}>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={16}
                >
                  <Grid item>
                    <ShareEntityDialog
                      hideGroups={sharedFilesContext}
                      opened={shareModalOpened}
                      emails={emails}
                      groups={groups}
                      path={resourcePath}
                      requestInProgress={shareInProgress}
                      onAddShare={this.handleAddShare}
                      onRemoveShare={this.handleRemoveShare}
                      onUpdateShares={this.updateSharesInfo}
                      onClose={this.handleCloseSharing}
                    />
                    {!sharedFilesContext && (
                      <Tooltip title="Share with">
                        <IconButton onClick={this.handleOpenSharing}>
                          <ReplyAllIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item>
                    <MoreVertIcon
                      onClick={this.handleOpenMenu}
                      className={classes.moreIcon}
                    />

                    <ConfirmationModal
                      opened={confirmModalOpened}
                      title="Delete confirmation"
                      question={`Following action will delete: ${path}, are you sure?`}
                      onCancel={this.handleCloseConfirmModal}
                      onConfirm={this.handleDelete}
                    />

                    <RenameFileModal
                      opened={renameModalOpened}
                      title="Rename"
                      name={entity.path}
                      currentPath={currentPath}
                      isDirectory={isEntityDirectory}
                      onCancel={this.handleCloseRenameModal}
                      onConfirm={this.handleRenameSuccess}
                    />

                    <Menu
                      id="user-menu"
                      anchorEl={anchorEl}
                      open={!!anchorEl}
                      onClose={this.handleCloseMenu}
                    >
                      <MenuItem
                        disabled={uploadingFiles}
                        onClick={this.onDownloadFile}
                      >
                        <ListItemIcon>
                          <DownloadIcon />
                        </ListItemIcon>
                        <ListItemText>Download</ListItemText>
                      </MenuItem>

                      {!sharedFilesContext && (
                        <MenuItem onClick={this.handleOpenRenameModal}>
                          <ListItemIcon>
                            <RateReviewIcon />
                          </ListItemIcon>
                          <ListItemText>Rename</ListItemText>
                        </MenuItem>
                      )}

                      <MenuItem onClick={this.handleOpenConfirmModal}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </TableCell>
            )}
          </TableRow>
        )}
      </>
    );
  }
}

export default withStyles(styles)(CustomTableRow);
