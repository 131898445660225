import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Formik } from 'formik';
import { RouteComponentProps } from '@reach/router';
import produce from 'immer';

import GroupAddIcon from '@material-ui/icons/GroupAdd';
import createStyles from '@material-ui/core/styles/createStyles';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import GroupList from './groups/GroupList';
import GroupEdit from './groups/GroupEdit';
import GroupMembers from './groups/GroupMembers';
import GroupPreview from './groups/GroupPreview';

import BoxColumn from '../../components/ui/BoxColumn';
import Box from '../../components/ui/boxColumn/Box';
import Modal from '../../components/ui/Modal';

import { GroupsStore } from './../../stores/groupsStore';
import { AuthStore } from './../../stores/authStore';
import { UsersStore } from './../../stores/usersStore';

//#region styles
const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
    },
    searchHeader: {
      fontWeight: 'bold',
    },
    searchInput: {
      marginBottom: 50,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  labName: string;
}

enum VIEW_MODE {
  READONLY,
  NEW_GROUP,
  EDIT_GROUP,
  MEMBERS_MODAL,
  SEARCH,
}

export interface GroupObjectUser {
  id: number;
  email: string;
  username: string;
  full_name: string;
}

export interface GroupObject {
  id: number;
  name: string;
  creator: string;
  group_type: 'department' | 'custom';
  department_name: string;
  users: GroupObjectUser[];
}

interface State {
  mode: VIEW_MODE;
  activeGroup: GroupObject | null;
}

interface InjectedProps extends Props {
  groupsStore: GroupsStore;
  authStore: AuthStore;
  usersStore: UsersStore;
}

//#endregion

export function isQueryMatchUser(user: GroupObjectUser, query: string) {
  const { full_name, email } = user;
  const lQuery = query.toLowerCase();
  const lUserName = full_name.toLowerCase();
  const lEmail = email.toLowerCase();
  return lUserName.includes(lQuery) || lEmail.includes(lQuery);
}

export function sortGroupByCreator(
  users: Array<GroupObjectUser>,
  creator: string,
) {
  return produce(users, newArray => {
    newArray.sort((userA, userB) =>
      userA.email === creator ? -1 : userB.email === creator ? 1 : 0,
    );
  });
}

function isGroupContainUser(group: GroupObject, query: string) {
  if (!query) {
    return true;
  }

  const filter = (user: GroupObjectUser) => isQueryMatchUser(user, query);
  return group.users.some(filter);
}

@inject('groupsStore', 'authStore', 'usersStore')
@observer
class Groups extends Component<Props, State> {
  state = {
    mode: VIEW_MODE.READONLY,
    activeGroup: null,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    this.loadAllUsers();
    this.loadGroups();
  }

  loadAllUsers = async () => {
    const { usersStore } = this.injected;

    if (usersStore.users.length === 0) {
      await usersStore.getUsersList();
    }
  };

  loadGroups = async () => {
    const { labName } = this.props;
    this.injected.groupsStore.getLabGroups(labName);
  };

  toggleSearch = () => {
    this.setState((prev: State) => {
      const current = prev.mode;
      const newMode =
        current === VIEW_MODE.SEARCH ? VIEW_MODE.READONLY : VIEW_MODE.SEARCH;

      return {
        ...prev,
        mode: newMode,
        activeGroup: null,
      };
    });
  };

  closeMembersModal = () => {
    this.setState({
      mode: VIEW_MODE.READONLY,
      activeGroup: null,
    });
  };

  openMembersModal = (group: GroupObject) => (event: any) => {
    this.setState({
      mode: VIEW_MODE.MEMBERS_MODAL,
      activeGroup: group,
    });

    event.stopPropagation();
  };

  addGroup = () =>
    this.setState({
      activeGroup: null,
      mode: VIEW_MODE.NEW_GROUP,
    });

  selectGroup = (group: GroupObject) => () => {
    const alreadySelected = this.state.activeGroup === group;

    this.setState({
      activeGroup: alreadySelected ? null : group,
      mode: alreadySelected ? VIEW_MODE.READONLY : VIEW_MODE.EDIT_GROUP,
    });
  };

  deselectGroup = () =>
    this.setState({
      activeGroup: null,
      mode: VIEW_MODE.READONLY,
    });

  render() {
    const { classes, labName } = this.props;
    const {
      usersStore: { listOfUsers, fetching },
    } = this.injected;
    const currentUser = this.injected.authStore.user.email!;
    const isAdmin = this.injected.authStore.isAdmin;

    const membersModalOpened = this.state.mode === VIEW_MODE.MEMBERS_MODAL;
    const newGroup = this.state.mode === VIEW_MODE.NEW_GROUP;
    const editGroup = this.state.mode === VIEW_MODE.EDIT_GROUP;
    const searchOpened = this.state.mode === VIEW_MODE.SEARCH;

    const { activeGroup } = this.state;

    const groupList = this.injected.groupsStore.labGroups;

    return (
      <div className={classes.root}>
        {fetching ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={75} />
          </div>
        ) : (
          <>
            <BoxColumn>
              <Box onClick={this.toggleSearch}>
                <Tooltip title="Users search">
                  <SearchIcon />
                </Tooltip>
              </Box>
              <Box onClick={this.addGroup}>
                <Tooltip title="Create new group">
                  <GroupAddIcon />
                </Tooltip>
              </Box>
            </BoxColumn>

            <GroupList>
              <Formik
                initialValues={{
                  searchQuery: '',
                }}
                onSubmit={() => {}}
              >
                {({ values, handleChange }) => (
                  <form noValidate onSubmit={(e: any) => e.preventDefault()}>
                    {searchOpened && (
                      <>
                        <Typography className={classes.searchHeader}>
                          Search
                        </Typography>
                        <TextField
                          autoFocus
                          id="searchQuery"
                          margin="normal"
                          name="searchQuery"
                          className={classes.searchInput}
                          placeholder="Name, surname or email"
                          value={values.searchQuery}
                          onChange={handleChange}
                          fullWidth
                        />
                      </>
                    )}

                    {groupList
                      .filter((group: GroupObject) =>
                        isGroupContainUser(group, values.searchQuery),
                      )
                      .map((group: GroupObject) => (
                        <GroupPreview
                          key={group.id}
                          group={group}
                          labName={labName}
                          active={group === activeGroup}
                          query={values.searchQuery}
                          onEdit={this.openMembersModal(group)}
                          onClick={this.selectGroup(group)}
                          editable={currentUser === group.creator}
                        />
                      ))}
                  </form>
                )}
              </Formik>
            </GroupList>

            {newGroup && (
              <GroupEdit
                labName={labName}
                onUpdate={this.loadGroups}
                close={this.deselectGroup}
                allGroups={groupList}
                editable={true}
              />
            )}

            {editGroup && activeGroup && (
              <GroupEdit
                labName={labName}
                allGroups={groupList}
                group={activeGroup}
                onUpdate={this.loadGroups}
                close={this.deselectGroup}
                editable={
                  isAdmin || (activeGroup as any).creator === currentUser
                }
              />
            )}

            {!!activeGroup && membersModalOpened && (
              <Modal
                open={membersModalOpened}
                closeModal={this.closeMembersModal}
                title="Add User to group"
              >
                <GroupMembers
                  group={activeGroup!}
                  labName={labName}
                  labUsers={listOfUsers}
                  onUpdate={this.loadGroups}
                  closeModal={this.closeMembersModal}
                />
              </Modal>
            )}
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Groups);
