import drawLine from './drawLine';
import drawText from './drawText';
import convertToUnit from './convertToUnit';

import {
  mediumLineWidth,
  markerTextVerticalOffset,
  rulerOffset,
  rightPadding,
} from './spacings';

// Adds marker to coronal/sagittal (xz/zy) output images marking heights at which
// exported axial images (slices) are

export default (
  context: CanvasRenderingContext2D,
  sliceParams: any[],
  sliceNumber: number,
  heightScreenPx: number,
  leftOffset: number,
  verticalOffset: number,
  depthUnit: string,
  decimalDigits: number,
) => {
  const lineStartX = leftOffset + rulerOffset;
  const lineEndX = leftOffset + rulerOffset + mediumLineWidth;

  sliceParams.forEach(singleSliceParams => {
    const y =
      (singleSliceParams.sliceIndex / sliceNumber) * heightScreenPx +
      verticalOffset;
    const depth = convertToUnit(singleSliceParams.depthM, depthUnit);

    drawLine(context, lineStartX, y, lineEndX, y, '#4a90e2');
    drawLine(context, lineStartX, y + 1, lineEndX, y + 1, '#4a90e2');
    drawText(
      context,
      `${depth.toFixed(decimalDigits)}`,
      lineEndX + markerTextVerticalOffset,
      y,
      'left',
      'middle',
      '#4a90e2',
    );
  });

  // Axial Images in 2 lines
  drawText(
    context,
    `Axial`,
    leftOffset + rightPadding - markerTextVerticalOffset - 2,
    verticalOffset + heightScreenPx + markerTextVerticalOffset + 2,
    'right',
    'top',
    '#4a90e2',
  );
  drawText(
    context,
    `Images`,
    leftOffset + rightPadding - markerTextVerticalOffset - 2,
    verticalOffset + heightScreenPx + markerTextVerticalOffset + 12,
    'right',
    'top',
    '#4a90e2',
  );
};
