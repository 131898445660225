import React, { PureComponent } from 'react';
/*
Adds drag functionality to
Adds props to component:

onMouseDown - should be connected to inner component HTML element onMouseDown={this.props.onMouseDown}
onMouseDownSaveInitialState - should be called with event (from HTML onMouseDown) and with initial state to save
registerOnDrag - registers callback function called on drag, callback should accept arguments (x, y, initialState)
registerSetDragInitialState - registers function called on drag start that should return initial state. Not called if drag begins with onMouseDownSaveInitialState

Callback functions should be bound to inner element.
Initial state could be anything, it's just passed to the callback.
*/

interface Props {}

interface State {
  isDragged?: boolean;
  initialDragX: number | null;
  initialDragY: number | null;
  initialState: any;
}

function withDrag(WrappedComponent: any) {
  return class extends PureComponent<Props, State> {
    constructor(props: any) {
      super(props);

      this.state = {
        isDragged: false,
        initialDragX: null, // in screen px
        initialDragY: null,
        initialState: undefined,
      };

      this.handleMouseDown = this.handleMouseDown.bind(this);
      this.handleMouseDownInitialState = this.handleMouseDownInitialState.bind(
        this,
      );
      this.handleMouseMove = this.handleMouseMove.bind(this);
      this.handleMouseUp = this.handleMouseUp.bind(this);

      this.registerSetDragInitialState = this.registerSetDragInitialState.bind(
        this,
      );
      this.registerOnDrag = this.registerOnDrag.bind(this);
    }

    setDragInitialState = () => {};

    onDrag = () => {};

    componentDidMount() {
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);
    }

    componentWillUnmount() {
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.handleMouseUp);
    }

    registerSetDragInitialState(callback: any) {
      this.setDragInitialState = callback;
    }

    registerOnDrag(callback: any) {
      this.onDrag = callback;
    }

    handleMouseDown(ev: any) {
      let initialState;
      if (this.setDragInitialState) {
        initialState = this.setDragInitialState();
      }
      this.handleMouseDownInitialState(ev, initialState);
    }

    handleMouseDownInitialState(ev: any, initialState: any) {
      ev.stopPropagation();
      this.setState({
        isDragged: true,
        initialDragX: ev.clientX,
        initialDragY: ev.clientY,
        initialState,
      });
    }

    handleMouseMove(ev: any) {
      if (!this.state.isDragged) return;
      if (this.onDrag) {
        const { initialState, initialDragX, initialDragY } = this.state;
        const xDiff = ev.clientX - initialDragX!;
        const yDiff = ev.clientY - initialDragY!;
        // @ts-ignore
        this.onDrag(xDiff, yDiff, initialState);
      }
    }

    handleMouseUp() {
      this.setState({
        isDragged: false,
        initialState: undefined,
      });
    }

    render() {
      return (
        <WrappedComponent
          onMouseDown={this.handleMouseDown}
          onMouseDownSaveInitialState={this.handleMouseDownInitialState}
          registerOnDrag={this.registerOnDrag}
          registerSetDragInitialState={this.registerSetDragInitialState}
          {...this.props}
        />
      );
    }
  };
}

export default withDrag;
