import React, { memo } from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999,
      background: 'rgba(0, 0, 0, 0.2)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loaderBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    progress: {
      borderRadius: '50%',
      boxShadow: 'inset 0 0 0 6px #fff',
    },
    processing: {
      marginTop: '20px',
      borderRadius: '6px',
      padding: '4px 14px',
      background: '#fff',
      maxWidth: '400px',
      textAlign: 'center',
    },
    processingText: {
      fontSize: '1rem',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  value: number;
}
//#endregion

const Loader: React.FunctionComponent<Props> = ({
  classes,
  value,
  children,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.loaderBox}>
        <CircularProgress
          variant="static"
          value={value}
          size={50}
          thickness={6}
          classes={{ static: classes.progress }}
        />
        <div className={classes.processing}>
          <Typography
            variant="subtitle1"
            component="strong"
            className={classes.processingText}
          >
            {children}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(Loader));
