import { CTImage } from '../stores/ctViewerStore';
import {
  CROP_RADIUS,
  CROP_CENTER_POSITION_X,
  CROP_CENTER_POSITION_Y,
  CROP_TOP_POSITION,
  CROP_BOTTOM_POSITION,
} from './sizingConstants';

function calculateDefaultCrop(xzImage: CTImage, numberOfImages: number) {
  const cropBottom = Math.round(xzImage.rows * 0.95);
  return {
    cropCenterX: CROP_CENTER_POSITION_X,
    cropCenterY: CROP_CENTER_POSITION_Y,
    cropTop: CROP_TOP_POSITION,
    cropBottom:
      cropBottom < CROP_BOTTOM_POSITION ? cropBottom : CROP_BOTTOM_POSITION,
    cropRadius: CROP_RADIUS,
    displayedSliceHeight: Math.round(numberOfImages / 2),
  };
}

export default calculateDefaultCrop;
