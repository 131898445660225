import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import find from 'lodash-es/find';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import MuiButton from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AutoCompleter from '../../../components/ui/AutoCompleter';

import Button from '../../../components/ui/Button';
import { UsersStore } from '../../../stores/usersStore';
import { AuthStore } from '../../../stores/authStore';
import { ProjectsStore } from '../../../stores/projectsStore';

import { AddUserInput } from '../Users';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '40px 20px 0 32px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    form: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      marginBottom: 16,
    },
    secondaryHeader: {
      fontSize: theme.typography.pxToRem(14),
      color: '#8A8B8D',
    },
    baseInfoSection: {
      marginBottom: 40,
    },
    permissionsSection: {
      marginTop: 20,
      backgroundColor: '#E8EEF0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 160,
      width: '100%',
      borderRadius: 10,
    },
    removeUserButton: {
      alignSelf: 'flex-start',
      color: '#D15151',
    },
    tabsRoot: {
      borderBottom: '1px solid #e8e8e8',
      marginBottom: '1rem',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
    bottomActions: {
      paddingTop: 52,
      paddingBottom: 52,
      borderTop: `1px dashed ${theme.palette.primary.main}`,
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  onSave: (userPayload: AddUserInput) => void;
  onDeleteFromProject: () => void;
  onCancel: () => void;
  projectId: number;
}

interface InjectedProps extends Props {
  usersStore: UsersStore;
  authStore: AuthStore;
  projectsStore: ProjectsStore;
}

interface State {
  tabValue: number;
  confirmationOpened: boolean;
}
//#endregion

@inject('usersStore', 'authStore', 'projectsStore')
@observer
class UserEdit extends React.Component<Props, State> {
  state = {
    tabValue: 0,
    confirmationOpened: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  setTabValue = (event: React.ChangeEvent<{}>, tabValue: number) => {
    this.setState({ tabValue });
  };

  async componentDidMount() {
    const { usersStore } = this.injected;

    if (usersStore.users.length === 0) {
      await usersStore.getUsersList();
    }
  }

  handleCancel = () => {
    this.setState({
      confirmationOpened: false,
    });
  };

  handleConfirm = () => {
    this.setState({
      confirmationOpened: false,
    });

    this.props.onDeleteFromProject();
  };

  openRemovalDialog = () => {
    this.setState({
      confirmationOpened: true,
    });
  };

  getButtonText = () => {
    const {
      usersStore,
      projectsStore: { selectedClient },
    } = this.injected;

    if (usersStore.selectedUser) {
      if (usersStore.saving) {
        return 'Saving...';
      } else {
        return 'Save';
      }
    } else {
      if (usersStore.saving) {
        return 'Inviting...';
      } else if (selectedClient) {
        return 'Resend invitation';
      } else {
        return 'Invite';
      }
    }
  };

  render() {
    const { classes, onSave, onCancel } = this.props;
    const { confirmationOpened, tabValue } = this.state;

    const {
      selectedUser,
      saving,
      getUserByProperty,
      getUsersForLab,
    } = this.injected.usersStore;

    const {
      selectedRole,
      currentProject,
      selectedClient,
    } = this.injected.projectsStore;
    const { userData, isAdmin } = this.injected.authStore;

    const isCurrentUserLabAdmin = (userData.lab_admin_labs_full || []).includes(
      currentProject!.lab.lab_name,
    );

    const listOfManagers = currentProject
      ? toJS(currentProject.project_managers)
      : [];
    const listOfTechnicians = currentProject
      ? toJS(currentProject.technicians)
      : [];

    const users = getUsersForLab(currentProject!.lab.lab_name)
      .map(user => ({
        value: user.email,
        label: user.full_name,
      }))
      .filter(user => user.value !== userData.email)
      .filter(user => !Boolean(find(listOfManagers, ['email', user.value])))
      .filter(user => !Boolean(find(listOfTechnicians, ['email', user.value])));

    const pmForCurrentProject = currentProject ? listOfManagers : [];
    const isPMForCurrentProject = Boolean(
      find(pmForCurrentProject, ['email', userData.email]),
    );

    const isFormEditable =
      isAdmin || isCurrentUserLabAdmin || isPMForCurrentProject;

    //#region form config
    let initialValues: any;

    if (selectedClient) {
      initialValues = {
        fullName: selectedClient.full_name,
        email: selectedClient.email,
        role: selectedRole,
      };
    } else {
      initialValues = {
        fullName: selectedUser ? selectedUser.full_name : '',
        email: selectedUser ? selectedUser.email : '',
        role:
          selectedUser && selectedRole
            ? selectedRole
            : tabValue === 1
            ? 'client'
            : 'technician',
      };
    }

    const validationSchema = Yup.object().shape({
      fullName: Yup.string().required('Field is required'),
      email: Yup.string()
        .email()
        .required('Field is required'),
      role: Yup.string().required('Field is required'),
    });
    //#endregion

    const selectedUserOrClient = selectedUser || selectedClient;
    const canRemoveUser =
      selectedUserOrClient && selectedUserOrClient.email !== userData.email;

    return (
      <div className={classes.root}>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          open={confirmationOpened}
        >
          <DialogTitle>User removal</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to remove this user from the project?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button width={100} onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button width={100} onClick={this.handleConfirm} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {isFormEditable && (
          <Typography className={classes.header}>
            {selectedUserOrClient
              ? selectedClient
                ? `Edit user's invitation`
                : initialValues.role === 'client'
                ? `Edit user's profile`
                : `User's profile`
              : `Invite user`}
          </Typography>
        )}

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values: AddUserInput) => {
            return onSave(values);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
            resetForm,
          }) => {
            const user = getUserByProperty('email', values.email)[0];

            return (
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                {selectedUserOrClient ? (
                  <Grid
                    container
                    spacing={24}
                    className={classes.baseInfoSection}
                  >
                    <Grid item xs={6}>
                      <TextField
                        autoFocus
                        required
                        fullWidth
                        id="fullName"
                        label="Full Name"
                        margin="normal"
                        disabled={
                          !isFormEditable ||
                          (initialValues.role === 'projectManager' ||
                            initialValues.role === 'technician')
                        }
                        error={!!(errors.fullName && touched.fullName)}
                        helperText={
                          errors.fullName && touched.fullName
                            ? errors.fullName
                            : ''
                        }
                        value={values.fullName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        margin="normal"
                        disabled={
                          Boolean(selectedUser) ||
                          Boolean(selectedClient) ||
                          !isFormEditable
                        }
                        error={!!(errors.email && touched.email)}
                        helperText={
                          errors.email && touched.email ? errors.email : ''
                        }
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} />

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="role"
                        name="role"
                        select
                        label="Role"
                        margin="normal"
                        value={values.role}
                        disabled={true}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => handleChange(event)}
                      >
                        <MenuItem value="projectManager">
                          Project Manager
                        </MenuItem>
                        <MenuItem value="technician">Technician</MenuItem>
                        <MenuItem value="client">Client</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                ) : (
                  <div>
                    <Tabs
                      value={tabValue}
                      onChange={(event, tabValue) => {
                        this.setTabValue(event, tabValue);
                        resetForm(initialValues);
                      }}
                      classes={{ indicator: classes.indicator }}
                      className={classes.tabsRoot}
                    >
                      <Tab label="Existing user" />
                      <Tab label="Client" />
                    </Tabs>

                    {tabValue === 0 && (
                      <Grid
                        container
                        spacing={24}
                        className={classes.baseInfoSection}
                      >
                        <Grid item xs={6}>
                          <AutoCompleter
                            autoFocus
                            options={users}
                            label="Choose existing user"
                            error={!!errors.email}
                            helperText={errors.email || ''}
                            value={{
                              value: values.email,
                              label: user ? user.full_name : '',
                            }}
                            onChange={(e, email) => {
                              if (!email) {
                                setFieldValue('fullName', '');
                              } else {
                                setFieldValue(
                                  'fullName',
                                  // @ts-ignore
                                  users.find(user => user.value === email)
                                    .label,
                                );
                              }

                              return setFieldValue('email', email);
                            }}
                            onBlur={() => {}}
                            id="email"
                            name="email"
                            noOptionsMessage="No users found"
                            isClearable
                          />

                          <Typography
                            className={classes.header}
                            style={{ marginTop: '2rem', marginBottom: '2rem' }}
                          >
                            Profile Information
                          </Typography>

                          <TextField
                            autoFocus
                            required
                            fullWidth
                            id="fullName"
                            label="Full Name"
                            margin="normal"
                            disabled={true}
                            error={!!(errors.fullName && touched.fullName)}
                            helperText={
                              errors.fullName && touched.fullName
                                ? errors.fullName
                                : ''
                            }
                            value={values.fullName}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            margin="normal"
                            disabled={true}
                            error={!!(errors.email && touched.email)}
                            helperText={
                              errors.email && touched.email ? errors.email : ''
                            }
                            value={values.email}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6} />

                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="role"
                            name="role"
                            select
                            label="Role"
                            margin="normal"
                            value={values.role}
                            disabled={!isFormEditable}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => handleChange(event)}
                          >
                            <MenuItem value="projectManager">
                              Project Manager
                            </MenuItem>
                            <MenuItem value="technician">Technician</MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    )}
                    {tabValue === 1 && (
                      <Grid
                        container
                        spacing={24}
                        className={classes.baseInfoSection}
                      >
                        <Grid item xs={6}>
                          <TextField
                            autoFocus
                            required
                            fullWidth
                            id="fullName"
                            label="Full Name"
                            margin="normal"
                            disabled={!isFormEditable}
                            error={!!(errors.fullName && touched.fullName)}
                            helperText={
                              errors.fullName && touched.fullName
                                ? errors.fullName
                                : ''
                            }
                            value={values.fullName}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            margin="normal"
                            disabled={Boolean(selectedUser) || !isFormEditable}
                            error={!!(errors.email && touched.email)}
                            helperText={
                              errors.email && touched.email ? errors.email : ''
                            }
                            value={values.email}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6} />

                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="role"
                            name="role"
                            select
                            label="Role"
                            margin="normal"
                            value={values.role}
                            disabled={true}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => handleChange(event)}
                          >
                            <MenuItem value="client">Client</MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                )}

                {isFormEditable && (
                  <>
                    <div style={{ flex: 1 }} />
                    <section className={classes.bottomActions}>
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          {canRemoveUser && (
                            <MuiButton
                              disabled={saving}
                              onClick={this.openRemovalDialog}
                              className={classes.removeUserButton}
                            >
                              <DeleteIcon fontSize="small" /> Remove From
                              Project
                            </MuiButton>
                          )}
                        </Grid>
                        <Grid item>
                          {selectedUser &&
                          initialValues.role !== 'client' ? null : (
                            <Grid container alignItems="center" spacing={24}>
                              <Grid item>
                                <MuiButton
                                  tabIndex={-1}
                                  disabled={saving}
                                  onClick={onCancel}
                                >
                                  Cancel
                                </MuiButton>
                              </Grid>
                              <Grid item>
                                <Button
                                  disabled={this.injected.usersStore.saving}
                                  type="submit"
                                  width={170}
                                >
                                  {this.getButtonText()}
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </section>
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default withStyles(styles)(UserEdit);
