import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 32,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {}
//#endregion

const Results: React.FunctionComponent<Props> = ({ classes }) => (
  <div className={classes.root}>Results</div>
);

export default withStyles(styles)(Results);
