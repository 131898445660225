import React, { useState, memo } from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import logoImage from '../../../assets/images/petricloud.png';
import SingleViewerWrapper from './ctViewer/SingleViewerWrapper';
import DualEnergy from './ctViewer/DualEnergy';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      padding: '0 16px',
      backgroundColor: '#1c1d1f',
      color: '#fff',
      boxShadow:
        '7px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
      position: 'relative',
      zIndex: 999,
    },
    content: {
      backgroundColor: '#F8FAFA',
      border: 'none',
      position: 'relative',
      padding: 0,
      overflow: 'hidden',
    },
    closeButton: {
      position: 'absolute',
      right: 3,
      top: 3,
      color: '#fff',
      fontSize: theme.typography.pxToRem(28),
      cursor: 'pointer',
    },
    logo: {
      width: 125,
      position: 'relative',
      top: 0,
    },
    tabs: {
      display: 'inline-block',
      minHeight: '56px',
      marginLeft: 24,
    },
    tab: {
      fontSize: '14px',
      minHeight: '56px',
    },
    tabLabelContainer: {
      padding: '6px 24px 10px 24px',
    },
    tabIndicator: {
      display: 'none',
    },
    tabSelected: {
      background: '#424242',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  open: boolean;
  closeModal: () => void;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
//#endregion

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 20,
    border: '1px solid #bbb',
    borderLeft: 'none',
    borderRight: 'none',
  },
}))(MuiDialogContent);

const CTViewer: React.FunctionComponent<Props> = ({
  classes,
  open,
  closeModal,
}) => {
  const projectTabs = [
    {
      label: 'Single Viewer',
      value: 'singleViewer',
    },
    // {
    //   label: 'Dual Energy',
    //   value: 'dualEnergy',
    // },
  ];
  const [tabValue, setTabValue] = useState<string>('singleViewer');

  const onHandleTabChange = (
    event: React.ChangeEvent<{}>,
    tabValue: string,
  ) => {
    setTabValue(tabValue);
  };

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="ctviewer-dialog-title"
      open={open}
      scroll="paper"
      fullScreen
    >
      <DialogTitle id="ctviewer-dialog-title" onClose={closeModal}>
        <>
          <img className={classes.logo} src={logoImage} alt="Petricloud logo" />
          <Tabs
            value={tabValue}
            onChange={onHandleTabChange}
            classes={{
              indicator: classes.tabIndicator,
              root: classes.tabs,
            }}
          >
            {projectTabs.map((tab: any) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                classes={{
                  root: classes.tab,
                  selected: classes.tabSelected,
                  labelContainer: classes.tabLabelContainer,
                }}
              />
            ))}
          </Tabs>
        </>
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.content,
        }}
      >
        {tabValue === 'singleViewer' ? <SingleViewerWrapper /> : <DualEnergy />}
      </DialogContent>
    </Dialog>
  );
};

export default memo(withStyles(styles)(CTViewer));
