import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Base from './userBase/Base';

import { AppStore } from '../../stores/appStore';
import { LabsStore } from '../../stores/labsStore';
import { UsersStore } from '../../stores/usersStore';

//#region Styles

const styles = (theme: Theme) =>
  createStyles({
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {}

interface InjectedProps extends Props {
  appStore: AppStore;
  usersStore: UsersStore;
  labsStore: LabsStore;
}
//#endregion

@inject('usersStore', 'labsStore')
@observer
class Userbase extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    const { usersStore, labsStore } = this.injected;

    if (usersStore.users.length === 0) {
      await usersStore.getUsersList();
    }

    if (usersStore.pendingUsers.length === 0) {
      await usersStore.getPendingUsersList();
    }

    if (labsStore.labs.length === 0) {
      await labsStore.getLabsList();
    }
  }

  render() {
    const { classes } = this.props;

    const {
      usersStore: { fetching, saving, listOfUsers, listOfPendingUsers },
      labsStore: { isLoading, labsList },
    } = this.injected;

    const labs = labsList.map((lab: Common.Lab) => lab.path);

    return (
      <div>
        {fetching || isLoading || saving ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={75} />
          </div>
        ) : (
          <Base
            listOfUsers={listOfUsers}
            listOfPendingUsers={listOfPendingUsers}
            labsList={labs}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Userbase);
