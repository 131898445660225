import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { navigate, RouteComponentProps } from '@reach/router';
import { Formik } from 'formik';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
// import AppButton from '../../components/ui/Button';
import ConfirmationModal from '../../components/ui/ConfirmationModal';
import { ProjectsStore } from '../../stores/projectsStore';
import { LabsStore } from '../../stores/labsStore';
import { AppStore } from '../../stores/appStore';
import { UsersStore } from '../../stores/usersStore';
import { AuthStore } from '../../stores/authStore';
import { CTViewerStore } from '../../stores/ctViewerStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 100px)',
      display: 'flex',
      flexDirection: 'column',
      padding: '36px 36px 0',
    },
    settingsContainer: {
      marginTop: 40,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
    },
    title: {
      fontWeight: 700,
    },
    adminsTitle: {
      marginTop: 40,
    },
    addAdmin: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontWeight: 700,
      cursor: 'pointer',
      marginLeft: -4,
      marginTop: 20,
    },
    emailInput: {
      width: '90%',
      maxWidth: 320,
    },
    addAdminIcon: {
      marginRight: 4,
      width: 25,
      height: 25,
    },
    capacitySetting: {
      marginTop: 60,
      maxWidth: 320,
    },
    minMaxValuesContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: 20,
      margin: '0 -18px',
    },
    bottomOptions: {
      marginTop: 100,
      height: 112,
      borderTop: `2px dashed ${theme.palette.primary.main}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    removeButton: {
      padding: '10px 20px 10px 20px',
      color: '#D15151',
    },
    removeButtonIcon: {
      marginRight: 9,
      fontSize: 16,
    },
    removeEmailIcon: {
      marginTop: 45,
      fontSize: 16,
      cursor: 'pointer',
      marginLeft: 10,
    },
    rightButtonsWrapper: {
      display: 'flex',
    },
  });
//#endregion

//#region Types

interface DefaultSettings {
  projectName: string;
  companyName: string;
  clientName: string;
  labName: string;
}
interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  id: number;
  defaultSettings: DefaultSettings;
  projectName: string;
}

interface InjectedProps extends Props {
  labsStore: LabsStore;
  appStore: AppStore;
  usersStore: UsersStore;
  authStore: AuthStore;
  projectsStore: ProjectsStore;
  ctViewerStore: CTViewerStore;
}

interface FormType {
  projectName: string;
  companyName: string;
  clientName: string;
  labName: string;
}

interface State {
  isLoading: boolean;
  confirmModalOpened: boolean;
  [key: string]: string | boolean | string[] | FormType;
  defaultFormValues: FormType;
}

//#endregion

@inject(
  'labsStore',
  'appStore',
  'usersStore',
  'authStore',
  'projectsStore',
  'ctViewerStore',
)
@observer
class Settings extends Component<Props, State> {
  private mounted = false;

  state = {
    isLoading: false,
    confirmModalOpened: false,
    defaultFormValues: {
      projectName: this.props.defaultSettings.projectName,
      companyName: this.props.defaultSettings.companyName,
      clientName: this.props.defaultSettings.clientName,
      labName: this.props.defaultSettings.labName,
    },
  };

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    this.mounted = true;
    // labsStore.get
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadUsers = async () => {
    this.setLoading(true);

    // const { labsStore } = this.injected;
    // const { id } = this.props;

    // const adminEmails = await labsStore.getSubAdmins(id);

    // if (this.mounted) {
    //   this.setAdminEmails(get(adminEmails, 'data', []));
    // }
  };

  setLoading = (value: boolean) => {
    this.setState({
      isLoading: value,
    });
  };

  openModal = () => {
    this.setState({ confirmModalOpened: true });
  };

  closeModal = () => {
    this.setState({ confirmModalOpened: false });
  };

  setAdminEmails = (admins: Common.User[]) => {
    const emails = admins.map(admin => admin.email!);

    this.setState({
      adminEmails: [...emails],
      initialAdminEmails: [...emails],
      isLoading: false,
    });
  };

  onSubmit = async (values: FormType) => {
    // const { id } = this.props;
  };

  onRemove = async () => {
    const { appStore, usersStore, projectsStore } = this.injected;
    const { id } = this.props;

    try {
      await projectsStore.removeProject(id);
      projectsStore.clearProjects();

      await usersStore.getUsersList();
      await usersStore.getPendingUsersList();
      navigate('/labs');
    } catch (e) {
      appStore.showErrorToaster('Error occured, project is not removed');
    }
  };

  render() {
    const { isAdmin, isLabAdmin, user } = this.injected.authStore;
    const { classes, projectName } = this.props;
    const { confirmModalOpened, isLoading, defaultFormValues } = this.state;

    const isCurrentLabPM = this.injected.projectsStore.isPMForCurrentProject(
      user,
    );

    if (!isAdmin && !isLabAdmin && !isCurrentLabPM) {
      navigate('/labs');
      return null;
    }

    if (isLoading) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress size={75} />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <ConfirmationModal
          opened={confirmModalOpened}
          title={`Remove ${projectName}`}
          question={`Following action will remove "${projectName}", continue?`}
          onCancel={this.closeModal}
          onConfirm={this.onRemove}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            projectName: defaultFormValues.projectName,
            companyName: defaultFormValues.companyName,
            clientName: defaultFormValues.clientName,
            labName: defaultFormValues.labName,
          }}
          onSubmit={this.onSubmit}
        >
          {({ values, handleChange, handleSubmit, errors }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid
                container
                spacing={40}
                className={classes.settingsContainer}
                style={{ flex: 1 }}
              >
                <Grid item xs={4}>
                  <div>
                    <Typography component="h3" className={classes.title}>
                      Project settings
                    </Typography>
                    <TextField
                      autoFocus
                      required
                      margin="normal"
                      fullWidth
                      label="Project Name"
                      id="projectName"
                      error={!!errors.projectName}
                      helperText={errors.projectName || ''}
                      onChange={handleChange}
                      value={values.projectName}
                      disabled
                    />

                    <TextField
                      required
                      margin="normal"
                      fullWidth
                      label="Company Name"
                      id="companyName"
                      error={!!errors.companyName}
                      helperText={errors.companyName || ''}
                      onChange={handleChange}
                      value={values.companyName}
                      disabled
                    />

                    <TextField
                      required
                      margin="normal"
                      fullWidth
                      label="Client Name"
                      id="clientName"
                      error={!!errors.clientName}
                      helperText={errors.clientName || ''}
                      onChange={handleChange}
                      value={values.clientName}
                      disabled
                    />

                    <TextField
                      required
                      margin="normal"
                      fullWidth
                      label="Lab Name"
                      id="labName"
                      error={!!errors.labName}
                      helperText={errors.labName || ''}
                      onChange={handleChange}
                      value={values.labName}
                      disabled
                    />
                  </div>
                </Grid>
              </Grid>

              <div className={classes.bottomOptions}>
                <Button
                  className={classes.removeButton}
                  onClick={this.openModal}
                  tabIndex={-1}
                >
                  <DeleteIcon className={classes.removeButtonIcon} />
                  Remove Project
                </Button>

                {/* <div className={classes.rightButtonsWrapper}>
                  <Button tabIndex={-1} onClick={this.loadUsers}>
                    Cancel
                  </Button>
                  <AppButton type="submit" width={170}>
                    Save
                  </AppButton>
                </div> */}
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withStyles(styles)(Settings);
