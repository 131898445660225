import React, { memo } from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,

      '& .axialMarker': {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        height: 0,
        width: 0,
        borderLeft: '14px solid rgba(67,175,251,0.6)',
        borderTop: '7px solid transparent',
        borderBottom: '7px solid transparent',
        transform: 'translateY(-6px)',

        '&:hover': {
          borderLeft: '14px solid rgba(67,175,251,0.3)',
        },

        '&.active': {
          borderLeft: '14px solid #43affb',
        },
      },

      '& .rulerMarker': {
        position: 'absolute',
        right: 0,
        width: '14px',
        height: '1px',
        background: '#000',

        '& .label': {
          position: 'absolute',
          height: '12px',
          top: '-5px',
          right: '16px',
          fontSize: '13px',
          lineHeight: '12px',
        },
      },

      '& .active-line': {
        minWidth: '710px',
        position: 'absolute',
        borderBottom: '2px #43affb solid',
        display: 'inline',
        zIndex: 99,
      },
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  axialImageHeights: number[];
  currentOffset: number;
  verticalScaling: number;
  selectedMarkerIndex: number;
  markerWidth: number;
  coreTopDepthMeters: number;
  coronalHeight: number;
  sliceThickness: number;
  onChange: (index: number) => void;
}
//#endregion

const screenTopOffset = 32;

const SingleViewerRuler: React.FunctionComponent<Props> = ({
  classes,
  axialImageHeights,
  currentOffset,
  verticalScaling,
  selectedMarkerIndex,
  markerWidth,
  coreTopDepthMeters,
  coronalHeight,
  sliceThickness,
  onChange,
}) => {
  const handleClick = (index: number) => {
    onChange(index);
  };

  const getScreenHeight = (height: number) => {
    return (height - currentOffset) * verticalScaling + screenTopOffset;
  };

  const renderAxialHeightMarker = (height: number, index: number) => {
    const screenHeight = getScreenHeight(height);

    if (screenHeight < screenTopOffset) return null;

    const isSelected = index === selectedMarkerIndex;
    const cssClass = isSelected ? 'axialMarker active' : 'axialMarker';

    return (
      <div key={`line-${height}-${index}`}>
        <div
          className={cssClass}
          style={{ top: screenHeight }}
          onClick={() => handleClick(index)}
        />
        <div
          className={isSelected ? 'active-line' : ''}
          style={{
            top: screenHeight,
            minWidth: isSelected ? markerWidth : undefined,
          }}
        />
      </div>
    );
  };

  const renderRulerMarkers = () => {
    const topDepthM = coreTopDepthMeters;
    const thicknessM = sliceThickness / 1000;
    const bottomDepthM = topDepthM + coronalHeight * thicknessM;
    const rulerStep = 0.05;
    const elemCount = Math.ceil((bottomDepthM - topDepthM) / rulerStep);

    const rulerElems = [];

    for (let counter = 0; counter < elemCount; counter++) {
      const distanceFromTopM = counter * rulerStep;
      const distanceFromTopNativePx = distanceFromTopM / thicknessM;
      const screenHeight = getScreenHeight(distanceFromTopNativePx);
      const label = (distanceFromTopM + topDepthM).toFixed(2);

      rulerElems.push(
        <div
          className="rulerMarker"
          style={{ top: screenHeight }}
          key={counter}
        >
          <div className="label">{label}</div>
        </div>,
      );
    }

    return rulerElems;
  };

  const axialHeightMarkers = axialImageHeights.map(renderAxialHeightMarker);
  const rulerMarkers = renderRulerMarkers();

  return (
    <div className={classes.root}>
      {rulerMarkers}
      {axialHeightMarkers}
    </div>
  );
};

export default memo(withStyles(styles)(SingleViewerRuler));
