import React from 'react';
import classNames from 'classnames';
import { Theme } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import 'flag-icon-css/css/flag-icon.min.css';

import countryCodeMap from 'utils/countryMapping';
import labFlagPlaceholder from 'assets/images/lab_flag_placeholder.png';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    flagWrapper: {
      display: 'inline-block',
      lineHeight: 'inherit',
      fontSize: theme.typography.pxToRem(16),
      flex: '0 0 21px',
    },
    logo: {
      width: 24,
      height: 23,
    },
  });
//#endregion

//#region Types
interface FlagProps extends WithStyles<typeof styles> {
  labName: string;
}
//#endregion

const getCountryCode = (labName: string) =>
  countryCodeMap[labName.toUpperCase()];

const Flag: React.FunctionComponent<FlagProps> = ({ labName, classes }) => {
  const countryCode = getCountryCode(labName);

  if (countryCode) {
    const flagClassName = `flag-icon flag-icon-${countryCode.toLowerCase()}`;
    return <span className={classNames(classes.flagWrapper, flagClassName)} />;
  }

  return (
    <img
      alt={labName}
      src={labFlagPlaceholder}
      className={classNames(classes.flagWrapper, classes.logo)}
    />
  );
};

export default withStyles(styles)(Flag);
