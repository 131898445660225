import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import IconButton from '@material-ui/core/IconButton';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    optionSection: {
      height: 97,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: `1px dashed ${theme.palette.primary.main}`,
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  onClick?: () => void;
}
//#endregion

const Box: React.FunctionComponent<Props> = ({
  classes,
  children,
  onClick,
}) => (
  <div className={classes.optionSection} onClick={onClick}>
    <IconButton>{children}</IconButton>
  </div>
);

export default withStyles(styles)(Box);
