import React from 'react';
import classNames from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    buttonWithIcon: {
      backgroundColor: `${theme.palette.primary.light} !important`,

      '& svg': {
        color: theme.palette.common.white,
        top: -1,
      },
    },
    buttonWithIconLabel: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.primary.main,
      fontWeight: 700,
      padding: '0 24px',
    },
    buttonWithIconAvatarColor: {
      backgroundColor: theme.palette.primary.main,
    },
    blueVariant: {
      backgroundColor: '#CBE2FB !important',

      '& span': {
        color: '#1880FF',
      },

      '& > div': {
        backgroundColor: '#1880FF',
      },
    },
    disabled: {
      opacity: 0.85,
      cursor: 'default',
      pointerEvents: 'none',
      backgroundColor: '#eee !important',

      '& span': {
        color: '#ccc !important',
      },

      '& > div': {
        backgroundColor: '#ccc !important',
      },
    },
  });
//endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  icon: React.ReactNode;
  disabled?: boolean;
  blueVariant?: boolean;
}
//#endregion

const ButtonWithIcon: React.FunctionComponent<Props & ButtonBaseProps> = ({
  classes,
  disabled = false,
  blueVariant = false,
  children,
  icon,
  onClick,
}) => {
  return (
    <Chip
      classes={{
        root: classes.buttonWithIcon,
        label: classes.buttonWithIconLabel,
      }}
      className={classNames({
        [classes.blueVariant]: blueVariant,
        [classes.disabled]: disabled,
      })}
      clickable
      onClick={onClick}
      avatar={
        <Avatar classes={{ colorDefault: classes.buttonWithIconAvatarColor }}>
          {icon}
        </Avatar>
      }
      label={children}
    />
  );
};

export default withStyles(styles)(ButtonWithIcon);
