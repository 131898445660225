import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { RouteComponentProps, navigate } from '@reach/router';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import get from 'lodash-es/get';
import difference from 'lodash-es/difference';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';

import SizeBox from './settings/SizeBox';
import AppButton from '../../components/ui/Button';
import ConfirmationModal from '../../components/ui/ConfirmationModal';
import { LabsStore } from './../../stores/labsStore';
import { saveSettings } from './settings/utils';
import { AppStore } from './../../stores/appStore';
import { UsersStore } from './../../stores/usersStore';
import { AuthStore } from './../../stores/authStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 100px)',
      display: 'flex',
      flexDirection: 'column',
      padding: '36px 36px 0',
    },
    settingsContainer: {
      marginTop: 40,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
    },
    title: {
      fontWeight: 700,
    },
    adminsTitle: {
      marginTop: 40,
    },
    addAdmin: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontWeight: 700,
      cursor: 'pointer',
      marginLeft: -4,
      marginTop: 20,
    },
    emailInput: {
      width: '90%',
      maxWidth: 320,
    },
    addAdminIcon: {
      marginRight: 4,
      width: 25,
      height: 25,
    },
    capacitySetting: {
      marginTop: 60,
      maxWidth: 320,
    },
    minMaxValuesContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: 20,
      margin: '0 -18px',
    },
    bottomOptions: {
      marginTop: 100,
      height: 112,
      borderTop: `2px dashed ${theme.palette.primary.main}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    removeButton: {
      padding: '10px 20px 10px 20px',
      color: '#D15151',
    },
    removeButtonIcon: {
      marginRight: 9,
      fontSize: 16,
    },
    removeEmailIcon: {
      marginTop: 45,
      fontSize: 16,
      cursor: 'pointer',
      marginLeft: 10,
    },
    rightButtonsWrapper: {
      display: 'flex',
    },
  });
//#endregion

//#region Types
interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  labName: string;
}

interface InjectedProps extends Props {
  labsStore: LabsStore;
  appStore: AppStore;
  usersStore: UsersStore;
  authStore: AuthStore;
}

interface FormType {
  adminEmails: string[];
}

interface State {
  adminEmails: string[];
  initialAdminEmails: string[];
  isLoading: boolean;

  confirmModalOpened: boolean;

  [key: string]: string | boolean | string[];
}
//#endregion
@inject('labsStore', 'appStore', 'usersStore', 'authStore')
class Settings extends Component<Props, State> {
  private MAX_ADMINS = 6;
  private mounted = false;

  state = {
    adminEmails: [],
    initialAdminEmails: [],
    isLoading: false,
    confirmModalOpened: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    this.loadUsers();
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadUsers = async () => {
    this.setLoading(true);

    const { labsStore } = this.injected;
    const { labName } = this.props;

    const adminEmails = await labsStore.getSubAdmins(labName);

    if (this.mounted) {
      this.setAdminEmails(get(adminEmails, 'data', []));
    }
  };

  setLoading = (value: boolean) => {
    this.setState({
      isLoading: value,
    });
  };

  openModal = () => {
    this.setState({ confirmModalOpened: true });
  };

  closeModal = () => {
    this.setState({ confirmModalOpened: false });
  };

  setAdminEmails = (admins: Common.User[]) => {
    const emails = admins.map(admin => admin.email!);

    this.setState({
      adminEmails: [...emails],
      initialAdminEmails: [...emails],
      isLoading: false,
    });
  };

  onSubmit = async (values: FormType) => {
    const { labName } = this.props;
    const { appStore } = this.injected;
    const initialAdmins = this.state.adminEmails;
    const removedAdmins = difference(initialAdmins, values.adminEmails);
    const newAdmins = difference(values.adminEmails, initialAdmins);

    if (removedAdmins.length || newAdmins.length) {
      await saveSettings(labName, newAdmins, removedAdmins, this.loadUsers);
    } else {
      appStore.showInfoToaster();
    }
  };

  onRemove = async () => {
    const { labsStore, appStore, usersStore } = this.injected;
    const { labName } = this.props;

    try {
      await labsStore.removeLab(labName);

      // clear labs and fetch users
      labsStore.clearLabs();
      await usersStore.getUsersList();
      await usersStore.getPendingUsersList();
      navigate('/labs');
    } catch (e) {
      appStore.showErrorToaster('Error occured, lab is not removed');
    }
  };

  render() {
    const { isAdmin } = this.injected.authStore;
    const { classes, labName } = this.props;
    const { adminEmails, confirmModalOpened, isLoading } = this.state;

    if (!isAdmin) {
      navigate('/labs');
      return null;
    }

    if (isLoading) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress size={75} />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <ConfirmationModal
          opened={confirmModalOpened}
          title={`Remove ${labName}`}
          question={`Following action will remove "${labName}", continue?`}
          onCancel={this.closeModal}
          onConfirm={this.onRemove}
        />

        <Formik
          initialValues={{
            adminEmails,
          }}
          onSubmit={this.onSubmit}
          validationSchema={Yup.object().shape({
            adminEmails: Yup.array()
              .of(
                Yup.string()
                  .email('Must be a valid email')
                  .required('Field is required'),
              )
              .max(this.MAX_ADMINS),
          })}
        >
          {({ values, handleChange, handleSubmit, errors }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid
                container
                spacing={40}
                className={classes.settingsContainer}
                style={{ flex: 1 }}
              >
                <Grid item xs={4}>
                  <div>
                    <Typography component="h3" className={classes.title}>
                      Admin list
                    </Typography>

                    <FieldArray
                      name="adminEmails"
                      render={arrayHelpers => {
                        const maxAdminsReached =
                          values.adminEmails.length >= this.MAX_ADMINS;
                        const emailsInput = values.adminEmails.map(
                          (email, index) => {
                            const errorText =
                              errors.adminEmails && errors.adminEmails[index]
                                ? errors.adminEmails[index]
                                : '';

                            return (
                              <div key={index}>
                                <TextField
                                  autoFocus
                                  id="adminEmail"
                                  type="email"
                                  name={`adminEmails.${index}`}
                                  className={classes.emailInput}
                                  margin="normal"
                                  label="Admin's Email"
                                  value={email}
                                  onChange={handleChange}
                                  error={!!errorText}
                                  helperText={errorText}
                                />

                                <ClearIcon
                                  className={classes.removeEmailIcon}
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </div>
                            );
                          },
                        );

                        return (
                          <>
                            {emailsInput}
                            <Typography
                              className={classes.addAdmin}
                              component="span"
                              onClick={() =>
                                !maxAdminsReached && arrayHelpers.push('')
                              }
                            >
                              <AddIcon className={classes.addAdminIcon} />
                              Add admin
                            </Typography>
                          </>
                        );
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <Typography component="h3" className={classes.title}>
                    Lab size
                  </Typography>

                  <SizeBox labName={labName} />
                </Grid>
              </Grid>

              <div className={classes.bottomOptions}>
                <Button
                  className={classes.removeButton}
                  onClick={this.openModal}
                  tabIndex={-1}
                >
                  <DeleteIcon className={classes.removeButtonIcon} />
                  Remove Lab
                </Button>

                <div className={classes.rightButtonsWrapper}>
                  <Button tabIndex={-1} onClick={this.loadUsers}>
                    Cancel
                  </Button>
                  <AppButton type="submit" width={170}>
                    Save Changes
                  </AppButton>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withStyles(styles)(Settings);
