import React, { memo, useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import withDrag from './drag/withDrag';
import ArrowSVG from './icons/ArrowIcon';
import RadialMarkerCrossLineCanvas from './radialGroup/RadialMarkerCrossLineCanvas';
import Marker from './radialGroup/Marker';
import CustomSlice from './radialGroup/CustomSlice';
import CustomSliceHolders from './radialGroup/CustomSliceHolders';

import { XY_SLICE_WIDTH_SCREEN_PX } from '../../../../utils/sizingConstants';
import { CTViewerStore, CTImage } from '../../../../stores/ctViewerStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    markerHolder: {
      position: 'absolute',
      top: '-12px',
      left: 'calc(50% - 12px)',
      width: '24px',
      height: '24px',
      background: '#fff',
      borderRadius: '50%',
      border: '1px solid #000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
  image: CTImage;
  registerOnDrag: any;
  registerSetDragInitialState: any;
  onMouseDown: ((e: React.MouseEvent) => void) | undefined;
}
//#endregion

const RadialGroup: React.FunctionComponent<Props> = ({
  ctViewerStore,
  registerOnDrag,
  registerSetDragInitialState,
  onMouseDown,
  image,
  classes,
}) => {
  const newPosition = useRef<any>();

  const handleDragCenter = (x: number, y: number) => {
    ctViewerStore!.setCropParameters({
      cropCenterX: x,
      cropCenterY: y,
    });
  };

  const handleMouseMove = (
    unscaledXDiff: number,
    unscaledYDiff: number,
    initialState: any,
  ) => {
    const { initialCenterX, initialCenterY } = initialState;
    const scaling = XY_SLICE_WIDTH_SCREEN_PX / ctViewerStore!.axialImage!.width;

    let newX = initialCenterX + unscaledXDiff / scaling;
    let newY = initialCenterY + unscaledYDiff / scaling;

    newX = Math.round(newX);
    newY = Math.round(newY);

    newX = Math.max(newX, 0);
    newY = Math.max(newY, 0);

    newX = Math.min(newX, ctViewerStore!.axialImage!.width);
    newY = Math.min(newY, ctViewerStore!.axialImage!.height);

    handleDragCenter(newX, newY);
  };

  const handleArrowSvgMouseMove = (newRadius: number) => {
    ctViewerStore!.setCropParameters({
      cropRadius: Math.round(newRadius),
    });
  };

  const setDragInitialState = () => newPosition.current;

  useEffect(() => {
    registerOnDrag(handleMouseMove);
    registerSetDragInitialState(setDragInitialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    newPosition.current = {
      initialCenterX: ctViewerStore!.cropCenterX,
      initialCenterY: ctViewerStore!.cropCenterY,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  if (!ctViewerStore || !image) {
    return null;
  }

  const scaling = XY_SLICE_WIDTH_SCREEN_PX / image.width;

  return (
    <>
      <CustomSlice scaling={scaling} customSlice={ctViewerStore!.customSlice} />
      <RadialMarkerCrossLineCanvas />
      <Marker
        centerX={ctViewerStore!.cropCenterX!}
        centerY={ctViewerStore!.cropCenterY!}
        radius={ctViewerStore!.cropRadius!}
        scaling={scaling}
        onMouseDown={onMouseDown}
        borderColor="#d0021b"
        backgroundColor="rgba(245, 166, 35, 0.31)"
      >
        <div className={classes.markerHolder}>
          <ArrowSVG
            onDrag={handleArrowSvgMouseMove}
            hasResizeDrag
            radius={ctViewerStore!.cropRadius}
            scaling={scaling}
          />
        </div>
      </Marker>
      <CustomSliceHolders
        scaling={scaling}
        maxX={image.width}
        maxY={image.height}
      />
    </>
  );
};

export default memo(
  withStyles(styles)(withDrag(inject('ctViewerStore')(observer(RadialGroup)))),
);
