const WIDTH_SCREEN_PX = 312;
const MAX_HEIGHT_SCREEN_PX = 512;
const XY_SLICE_WIDTH_SCREEN_PX = 333;
const XY_SLICE_HEIGHT_SCREEN_PX = 333;
const VERTICAL_OFFSET_SCREEN_PX = 80;
const XZ_LEFT_OFFSET_SCREEN_PX = 45;
const ZY_LEFT_OFFSET_SCREEN_PX = 45 + 312 + 66;
const VERTICAL_LINE_ADDED_HEIGHT_PX = 626 - 512;
const FULL_LINE_WIDTH_SCREEN_PX = 770;
const DENSITY_TAB_BASE_WIDTH = 280;
const GENERATED_SLICE_NUMBER = 50;

// DEFAULT CROPS
const CROP_RADIUS = 154;
const BH_CROP_RADIUS = CROP_RADIUS + 16;
const CROP_CENTER_POSITION_X = 274;
const CROP_CENTER_POSITION_Y = 287;
const CROP_TOP_POSITION = 98;
const CROP_BOTTOM_POSITION = 2039;

export {
  WIDTH_SCREEN_PX,
  MAX_HEIGHT_SCREEN_PX,
  XY_SLICE_WIDTH_SCREEN_PX,
  XY_SLICE_HEIGHT_SCREEN_PX,
  VERTICAL_OFFSET_SCREEN_PX,
  XZ_LEFT_OFFSET_SCREEN_PX,
  ZY_LEFT_OFFSET_SCREEN_PX,
  VERTICAL_LINE_ADDED_HEIGHT_PX,
  FULL_LINE_WIDTH_SCREEN_PX,
  DENSITY_TAB_BASE_WIDTH,
  GENERATED_SLICE_NUMBER,
  CROP_RADIUS,
  BH_CROP_RADIUS,
  CROP_CENTER_POSITION_X,
  CROP_CENTER_POSITION_Y,
  CROP_TOP_POSITION,
  CROP_BOTTOM_POSITION,
};
