import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MuiButton from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';

import { UsersStore } from '../../../stores/usersStore';
import Button from '../../../components/ui/Button';
import ConfirmationModal from './../../../components/ui/ConfirmationModal';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '40px 20px 0 32px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      marginBottom: 16,
    },
    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    bottomActions: {
      marginTop: 'auto',
      paddingTop: 52,
      paddingBottom: 52,
      borderTop: `1px dashed ${theme.palette.primary.main}`,
    },
    removeDepartmentButton: {
      alignSelf: 'flex-start',
      color: '#D15151',
    },
  });
//#endregion

//#region types
interface State {
  confirmationOpened: boolean;
}

interface Props extends WithStyles<typeof styles> {
  onSave: (name: string) => void;
  onDelete: () => void;
  onCancel: () => void;
  usersStore?: UsersStore;
}

interface InjectedProps extends Props {
  usersStore: UsersStore;
}
//#endregion

@inject('usersStore')
@observer
class DepartmentEdit extends React.Component<Props, State> {
  state = {
    confirmationOpened: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  handleCancel = () => {
    this.setState({
      confirmationOpened: false,
    });
  };

  handleConfirm = () => {
    this.setState({
      confirmationOpened: true,
    });

    this.props.onDelete();
  };

  openRemovalDialog = () => {
    this.setState({
      confirmationOpened: true,
    });
  };

  render() {
    const { confirmationOpened } = this.state;
    const { classes, onSave, onCancel } = this.props;
    const { saving, selectedDepartment } = this.injected.usersStore;

    return (
      <div className={classes.root}>
        <ConfirmationModal
          opened={confirmationOpened}
          title="Department removal"
          question="Are you sure you want to delete this department?"
          inProgress={saving}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />

        <Typography className={classes.header}>
          {selectedDepartment
            ? `${selectedDepartment.department_name} department`
            : 'Add Department'}
        </Typography>

        {selectedDepartment && (
          <div className={classes.bottomActions}>
            <MuiButton
              className={classes.removeDepartmentButton}
              onClick={this.openRemovalDialog}
            >
              <DeleteIcon fontSize="small" /> Remove Department
            </MuiButton>
          </div>
        )}

        {!selectedDepartment && (
          <Formik
            initialValues={{ name: '' }}
            onSubmit={values => onSave(values.name)}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .matches(/^[0-9a-zA-Z ]+$/, 'Name contains invalid characters')
                .lowercase()
                .notOneOf(['projects'], 'This name is not allowed')
                .required('Field is required'),
            })}
          >
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form noValidate className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  autoFocus
                  required
                  fullWidth
                  id="name"
                  label="Department"
                  margin="normal"
                  error={!!(errors.name && touched.name)}
                  helperText={errors.name && touched.name ? errors.name : ''}
                  onChange={handleChange}
                  value={values.name}
                />

                <section className={classes.bottomActions}>
                  <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                      <MuiButton
                        tabIndex={-1}
                        onClick={onCancel}
                        style={{ width: 170 }}
                      >
                        Cancel
                      </MuiButton>
                      <Button disabled={saving} type="submit" width={170}>
                        {saving ? 'Saving...' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </section>
              </form>
            )}
          </Formik>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DepartmentEdit);
