import React from 'react';
import { inject, observer } from 'mobx-react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { FilesStore } from 'stores/files/filesStore';
import { DownloadStore } from 'stores/files/downloadStore';
import Navbar from './navbar/Navbar';
import FileUploadPopup from '../FileProcessPopup/fileUpload/FileUploadPopup';
import FileZipPopup from '../FileProcessPopup/fileZip/FileZipPopup';
import FileProcessPopupContainer from '../FileProcessPopup/fileProcessPopupContainer/FileProcessPopupContainer';
import ConfirmationModal from '../ui/ConfirmationModal';

const styles = () =>
  createStyles({
    root: {
      position: 'relative',
      height: '100%',
      marginTop: 68,
    },
    contentWrapper: {
      position: 'relative',
    },
  });

interface Props extends WithStyles<typeof styles> {
  filesStore?: FilesStore;
  downloadStore?: DownloadStore;
}

const Layout: React.FunctionComponent<Props> = ({
  classes,
  children,
  filesStore,
  downloadStore,
}) => {
  const { limitExceed, setLimitExceed } = downloadStore!;

  return (
    <div className={classes.root}>
      <Navbar />
      <div className={classes.contentWrapper}>{children}</div>
      <FileProcessPopupContainer>
        {filesStore!.uploadingFiles && <FileUploadPopup />}
        {downloadStore!.showCompressionPopup && <FileZipPopup />}
        <ConfirmationModal
          opened={limitExceed}
          title="Wait for the other files to be compressed."
          question="You can’t have more than 10 active compression processes at once. Wait for other files to be compressed and then start new download."
          onConfirm={() => setLimitExceed(false)}
        />
      </FileProcessPopupContainer>
    </div>
  );
};

export default withStyles(styles)(
  inject('filesStore', 'downloadStore')(observer(Layout)),
);
