import React, { memo, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import LineMarker from './lineMarkerGroup/LineMarker';

import { CTViewerStore } from '../../../../stores/ctViewerStore';
import calculateDefaultCrop from '../../../../utils/calculateDefaultCrop';
import {
  XZ_LEFT_OFFSET_SCREEN_PX,
  ZY_LEFT_OFFSET_SCREEN_PX,
  WIDTH_SCREEN_PX,
  VERTICAL_OFFSET_SCREEN_PX,
} from '../../../../utils/sizingConstants';

//#region Types
interface Props {
  ctViewerStore?: CTViewerStore;
}
//#endregion

const LineMarkerGroup: React.FunctionComponent<Props> = ({ ctViewerStore }) => {
  const getMaxHeight = () => {
    const image = ctViewerStore!.coronalImage;
    if (!image) return 0;
    return image.height;
  };

  const applyHeightLimits = (height: number) => {
    const maxHeight = getMaxHeight();

    if (height < 0) height = 0;
    if (height > maxHeight) height = maxHeight;

    return height;
  };

  const getXZHalfWidth = () => {
    const image = ctViewerStore!.coronalImage;
    if (!image) return 0;
    return image.width / 2;
  };

  const getZYHalfWidth = () => {
    const image = ctViewerStore!.sagittalImage;
    if (!image) return 0;
    return image.width / 2;
  };

  const handleChangeSliceHeight = (position: number) => {
    const displayedSliceHeight = Math.round(applyHeightLimits(position));
    ctViewerStore!.setCropParameters({ displayedSliceHeight });
  };

  const handleChangeTopCrop = (position: number) => {
    const cropTop = Math.min(
      applyHeightLimits(position),
      ctViewerStore!.cropBottom!,
    );
    ctViewerStore!.setCropParameters({ cropTop });
  };

  const handleChangeBottomCrop = (position: number) => {
    const cropBottom = Math.max(
      applyHeightLimits(position),
      ctViewerStore!.cropTop!,
    );
    ctViewerStore!.setCropParameters({ cropBottom });
  };

  const handleChangeLeftXZ = (position: number) => {
    let newRadius = getXZHalfWidth() - position;

    newRadius = Math.max(0, newRadius);
    newRadius = Math.min(newRadius, getXZHalfWidth());

    ctViewerStore!.setCropParameters({ cropRadius: Math.round(newRadius) });
  };

  const handleChangeRightXZ = (position: number) => {
    let newRadius = position - getXZHalfWidth();

    newRadius = Math.max(0, newRadius);
    newRadius = Math.min(newRadius, getXZHalfWidth());

    ctViewerStore!.setCropParameters({ cropRadius: Math.round(newRadius) });
  };

  const handleChangeLeftZY = (position: number) => {
    let newRadius = getZYHalfWidth() - position;

    newRadius = Math.max(0, newRadius);
    newRadius = Math.min(newRadius, getZYHalfWidth());

    ctViewerStore!.setCropParameters({ cropRadius: Math.round(newRadius) });
  };

  const handleChangeRightZY = (position: number) => {
    let newRadius = position - getZYHalfWidth();

    newRadius = Math.max(0, newRadius);
    newRadius = Math.min(newRadius, getZYHalfWidth());

    ctViewerStore!.setCropParameters({ cropRadius: Math.round(newRadius) });
  };

  useEffect(() => {
    if (
      ctViewerStore!.coronalImage &&
      ctViewerStore!.sagittalImage &&
      !ctViewerStore!.displayedSliceHeight
    ) {
      ctViewerStore!.setCropParameters(
        calculateDefaultCrop(
          ctViewerStore!.coronalImage,
          ctViewerStore!.generatedResult.length,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctViewerStore!.coronalImage, ctViewerStore!.sagittalImage]);

  if (
    !ctViewerStore ||
    !ctViewerStore.coronalImage ||
    !ctViewerStore.sagittalImage
  )
    return null;

  const cropRadius = ctViewerStore!.cropRadius!;
  const cropCenterX = ctViewerStore!.cropCenterX!;
  const cropCenterY = ctViewerStore!.cropCenterY!;

  const xzScaling = WIDTH_SCREEN_PX / ctViewerStore.coronalImage.width;
  const zyScaling = WIDTH_SCREEN_PX / ctViewerStore.sagittalImage.width;

  const xzLeftMarkerPosition = cropCenterX - cropRadius;
  const xzRightMarkerPosition = cropCenterX + cropRadius;
  const zyLeftMarkerPosition = cropCenterY - cropRadius;
  const zyRightMarkerPosition = cropCenterY + cropRadius;

  const verticalScaling = xzScaling; // LATER: handle case if xz and zy are different
  const verticalPixelRatio =
    ctViewerStore.coronalImage.rowPixelSpacing /
    ctViewerStore.coronalImage.columnPixelSpacing;

  return (
    <>
      <LineMarker
        position={ctViewerStore.displayedSliceHeight}
        color="#4a90e2"
        label="axial"
        onChange={handleChangeSliceHeight}
        scaling={verticalScaling * verticalPixelRatio}
        offset={VERTICAL_OFFSET_SCREEN_PX}
        viewportOffset={ctViewerStore.viewportOffset}
      />
      <LineMarker
        position={ctViewerStore.cropTop}
        color="#f5a623"
        label="top crop"
        onChange={handleChangeTopCrop}
        scaling={verticalScaling * verticalPixelRatio}
        offset={VERTICAL_OFFSET_SCREEN_PX}
        viewportOffset={ctViewerStore.viewportOffset}
      />
      <LineMarker
        position={ctViewerStore.cropBottom}
        color="#f5a623"
        label="bottom crop"
        onChange={handleChangeBottomCrop}
        scaling={verticalScaling * verticalPixelRatio}
        offset={VERTICAL_OFFSET_SCREEN_PX}
        viewportOffset={ctViewerStore.viewportOffset}
      />

      <LineMarker
        position={xzLeftMarkerPosition}
        isVertical
        color="#d0021b"
        onChange={handleChangeLeftXZ}
        scaling={xzScaling}
        offset={XZ_LEFT_OFFSET_SCREEN_PX}
        imageHeight={ctViewerStore.coronalImage.height * verticalPixelRatio}
      />
      <LineMarker
        position={xzRightMarkerPosition}
        isVertical
        color="#d0021b"
        onChange={handleChangeRightXZ}
        scaling={xzScaling}
        offset={XZ_LEFT_OFFSET_SCREEN_PX}
        imageHeight={ctViewerStore.coronalImage.height * verticalPixelRatio}
      />
      <LineMarker
        position={zyLeftMarkerPosition}
        isVertical
        color="#d0021b"
        onChange={handleChangeLeftZY}
        scaling={zyScaling}
        offset={ZY_LEFT_OFFSET_SCREEN_PX}
        imageHeight={ctViewerStore.sagittalImage.height * verticalPixelRatio}
      />
      <LineMarker
        position={zyRightMarkerPosition}
        isVertical
        color="#d0021b"
        onChange={handleChangeRightZY}
        scaling={zyScaling}
        offset={ZY_LEFT_OFFSET_SCREEN_PX}
        imageHeight={ctViewerStore.sagittalImage.height * verticalPixelRatio}
      />
    </>
  );
};

export default memo(inject('ctViewerStore')(observer(LineMarkerGroup)));
