export async function fileReaderPromise(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    let content: any = '';
    const reader = new FileReader();
    reader.onloadend = (e: ProgressEvent) => {
      content = reader.result;
      resolve(content);
    };
    reader.onerror = (e: ProgressEvent) => {
      reject(e);
    };
    reader.readAsText(file);
  });
}
