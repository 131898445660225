import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: theme.spacing.unit * 50,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: '40px 70px',
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
      borderRadius: 10,
      minWidth: 500,
    },
    closeIcon: {
      position: 'absolute',
      right: 20,
      top: 20,
      fontSize: theme.typography.pxToRem(28),
      cursor: 'pointer',
    },
    content: {
      paddingTop: 20,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  open: boolean;
  title: string;
  closeModal: () => void;
}
//#endregion

// Component
const WrappedModal: React.FunctionComponent<Props> = ({
  children,
  classes,
  open,
  title,
  closeModal,
}) => (
  <Modal open={open} onClose={closeModal}>
    <div className={classes.paper}>
      <CloseIcon className={classes.closeIcon} onClick={closeModal} />
      <Typography variant="h1">{title}</Typography>
      <div className={classes.content}>{children}</div>
    </div>
  </Modal>
);

export default withStyles(styles)(WrappedModal);
