import React, { memo } from 'react';
import { inject, observer } from 'mobx-react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Button from '@material-ui/core/Button';

import { CTViewerStore, CTImage } from '../../../../stores/ctViewerStore';

//#region Styles
const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '50px',
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  image: CTImage | null;
  ctViewerStore?: CTViewerStore;
}
//#endregion

const SlabButton: React.FunctionComponent<Props> = ({
  classes,
  image,
  ctViewerStore,
}) => {
  if (!ctViewerStore || !image) {
    return null;
  }

  const onClickHandler = () => {
    const { width, height } = image;
    if (ctViewerStore!.customSlice) {
      ctViewerStore!.singleSetCustomSlice(null);
    } else {
      const x1 = Math.round(width / 3);
      const y1 = Math.round(height / 3);
      const x2 = Math.round((width * 2) / 3);
      const y2 = Math.round((height * 2) / 3);

      ctViewerStore!.singleSetCustomSlice({ x1, y1, x2, y2 });
    }
  };

  return (
    <div className={classes.root}>
      <Button variant="outlined" color="primary" onClick={onClickHandler}>
        {ctViewerStore!.customSlice
          ? 'Remove slab (custom slice)'
          : 'Add slab (custom slice)'}
      </Button>
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(SlabButton))),
);
