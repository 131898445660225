import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';

export const styles = (theme: Theme) =>
  createStyles({
    closeIcon: {
      position: 'absolute',
      right: 20,
      top: 20,
      fontSize: theme.typography.pxToRem(20),
      cursor: 'pointer',
      color: theme.palette.background.paper,
      zIndex: 10,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    lightbox: {
      paddingTop: 20,
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '90%',
    },
    paper: {
      width: theme.spacing.unit * 80,
      backgroundColor: theme.palette.background.paper,
      padding: '40px 70px',
      minWidth: 500,
      margin: '0 auto',
      maxHeight: 'calc(100vh - 90px)',
      overflowY: 'auto',
    },
    txt: {
      whiteSpace: 'pre-wrap',
    },
    picture: {
      textAlign: 'center',
    },
    image: {
      height: 'auto',
      maxHeight: 'calc(100vh - 90px)',
      maxWidth: '100%',
    },
    name: {
      marginTop: theme.spacing.unit * 2,
      color: theme.palette.background.paper,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    navBtn: {
      color: theme.palette.background.paper,
    },
    hideNavBtn: {
      visibility: 'hidden',
    },
  });
