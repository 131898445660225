import React, { FC } from 'react';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import BoxColumn from '../../../components/ui/BoxColumn';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    optionSection: {
      height: 97,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: `1px dashed ${theme.palette.primary.main}`,
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  onCreateUser: () => void;
  onPendingUsers: () => void;
  onToggleSearch: () => void;
  canEdit: boolean;
}
//#endregion

const Navigation: FC<Props> = ({
  onCreateUser,
  onPendingUsers,
  onToggleSearch,
  canEdit,
  classes,
}) => (
  <BoxColumn>
    <div className={classes.optionSection}>
      <Tooltip title="Users search">
        <IconButton onClick={onToggleSearch}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
    </div>

    {canEdit && (
      <>
        <AddIcon
          style={{
            fontSize: 16,
            position: 'absolute',
            top: 128,
            left: 38,
            color: 'rgba(0, 0, 0, 0.54)',
          }}
        />

        <div className={classes.optionSection}>
          <Tooltip title="Invite new user">
            <IconButton onClick={onCreateUser}>
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.optionSection}>
          <Tooltip title="Pending users">
            <IconButton onClick={onPendingUsers}>
              <AccessTimeIcon />
            </IconButton>
          </Tooltip>
        </div>
      </>
    )}
  </BoxColumn>
);

export default withStyles(styles)(Navigation);
