import React, { memo, useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import withDrag from '../../drag/withDrag';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      width: '7px',
      height: '7px',
      border: '1px solid #0f0',
      transform: 'translateX(-50%) translateY(-50%)',

      '&::after': {
        content: '""',
        position: 'absolute',
        width: '13px',
        height: '13px',
        top: '-3px',
        left: '-3px',
      },
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  customSlicePosition: any;
  position: string;
  scaling: number;
  onDragHolder: any;
  registerOnDrag: any;
  registerSetDragInitialState: any;
  onMouseDown: ((e: React.MouseEvent) => void) | undefined;
}
//#endregion

const CustomSliceHolder: React.FunctionComponent<Props> = ({
  position,
  scaling,
  customSlicePosition,
  onDragHolder,
  registerOnDrag,
  registerSetDragInitialState,
  onMouseDown,
  classes,
}) => {
  const newPosition = useRef<any>();

  const handleMouseMove = (
    screenX: number,
    screenY: number,
    initialPosition: any,
  ) => {
    const offsetX = screenX / scaling;
    const offsetY = screenY / scaling;

    if (position === 'center') {
      onDragHolder({
        x1: initialPosition.x1 + offsetX,
        y1: initialPosition.y1 + offsetY,
        x2: initialPosition.x2 + offsetX,
        y2: initialPosition.y2 + offsetY,
      });
      return;
    }

    const initialCenterX = (initialPosition.x1 + initialPosition.x2) / 2;
    const initialCenterY = (initialPosition.y1 + initialPosition.y2) / 2;
    const initialDiffX = initialCenterX - initialPosition.x1;
    const initialDiffY = initialCenterY - initialPosition.y1;

    if (position === 'x1') {
      onDragHolder({
        x1: initialCenterX - initialDiffX + offsetX,
        y1: initialCenterY - initialDiffY + offsetY,
        x2: initialCenterX + initialDiffX - offsetX,
        y2: initialCenterY + initialDiffY - offsetY,
      });
    }
    if (position === 'x2') {
      onDragHolder({
        x1: initialCenterX - initialDiffX - offsetX,
        y1: initialCenterY - initialDiffY - offsetY,
        x2: initialCenterX + initialDiffX + offsetX,
        y2: initialCenterY + initialDiffY + offsetY,
      });
    }
  };

  const getInputData = () => newPosition.current;

  useEffect(() => {
    registerOnDrag(handleMouseMove);
    registerSetDragInitialState(getInputData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    newPosition.current = customSlicePosition;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  if (!customSlicePosition) {
    return null;
  }

  const getPosition = (): any => {
    const { x1, y1, x2, y2 } = customSlicePosition;

    if (position === 'x1') {
      return {
        x: x1,
        y: y1,
      };
    }

    if (position === 'x2') {
      return {
        x: x2,
        y: y2,
      };
    }

    if (position === 'center') {
      return {
        x: (x2 + x1) / 2,
        y: (y2 + y1) / 2,
      };
    }
  };

  const { x, y } = getPosition();
  const styleElem = {
    top: y * scaling,
    left: x * scaling,
  };

  return (
    <div className={classes.root} style={styleElem} onMouseDown={onMouseDown} />
  );
};

export default memo(
  withStyles(styles)(
    withDrag(inject('ctViewerStore')(observer(CustomSliceHolder))),
  ),
);
