import React, { FC } from 'react';
import { lighten } from 'polished';
import fileSize from 'filesize';
import classNames from 'classnames';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';

import Flag from 'components/ui/flag/Flag';

export const MY_FILES_CARD_COLOR = '#517FD1';
export const SHARED_WITH_ME_COLOR = '#D15151';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 156,
      width: 318,
      border: `2px solid ${lighten(0.3, theme.palette.primary.main)}`,
      background: 'transparent',
      boxShadow: 'none',
      borderRadius: 11,
      cursor: 'pointer',
      display: 'flex',
      marginBottom: 30,

      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
        background: theme.palette.common.white,
        boxShadow: '-1px 3px 6px 0 rgba(183, 190, 198, 0.36)',
      },

      '&:hover $leftIndicator': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    text: {
      marginTop: -4,
    },
    spacer: {
      flex: 1,
    },
    leftIndicator: {
      height: '100%',
      width: 10,
      backgroundColor: lighten(0.35, theme.palette.primary.main),
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    content: {
      padding: 24,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    topSection: {
      flex: 1,
    },
    dataContainer: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    customIconContainer: {
      alignItems: 'center',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 10,
      minHeight: 23,
    },
    countryFlag: {
      width: 30,
      height: 17,
      objectFit: 'contain',
      marginRight: 17,
    },
    labName: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 'bold',
      margin: 0,
      color: theme.palette.text.primary,
    },
    labSubName: {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.disabled,
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    labInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    usersInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    usersAmount: {
      width: 27,
      height: 27,
      borderRadius: 13,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      fontSize: '1.4rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 7,

      '& > span': {
        marginTop: -4,
      },
    },
    quotaInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    quotaAmount: {
      height: 27,
      borderRadius: 13,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      fontSize: '1.4rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 7,
      padding: 13,

      '& > span': {
        marginTop: -4,
      },
    },
    customIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      color: 'white',
      fontSize: 20,
      height: 33,
      width: 33,
      '& > svg': {
        fontSize: 19,
      },
    },
    myFiles: {
      border: `2px solid ${lighten(0.1, MY_FILES_CARD_COLOR)}`,

      '& $customIcon': {
        backgroundColor: MY_FILES_CARD_COLOR,
      },

      '& $quotaAmount': {
        backgroundColor: MY_FILES_CARD_COLOR,
        color: 'white',
      },

      '&:hover': {
        border: `2px solid ${MY_FILES_CARD_COLOR}`,
      },

      '& $leftIndicator': {
        backgroundColor: lighten(0.1, MY_FILES_CARD_COLOR),
      },

      '&:hover $leftIndicator': {
        backgroundColor: MY_FILES_CARD_COLOR,
      },
    },
    sharedWithMe: {
      border: `2px solid ${lighten(0.1, SHARED_WITH_ME_COLOR)}`,

      '& $customIcon': {
        backgroundColor: SHARED_WITH_ME_COLOR,
      },

      '& $quotaAmount': {
        backgroundColor: SHARED_WITH_ME_COLOR,
        color: 'white',
      },

      '&:hover': {
        border: `2px solid ${SHARED_WITH_ME_COLOR}`,
      },

      '& $leftIndicator': {
        backgroundColor: lighten(0.1, SHARED_WITH_ME_COLOR),
      },

      '&:hover $leftIndicator': {
        backgroundColor: SHARED_WITH_ME_COLOR,
      },
    },
  });
//#endregion

//#region Types
export enum CustomCardType {
  SHARED_WITH_ME = 1,
  MY_FILES = 2,
}

interface Props extends WithStyles<typeof styles> {
  name: string;
  subName?: string;
  size?: number;
  icon?: JSX.Element;
  custom?: CustomCardType;
  onClick?: (event: React.MouseEvent) => void;
}
//#endregion

const DashboardCard: FC<Props> = ({
  name,
  subName,
  size,
  icon,
  custom,
  classes,
  onClick,
}) => {
  const myFilesContext = custom === CustomCardType.MY_FILES;
  const sharedWithMeContext = custom === CustomCardType.SHARED_WITH_ME;

  const rootClassNames = classNames(classes.root, {
    [classes.myFiles]: myFilesContext,
    [classes.sharedWithMe]: sharedWithMeContext,
  });

  const dataClassNames = classNames(classes.dataContainer, {
    [classes.customIconContainer]: icon,
  });

  return (
    <div className={rootClassNames} onClick={onClick}>
      <div className={classes.leftIndicator} />
      <article className={classes.content}>
        <section className={classes.topSection}>
          <div className={dataClassNames}>
            <div className={classes.iconContainer}>
              {(icon && <div className={classes.customIcon}>{icon}</div>) || (
                <Flag labName={subName || name} />
              )}
            </div>

            <div>
              <Typography className={classes.labName} component="h2">
                {name}
              </Typography>
              <Typography className={classes.labSubName} component="h4">
                {subName}
              </Typography>
            </div>
          </div>
        </section>

        {typeof size === 'number' && (
          <section className={classes.labInfo}>
            <div className={classes.quotaInfo}>
              <div className={classes.quotaAmount}>
                <Typography
                  variant="subtitle2"
                  component="span"
                  color="inherit"
                >
                  {fileSize(size)}
                </Typography>
              </div>
            </div>
          </section>
        )}
      </article>
    </div>
  );
};

export default withStyles(styles)(DashboardCard);
