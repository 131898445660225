import {
  topMarkerHeight,
  leftMarkerWidth,
  bottomMarkerHeight,
  bottomLineHeight,
  rightPadding,
} from './spacings';

export default (inputCanvas: HTMLCanvasElement) => {
  const outputCanvas = document.createElement('canvas');

  const originalWidth = parseInt(inputCanvas.getAttribute('width')!);
  const originalHeight = parseInt(inputCanvas.getAttribute('height')!);

  const outputWidth = originalWidth + leftMarkerWidth + rightPadding;
  const outputHeight =
    originalHeight + topMarkerHeight + bottomMarkerHeight + bottomLineHeight;

  outputCanvas.setAttribute('width', `${outputWidth}`);
  outputCanvas.setAttribute('height', `${outputHeight}`);

  const outputContext = outputCanvas.getContext('2d')!;

  // Draw background
  outputContext.fillStyle = '#000';
  outputContext.fillRect(0, 0, outputWidth, outputHeight);

  // Draw image
  outputContext.drawImage(inputCanvas, leftMarkerWidth, topMarkerHeight);

  return {
    outputCanvas,
    outputWidth,
    outputHeight,
    outputContext,
    originalWidth,
    originalHeight,
  };
};
