import React, { Component } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { inject } from 'mobx-react';
import queryString from 'query-string';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import UserBase from './users/UserBase';
import Labs from './users/Labs';

import { AuthStore } from '../stores/authStore';
import { UsersStore } from '../stores/usersStore';
import { FilesStore } from '../stores/files/filesStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    mainContainer: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      padding: '32px 16px 0',
    },
    topPanel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    tab: {
      fontSize: '1.2rem',
      margin: '0 20px',
      minWidth: 0,
      opacity: 0.35,
      '&:first-child': {
        margin: '0 20px 0 45px',
      },
    },
    tabLabel: {
      padding: '0 12px 10px 12px',
    },
    tabSelected: {
      opacity: 1,
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.main,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

interface InjectedProps extends Props {
  authStore: AuthStore;
  usersStore: UsersStore;
  filesStore: FilesStore;
}

interface State {
  tabValue: number;
}
//#endregion

@inject('authStore', 'usersStore', 'filesStore')
class Users extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { location } = this.props;

    if (location && location.search) {
      const tabValue = queryString.parse(location.search, {
        parseNumbers: true,
      }).type as number;
      this.state = { tabValue };
    } else {
      this.state = { tabValue: 0 };
    }
  }

  get injected() {
    return this.props as InjectedProps;
  }

  componentWillUnmount() {
    const { usersStore, filesStore } = this.injected;

    filesStore.clear();
    usersStore.cleanUp();
  }

  handleTabChange = (event: object, value: number) => {
    const query = { type: value };
    const search = queryString.stringify(query);
    navigate(`/users?${search}`);
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      prevProps.location &&
      this.props.location &&
      prevProps.location.search !== this.props.location.search
    ) {
      const tabValue = queryString.parse(this.props.location.search, {
        parseNumbers: true,
      }).type as number;
      this.setState({ tabValue });
    }
  }

  render() {
    const { classes } = this.props;
    const { tabValue } = this.state;

    const {
      authStore: { isAdmin },
    } = this.injected;

    return (
      <div className={classes.root}>
        <div className={classes.mainContainer}>
          <div className={classes.topPanel}>
            <Tabs
              value={tabValue}
              onChange={this.handleTabChange}
              classes={{
                indicator: classes.tabIndicator,
              }}
            >
              {isAdmin && (
                <Tab
                  label="Userbase"
                  classes={{
                    root: classes.tab,
                    labelContainer: classes.tabLabel,
                    selected: classes.tabSelected,
                  }}
                />
              )}
              <Tab
                label="Labs"
                classes={{
                  root: classes.tab,
                  labelContainer: classes.tabLabel,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </div>

          {isAdmin && tabValue === 0 && <UserBase />}

          {((!isAdmin && tabValue === 0) || (isAdmin && tabValue === 1)) && (
            <Labs />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Users);
