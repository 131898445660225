import React, { ChangeEvent } from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Indicator from './userTabs/Indicator';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    tab: {
      fontSize: '.95rem',
      fontWeight: 500,
      margin: '0 22px',
      padding: 0,
      minWidth: 0,
      minHeight: 48,
      '&:first-child': {
        margin: '0 22px 0 0',
      },
    },
    tabLabel: {
      padding: 0,
    },
    tabLabelValue: {
      textShadow: '0 0 1px #000',
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.main,
    },
    tabWrapper: {
      flexDirection: 'row',
    },
  });
//#endregion

//#region Types
interface Tab {
  label: string;
  id: number;
  people: number;
  value: string;
}

interface Props extends WithStyles<typeof styles> {
  value: string;
  tabs: Tab[];
  hiddenTabs?: string[];
  handleTabChange: (value: string) => void;
}
//#endregion

// Component
const UserTabs: React.FunctionComponent<Props> = ({
  classes,
  value,
  tabs,
  hiddenTabs,
  handleTabChange,
}) => {
  const onHandleTabChange = (event: ChangeEvent<{}>, tabValue: string) => {
    handleTabChange(tabValue);
  };

  let filteredTabs;
  if (hiddenTabs) {
    filteredTabs = tabs.filter((tab: Tab) => !hiddenTabs.includes(tab.value));
  } else {
    filteredTabs = tabs;
  }

  return (
    <Tabs
      value={value}
      onChange={onHandleTabChange}
      classes={{
        indicator: classes.tabIndicator,
      }}
    >
      {filteredTabs.map((tab: Tab) => (
        <Tab
          key={tab.id}
          label={tab.label}
          value={tab.value}
          icon={
            <Indicator selected={tab.value === value}>{tab.people}</Indicator>
          }
          classes={{
            root: classes.tab,
            labelContainer: classes.tabLabel,
            label: classes.tabLabelValue,
            wrapper: classes.tabWrapper,
          }}
        />
      ))}
    </Tabs>
  );
};

export default withStyles(styles)(UserTabs);
