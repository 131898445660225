import React, { Component, SyntheticEvent } from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Modal from '@material-ui/core/Modal';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Typography } from '@material-ui/core';
import { supportedImagesExtensions } from './fileTypesSupport';
import { FileToDisplay } from './model';
import { getFileToDisplay } from './getFileToDisplay';
import { inject, observer } from 'mobx-react';
import { FilesStore } from 'stores/files/filesStore';
import { DownloadStore } from 'stores/files/downloadStore';
import { styles } from './styles';

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  fileUri: string;
  files: Common.File[];
  closeModal: () => void;
}

interface State {
  currentFile: FileToDisplay;
  currentIndex: number;
}

interface InjectedProps extends Props {
  filesStore: FilesStore;
  downloadStore: DownloadStore;
}

@inject('filesStore', 'downloadStore')
@observer
class Lightbox extends Component<Props, State> {
  state = {
    currentFile: { name: '', type: '', data: '' },
    currentIndex: 0,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  goPrev = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.currentIndex) {
      return;
    }

    const { files } = this.props;
    const nextFile = files[--this.state.currentIndex];
    await this.getCurrentFile(nextFile.uri);
  };

  goNext = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = this.props;

    if (this.state.currentIndex === files.length - 1) {
      return;
    }

    const nextFile = files[++this.state.currentIndex];
    await this.getCurrentFile(nextFile.uri);
  };

  componentDidMount() {
    this.getCurrentFile(this.props.fileUri);
  }

  async getCurrentFile(fileUri: string) {
    const { files } = this.props;
    const { downloadStore } = this.injected;
    const currentIndex = files.findIndex((file: any) => file.uri === fileUri);
    const file = files[currentIndex];
    const blob = await downloadStore.downloadFile(file);
    const currentFile = await getFileToDisplay(file, blob);
    this.setState({
      currentFile,
      currentIndex,
    });
  }

  render() {
    const { classes, closeModal, open, files } = this.props;
    const file: FileToDisplay = this.state.currentFile;

    return (
      <Modal open={open} onClose={closeModal} className={classes.modal}>
        <div className={classes.lightbox}>
          <CloseIcon className={classes.closeIcon} onClick={closeModal} />
          {file.data && (
            <>
              <div className={classes.content}>
                <IconButton
                  disableRipple
                  className={
                    !this.state.currentIndex
                      ? classes.hideNavBtn
                      : classes.navBtn
                  }
                  type="button"
                  onClick={this.goPrev}
                >
                  <ChevronLeftIcon />
                </IconButton>
                {supportedImagesExtensions.includes(file.type) ? (
                  <div className={classes.picture}>
                    <img className={classes.image} src={file.data} alt="" />
                  </div>
                ) : (
                  <div className={classes.paper}>
                    <pre className={classes.txt}>{file.data}</pre>
                  </div>
                )}
                <IconButton
                  disableRipple
                  className={
                    this.state.currentIndex < files.length - 1
                      ? classes.navBtn
                      : classes.hideNavBtn
                  }
                  type="button"
                  onClick={this.goNext}
                >
                  <ChevronRightIcon />
                </IconButton>
              </div>
              <Typography className={classes.name}>{file.name}</Typography>
            </>
          )}
        </div>
      </Modal>
    );
  }
}
export default withStyles(styles)(Lightbox);
