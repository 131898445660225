import React from 'react';
import classNames from 'classnames';
import truncate from 'lodash-es/truncate';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 50,
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: 10,
      cursor: 'pointer',
      padding: '10px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 8,

      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    selected: {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: '#ffffff',
    },
    name: {
      fontSize: theme.typography.pxToRem(18),
    },
    role: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  name: string;
  selected?: boolean;
  role?: string;
  onClick?: () => void;
}
//#endregion

const ListItem: React.FunctionComponent<Props> = ({
  classes,
  name,
  selected = false,
  role,
  onClick,
}) => {
  const truncName = truncate(name, {
    length: 24,
  });

  return (
    <li
      className={classNames(classes.root, {
        [classes.selected]: selected,
      })}
      onClick={onClick}
    >
      <Typography className={classes.name}>{truncName}</Typography>
      <Typography className={classes.role}>{role}</Typography>
    </li>
  );
};

export default withStyles(styles)(ListItem);
