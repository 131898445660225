import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';

import Button from '../../../components/ui/Button';

//#region Styles
const styles = () =>
  createStyles({
    root: {},
    createLabButton: {
      marginTop: 40,
    },
  });
//#endregion

//#region Types

interface Props extends WithStyles<typeof styles> {
  onSave: (email: string, fullName: string) => void;
  inProgress: boolean;
}
//#endregion

// Component
const InviteUser: React.FunctionComponent<Props> = ({
  onSave,
  inProgress,
  classes,
}) => {
  return (
    <Formik
      initialValues={{
        email: '',
        fullName: '',
      }}
      onSubmit={values => {
        onSave(values.email.toLowerCase(), values.fullName);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required('Invalid e-mail format'),
        fullName: Yup.string().required('Full name is required'),
      })}
    >
      {({ values, handleChange, handleSubmit, errors }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            margin="normal"
            fullWidth
            label="Full name"
            id="fullName"
            name="fullName"
            error={!!errors.fullName}
            helperText={errors.fullName || ''}
            onChange={handleChange}
            value={values.fullName}
          />

          <TextField
            required
            margin="normal"
            fullWidth
            label="E-mail"
            id="email"
            name="email"
            error={!!errors.email}
            helperText={errors.email || ''}
            onChange={handleChange}
            value={values.email}
          />

          <Button
            disabled={inProgress}
            type="submit"
            className={classes.createLabButton}
          >
            {inProgress ? 'Sending...' : 'Invite'}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(InviteUser);
