import React from 'react';
import Select from 'react-select';
import { OptionsType, ValueType } from 'react-select/lib/types';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 200,
    },
    input: {
      display: 'flex',
      padding: 0,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 9999,
      marginTop: theme.spacing.unit - 16,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
  });
//#endregion

//#region Types
interface Option {
  label: string;
  value: string;
}

interface CustomSelectProps {
  options: OptionsType<Option>;
}

interface Props extends WithStyles<typeof styles> {
  options: Array<Option>;
  label: string;
  id: string;
  name: string;
  noOptionsMessage?: string;
  isClearable?: boolean;
  autoFocus?: boolean;
  helperText: string;
  error: boolean;
  value?: Option;
  onChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  onBlur: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
}
//#endregion

//#region Components
function NoOptionsMessage(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent(props: any) {
  const { inputRef, ...others } = props;
  return <div ref={inputRef} {...others} />;
}

function Option(props: any) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props: any) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: any) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props: any) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}
//#endregion

const components = {
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const AutoCompleter: React.FunctionComponent<Props & CustomSelectProps> = ({
  label,
  autoFocus,
  classes,
  id,
  name,
  options,
  helperText,
  error,
  value,
  onChange,
  onBlur,
  isClearable,
  noOptionsMessage,
}) => {
  const Control = () => (props: any) => (
    <TextField
      fullWidth
      autoFocus={autoFocus}
      margin="normal"
      id={id}
      name={name}
      error={error}
      helperText={helperText}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );

  const handleChange = (option: ValueType<Option | Option[]>) => {
    if (option) {
      onChange(name, (option as any).value);
    } else {
      onChange(name, '');
    }
  };

  const handleBlur = () => {
    onBlur(name, true);
  };

  return (
    <Select
      autoFocus={autoFocus}
      classes={classes}
      options={options}
      components={{
        ...components,
        Control: Control(),
      }}
      textFieldProps={{
        label,
        InputLabelProps: {
          shrink: true,
        },
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      menuPlacement="auto"
      isClearable={isClearable}
      value={value}
      noOptionsMessage={noOptionsMessage ? () => noOptionsMessage : undefined}
      escapeClearsValue
    />
  );
};

export default withStyles(styles)(AutoCompleter);
