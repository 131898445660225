import React, { memo, useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '../../components/ui/Button';
import Modal from '../../components/ui/Modal';

import { FilesStore } from 'stores/files/filesStore';
import { AppStore } from 'stores/appStore';
import { isDicomFile } from 'utils/directoryUtils';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    button: {
      marginTop: 40,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 20,
    },
    inputLabel: {
      color: '#8A8B8D',
      fontSize: '12px',
      margin: '20px 0 15px 0',
      padding: '0 12px',
    },
    inputLabelFirst: {
      padding: 0,
    },
    setName: {
      color: '#1E1F24',
      fontWeight: 'bold',
      fontSize: '15px',
      marginTop: '-16px',
    },
    energyBox: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      margin: '0 -12px',
    },
    energyPosition: {
      width: '50%',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    chip: {
      padding: '2px 20px',
      background: '#E1F3EE',
      borderRadius: '16px',
      display: 'inline-block',
      width: 124,
    },
    chipLabel: {
      fontSize: '16px',
      letterSpacing: '0.15px',
      color: '#1E1F24',
    },
    radioBtn: {
      marginRight: 0,
      marginLeft: '-2px',
      marginTop: '-10px',
      width: '50%',

      '&:last-child': {
        marginLeft: 0,
      },
    },
    radio: {
      color: `${theme.palette.primary.main} !important`,
    },
    radioMissing: {
      display: 'none',
    },
    radioBtnMissing: {
      cursor: 'default',
      width: '50%',
      padding: '0 12px',
      margin: 0,
      marginTop: '-10px',
    },
    missingLabel: {
      color: '#D15151',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  opened: boolean;
  title: string;
  name: string;
  onCancel: () => void;
  onConfirm: (value: string | undefined) => void;
  filesStore?: FilesStore;
  appStore?: AppStore;
}
//#endregion

const EnergyChoserModal: React.FunctionComponent<Props> = ({
  classes,
  title,
  name,
  opened,
  onCancel,
  onConfirm,
  filesStore,
  appStore,
}) => {
  const [value, setValue] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [lowAmount, setLowAmount] = useState<number>(0);
  const [highAmount, setHighAmount] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, value: string) => {
    setValue(value);
  };

  const countFiles = async (currentPath: string) => {
    let tempValue;
    setLoading(true);

    try {
      let lowDataExists = false;
      let highDataExists = false;
      const setData = await filesStore!.getFilesStructureFromPath(
        `${currentPath}/${name}.ctimagesset`,
      );

      setData.forEach((file: Common.File) => {
        if (file.path === 'Low Energy.low') {
          lowDataExists = true;
        }
        if (file.path === 'High Energy.high') {
          highDataExists = true;
        }
      });

      if (lowDataExists) {
        const lowData = await filesStore!.getFilesStructureFromPath(
          `${currentPath}/${name}.ctimagesset/Low Energy.low`,
        );
        const dicomFiles = lowData.filter((file: Common.File) =>
          isDicomFile(file.path),
        );

        if (dicomFiles.length) {
          setLowAmount(dicomFiles.length);
          tempValue = 'low';
        }
      }

      if (highDataExists) {
        const highData = await filesStore!.getFilesStructureFromPath(
          `${currentPath}/${name}.ctimagesset/High Energy.high`,
        );
        const dicomFiles = highData.filter((file: Common.File) =>
          isDicomFile(file.path),
        );

        if (dicomFiles.length) {
          setHighAmount(dicomFiles.length);

          if (!tempValue) {
            tempValue = 'high';
          }
        }
      }

      setValue(tempValue);
      setLoading(false);
    } catch (e) {
      appStore!.showErrorToaster('Failed to fetch energies');
      setLoading(false);
    }
  };

  const handleOnOpen = () => {
    onConfirm(value);
  };

  useEffect(() => {
    if (filesStore) {
      countFiles(filesStore.currentPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open={opened} closeModal={onCancel} title={title}>
      <div className={classes.root}>
        <Typography
          variant="subtitle1"
          component="p"
          className={classNames(classes.inputLabel, classes.inputLabelFirst)}
        >
          CTImages set name
        </Typography>
        <Typography
          variant="subtitle1"
          component="strong"
          className={classes.setName}
        >
          {name}
        </Typography>

        <div className={classes.energyBox}>
          <div className={classes.energyPosition}>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.inputLabel}
            >
              Low energy images
            </Typography>
          </div>
          <div className={classes.energyPosition}>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.inputLabel}
            >
              High energy images
            </Typography>
          </div>
        </div>

        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={50} />
          </div>
        ) : (
          <RadioGroup
            classes={{ root: classes.energyBox }}
            aria-label="images"
            name="images"
            value={value}
            onChange={handleChange}
          >
            {!lowAmount ? (
              <FormControlLabel
                classes={{
                  root: classes.radioBtnMissing,
                }}
                value={undefined}
                control={<Radio classes={{ root: classes.radioMissing }} />}
                label={
                  <div>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      className={classes.missingLabel}
                    >
                      Energy Missing
                    </Typography>
                  </div>
                }
              />
            ) : (
              <FormControlLabel
                classes={{
                  root: classes.radioBtn,
                }}
                value="low"
                control={<Radio classes={{ colorSecondary: classes.radio }} />}
                label={
                  <div className={classes.chip}>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      className={classes.chipLabel}
                    >
                      {lowAmount} images
                    </Typography>
                  </div>
                }
              />
            )}

            {!highAmount ? (
              <FormControlLabel
                classes={{
                  root: classes.radioBtnMissing,
                }}
                value={undefined}
                control={<Radio classes={{ root: classes.radioMissing }} />}
                label={
                  <div>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      className={classes.missingLabel}
                    >
                      Energy Missing
                    </Typography>
                  </div>
                }
              />
            ) : (
              <FormControlLabel
                classes={{
                  root: classes.radioBtn,
                }}
                value="high"
                control={<Radio classes={{ colorSecondary: classes.radio }} />}
                label={
                  <div className={classes.chip}>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      className={classes.chipLabel}
                    >
                      {highAmount} images
                    </Typography>
                  </div>
                }
              />
            )}
          </RadioGroup>
        )}

        <Button
          onClick={handleOnOpen}
          disabled={loading || !value}
          className={classes.button}
        >
          {loading ? 'Loading energies...' : 'Open'}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(
  withStyles(styles)(
    inject('filesStore', 'appStore')(observer(EnergyChoserModal)),
  ),
);
