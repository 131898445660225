import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, navigate } from '@reach/router';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '../components/ui/Button';
import SnackbarMessage from '../components/ui/SnackbarMessage';

import { AuthStore } from '../stores/authStore';
import { UsersStore } from '../stores/usersStore';
import Step1 from './passwordReset/Step1';
import Step2 from './passwordReset/Step2';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 68px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      height: '100%',
      width: 498,
      boxShadow: theme.shadows[1],
    },
    cardContent: {
      padding: '34px 90px 0 !important',
    },
    form: {
      marginBottom: 40,
      paddingTop: 10,
    },
    submitButton: {
      marginTop: 40,
    },
    loginButton: {
      marginTop: 40,
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    errorIcon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing.unit,
    },
    errorMessage: {
      display: 'flex',
      alignItems: 'center',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

interface InjectedProps extends Props {
  authStore: AuthStore;
  usersStore: UsersStore;
}
//#endregion

//#region Component
@inject('authStore', 'usersStore')
@observer
class PasswordReset extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleSendResetLink = (email: string) => {
    this.injected.authStore.sendPasswordRest(email);
  };

  handleSetNewPassword = (password: string) => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email')!;
    const token = params.get('token')!;

    this.injected.authStore.setNewPassword({
      email,
      token,
      password,
    });
  };

  goToDashboard = () => {
    navigate('/');
  };

  render() {
    const { classes } = this.props;
    const {
      passwordResetInProgress,
      hasError,
      passwordResetSent,
      isLoggedIn,
    } = this.injected.authStore;

    const params = new URLSearchParams(window.location.search);
    const email = params.get('email')!;
    const token = params.get('token')!;
    const isStep2 = Boolean(email) && Boolean(token);

    return (
      <div className={classes.root}>
        <SnackbarMessage
          opened={hasError}
          variant="error"
          message="Wrong credentials"
          onClose={this.injected.authStore.clearError}
        />

        <Card className={classes.card}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Typography variant="h1" align="center" gutterBottom>
              {isStep2 ? 'Set new password' : 'Send reset link'}
            </Typography>
            {isLoggedIn ? (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{
                  paddingTop: 24,
                  paddingBottom: 40,
                }}
              >
                <Grid item>
                  <Typography variant="h6">You are now logged in</Typography>
                </Grid>
                <Grid item>
                  <Button
                    type="button"
                    width={300}
                    className={classes.loginButton}
                    onClick={this.goToDashboard}
                  >
                    Go to dashboard
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <>
                {!isStep2 && (
                  <Step1
                    isLoading={passwordResetInProgress}
                    onSend={this.handleSendResetLink}
                    passwordResetSent={passwordResetSent}
                  />
                )}

                {isStep2 && (
                  <Step2
                    isLoading={passwordResetInProgress}
                    onSend={this.handleSetNewPassword}
                  />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
}
//#endregion

export default withStyles(styles)(PasswordReset);
