import React, { Component } from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import LeftMenu from './lab/LeftMenu';
import Settings from './lab/Settings';
import Users from './lab/Users';
import Groups from './lab/Groups';
import DirectoryListing from './lab/DirectoryListing';
import { observer, inject } from 'mobx-react';
import { GroupsStore } from './../stores/groupsStore';

//#region Styles
const styles = () =>
  createStyles({
    root: {
      paddingLeft: 240,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
    },
  });
//#endregion

//#region Types
interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  id: string;
}

interface InjectedProps extends Props {
  groupsStore: GroupsStore;
}
//#endregion

@inject('groupsStore')
@observer
class Lab extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    const { id } = this.props;
    this.injected.groupsStore.getLabGroups(id);
  }

  render() {
    const pathname = (this.props.location || { pathname: '' }).pathname;
    const { classes, id } = this.props;

    return (
      <div className={classes.root}>
        <aside>
          <LeftMenu labName={id || ''} />
        </aside>

        <div>
          <Router>
            <DirectoryListing id={id} fullPath={pathname} path="/*" />
            <Settings labName={id} path="settings" />
            <Users labName={id} path="users" />
            <Groups labName={id} path="groups" />
          </Router>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Lab);
