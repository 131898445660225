import NProgress from 'nprogress';
import streamSaver from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill/ponyfill';

export function getStream(downloadUrl: string) {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const token = user.token || '';
  let headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);

  return fetch(downloadUrl, {
    method: 'GET',
    headers,
  });
}

export function downloadURI(uri: string) {
  const link = document.createElement('a');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function getFileStream(response: any, fileName: string) {
  const readableStream = response.body;

  if (!(window as any).WritableStream) {
    streamSaver.WritableStream = WritableStream;
    (window as any).WritableStream = WritableStream;
  }

  const fileStream = streamSaver.createWriteStream(fileName);
  let writer: WritableStreamDefaultWriter;

  window.onunload = () => {
    if (writer && (window as any).WritableStream) {
      (window as any).WritableStream.abort();
      writer.abort();
    }
  };

  if (WritableStream && readableStream.pipeTo) {
    return readableStream.pipeTo(fileStream).then(() => NProgress.done());
  }
  writer = fileStream.getWriter();

  const reader = readableStream.getReader();
  const pump = () =>
    reader
      .read()
      .then((res: any) =>
        res.done ? writer.close() : writer.write(res.value).then(pump),
      );

  pump();
}

export default getFileStream;
