import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';

import { CompressFileProcess, DownloadStore } from 'stores/files/downloadStore';
import FileProcessStatus from '../fileProcessStatus/FileProcessStatus';
import FileProcessPopup from '../FileProcessPopup';
import FileProcesses from '../fileProcessStatus/FileProcesses';
import ConfirmationModal from '../../ui/ConfirmationModal';

interface Props {
  downloadStore?: DownloadStore;
}

const FileZipPopup: React.FunctionComponent<Props> = ({ downloadStore }) => {
  const [cancelCompressionOpen, setCancelCompressionOpen] = useState(false);
  const {
    compressFilesArray,
    compressFilesAmount,
    compressProgress,
    compressionInProgress,
    compressionDone,
    cancelling,
    retryCompression,
    cancelCompression,
    closeCompression,
  } = downloadStore!;

  const uploadHeaderStatus = compressionDone ? (
    <>Files zipped successfully</>
  ) : (
    <>
      Zipping {compressFilesAmount}{' '}
      {compressFilesAmount > 1 ? 'packages' : 'package'} (
      {`${compressProgress || 0}%`})
    </>
  );

  const handleCloseZipBox = () => {
    if (!compressionInProgress) {
      closeCompression();
    } else {
      setCancelCompressionOpen(true);
    }
  };

  const handleCancel = (id: number) => {
    cancelCompression(id);
  };

  const handleRetry = (id: number) => {
    retryCompression(id);
  };

  return (
    <>
      <ConfirmationModal
        opened={cancelCompressionOpen}
        title="Cancel download"
        question="Your download is not complete. Would you like to cancel the download?"
        onCancel={() => setCancelCompressionOpen(false)}
        onConfirm={closeCompression}
      />

      <FileProcessPopup
        aborted={cancelling}
        header={uploadHeaderStatus}
        progress={compressProgress}
        onCloseFloatBox={handleCloseZipBox}
      >
        <FileProcesses amount={compressFilesAmount}>
          {compressFilesArray.map((zip: CompressFileProcess) => {
            const emptyDir = zip.error === 'No files in paths';
            return (
              <FileProcessStatus
                key={zip.id}
                datasetName={`Zipping ${zip.paths.length} ${
                  zip.paths.length > 1 ? 'files' : 'file'
                }`}
                icon={<ArchiveOutlinedIcon />}
                finished={zip.status === 'ready'}
                failed={zip.status === 'failed'}
                cancelled={zip.status === 'cancelled'}
                onCancel={() => handleCancel(zip.id)}
                onRetry={emptyDir ? undefined : () => handleRetry(zip.id)}
              >
                {emptyDir ? 'The folder is empty' : ''}
              </FileProcessStatus>
            );
          })}
        </FileProcesses>
      </FileProcessPopup>
    </>
  );
};

export default inject('downloadStore')(observer(FileZipPopup));
