import React, { memo } from 'react';
import { inject, observer } from 'mobx-react';

import { FilesStore } from '../../../stores/files/filesStore';
import FileProcessStatus from '../fileProcessStatus/FileProcessStatus';
import FileProcessPopup from '../FileProcessPopup';

interface Props {
  filesStore?: FilesStore;
}

const FileUploadPopup: React.FunctionComponent<Props> = ({ filesStore }) => {
  const {
    filesToUploadAmount,
    uploadedFilesAmount,
    filesUploadProgress,
    uploaded,
    fileUploadAborted,
    closeFileUpload,
    hasToRetryUpload,
    datasetName,
    retryUpload,
  } = filesStore!;

  const closeUploadBox = () => {
    if (!fileUploadAborted) {
      closeFileUpload();
    }
  };

  const uploadHeaderStatus = hasToRetryUpload ? (
    'Upload failed!'
  ) : uploaded ? (
    <>Files uploaded successfully</>
  ) : (
    <>
      Uploading {filesToUploadAmount} files ({`${filesUploadProgress || 0}%`})
    </>
  );

  return (
    <FileProcessPopup
      aborted={fileUploadAborted}
      header={uploadHeaderStatus}
      progress={filesUploadProgress}
      onCloseFloatBox={closeUploadBox}
    >
      <FileProcessStatus
        datasetName={datasetName}
        finished={uploaded}
        failed={hasToRetryUpload}
        onRetry={retryUpload}
      >
        {uploadedFilesAmount} from {filesToUploadAmount}
      </FileProcessStatus>
    </FileProcessPopup>
  );
};

export default memo(inject('filesStore')(observer(FileUploadPopup)));
