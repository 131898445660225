import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import { inject, observer } from 'mobx-react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { UsersStore } from '../../stores/usersStore';
import { AuthStore } from '../../stores/authStore';
import { AppStore } from '../../stores/appStore';
import { ProjectsStore } from '../../stores/projectsStore';
import Navigation from './users/Navigation';
import UsersList from './users/UsersList';
import PendingUsers from './users/PendingUsers';
import UserEdit from './users/UserEdit';

//#region styles
const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
    },
  });
//#endregion

//#region types
export interface AddUserInput {
  email: string;
  fullName: string;
  role: string;
  id?: string;
  projectId?: number;
}

interface EditUserInput {
  fullName: string;
  email: string;
  role: string;
  projectId: number;
  id: number;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  id: number;
  labName: string;
}

interface State {
  showPending: boolean;
}

interface InjectedProps extends Props {
  usersStore: UsersStore;
  authStore: AuthStore;
  projectsStore: ProjectsStore;
  appStore: AppStore;
}
//#endregion

@inject('usersStore', 'authStore', 'projectsStore', 'appStore')
@observer
class Users extends Component<Props, State> {
  state = {
    showPending: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    this.injected.usersStore.getUsersList();
  }

  componentWillUnmount() {
    this.injected.usersStore.cleanUp();
  }

  handleCreateUser = () => {
    this.setState({
      showPending: false,
    });
    this.injected.projectsStore.resetEditData();
    this.injected.usersStore.setUserEditOrCreate();
  };

  handleSelectUser = (id?: number, role: string = 'technician') => {
    if (id) {
      this.injected.usersStore.setUserEditOrCreate(id);
      this.injected.projectsStore.setUserRole(role);
    }
  };

  handleSelectPendingUser = (id?: number) => {
    if (id) {
      this.handleCancelEdit();
      this.injected.usersStore.setUserEditOrCreate();
      this.injected.projectsStore.setClientPendingInvitation(id);
    }
  };

  handleDeleteUserFromProject = async () => {
    const {
      projectsStore,
      usersStore: { selectedUser },
    } = this.injected;
    const { selectedClient } = projectsStore;

    if (!selectedUser && !selectedClient) {
      return;
    }

    if (selectedClient) {
      await this.deleteInvitationFromProject(selectedClient.id);
    } else {
      await this.deleteUserFromProject();
    }
  };

  deleteInvitationFromProject = async (clientId: number) => {
    const { id } = this.props;
    const { projectsStore, appStore } = this.injected;

    try {
      projectsStore.setRequestInProgress(true);
      await projectsStore.removeInvitation(id, clientId);

      appStore.showSuccessToaster(
        'Invitation successfully removed from the project',
      );
      projectsStore.setRequestInProgress(false);
      await projectsStore.getSingleProject(String(id));
    } catch (error) {
      projectsStore.setRequestInProgress(false);
      appStore.showErrorToaster('Failed to remove invitation from the project');
    }
  };

  deleteUserFromProject = async () => {
    const { id } = this.props;
    const {
      projectsStore,
      appStore,
      usersStore: { selectedUser },
    } = this.injected;
    const { selectedRole } = projectsStore;

    try {
      projectsStore.setRequestInProgress(true);

      if (selectedRole === 'client') {
        await projectsStore.revokeClient({
          id: selectedUser!.id,
          projectId: id,
        });
      }

      if (selectedRole === 'technician') {
        await projectsStore.revokeTechnician({
          id: selectedUser!.id,
          projectId: id,
        });
      }

      if (selectedRole === 'projectManager') {
        await projectsStore.revokeProjectManager({
          id: selectedUser!.id,
          projectId: id,
        });
      }

      appStore.showSuccessToaster('User successfully revoked from the project');
      projectsStore.setRequestInProgress(false);
      await projectsStore.getSingleProject(String(id));
    } catch (error) {
      projectsStore.setRequestInProgress(false);
      appStore.showErrorToaster('Failed to revoke user from the project');
    }
  };

  handleSubmitUserToProject = async (data: AddUserInput | EditUserInput) => {
    const {
      usersStore: { selectedUser },
      projectsStore: { selectedClient },
    } = this.injected;

    if (selectedUser && !selectedClient) {
      await this.handleEditUser(data as EditUserInput);
    } else if (selectedClient) {
      await this.handleEditPendingUser(data as EditUserInput);
    } else {
      await this.handleAddUserToProject(data as AddUserInput);
    }
  };

  handleEditPendingUser = async (data: EditUserInput) => {
    const { id } = this.props;
    const { projectsStore, appStore } = this.injected;

    try {
      projectsStore.setRequestInProgress(true);
      await projectsStore.addClient({ ...data, projectId: id });

      projectsStore.setRequestInProgress(false);
      appStore.showSuccessToaster('Invitation resend successfully');

      await projectsStore.getSingleProject(String(id));
    } catch (error) {
      projectsStore.setRequestInProgress(false);
      appStore.showErrorToaster('Invitation not send');
    }
  };

  handleEditUser = async (data: EditUserInput) => {
    const { id } = this.props;
    const {
      projectsStore,
      appStore,
      usersStore: { listOfUsers },
    } = this.injected;

    try {
      projectsStore.setRequestInProgress(true);
      const user = listOfUsers.find(user => user.email === data.email);
      await projectsStore.editClient({ ...data, id: user!.id });

      projectsStore.setRequestInProgress(false);
      appStore.showSuccessToaster('Client successfully edited');

      await projectsStore.getSingleProject(String(id));
    } catch (error) {
      projectsStore.setRequestInProgress(false);
      appStore.showErrorToaster('Failed to edit client');
    }
  };

  handleAddUserToProject = async (data: AddUserInput) => {
    const { id } = this.props;
    const {
      projectsStore,
      appStore,
      usersStore: { listOfUsers },
    } = this.injected;

    try {
      projectsStore.setRequestInProgress(true);

      if (data.role === 'client') {
        await projectsStore.addClient({ ...data, projectId: id });
      }

      if (data.role === 'technician') {
        const user = listOfUsers.find(user => user.email === data.email);

        await projectsStore.addTechnician({
          ...data,
          projectId: id,
          // @ts-ignore
          id: user.id,
        });
      }

      if (data.role === 'projectManager') {
        const user = listOfUsers.find(user => user.email === data.email);

        await projectsStore.addProjectManager({
          ...data,
          projectId: id,
          // @ts-ignore
          id: user.id,
        });
      }

      projectsStore.setRequestInProgress(false);

      if (data.role === 'client') {
        appStore.showSuccessToaster(
          'Invitation to client user successfully sent',
        );
      } else {
        appStore.showSuccessToaster('User successfully added to the project');
      }

      await projectsStore.getSingleProject(String(id));
    } catch (error) {
      projectsStore.setRequestInProgress(false);
      if (error.data) {
        appStore.showErrorToaster(error.data && error.data.message);
      } else {
        appStore.showErrorToaster('Failed to add user to the project');
      }
    }
  };

  handleCancelEdit = () => {
    this.injected.projectsStore.resetEditData();
    this.injected.usersStore.resetEditData();
  };

  handleToggleSearch = () => {
    this.injected.projectsStore.toggleSearchActive();
  };

  handlePendingUsers = () => {
    this.handleCancelEdit();
    this.setState({
      showPending: true,
    });
  };

  getEditSectionComponent = (type: string) => {
    if (type === 'user') {
      return (
        <UserEdit
          projectId={this.props.id}
          onSave={this.handleSubmitUserToProject}
          onDeleteFromProject={this.handleDeleteUserFromProject}
          onCancel={this.handleCancelEdit}
        />
      );
    }

    return null;
  };

  render() {
    const { classes, labName } = this.props;
    const { showPending } = this.state;
    const { isAdmin, user } = this.injected.authStore;
    const { selectedOption } = this.injected.usersStore;
    const isCurrentLabAdmin = (user.lab_admin_labs_full || []).includes(
      labName,
    );
    const isCurrentLabPM = this.injected.projectsStore.isPMForCurrentProject(
      user,
    );

    return (
      <div className={classes.root}>
        <Navigation
          onCreateUser={this.handleCreateUser}
          onPendingUsers={this.handlePendingUsers}
          onToggleSearch={this.handleToggleSearch}
          canEdit={isAdmin || isCurrentLabAdmin || isCurrentLabPM}
        />

        {!showPending ? (
          <UsersList
            onSelectUser={this.handleSelectUser}
            canEdit={isAdmin || isCurrentLabAdmin || isCurrentLabPM}
          />
        ) : (
          <PendingUsers onSelectUser={this.handleSelectPendingUser} />
        )}

        {selectedOption && this.getEditSectionComponent(selectedOption)}
      </div>
    );
  }
}

export default withStyles(styles)(Users);
