import drawText from './drawText';
import dayjs from 'dayjs';

export default (
  context: CanvasRenderingContext2D,
  width: number,
  topOffset: number,
  tiltAngle: number,
  voltage: number,
  logoImage: HTMLImageElement,
) => {
  const dayStr = dayjs().format('MMMM DD, YYYY');
  const rightPosition = width - 5;

  drawText(
    context,
    `${voltage} kV`,
    rightPosition,
    topOffset + 2,
    'right',
    'bottom',
  );
  drawText(context, dayStr, rightPosition, topOffset + 12, 'right', 'bottom');
  drawText(
    context,
    `Tilt angle: ${tiltAngle}`,
    rightPosition,
    topOffset + 22,
    'right',
    'bottom',
  );

  context.drawImage(logoImage, 8, topOffset - 4, 30, 30);
};
