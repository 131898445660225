import React, { Component } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { inject } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Button from './Button';

import { SelectedFilesStore } from '../../stores/files/selectedFilesStore';
import { FilesStore } from '../../stores/files/filesStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    fileNameInput: {
      minWidth: '270px',
      marginTop: '7px',
      marginBottom: 0,
    },
    button: {
      marginTop: '0px',
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: theme.palette.grey[800],
    },
    fieldContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
    },
    actions: {
      marginTop: 32,
    },
    content: {
      paddingLeft: 36,
      paddingRight: 36,
    },
    assetName: {
      fontWeight: 'normal',
      display: 'inline-block',
      marginLeft: '20px',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  title: string;
  opened: boolean;
  name: string;
  currentPath: string;
  onCancel: () => void;
  onConfirm: () => void;
  isDirectory: boolean;
  isProjectListing?: boolean;
  isSpecialDirectory?: boolean;
}

interface InjectedProps extends Props {
  selectedFilesStore: SelectedFilesStore;
  filesStore: FilesStore;
}

interface State {
  isLoading: boolean;
}
//#endregion

@inject('selectedFilesStore', 'filesStore')
class RenameFileModal extends Component<Props, State> {
  state = {
    isLoading: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  handleRename = async (newName: any) => {
    const { currentPath, name, onConfirm, isProjectListing } = this.props;
    const { selectedFilesStore, filesStore } = this.injected;

    this.setState({
      isLoading: true,
    });

    const oldPath = isProjectListing
      ? `Projects/${currentPath}/${name}`
      : `${currentPath}/${name}`;
    const newPath = isProjectListing
      ? `Projects/${currentPath}/${newName}`
      : `${currentPath}/${newName}`;

    await selectedFilesStore.pasteFile(oldPath, newPath);
    onConfirm();
    filesStore.refresh();
  };

  render() {
    const {
      name,
      classes,
      opened,
      title,
      isDirectory,
      isSpecialDirectory,
      onCancel,
    } = this.props;

    const { isLoading } = this.state;
    let extension: string | undefined = '';
    let fileName: string = name;

    if (!isDirectory || isSpecialDirectory) {
      const lastDot = name.lastIndexOf('.');

      fileName = name.substring(0, lastDot);
      extension = name.substring(lastDot + 1);
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        open={opened}
      >
        <DialogTitle classes={{ root: classes.content }}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onCancel}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <Formik
            initialValues={{
              name: fileName,
            }}
            onSubmit={values => {
              if (isDirectory && !isSpecialDirectory) {
                this.handleRename(values.name.trim());
              } else {
                this.handleRename(`${values.name.trim()}.${extension}`);
              }
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .trim()
                .required('Field is required'),
            })}
          >
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className={classes.fieldContainer}>
                  <TextField
                    autoFocus
                    className={classes.fileNameInput}
                    required
                    margin="normal"
                    id="name"
                    label="Name"
                    error={!!(errors.name && touched.name)}
                    helperText={errors.name && touched.name ? errors.name : ''}
                    onChange={handleChange}
                    value={values.name}
                  />

                  {!isDirectory && extension && (
                    <Typography className={classes.assetName} component="span">
                      {`.${extension}`}
                    </Typography>
                  )}
                </div>

                <DialogActions classes={{ root: classes.actions }}>
                  <Button
                    width={100}
                    onClick={onCancel}
                    color="primary"
                    variant="outlined"
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    width={100}
                    color="primary"
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(RenameFileModal);
