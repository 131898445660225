import drawLine from './drawLine';
import drawText from './drawText';

import {
  leftMarkerWidth,
  largeLineWidth,
  mediumLineWidth,
  smallLineWidth,
  markerTextVerticalOffset,
  rulerOffset,
} from './spacings';

// verticalOffset = position in px from top
// isVertical true -> to the left side of sample image
// isVertical false -> to the bottom side of sample image

// in m,  every 1/1000 m = 1 mm
// in ft, every 1/100 ft = 3.054 mm

function sizeAndPxInSelectedUnit(
  pxPerMm: number,
  sizeMm: number,
  depthUnit: string,
) {
  if (depthUnit === 'ft') {
    return {
      pxPerSelectedUnit: pxPerMm * 3.054,
      sizeInSelectedUnit: sizeMm / 3.054,
    };
  }
  return {
    pxPerSelectedUnit: pxPerMm,
    sizeInSelectedUnit: sizeMm,
  };
}

function depthInSelectedUnit(
  counter: number,
  startOffsetM: number,
  depthUnit: string,
) {
  if (depthUnit === 'ft') {
    const startOffsetFt = startOffsetM / 0.3054;
    return counter / 100 + startOffsetFt;
  }
  return counter / 1000 + startOffsetM;
}

function drawRuler(
  context: CanvasRenderingContext2D,
  sizeMm: number,
  widthScreenPx: number,
  isVertical: boolean,
  verticalOffset: number,
  startOffsetM: number,
  depthUnit: string,
  baseDecimalDigits: number,
) {
  // 1 ftstep = 1/100 ft = 3.054 mm

  const pxPerMm = widthScreenPx / sizeMm;
  const { sizeInSelectedUnit, pxPerSelectedUnit } = sizeAndPxInSelectedUnit(
    pxPerMm,
    sizeMm,
    depthUnit,
  );

  const depthUnitText = sizeMm < 1000 ? 'cm' : 'm';
  const depthUnitMultiplier = sizeMm < 1000 ? 100 : 1;

  const numberUnderMarkerDensity = sizeMm < 100 ? 20 : 50;

  const decimalDigits = sizeMm < 1000 ? 0 : baseDecimalDigits;

  for (let counter = 0; counter <= sizeInSelectedUnit; counter++) {
    const position = counter * pxPerSelectedUnit;

    let lineWidth = 0;

    if (counter % 10 === 0) {
      lineWidth = largeLineWidth;
    } else if (counter % 5 === 0) {
      lineWidth = mediumLineWidth;
    } else {
      lineWidth = smallLineWidth;
    }

    // vertical: y constant, dependent on position
    // non-vertical: x constant, dependent on position
    let xStart = 0;
    let yStart = 0;
    let xEnd = 0;
    let yEnd = 0;

    if (isVertical) {
      xStart = leftMarkerWidth - lineWidth - rulerOffset;
      xEnd = leftMarkerWidth - rulerOffset;
      yStart = verticalOffset + position;
      yEnd = yStart;
    } else {
      xStart = leftMarkerWidth + position;
      xEnd = xStart;
      yStart = verticalOffset + rulerOffset;
      yEnd = verticalOffset + lineWidth + rulerOffset;
    }

    drawLine(context, xStart, yStart, xEnd, yEnd);

    if (counter % numberUnderMarkerDensity === 0) {
      const depthToDisplay =
        depthInSelectedUnit(counter, startOffsetM, depthUnit) *
        depthUnitMultiplier;
      const text = `${depthToDisplay.toFixed(decimalDigits)}`;

      if (isVertical) {
        drawText(
          context,
          text,
          xStart - markerTextVerticalOffset,
          yStart,
          'right',
          'middle',
        );
      } else {
        drawText(
          context,
          text,
          xStart,
          yEnd + markerTextVerticalOffset,
          'center',
          'top',
        );
      }
    }
  }

  // Unit of the ruler
  if (isVertical) {
    drawText(
      context,
      depthUnitText,
      leftMarkerWidth - rulerOffset,
      verticalOffset,
      'right',
      'bottom',
    );
  } else {
    drawText(
      context,
      depthUnitText,
      leftMarkerWidth - smallLineWidth,
      verticalOffset + smallLineWidth,
      'right',
      'middle',
    );
  }
}

export default drawRuler;
