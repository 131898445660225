import React, { memo, useState } from 'react';
import { inject, observer } from 'mobx-react';
//@ts-ignore
import ReactSlider from 'react-slider';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Typography from '@material-ui/core/Typography';

import { CTViewerStore } from '../../../../stores/ctViewerStore';

//#region Styles
const styles = () =>
  createStyles({
    root: {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    slider: {
      width: '225px',
      height: '20px',
      margin: '0 12px',
    },
    thumb: {
      position: 'relative',
      backgroundColor: 'rgb(110, 206, 178)',
      width: '19px',
      height: '19px',
      borderRadius: '50%',
      border: '1px solid rgb(10, 166, 148)',
      transform: 'translateY(-5px)',

      '&:focus': {
        outline: 'none',
      },
    },
    track: {
      height: '4px',
      top: '3px',
      borderRadius: '6px',
      background: '#e9e9e9',

      '&-0': {
        background: '#e9e9e9',
      },
      '&-1': {
        background: '#e9e9e9',
      },
    },
    title: {
      display: 'block',
      marginBottom: 10,
    },
    input: {
      fontSize: 16,
      textAlign: 'center',
      borderRadius: '7px',
      border: '1px solid #DFE3ED',
      height: 33,
      width: 60,
      marginTop: '-9px',
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
}
//#endregion

const TiltSlider: React.FunctionComponent<Props> = ({
  classes,
  ctViewerStore,
}) => {
  const [localValue, setLocalValue] = useState<string | undefined>(undefined);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const isEmpty = !ev.target.value.trim();
    const isNaN = Number.isNaN(parseInt(ev.target.value, 10));

    if (!isEmpty && !isNaN) {
      ctViewerStore!.changeTilt(parseInt(ev.target.value, 10));
      setLocalValue(undefined);
    } else {
      setLocalValue(ev.target.value);
    }
  };

  const handleChangeSlider = (value: number) => {
    ctViewerStore!.changeTilt(value);
    setLocalValue(undefined);
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="subtitle1"
        component="span"
        className={classes.title}
      >
        Tilt:
      </Typography>
      <ReactSlider
        min={-90}
        max={90}
        value={[ctViewerStore!.tiltAngle]}
        withBars
        className={classes.slider}
        thumbClassName={classes.thumb}
        trackClassName={classes.track}
        onChange={handleChangeSlider}
      />
      <input
        type="number"
        value={localValue !== undefined ? localValue : ctViewerStore!.tiltAngle}
        onChange={handleChange}
        min={-90}
        max={90}
        step={1}
        className={classes.input}
      />
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(TiltSlider))),
);
