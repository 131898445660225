import React, { useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';

import { TempFile } from 'stores/files/filesStore';
import { isDicomFile } from 'utils/directoryUtils';
import { styles } from './styles';

interface Props extends WithStyles<typeof styles> {
  idAttr: string;
  filesAmount: number;
  onAreaDrop: (files: TempFile[]) => void;
}

const DraggableFileUpload: React.FunctionComponent<Props> = ({
  classes,
  idAttr,
  filesAmount,
  onAreaDrop,
}) => {
  const [drag, setDrag] = useState(false);

  const getTempFiles = (files: FileList) => {
    let filesArray: TempFile[] = [];

    filesArray = Array.from(files)
      .filter((file: File) => isDicomFile(file.name))
      .map((file: File) => ({
        file,
        name: file.name,
        exist: false,
        uploadProgress: 0,
        uploaded: false,
      }));

    return filesArray;
  };

  const handleDelete = () => {
    onAreaDrop([]);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDrag(true);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    const { files } = event.dataTransfer;
    event.preventDefault();
    setDrag(false);
    onAreaDrop(getTempFiles(files));
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDrag(false);
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files === null) {
      return;
    }

    onAreaDrop(getTempFiles(event.currentTarget.files));
  };

  return (
    <>
      {filesAmount > 0 ? (
        <div className={classes.rootAlternative}>
          <Chip
            classes={{
              root: classes.chip,
              label: classes.chipLabel,
              deleteIcon: classes.deleteIcon,
            }}
            deleteIcon={<ClearIcon />}
            label={`${filesAmount} ${filesAmount > 1 ? 'images' : 'image'}`}
            onDelete={handleDelete}
            clickable={false}
          />
          <input
            type="file"
            accept=".dcm"
            onChange={handleChange}
            className={classes.fileInput}
            multiple
            id={idAttr}
          />
          <Typography
            variant="subtitle1"
            component="span"
            className={classes.buttonText}
          >
            <label
              htmlFor={idAttr}
              className={classNames(classes.label, classes.labelAlt)}
            >
              Replace images
            </label>
          </Typography>
        </div>
      ) : (
        <div
          className={classNames(classes.root, {
            [classes.onDragHover]: drag,
          })}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragEnter}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
        >
          <input
            type="file"
            accept=".dcm"
            onChange={handleChange}
            className={classes.fileInput}
            multiple
            id={idAttr}
          />
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.areaText}
          >
            Drag and drop files or{' '}
            <label htmlFor={idAttr} className={classes.label}>
              browse
            </label>{' '}
            from your computer
          </Typography>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(DraggableFileUpload);
