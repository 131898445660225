import React, { memo } from 'react';
import classNames from 'classnames';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    search: {
      fontWeight: 'normal',
      fontSize: 13,
    },
    icon: {
      color: theme.palette.primary.main,
      marginTop: 3,
    },
    tableCell: {
      borderBottom: '2px solid #f3f3f3',
      paddingLeft: 16,
      width: '21%',
      '&:last-child': {
        width: '8%',
      },
      '&:first-child': {
        paddingLeft: 43,
      },
    },
    statusCell: {
      width: '8%',
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  onSearch: (val: string) => void;
  phrase?: string;
  tabValue: string;
}
//#endregion

const rows = [
  { id: 'user', label: 'User' },
  { id: 'lab', label: 'Lab' },
  { id: 'departments', label: 'Departments' },
  { id: 'last_activity', label: 'Last activity' },
  { id: 'status', label: 'Account status' },
];

const UserTableHead: React.FunctionComponent<Props> = ({
  onSearch,
  phrase,
  tabValue,
  classes,
}: Props) => {
  const onKeyDown = (event: React.ChangeEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement;
    onSearch(element.value);
  };

  return (
    <TableHead>
      <TableRow>
        {rows.map(row => {
          if (tabValue === 'pending' && row.id === 'last_activity') {
            return null;
          }
          return (
            <TableCell
              key={row.id}
              align="left"
              padding="none"
              className={classNames(classes.tableCell, {
                [classes.statusCell]: row.id === 'status',
              })}
            >
              {row.id === 'user' ? (
                <Grid container spacing={8} alignItems="flex-start">
                  <Grid item>
                    <SearchIcon className={classes.icon} />
                  </Grid>
                  <Grid item>
                    <InputBase
                      id="input-with-icon-grid"
                      placeholder="Search people"
                      className={classes.search}
                      type="search"
                      onChange={onKeyDown}
                      value={phrase}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ) : (
                <span>{row.label}</span>
              )}
            </TableCell>
          );
        })}
        <TableCell align="right" className={classes.tableCell} />
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles)(memo(UserTableHead));
