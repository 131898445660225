import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import is from 'is_js';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';

import { UsersStore } from '../../../stores/usersStore';
import { ProjectsStore } from '../../../stores/projectsStore';
import ListItem from './usersList/ListItem';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRight: `1px dashed ${theme.palette.primary.main}`,
      width: 350,
      padding: '40px 20px 0 32px',
    },
    section: {
      marginBottom: 30,
    },
    sectionTitle: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 'bold',
      color: '#C6CACB',
      marginBottom: 16,
    },
    usersList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      width: is.ie() ? '100%' : 300,
    },
    searchField: {
      marginBottom: 30,
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  onSelectUser: (id: number) => void;
}

interface InjectedProps extends Props {
  usersStore: UsersStore;
  projectsStore: ProjectsStore;
}
//#endregion

@inject('usersStore', 'projectsStore')
@observer
class PendingUsers extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { classes, onSelectUser } = this.props;
    const {
      selectedClient,
      requestInProgress,
      userSearchValue,
      setSearchValue,
      clearSearch,
      searchActive,
      filteredPendingClients,
    } = this.injected.projectsStore;

    return (
      <div className={classes.root}>
        {requestInProgress ? (
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            {searchActive && (
              <TextField
                autoFocus
                id="search"
                label="Search"
                fullWidth
                className={classes.searchField}
                value={userSearchValue}
                onChange={event => setSearchValue(event.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Clear search field">
                        <IconButton
                          aria-label="Clear search"
                          onClick={clearSearch}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {filteredPendingClients && filteredPendingClients.length >= 1 ? (
              <section className={classes.section}>
                <Typography className={classNames(classes.sectionTitle)}>
                  Pending users
                </Typography>

                <ul className={classes.usersList}>
                  {filteredPendingClients.map(user => (
                    <ListItem
                      key={user.id}
                      name={user.full_name}
                      selected={
                        selectedClient ? user.id === selectedClient.id : false
                      }
                      onClick={() => onSelectUser(user.id)}
                    />
                  ))}
                </ul>
              </section>
            ) : (
              <Grid container justify="center">
                <Grid item>
                  <Typography variant="subtitle1">No pending users</Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PendingUsers);
