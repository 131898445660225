import { observable, action, flow, computed } from 'mobx';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import uniqBy from 'lodash-es/uniqBy';
import pick from 'lodash-es/pick';
import difference from 'lodash-es/difference';
import snakeCase from 'lodash-es/snakeCase';
import { toJS } from 'mobx';

import appStore from './appStore';
import authStore from './authStore';
import httpClient from '../utils/api/httpClient';
import { navigate } from '@reach/router';

const endpoints = {
  labAdmins: 'api/v1/groups/get_sub_admins',
  departments: 'api/v1/departments',
  users: 'api/v1/users',
  departmentsWithUsers: 'api/v1/groups/lab_departments',
  usersWithoutDepartments: 'api/v1/labs/users_without_departments',
  addUserToDepartment: 'api/v1/departments/add_user',
  removeUserFromDepartment: 'api/v1/departments/remove_user',
  groupDelete: 'api/v1/groups/delete',
  folderDelete: 'api/v1/resources/delete',
  groupChange: 'api/v1/groups/change_name',
  folderNameChange: 'api/v1/resources/move',
  userInvite: 'api/v1/invitations',
  deleteUserFromLab: 'api/v1/labs/remove_user',
};

export type GroupType = 'lab' | 'department' | 'custom';

export interface Group {
  lab_name?: string;
  department_name?: string;
  group_type: GroupType;
  id: number;
}

export interface User {
  id: number;
  email: string;
  username: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  lab_admins: Common.LabAdmin[];
  last_active?: string;
  groups?: Group[];
  pending?: boolean;
  departments?: string[];
  pm_for_labs: string[];
  lab?: string;
  role?: string;
}

type UserKey = keyof User;

export class UsersStore {
  @observable labName: string = '';
  @observable labDepartments: Common.Department[] = [];
  @observable selectedOption: 'user' | 'department' | null = null;
  @observable selectedUser: Common.UserData | null = null;
  @observable selectedDepartment: Common.Department | null = null;
  @observable fetching: boolean = false;
  @observable fetchingDepartments: boolean = false;
  @observable saving: boolean = false;
  @observable searchActive: boolean = false;
  @observable userSearchValue: string = '';
  @observable users: User[] = [];
  @observable pendingUsers: User[] = [];

  @computed
  get departmentNames() {
    const departmentsList: string[] = [];

    this.labDepartments.forEach((department, index) => {
      // Skip two virtual department and outsiders
      if (index > 1 && department.id !== -9999999) {
        departmentsList.push(department.department_name);
      }
    });

    return departmentsList;
  }

  @computed
  get currentLab() {
    return toJS(this.labName);
  }

  @computed
  get listOfUsers() {
    return toJS(this.users);
  }

  @computed
  get listOfPendingUsers() {
    return toJS(this.pendingUsers);
  }

  @computed
  get filteredDepartments() {
    const labDepartments = toJS(this.labDepartments);

    const filteredDepartments = labDepartments.filter(department => {
      const departmentHasUser = find(
        department.users,
        user =>
          user &&
          user.full_name
            .toLowerCase()
            .indexOf(this.userSearchValue.toLowerCase()) >= 0,
      );

      return departmentHasUser || department.users.length === 0;
    });

    return filteredDepartments.map(department => {
      return {
        ...department,
        users: department.users.filter(
          user =>
            user.full_name
              .toLowerCase()
              .indexOf(this.userSearchValue.toLowerCase()) >= 0,
        ),
      };
    });
  }

  @action.bound
  getUsersForLab(labName: string = this.labName, pending: boolean = false) {
    const users = toJS(pending ? this.pendingUsers : this.users);

    return users.filter((user: User) => {
      const showUser =
        user.full_name
          .toLowerCase()
          .indexOf(this.userSearchValue.toLowerCase()) >= 0;
      if (pending) {
        return user.pending && showUser && (user.lab && user.lab === labName);
      } else {
        return (
          !user.pending &&
          showUser &&
          ((user.groups &&
            user.groups.find(
              (group: Group) =>
                group.group_type === 'lab' && group.lab_name === labName,
            )) ||
            (user.lab_admins &&
              user.lab_admins.find(
                (admin: Common.LabAdmin) => admin.full_lab_name === labName,
              )))
        );
      }
    });
  }

  @action.bound
  resetEditData() {
    this.selectedUser = null;
    this.selectedOption = null;
    this.selectedDepartment = null;
  }

  @action.bound
  cleanUp() {
    this.labName = '';
    this.labDepartments = [];
    this.selectedOption = null;
    this.selectedUser = null;
    this.fetching = false;
    this.fetchingDepartments = false;
    this.saving = false;
    this.users = [];
    this.pendingUsers = [];
  }

  @action.bound
  setUserEditOrCreate(id?: number) {
    this.resetEditData();

    /**
     * If ID is provided find user with this ID
     * within all departments users and assign it
     * to selectedUser field
     */

    if (id) {
      let usersList: Common.UserData[] = [];

      toJS(this.labDepartments).forEach(department => {
        usersList = [...usersList, ...department.users];
      });

      let user = find(usersList, ['id', id]);

      if (!user) {
        user = find(this.listOfUsers, ['id', id]);
      }

      const selectedUser = pick(user, [
        'id',
        'email',
        'username',
        'full_name',
        'lab_admins',
        'pm_for_labs',
      ]) as Common.UserData;

      const userDepartments = this.getUserDepartments(id);
      selectedUser.departments = userDepartments;

      this.selectedUser = selectedUser;
    }

    this.selectedOption = 'user';
  }

  @action.bound
  setUserToRemove(id?: number, pending: boolean = false) {
    this.resetEditData();

    /**
     * If ID is provided find user with this ID
     * within all departments users and assign it
     * to selectedUser field
     */
    if (id) {
      const user = find(pending ? this.pendingUsers : this.users, ['id', id]);
      const selectedUser = pick(user, [
        'id',
        'email',
        'username',
        'full_name',
        'departments',
        'lab',
      ]) as Common.UserData;

      this.selectedUser = selectedUser;
    }

    this.selectedOption = 'user';
  }

  @action.bound
  setUserPendingInvitation(id?: number) {
    this.resetEditData();

    /**
     * If ID is provided find pending user with this ID
     * within all departments users and assign it
     * to selectedUser field
     */
    if (id) {
      const user = find(this.pendingUsers, ['id', id]);
      const selectedUser = pick(user, [
        'id',
        'email',
        'username',
        'full_name',
        'departments',
        'lab',
        'role',
      ]) as Common.UserData;

      this.selectedUser = selectedUser;
    }

    this.selectedOption = 'user';
  }

  @action.bound
  setDepartmentEditOrCreate(name?: string) {
    this.resetEditData();

    if (name) {
      const department = find(toJS(this.labDepartments), [
        'name',
        name,
      ]) as Common.Department;

      // If it is not virtual moderators department
      if (department.id !== 9999999) {
        this.selectedDepartment = department;
      }
    }

    this.selectedOption = 'department';
  }

  @action.bound
  getLabDepartments(labName: string) {
    // Save lab name for later usage
    this.labName = labName;

    this.fetchDepartments();
  }

  @action.bound
  getUserByProperty(property: UserKey, value: any): User[] {
    return this.users.filter(user => user[property] === value);
  }

  @action.bound
  toggleSearchActive() {
    this.searchActive = !this.searchActive;
  }

  @action.bound
  clearSearch() {
    this.setSearchValue('');
  }

  @action.bound
  setSearchValue(value: string) {
    this.resetEditData();
    this.userSearchValue = value;
  }

  fetchDepartments = flow(function*(this: UsersStore) {
    const groupName = this.labName;

    // Prepare requests for each type of users
    const adminsRequest = () =>
      httpClient.get(endpoints.labAdmins, {
        params: {
          group_name: groupName,
        },
      });
    const departmentsWithUsersRequest = () =>
      httpClient.get(endpoints.departmentsWithUsers, {
        params: {
          lab_name: groupName,
        },
      });
    const usersWithoutDepartmentsRequest = () =>
      httpClient.get(endpoints.usersWithoutDepartments, {
        params: {
          lab_name: groupName,
        },
      });

    // Start fetching all required users
    this.fetchingDepartments = true;

    try {
      const [
        adminsResponse,
        departmentsWithUsersResponse,
        usersWithoutDepartmentsResponse,
      ] = yield Promise.all([
        adminsRequest(),
        departmentsWithUsersRequest(),
        usersWithoutDepartmentsRequest(),
      ]);
      const moderatorsDepartment: Common.Department = {
        id: 9999999,
        department_name: 'Admin',
        lab_name: this.labName,
        name: `${snakeCase(this.labName)}_moderators`,
        users: [],
      };

      const admins = get(adminsResponse, 'data', []);
      moderatorsDepartment.users = [...moderatorsDepartment.users, ...admins];

      const outsidersDepartment: Common.Department = {
        id: -9999999,
        department_name: 'Users without department',
        lab_name: this.labName,
        name: `${snakeCase(this.labName)}_outsiders`,
        users: [],
      };

      const outsiders = get(usersWithoutDepartmentsResponse, 'data', []);
      outsidersDepartment.users = [...outsidersDepartment.users, ...outsiders];

      const departmentsWithUsers = get(departmentsWithUsersResponse, 'data');

      const projectManagersDepartment: Common.Department = {
        id: -1,
        department_name: 'Project Managers',
        lab_name: this.labName,
        name: `${snakeCase(this.labName)}_pms`,
        users: [],
      };

      let labManagers = outsidersDepartment.users.filter(user =>
        user.pm_for_labs.includes(groupName),
      );
      departmentsWithUsers.forEach((department: Common.Department) => {
        labManagers = labManagers.concat(
          department.users.filter(user => user.pm_for_labs.includes(groupName)),
        );
      });
      projectManagersDepartment.users = uniqBy(labManagers, 'id');

      this.labDepartments = [
        projectManagersDepartment,
        moderatorsDepartment,
        ...departmentsWithUsers,
        outsidersDepartment,
      ];

      this.fetchingDepartments = false;
    } catch (error) {
      this.fetchingDepartments = false;
      appStore.showErrorToaster('Failed to load users list');
    }
  });

  saveDepartment = flow(function*(this: UsersStore, name: string) {
    this.saving = true;

    try {
      if (this.selectedDepartment) {
        const newGroupName = `${snakeCase(this.labName)}_${snakeCase(name)}`;

        yield httpClient.put(endpoints.groupChange, {
          old_name: this.selectedDepartment!.name,
          name: newGroupName,
          department_name: name,
        });

        yield httpClient.put(endpoints.folderNameChange, {
          source_path: `/${this.labName}/${
            this.selectedDepartment.department_name
          }`,
          destination_path: `/${this.labName}/${name}`,
        });
      } else {
        yield httpClient.post(endpoints.departments, {
          lab_name: this.labName,
          department_name: name,
        });
      }

      this.saving = false;
      this.resetEditData();
      this.fetchDepartments();
      appStore.showSuccessToaster('Department saved successfully');
    } catch {
      this.saving = false;
      this.resetEditData();
      appStore.showErrorToaster('Failed to save department');
    }
  });

  saveUser = flow(function*(this: UsersStore, payload: Common.UserPayload) {
    this.saving = true;

    try {
      if (this.selectedUser) {
        const promotedToAdmin = payload.role === 'lab_admin';

        yield httpClient.put(`${endpoints.users}/${this.selectedUser.id}`, {
          full_name: payload.fullName,
          isAdmin: promotedToAdmin,
          role: payload.role,
          lab: this.labName,
        });

        let removedDepartments = difference(
          this.selectedUser.departments,
          payload.departments!,
        );

        if (promotedToAdmin) {
          removedDepartments = removedDepartments.filter(
            (department: string) => {
              return department !== 'Admin';
            },
          );
        }

        const addedDepartments = difference(
          payload.departments!,
          this.selectedUser.departments!,
        );

        if (!promotedToAdmin && removedDepartments.length > 0) {
          yield httpClient.put(endpoints.removeUserFromDepartment, {
            departments: removedDepartments,
            lab_name: this.labName,
            username: payload.email.toLowerCase(),
          });
        }

        if (!promotedToAdmin && addedDepartments.length > 0) {
          yield httpClient.put(endpoints.addUserToDepartment, {
            departments: addedDepartments,
            lab_name: this.labName,
            username: payload.email.toLowerCase(),
          });
        }

        this.fetchDepartments();
        this.getUsersList();
        appStore.showSuccessToaster('User saved successfully');
        this.saving = false;
        this.resetEditData();
      } else {
        this.sendInvitation(payload);
      }
    } catch {
      this.saving = false;
      this.resetEditData();
      appStore.showErrorToaster('Operation failed');
    }
  });

  sendInvitation = flow(function*(
    this: UsersStore,
    payload: Common.UserPayload,
    resend: boolean = false,
  ) {
    this.saving = true;

    try {
      const { data } = yield httpClient.post(endpoints.userInvite, {
        email: payload.email.toLowerCase(),
        lab: payload.lab || this.labName,
        full_name: payload.fullName,
        departments: payload.departments,
        role: payload.role,
      });

      if (data.message && data.message === 'User added to another lab.') {
        this.fetchDepartments();
        this.getUsersList();
        appStore.showSuccessToaster('User saved successfully');
      } else {
        this.getPendingUsersList();
        appStore.showSuccessToaster(
          `Invitation ${resend ? 'resend' : 'send'} successfully`,
        );
      }

      this.saving = false;
      this.resetEditData();
    } catch (e) {
      this.saving = false;
      this.resetEditData();

      if (
        e.data &&
        e.data.message &&
        e.data.message === 'User already in lab'
      ) {
        appStore.showErrorToaster(e.data.message);
      } else {
        appStore.showErrorToaster(
          `Failed to ${resend ? 'resend' : 'send'} invitation`,
        );
      }
    }
  });

  getUsersList = flow(function*(this: UsersStore) {
    this.fetching = true;

    try {
      const { data }: { data: User[] } = yield httpClient.get(endpoints.users);
      this.fetching = false;
      this.users = data;
    } catch {
      this.fetching = false;
      appStore.showErrorToaster('Failed to load users list');
    }
  });

  getPendingUsersList = flow(function*(this: UsersStore) {
    this.fetching = true;

    try {
      const { data }: { data: User[] } = yield httpClient.get(
        endpoints.userInvite,
      );
      this.fetching = false;
      this.pendingUsers = data.map((user: User) => {
        return {
          ...user,
          pending: true,
        };
      });
    } catch {
      this.fetching = false;
      appStore.showErrorToaster('Failed to load pending users list');
    }
  });

  @action
  inviteUser = async (email: string, fullName: string) => {
    this.saving = true;
    try {
      await httpClient.post(endpoints.userInvite, {
        email: email.toLowerCase(),
        full_name: fullName,
      });
      this.getPendingUsersList();
      appStore.showSuccessToaster(`${email} invited successfully`);
    } catch (e) {
      appStore.showErrorToaster(`User already invited to the application`);
    }

    this.saving = false;
    appStore.toggleModal('inviteUserOpened', false);
  };

  createUser = flow(function*(
    this: UsersStore,
    userCreatePayload: { email: string; password: string; token: string },
  ) {
    this.saving = true;
    const email = userCreatePayload.email.toLowerCase();

    try {
      yield httpClient.post(endpoints.users, {
        email,
        password: userCreatePayload.password,
        token: userCreatePayload.token,
      });

      authStore.login({
        email,
        password: userCreatePayload.password,
      });
      navigate('/');
    } catch {
      this.saving = false;
      appStore.showErrorToaster('User creation failed');
    }
  });

  deleteDepartment = flow(function*(this: UsersStore) {
    const department = toJS(this.selectedDepartment);

    if (department) {
      this.saving = true;

      try {
        yield httpClient.delete(endpoints.departments, {
          data: {
            lab_name: this.labName,
            department_name: department.department_name,
          },
        });

        this.saving = false;
        this.resetEditData();
        this.getUsersList();
        this.getPendingUsersList();
        this.fetchDepartments();
        appStore.showSuccessToaster('Department removed successfully');
      } catch (error) {
        this.saving = false;
        this.resetEditData();
        appStore.showErrorToaster('Failed to remove department');
      }
    }
  });

  deleteUserFromLab = flow(function*(this: UsersStore) {
    this.fetching = true;

    try {
      yield httpClient.put(endpoints.deleteUserFromLab, {
        username: this.selectedUser!.username,
        lab_name: this.labName,
      });

      appStore.showSuccessToaster(
        `${this.selectedUser!.full_name} removed successfully`,
      );
      this.fetching = false;
      this.resetEditData();
      this.getUsersList();
      this.fetchDepartments();
    } catch {
      this.resetEditData();
      this.fetching = false;
      appStore.showErrorToaster('Failed to remove user');
    }
  });

  deleteUserFromSystem = flow(function*(this: UsersStore) {
    const userId = this.selectedUser!.id;
    this.fetching = true;

    try {
      yield httpClient.delete(`${endpoints.users}/${userId}`);
      this.getUsersList();
      this.fetching = false;
      appStore.showSuccessToaster(
        `${this.selectedUser!.full_name} removed successfully`,
      );
      this.resetEditData();
    } catch (error) {
      this.resetEditData();
      this.fetching = false;
      appStore.showErrorToaster('Failed to remove user');
    }
  });

  deleteInvitation = flow(function*(this: UsersStore) {
    const userId = this.selectedUser!.id;
    this.fetching = true;

    try {
      yield httpClient.delete(`${endpoints.userInvite}/${userId}`);
      this.getPendingUsersList();
      this.fetching = false;
      appStore.showSuccessToaster(
        `Invitation for ${this.selectedUser!.full_name} removed successfully`,
      );
      this.resetEditData();
    } catch (error) {
      this.resetEditData();
      this.fetching = false;
      appStore.showErrorToaster('Failed to remove invitation');
    }
  });

  private getUserDepartments(id: number) {
    const departments: string[] = [];

    this.labDepartments.forEach((department, index) => {
      // Skip 1-st virtual department and outsiders
      if (index > 0 && department.id !== -9999999) {
        if (find(department.users, ['id', id])) {
          departments.push(department.department_name);
        }
      }
    });

    return departments;
  }
}

export default new UsersStore();
