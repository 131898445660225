import React, { Component } from 'react';
import { Location, navigate } from '@reach/router';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Drawer from '@material-ui/core/Drawer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BuildIcon from '@material-ui/icons/Build';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloudIcon from '@material-ui/icons/Cloud';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AuthStore } from '../../stores/authStore';
import { LabsStore } from '../../stores/labsStore';
import { ProjectsStore } from '../../stores/projectsStore';
import MenuOption from './leftMenu/MenuOption';
import Typography from '@material-ui/core/Typography';
import LocationIcon from '@material-ui/icons/LocationCity';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 237,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 237,
      paddingTop: 30,
      paddingLeft: 30,
      borderRight: `1px dashed ${theme.palette.primary.main}`,
    },
    toolbar: {
      height: 68,
    },
    projectIcon: {
      transform: 'rotate(-45deg)',
    },
    menu: {
      padding: 0,
      margin: 0,
    },
    myFileHeader: {
      marginTop: 25,
      marginBottom: 10,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  projectName: string;
  labName: string;
  projectId: number;
}

interface InjectedProps extends Props {
  authStore: AuthStore;
  labsStore: LabsStore;
  projectsStore: ProjectsStore;
}

interface State {
  isRootLab: boolean;
  hasError: boolean;
}
//#endregion

@inject('authStore', 'labsStore', 'projectsStore')
@observer
class LeftMenu extends Component<Props, State> {
  state = {
    isRootLab: false,
    hasError: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  mounted = false;

  async componentDidMount() {
    this.mounted = true;
    if (this.injected.labsStore.labs.length === 0) {
      try {
        await this.injected.labsStore.getLabsList();
        this.setIsLabInfo();
      } catch {
        this.hideMenuWhenErrorAppears();
      }
    } else {
      this.setIsLabInfo();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  hideMenuWhenErrorAppears() {
    if (this.mounted) {
      this.setState({
        hasError: true,
      });
    }
  }

  setIsLabInfo() {
    const isLab = Boolean(
      this.injected.labsStore.labsList.find(
        lab => lab.path === this.props.labName,
      ),
    );

    if (this.mounted) {
      this.setState({
        isRootLab: isLab,
      });
    }
  }

  goBack = () => {
    navigate('/labs');
  };

  navigateTo = (url: string) => {
    if (url.length > 0) {
      navigate(`/projects/${this.props.projectId}/${url}`);
    } else {
      navigate(`/projects/${this.props.projectId}`);
    }
  };

  isActive = (url: string) => {
    const pathName = window.location.pathname.replace('%20', ' ');
    const sections = ['settings', 'users', 'groups'];

    if (url.length > 0) {
      return pathName === `/projects/${this.props.projectId}/${url}`;
    }

    const isSection = sections.some(section => pathName.endsWith(section));

    if (!isSection) {
      return true;
    }
  };

  render() {
    const { classes, labName, projectName } = this.props;
    const { hasError } = this.state;
    const { isAdmin, user } = this.injected.authStore;
    const { isLoading } = this.injected.labsStore;
    const { projectsList } = this.injected.projectsStore;

    const isSharedWithMeContext = 'Shared with me' === this.props.labName;
    const isMyFilesContext = 'My Files' === this.props.labName;

    const isCustomLab = isSharedWithMeContext || isMyFilesContext;
    const isCurrentLabAdmin = (user.lab_admin_labs_full || []).includes(
      labName,
    );
    const isCurrentLabPM = this.injected.projectsStore.isPMForCurrentProject(
      user,
    );

    return (
      <Drawer
        variant="permanent"
        className={classes.root}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />

        {isLoading && !hasError ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={75} />
          </div>
        ) : (
          <nav className={classes.menu}>
            <Location>
              {() => (
                <>
                  {!hasError && !isSharedWithMeContext && !isMyFilesContext && (
                    <>
                      <MenuOption
                        icon={<ArrowBackIcon />}
                        onClicked={this.goBack}
                        isFirst
                      >
                        Lab List
                      </MenuOption>

                      {!isCustomLab && (
                        <>
                          <MenuOption
                            icon={<CloudIcon />}
                            onClicked={() => this.navigateTo('')}
                            isActive={this.isActive('')}
                          >
                            {projectName}
                          </MenuOption>
                        </>
                      )}

                      {(isAdmin || isCurrentLabAdmin || isCurrentLabPM) && (
                        <MenuOption
                          icon={<AccountBoxIcon />}
                          onClicked={() => this.navigateTo('users')}
                          isActive={this.isActive('users')}
                        >
                          {isAdmin || isCurrentLabAdmin || isCurrentLabPM
                            ? 'Users'
                            : 'Contacts'}
                        </MenuOption>
                      )}

                      {(isAdmin || isCurrentLabAdmin || isCurrentLabPM) && (
                        <MenuOption
                          icon={<BuildIcon />}
                          onClicked={() => this.navigateTo('settings')}
                          isActive={this.isActive('settings')}
                        >
                          Project Settings
                        </MenuOption>
                      )}
                    </>
                  )}

                  <Typography
                    variant="subtitle1"
                    className={classes.myFileHeader}
                  >
                    Files
                  </Typography>

                  <MenuOption
                    icon={<AttachmentIcon className={classes.projectIcon} />}
                    onClicked={() => navigate('/labs/My Files')}
                    isActive={isMyFilesContext}
                    variant="MY_FILES"
                  >
                    My Files
                  </MenuOption>
                  <MenuOption
                    icon={<ReplyAllIcon />}
                    onClicked={() => navigate('/labs/Shared with me')}
                    isActive={isSharedWithMeContext}
                    variant="SHARED"
                  >
                    Shared with me
                  </MenuOption>

                  {projectsList.length > 0 && (
                    <>
                      <Typography
                        variant="subtitle1"
                        className={classes.myFileHeader}
                      >
                        My Projects
                      </Typography>

                      {projectsList.map((project: Common.Project) => (
                        <MenuOption
                          key={project.id}
                          icon={<LocationIcon />}
                          onClicked={() => navigate(`/projects/${project.id}`)}
                          isActive={isMyFilesContext}
                        >
                          {project.name}
                        </MenuOption>
                      ))}
                    </>
                  )}
                </>
              )}
            </Location>
          </nav>
        )}
      </Drawer>
    );
  }
}

export default withStyles(styles)(LeftMenu);
