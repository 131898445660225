import React, { memo, useState, useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import CanvasViewer from '../../CanvasViewer';
import SingleViewerRuler from './SingleViewerRuler';
import UnrolledPositionMarkers from './UnrolledPositionMarkers';
import ColorSettings from './resultsImages/ColorSettings';
import DensityColorWindowSettings from './resultsImages/DensityColorWindowSettings';
import Loader from '../results/Loader';
import getOutputCanvas from './resultsImages/getOutputCanvas';
import drawHeader from './resultsImages/drawHeader';
import drawRuler from './resultsImages/drawRuler';
import drawBottomOrientationMarkers from './resultsImages/drawBorromOrientationMarkers';
import drawDateAngleMarker from './resultsImages/drawDateAngleMarker';
import drawSliceHeightMarkers from './resultsImages/drawSliceHeightMarkers';
import convertToUnit from './resultsImages/convertToUnit';
import {
  topMarkerHeight,
  leftMarkerWidth,
  rightPadding,
  bottomMarkerHeight,
} from './resultsImages/spacings';
import loadLogoImage from './resultsImages/loadLogoImage';
import logoImage from '../../../../../../assets/images/black_bg_small.png';

import regenerateImageWithColorMap from '../../../../../../utils/regenerateImageWithColorMap';
import { CTViewerStore } from '../../../../../../stores/ctViewerStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '12px 15px',
      margin: '0 auto',
      border: '1px solid rgb(224, 224, 224)',
      position: 'relative',
    },
    container: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',

      '& > div': {
        marginLeft: '15px',

        '&:first-child': {
          marginLeft: '30px',
        },
      },

      '&.fullHeight': {
        maxHeight: 'none',
      },

      '& .fullHeightSwitch': {
        input: {
          marginRight: '5px',
        },
      },

      '& .ViewerResultPanel': {
        position: 'relative',
      },
    },
    resultViewerImageLabel: {
      height: 20,
      marginBottom: 12,
      textAlign: 'center',
    },
    buttonContainer: {
      marginTop: '18px',
      borderTop: '1px solid rgb(224, 224, 224)',
      textAlign: 'center',
      padding: '24px 0 10px 0',
    },
    button: {
      color: '#fff',
      boxShadow: 'none',
      backgroundColor: '#69C3AC',
      padding: '6px 16px',
      lineHeight: 1.75,
      minWidth: '64px',
      fontSize: '0.9375rem',
      fontFamily: 'acumin-pro, sans-serif',
      fontWeight: 'bold',
      borderRadius: '10px',
      textTransform: 'initial',
      border: 0,
      margin: 0,
      cursor: 'pointer',
      display: 'inline-flex',
      position: 'relative',
      alignItems: 'center',
      userSelect: 'none',
      verticalAlign: 'middle',
      justifyContent: 'center',
      outline: 'none',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&[disabled]': {
        opacity: 0.3,
        pointerEvents: 'none',
      },
    },
    buttonAxial: {
      minWidth: '32px',
      padding: '6px 8px',

      '&:first-child': {
        marginRight: 5,
      },
    },
    axialSlices: {
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& span:nth-of-type(2)': {
        margin: '0 0.8rem',
        color: '#9e9e9e',
      },
    },
    input: {
      height: '2.4rem',
      width: '56px',
      border: '1px solid #dfe3ed',
      borderRadius: '3px',
      backgroundColor: '#fefefe',
      padding: '.8rem',
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.2rem',
      color: '#2c2e32',
      marginRight: 5,
    },
    hiddenCanvasBox: {
      visibility: 'hidden',
      position: 'absolute',
      bottom: 0,
      left: -9999,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  coronalData: any;
  sagittalData: any;
  unrolledData: any;
  metaData: any;
  axialData: any;
  slabData?: any;
  ctViewerStore?: CTViewerStore;
}
//#endregion

const WRAPPER_WIDTH = 1170;
const IMAGE_SPACING = 15;
const SCROLL_BAR_RESERVE = 15;
const RULER_WIDTH = 40;

const ResultsImages: React.FunctionComponent<Props> = ({
  classes,
  coronalData,
  sagittalData,
  unrolledData,
  metaData,
  axialData,
  slabData,
  ctViewerStore,
}) => {
  const [axialIndex, setAxialIndex] = useState<number>(0);
  const [markerWidth, setMarkerWidth] = useState<any>(undefined);
  const [coronalDataColor, setCoronalDataColor] = useState<any>(undefined);
  const [sagittalDataColor, setSagittalDataColor] = useState<any>(undefined);
  const [unrolledDataColor, setUnrolledDataColor] = useState<any>(undefined);
  const [axialDataColor, setAxialDataColor] = useState<any[]>([]);
  const [slabDataColor, setSlabDataColor] = useState<any>(undefined);
  const coronalRef = useRef<HTMLDivElement>(null);
  const unwrappedRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const getAxialData = (id: string) => {
    return axialDataColor.find((axial: any) => axial.requestParams.name === id);
  };

  const exportResults = async () => {
    const container = containerRef.current;
    if (!container) return;

    const canvasCollection = container.querySelectorAll('canvas');
    const arrayToSend: any[] = [];
    const axialSlicesToSend: any[] = [];
    const logoForCanvas: HTMLImageElement = (await loadLogoImage(
      logoImage,
    )) as HTMLImageElement;

    canvasCollection.forEach(canvas => {
      const parent = canvas.parentElement;
      const { id } = parent!.dataset!;

      if (parent && id !== 'axial') {
        const isAxial = id!.startsWith('axial');
        const {
          outputCanvas,
          outputWidth,
          outputContext,
          originalWidth,
          originalHeight,
        } = getOutputCanvas(canvas);
        const axialImagesDepthM = metaData.axialImageHeights.map(
          (heightPx: number) => {
            return {
              depthM:
                heightPx * (metaData.sliceThickness / 1000) +
                metaData.coreTopDepthMeters,
              sliceIndex: heightPx,
            };
          },
        );
        let decimalDigits = 2;
        if (axialImagesDepthM.length >= 2) {
          if (
            axialImagesDepthM[1].depthM - axialImagesDepthM[0].depthM <
            0.02
          ) {
            decimalDigits = 3;
          }
        }

        // Draw header with sample name, slice name and depth
        drawHeader(
          outputContext,
          outputWidth,
          ctViewerStore!.sampleName,
          isAxial
            ? 'Axial View'
            : `${id!.replace(/^\w/, c => c.toUpperCase())} View`,
          ctViewerStore!.depth,
          ctViewerStore!.depthUnit,
          decimalDigits,
        );

        if (isAxial) {
          const {
            image: { width, columnPixelSpacing },
          } = getAxialData(id!);
          const axialSizeMm = columnPixelSpacing * width;

          drawRuler(
            outputContext,
            axialSizeMm,
            originalWidth, // screen px
            true,
            topMarkerHeight,
            0,
            ctViewerStore!.depthUnit,
            decimalDigits,
          );

          drawRuler(
            outputContext,
            axialSizeMm,
            originalWidth, // screen px
            false,
            originalHeight + topMarkerHeight,
            0,
            ctViewerStore!.depthUnit,
            decimalDigits,
          );
        } else {
          const nativeHeightMm =
            metaData.sliceThickness *
            (ctViewerStore!.cropBottom! - ctViewerStore!.cropTop!);

          drawRuler(
            outputContext,
            nativeHeightMm, // native mm
            originalHeight, // screen px
            true,
            topMarkerHeight,
            ctViewerStore!.depth,
            ctViewerStore!.depthUnit,
            decimalDigits,
          );

          drawSliceHeightMarkers(
            outputContext,
            axialImagesDepthM,
            ctViewerStore!.cropBottom! - ctViewerStore!.cropTop!,
            originalHeight,
            originalWidth + leftMarkerWidth, // screen px
            topMarkerHeight,
            ctViewerStore!.depthUnit,
            decimalDigits,
          );

          if (id === 'unwrapped') {
            drawBottomOrientationMarkers(
              outputContext,
              originalHeight + topMarkerHeight,
              originalWidth,
            );
          }
        }

        drawDateAngleMarker(
          outputContext,
          originalWidth + leftMarkerWidth + rightPadding,
          originalHeight + topMarkerHeight + bottomMarkerHeight,
          ctViewerStore!.tiltAngle,
          parseInt(metaData.KVP),
          logoForCanvas,
        );

        let fileName = '';
        if (!isAxial) {
          const topM = ctViewerStore!.depth;
          const bottomM =
            topM +
            ((ctViewerStore!.cropBottom! - ctViewerStore!.cropTop!) *
              metaData.sliceThickness) /
              1000;

          const topDepth = convertToUnit(topM, ctViewerStore!.depthUnit);
          const bottomDepth = convertToUnit(bottomM, ctViewerStore!.depthUnit);
          const topText = topDepth.toFixed(decimalDigits);
          const bottomText = bottomDepth.toFixed(decimalDigits);

          fileName = `${topText}-${bottomText}_${id!}`;
        } else {
          const index = id!.match(/\d+$/);
          if (index) {
            const depthM = axialImagesDepthM[parseInt(index[0], 10)].depthM;
            const depth = convertToUnit(depthM, ctViewerStore!.depthUnit);

            fileName = `${depth.toFixed(decimalDigits)}_Axial_Slice`;
          } else {
            fileName = `${id}_Slice`;
          }
        }

        const data = outputCanvas.toDataURL();
        const canvasObj = {
          path: `${ctViewerStore!.pathToProject}/${
            ctViewerStore!.lastResultsId
          }`,
          name: fileName,
          dataURL: data,
        };

        if (isAxial) {
          arrayToSend.push(canvasObj);
        } else {
          axialSlicesToSend.push(canvasObj);
        }
      }
    });

    if (arrayToSend.length && axialSlicesToSend.length) {
      ctViewerStore!.saveSingleResult(arrayToSend, axialSlicesToSend);
    }
  };

  const convertToCenterWidth = (input: any) => {
    return {
      center: Math.round((input.min + input.max) / 2),
      width: Math.round(input.max - input.min),
    };
  };

  const refreshMarkerWidth = () => {
    const coronalDiv = coronalRef.current;
    const unwrappedDiv = unwrappedRef.current;
    if (!coronalDiv || !unwrappedDiv) return;

    const coronalLeft = coronalDiv.getBoundingClientRect().left;
    const unwrappedRight = unwrappedDiv.getBoundingClientRect().right;

    setMarkerWidth(unwrappedRight - coronalLeft);
  };

  const totalImagesNativeWidth = () => {
    const { coronalWidth, sagittalWidth, unrolledWidth, axialSize } = metaData;

    return (
      RULER_WIDTH + coronalWidth + sagittalWidth + unrolledWidth + axialSize
    );
  };

  const getScalingFactor = () => {
    const totalWidthPx = WRAPPER_WIDTH - SCROLL_BAR_RESERVE; // 1300 base - 2x15px padding l/r
    const spacingBetweenImagesPx = IMAGE_SPACING; // sync with css!
    const numberOfImages = 5;
    const totalImagesWidth = totalImagesNativeWidth();

    let scalingFactor =
      (totalWidthPx - (numberOfImages - 1) * spacingBetweenImagesPx) /
      totalImagesWidth;

    if (scalingFactor > 1) scalingFactor = 1;
    return scalingFactor;
  };

  const getVerticalScaling = () => {
    const { rowPixelSpacing, columnPixelSpacing } = metaData;

    const verticalPixelRatio = rowPixelSpacing / columnPixelSpacing;
    return getScalingFactor() * verticalPixelRatio;
  };

  const getActualAxial = () =>
    axialDataColor.find(
      (axial: any) => axial.requestParams.name === `axial${axialIndex}`,
    );

  const nextAxial = () => {
    const length = metaData.axialSlicesNumber;
    if (axialIndex < length - 1) {
      changeAxialIndex(axialIndex + 1);
    }
  };

  const setAxial = (event: any) => {
    const index = event.target.value;

    const length = metaData.axialSlicesNumber;
    if (index > 0 && index <= length) {
      changeAxialIndex(index - 1);
    }
  };

  const previousAxial = () => {
    if (axialIndex > 0) {
      changeAxialIndex(axialIndex - 1);
    }
  };

  const changeAxialIndex = (newIndex: number) => {
    setAxialIndex(newIndex);
    // ctViewerStore!.getRawResultsSingleForAxialSlice(newIndex);
  };

  const renderRuler = () => (
    <SingleViewerRuler
      currentOffset={0}
      axialImageHeights={metaData.axialImageHeights}
      markerWidth={markerWidth}
      verticalScaling={getVerticalScaling()}
      selectedMarkerIndex={axialIndex}
      coreTopDepthMeters={metaData.coreTopDepthMeters}
      coronalHeight={coronalData.image.height}
      sliceThickness={metaData.sliceThickness}
      onChange={changeAxialIndex}
    />
  );

  const renderUnrolledMarker = () => {
    const imageWidth = metaData.unrolledWidth;
    return <UnrolledPositionMarkers width={imageWidth * getScalingFactor()} />;
  };

  const renderAxialSelector = () => {
    const currentIndex = axialIndex + 1;
    const totalIndex = metaData.axialSlicesNumber;

    return (
      <div className={classes.axialSlices}>
        <span>
          <button
            className={classNames(classes.button, classes.buttonAxial)}
            onClick={previousAxial}
          >
            <ChevronLeft />
          </button>
        </span>
        <span>
          <input
            className={classes.input}
            type="number"
            name="axialSlice"
            min={0}
            step={1}
            max={totalIndex}
            value={currentIndex}
            onChange={setAxial}
          />{' '}
          of {totalIndex} images
        </span>
        <span>
          <button
            className={classNames(classes.button, classes.buttonAxial)}
            onClick={nextAxial}
          >
            <ChevronRight />
          </button>
        </span>
      </div>
    );
  };

  const renderSingleImage = (
    data: any,
    label: string,
    bottomMarkerRenderFunction?: () => void,
  ) => {
    const { image } = data;
    const scale = getScalingFactor();
    const verticalPixelRatio =
      metaData.rowPixelSpacing / metaData.columnPixelSpacing;
    let height = metaData.height * verticalPixelRatio * scale;
    let width = image.width * scale;
    let loading = false;

    if (label.toLowerCase().startsWith('axial')) {
      height = image.height;
      width = image.width;
      loading = ctViewerStore!.axialSliceLoading;
    }

    return (
      <div className="ViewerResultPanel">
        <div className={classes.resultViewerImageLabel}>
          <Typography variant="subtitle1" component="strong">
            {label}
          </Typography>
        </div>
        <CanvasViewer
          id={label.toLowerCase()}
          image={image}
          viewportSettings={convertToCenterWidth(ctViewerStore!.outputWindow)}
          width={width}
          height={height}
          loading={loading}
        />
        {bottomMarkerRenderFunction ? bottomMarkerRenderFunction() : null}
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener('resize', refreshMarkerWidth);

    return () => {
      window.removeEventListener('resize', refreshMarkerWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const axialSlices: any[] = [];

    setCoronalDataColor({
      ...coronalData,
      image: regenerateImageWithColorMap(
        coronalData.image,
        ctViewerStore!.colormap,
        `coronal-${Math.random()}`,
      ),
    });
    setSagittalDataColor({
      ...sagittalData,
      image: regenerateImageWithColorMap(
        sagittalData.image,
        ctViewerStore!.colormap,
        `sagittal-${Math.random()}`,
      ),
    });
    setUnrolledDataColor({
      ...unrolledData,
      image: regenerateImageWithColorMap(
        unrolledData.image,
        ctViewerStore!.colormap,
        `unrolled-${Math.random()}`,
      ),
    });

    axialData.forEach((axial: any, index: number) => {
      axialSlices.push({
        ...axial,
        image: regenerateImageWithColorMap(
          axial.image,
          ctViewerStore!.colormap,
          `axial-${index}-${Math.random()}`,
        ),
      });
    });
    setAxialDataColor(axialSlices);

    if (slabData) {
      setSlabDataColor({
        ...slabData,
        image: regenerateImageWithColorMap(
          slabData.image,
          ctViewerStore!.colormap,
          `slab-${Math.random()}`,
        ),
      });
    }
    refreshMarkerWidth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctViewerStore!.outputWindow, ctViewerStore!.colormap]);

  const actualAxial = getActualAxial();
  let percent = 0;

  if (ctViewerStore!.resultsExporting) {
    percent =
      (ctViewerStore!.resultsExportingCopy / ctViewerStore!.resultsExporting) *
      100;
  }

  return (
    <div className={classes.root}>
      <div ref={containerRef}>
        <div className={classes.container}>
          <div ref={coronalRef}>
            {coronalDataColor &&
              renderSingleImage(coronalDataColor, 'Coronal', renderRuler)}
          </div>
          <div>
            {sagittalDataColor &&
              renderSingleImage(sagittalDataColor, 'Sagittal')}
          </div>
          <div ref={unwrappedRef}>
            {unrolledDataColor &&
              renderSingleImage(
                unrolledDataColor,
                'Unwrapped',
                renderUnrolledMarker,
              )}
          </div>
          <div>
            {actualAxial &&
              renderSingleImage(actualAxial, 'Axial', renderAxialSelector)}
            <ColorSettings />
            {coronalDataColor && (
              <DensityColorWindowSettings
                lowestDensityValue={ctViewerStore!.brightnessRange.min}
                highestDensityValue={ctViewerStore!.brightnessRange.max}
              />
            )}
            <div className={classes.buttonContainer}>
              <button
                className={classes.button}
                onClick={exportResults}
                disabled={ctViewerStore!.resultsExported}
              >
                Export results
              </button>
            </div>
          </div>
        </div>
        <div className={classes.hiddenCanvasBox}>
          {slabDataColor && renderSingleImage(slabDataColor, 'Slab')}
          {axialDataColor.map((axial: any, index: number) => (
            <div key={`slice-${index}`}>
              {renderSingleImage(axial, axial.requestParams.name)}
            </div>
          ))}
        </div>
      </div>
      {ctViewerStore!.resultsExporting > 0 && (
        <Loader value={percent}>Exporting results...</Loader>
      )}
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(ResultsImages))),
);
