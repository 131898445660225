import React, { Component } from 'react';
import { inject } from 'mobx-react';
import fileSize from 'filesize';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';

import { LabsStore } from '../../../stores/labsStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 80,
      width: '100%',
      maxWidth: 180,
      borderRadius: 15,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `2px dashed ${theme.palette.primary.main}`,
      marginTop: 20,
      padding: '20px 20px',
    },
    capacity: {
      fontWeight: 'bold',
    },
    unit: {
      marginTop: 20,
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  capacityUsage?: number;
  maxCapacity?: number;
  labName: string;
}

interface InjectedProps extends Props {
  labsStore: LabsStore;
}

interface State {
  size: number;
  isLoading: boolean;
}
//#endregion

@inject('labsStore')
class SizeBox extends Component<Props, State> {
  state = {
    size: 0,
    isLoading: true,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  mounted = false;

  componentDidMount() {
    this.mounted = true;
    this.getSize();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getSize = async () => {
    const { labName } = this.props;
    const labList = await this.injected.labsStore.getLabsList();

    const lab = labList.find((lab: Common.Lab) => lab.path === labName);
    if (this.mounted && lab) {
      this.setState({
        isLoading: false,
        size: lab.size,
      });
    }
  };

  render() {
    const { isLoading, size } = this.state;
    const { classes } = this.props;

    const sizeObject = fileSize(size, { round: 0 });
    const [value, unit] = sizeObject.split(' ');

    return (
      <div className={classes.root}>
        {isLoading && <CircularProgress />}

        {!isLoading && (
          <>
            <Typography
              align="center"
              variant="h3"
              className={classes.capacity}
            >
              {value}
            </Typography>

            <Typography align="center" variant="h6" className={classes.unit}>
              {unit}
            </Typography>
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SizeBox);
