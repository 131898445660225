import React, { memo } from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import {
  XY_SLICE_HEIGHT_SCREEN_PX,
  XY_SLICE_WIDTH_SCREEN_PX,
} from '../../../../../utils/sizingConstants';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  customSlice?: any;
  scaling: number;
}
//#endregion

const CustomSlice: React.FunctionComponent<Props> = ({
  scaling,
  customSlice,
  classes,
}) => {
  if (typeof scaling === 'undefined' || !customSlice) {
    return null;
  }

  return (
    <svg
      className={classes.root}
      width={XY_SLICE_WIDTH_SCREEN_PX}
      height={XY_SLICE_HEIGHT_SCREEN_PX}
    >
      <line
        x1={customSlice.x1 * scaling}
        y1={customSlice.y1 * scaling}
        x2={customSlice.x2 * scaling}
        y2={customSlice.y2 * scaling}
        stroke="#6dcbaf"
        strokeDasharray="4"
      />
    </svg>
  );
};

export default memo(withStyles(styles)(CustomSlice));
