function convertArrayToImage(flatPixels: Int16Array, data: any) {
  const minPixelValue = flatPixels.reduce(
    (counter: number, value: number) => (value < counter ? value : counter),
    flatPixels[0],
  );
  const maxPixelValue = flatPixels.reduce(
    (counter: number, value: number) => (value > counter ? value : counter),
    flatPixels[0],
  );

  return {
    imageId: data.imageId,
    minPixelValue,
    maxPixelValue,
    slope: data.slope,
    intercept: data.intercept,
    windowWidth: data.windowWidth,
    windowCenter: data.windowCenter,
    rows: data.rows,
    columns: data.columns,
    width: Math.round(data.width),
    height: data.height,
    color: data.color,

    flatPixels,
    getPixelData() {
      return this.flatPixels;
    },
    sizeInBytes: data.sizeInBytes, // assume 32 bit storage
    columnPixelSpacing: data.columnPixelSpacing, // horizontal spacing
    rowPixelSpacing: data.rowPixelSpacing, // vertical spacing
  };
}

export default convertArrayToImage;
