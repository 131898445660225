import httpClient from '../utils/api/httpClient';
import { observable, action, computed, toJS } from 'mobx';
import find from 'lodash-es/find';
import pick from 'lodash-es/pick';
import { CreateProjectData } from '../pages/labs/CreateProject';

interface Lab {
  created_at: string;
  creator: string;
  department_name: string;
  group_type: string;
  id: number;
  lab_name: string;
  name: string;
  updated_at: string;
  users: [];
}

interface Project {
  client_name: string;
  company_name: string;
  created_at: string;
  files: [];
  id: number;
  lab: Lab;
  name: string;
  project_managers?: any[];
  technicians?: [];
  clients?: [];
  invitations?: [];
  updated_at: string;
}

interface AddClientToProjectInput {
  fullName: string;
  email: string;
  role: string;
  projectId: number;
}

interface EditUserInProjectInput {
  fullName: string;
  email: string;
  role: string;
  projectId: number;
  id: number;
}

interface AddTechnicianToProjectInput {
  id?: number;
  projectId: number;
}

interface RevokeInput {
  id: number;
  projectId: number;
}

interface User {
  id: number;
  full_name: string;
  email?: string;
}

export class ProjectsStore {
  private endpoints = {
    createProject: 'api/v1/projects',
    files: 'api/v1/projects',
    removeProject: 'api/v1/projects',
    getProjects: 'api/v1/projects',
    getProject: 'api/v1/projects',
    addUser: 'api/v1/projects',
    editUser: 'api/v1/users',
    revokeUser: 'api/v1/projects',
    removeInvitation: 'api/v1/projects',
    createCTImagesSet: 'api/v1/projects',
  };

  @observable
  projects: Common.Project[] = [];

  @observable
  requestInProgress: boolean = false;

  @observable
  requestError: boolean = false;

  @observable
  currentProject: Project | null = null;

  @observable
  selectedClient: User | null = null;

  @observable
  selectedRole: string | null = null;

  @observable
  searchActive: boolean = false;

  @observable
  userSearchValue: string = '';

  @observable
  pendingUsers: User[] | undefined = [];

  @computed
  get projectsList() {
    return this.projects.filter(lab => lab.id);
  }

  @action.bound
  setRequestInProgress(value: boolean) {
    this.requestInProgress = value;
  }

  @action.bound
  toggleSearchActive() {
    this.searchActive = !this.searchActive;
  }

  @action.bound
  clearSearch() {
    this.setSearchValue('');
  }

  @action.bound
  setSearchValue(value: string) {
    this.resetEditData();
    this.userSearchValue = value;
  }

  @action
  async getProjectsList(): Promise<Common.Project[]> {
    this.requestInProgress = true;
    const { data }: { data: Common.Project[] } = await httpClient.get(
      this.endpoints.getProjects,
    );

    this.projects = data;
    this.requestInProgress = false;

    return this.projects;
  }

  @action
  async getSingleProject(id: string): Promise<Project> {
    this.requestInProgress = true;
    const { data }: { data: Project } = await httpClient.get(
      `${this.endpoints.getProject}/${id}`,
    );

    // exclude Superadmin from PM list
    data.project_managers = (data.project_managers || []).filter(
      (pm: Common.UserData) => pm.username !== 'superadmin@petricloud.com',
    );

    this.currentProject = data;
    this.requestInProgress = false;

    this.pendingUsers = this.currentProject.invitations;

    return data;
  }

  async createCTImagesSet(id: number, path: string, datasetName: string) {
    return await httpClient.post(
      `${this.endpoints.createCTImagesSet}/${id}/ctimages_sets`,
      {
        name: datasetName,
        path,
      },
    );
  }

  @action.bound
  clearCurrentProject() {
    this.currentProject = null;
  }

  @action.bound
  clearProjects() {
    this.projects = [];
    this.selectedRole = null;
    this.selectedClient = null;
  }

  @action.bound
  resetEditData() {
    this.selectedRole = null;
    this.selectedClient = null;
  }

  @action.bound
  setClientPendingInvitation(id: number) {
    this.resetEditData();

    /**
     * If ID is provided find pending user with this ID
     * within all departments users and assign it
     * to selectedClient field
     */
    if (this.pendingUsers && this.pendingUsers.length > 0) {
      const client = find(toJS(this.pendingUsers), ['id', id]);
      const selectedUser = pick(client, ['id', 'email', 'full_name']) as User;

      this.selectedClient = selectedUser;
    }

    this.selectedRole = 'client';
  }

  @action.bound
  setUserRole(role?: string) {
    this.resetEditData();

    if (role) {
      this.selectedRole = role;
    }
  }

  @action
  isPMForCurrentProject(userData: Common.User): boolean {
    if (this.currentProject) {
      const managers = toJS(this.currentProject.project_managers || []);
      const user = find(managers, ['email', userData.email]);

      return !!user;
    }

    return false;
  }

  @computed
  get filteredManagers(): User[] | undefined {
    if (this.currentProject) {
      const managers = toJS(this.currentProject.project_managers || []);

      return managers.filter(
        (user: User) =>
          user.full_name
            .toLowerCase()
            .indexOf(this.userSearchValue.toLowerCase()) >= 0,
      );
    }

    return [];
  }

  @computed
  get filteredTechnicians(): User[] | undefined {
    if (this.currentProject) {
      const technicians = toJS(this.currentProject.technicians || []);

      return technicians.filter(
        (user: User) =>
          user.full_name
            .toLowerCase()
            .indexOf(this.userSearchValue.toLowerCase()) >= 0,
      );
    }

    return [];
  }

  @computed
  get filteredClients(): User[] | undefined {
    if (this.currentProject) {
      const clients = toJS(this.currentProject.clients || []);

      return clients.filter(
        (user: User) =>
          user.full_name
            .toLowerCase()
            .indexOf(this.userSearchValue.toLowerCase()) >= 0,
      );
    }

    return [];
  }

  @computed
  get filteredPendingClients(): User[] | undefined {
    if (this.currentProject) {
      const clients = toJS(this.currentProject.invitations || []);

      return clients.filter(
        (user: User) =>
          user.full_name
            .toLowerCase()
            .indexOf(this.userSearchValue.toLowerCase()) >= 0,
      );
    }

    return [];
  }

  async createProject(data: CreateProjectData) {
    return await httpClient.post(this.endpoints.createProject, {
      name: data.projectName,
      client_name: data.clientName,
      company_name: data.companyName,
      lab_id: data.labName,
    });
  }

  async removeProject(id: number) {
    return await httpClient.delete(`${this.endpoints.removeProject}/${id}`);
  }

  async removeInvitation(projectId: number, id: number) {
    await httpClient.delete(
      `${
        this.endpoints.removeInvitation
      }/${projectId}/clients/invitations/${id}`,
    );
  }

  async addClient(data: AddClientToProjectInput) {
    await httpClient.post(
      `${this.endpoints.addUser}/${data.projectId}/clients/invitations`,
      {
        full_name: data.fullName,
        email: data.email,
        role: 'client',
      },
    );
  }

  async addProjectManager(data: AddTechnicianToProjectInput) {
    await httpClient.post(
      `${this.endpoints.addUser}/${data.projectId}/managers`,
      {
        user_id: data.id,
      },
    );
  }

  async addTechnician(data: AddTechnicianToProjectInput) {
    await httpClient.post(
      `${this.endpoints.addUser}/${data.projectId}/technicians`,
      {
        user_id: data.id,
      },
    );
  }

  async editClient(data: EditUserInProjectInput) {
    await httpClient.put(`${this.endpoints.editUser}/${data.id}`, {
      full_name: data.fullName,
    });
  }

  async revokeClient(data: RevokeInput) {
    await httpClient.delete(
      `${this.endpoints.revokeUser}/${data.projectId}/clients/${data.id}`,
    );
  }

  async revokeTechnician(data: RevokeInput) {
    await httpClient.delete(
      `${this.endpoints.revokeUser}/${data.projectId}/technicians/${data.id}`,
    );
  }

  async revokeProjectManager(data: RevokeInput) {
    await httpClient.delete(
      `${this.endpoints.revokeUser}/${data.projectId}/managers/${data.id}`,
    );
  }
}

export default new ProjectsStore();
