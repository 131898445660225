import React, { useEffect, memo } from 'react';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';

import CanvasViewer from '../CanvasViewer';
import CanvasRadialViewer from '../CanvasRadialViewer';
import ContrastSlider from '../ContrastSlider';
import TiltSlider from '../TiltSlider';
import BeamHardeningCheckbox from '../BeamHardeningCheckbox';
import CropDiameter from '../CropDiameter';
import SlabButton from '../SlabButton';
import BHSlider from '../BeamHardeningSlider';
import LineMarkerGroup from '../LineMarkerGroup';
import RadialGroup from '../RadialGroup';
import BeamHardeningSelector from '../radialGroup/BeamHardeningSelector';

import { CTViewerStore } from '../../../../../stores/ctViewerStore';

import convertArrayToImage from '../../../../../utils/convertArrayToImage';
import calculateHeightScreenPx from '../../../../../utils/calculateHeightScreenPx';
import {
  WIDTH_SCREEN_PX,
  MAX_HEIGHT_SCREEN_PX,
  XY_SLICE_WIDTH_SCREEN_PX,
  XY_SLICE_HEIGHT_SCREEN_PX,
} from '../../../../../utils/sizingConstants';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexWrap: 'wrap',
      padding: '24px 24px 32px 24px',
      borderBottom: '2px solid #f0f0f0',
    },
    infoBox: {
      width: '100%',
      padding: '12px 0 10px 0',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    infoTitle: {
      fontWeight: 'bold',
      display: 'inline-block',
      fontSize: '1.1rem',
    },
    subtitleBox: {
      width: '100%',
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    infoSubtitle: {
      color: '#949494',
      fontSize: '.9rem',
      userSelect: 'none',
    },
    ctViewerSetFolder: {
      color: '#1880FF',
      fontSize: '18px',
      marginRight: '6px',
    },
    leftPanel: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      padding: '0 12px',
    },
    canvasBox: {
      padding: '16px 32px 26px 32px',
    },
    rightPanel: {
      padding: '16px 32px',
      alignSelf: 'flex-start',
      minWidth: 332,
    },
    radialCanvasBox: {
      position: 'relative',
      width: `${XY_SLICE_WIDTH_SCREEN_PX}px`,
      height: `${XY_SLICE_HEIGHT_SCREEN_PX}px`,
    },
    rangeInput: {
      marginBottom: 20,
    },
    subtitle: {
      marginBottom: 32,
      userSelect: 'none',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  imageCoronalData: any;
  imageCoronalParams: any;
  imageSagittalData: any;
  imageSagittalParams: any;
  imageAxialData: any;
  imageAxialParams: any;
  axialLoading: boolean;
  corSagLoading: boolean;
  firstRender: boolean;
  contrastSwitcher: boolean;
  ctViewerStore?: CTViewerStore;
}
//#endregion

const Image: React.FunctionComponent<Props> = ({
  classes,
  imageCoronalData,
  imageCoronalParams,
  imageSagittalData,
  imageSagittalParams,
  imageAxialData,
  imageAxialParams,
  axialLoading,
  corSagLoading,
  firstRender,
  contrastSwitcher,
  ctViewerStore,
}) => {
  useEffect(() => {
    if (imageCoronalData && imageCoronalParams) {
      const image = convertArrayToImage(imageCoronalData, imageCoronalParams);
      ctViewerStore!.setCoronalImage(image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageCoronalData, imageCoronalParams]);

  useEffect(() => {
    if (imageSagittalData && imageSagittalParams) {
      const image = convertArrayToImage(imageSagittalData, imageSagittalParams);
      ctViewerStore!.setSagittalImage(image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSagittalData, imageSagittalParams]);

  useEffect(() => {
    if (imageAxialData && imageAxialParams) {
      const image = convertArrayToImage(imageAxialData, imageAxialParams);
      ctViewerStore!.setAxialImage(image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageAxialData, imageAxialParams]);

  const handleDragVerticalSlice = (
    x: number,
    y: number,
    initialOffsetNativePx: number,
  ) => {
    if (ctViewerStore && ctViewerStore.coronalImage) {
      const image = ctViewerStore.coronalImage;
      const verticalPixelRatio =
        image.rowPixelSpacing / image.columnPixelSpacing;
      const scaling = image.width / WIDTH_SCREEN_PX / verticalPixelRatio;

      let maxOffset = image.height - MAX_HEIGHT_SCREEN_PX * scaling;
      if (maxOffset < 0) maxOffset = 0;

      let newOffset = initialOffsetNativePx - scaling * y;
      if (newOffset < 0) newOffset = 0;
      if (newOffset > maxOffset) newOffset = maxOffset;

      ctViewerStore.setViewportOffset(newOffset);
    }
  };

  const handleScrollVerticalSlice = (diff: number) => {
    // only changes offset
    const oldOffset = ctViewerStore!.viewportOffset;
    handleDragVerticalSlice(0, -diff, oldOffset);
  };

  return (
    <div className={classes.root}>
      <div className={classes.infoBox}>
        <Typography
          variant="subtitle1"
          component="p"
          className={classes.infoTitle}
        >
          Number of scans - {ctViewerStore!.resultLength}
        </Typography>
        <div className={classes.subtitleBox}>
          <FolderIcon className={classes.ctViewerSetFolder} />
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.infoSubtitle}
          >
            CTImages : {ctViewerStore!.projectName}
          </Typography>
        </div>
      </div>
      <div className={classes.leftPanel}>
        <div className={classes.canvasBox}>
          <Typography
            variant="h6"
            component="strong"
            className={classes.subtitle}
          >
            Coronal
          </Typography>
          <CanvasViewer
            image={ctViewerStore!.coronalImage}
            onScroll={handleScrollVerticalSlice}
            onDrag={handleDragVerticalSlice}
            viewportSettings={ctViewerStore!.viewportSettings}
            viewportOffset={ctViewerStore!.viewportOffset}
            loading={corSagLoading}
            firstRender={firstRender}
            width={WIDTH_SCREEN_PX}
            height={calculateHeightScreenPx(ctViewerStore!.coronalImage)}
            displayScales
            mouseScrollable
          />
        </div>
        <div className={classes.canvasBox}>
          <Typography
            variant="h6"
            component="strong"
            className={classes.subtitle}
          >
            Sagittal
          </Typography>
          <CanvasViewer
            image={ctViewerStore!.sagittalImage}
            onScroll={handleScrollVerticalSlice}
            onDrag={handleDragVerticalSlice}
            viewportSettings={ctViewerStore!.viewportSettings}
            viewportOffset={ctViewerStore!.viewportOffset}
            loading={corSagLoading}
            firstRender={firstRender}
            width={WIDTH_SCREEN_PX}
            height={calculateHeightScreenPx(ctViewerStore!.sagittalImage)}
            displayScales
            mouseScrollable
          />
        </div>
        <LineMarkerGroup />
      </div>
      <div className={classes.rightPanel}>
        <Typography
          variant="h6"
          component="strong"
          className={classes.subtitle}
        >
          Axial
        </Typography>
        <div className={classes.radialCanvasBox}>
          <CanvasRadialViewer
            image={ctViewerStore!.axialImage}
            viewportSettings={ctViewerStore!.viewportSettings}
            loading={axialLoading}
            firstRender={firstRender}
            width={XY_SLICE_WIDTH_SCREEN_PX}
            height={XY_SLICE_HEIGHT_SCREEN_PX}
            displayScales
          />
          <RadialGroup image={ctViewerStore!.axialImage} />
          {ctViewerStore!.beamHardening && (
            <BeamHardeningSelector image={ctViewerStore!.axialImage} />
          )}
        </div>
        {contrastSwitcher && (
          <>
            <SlabButton image={ctViewerStore!.axialImage} />
            <CropDiameter />
            <BeamHardeningCheckbox />
            <TiltSlider />
            {ctViewerStore!.beamHardening && <BHSlider />}
            <ContrastSlider />
          </>
        )}
      </div>
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(Image))),
);
