import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

import Button from '../../../components/ui/Button';
import DraggableFileUpload from './createDataset/DraggableFileUpload';

import { TempFile } from '../../../stores/files/filesStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    button: {
      marginTop: 40,
    },
    inputLabel: {
      color: '#8A8B8D',
      fontSize: '12px',
      margin: '20px 0 15px 0',
    },
    warning: {
      fontSize: '13px',
      color: '#D05152',
      fontWeight: 600,
      letterSpacing: '0.19px',
      lineHeight: '18px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    },
    warningIcon: {
      marginRight: '8px',
      fontSize: '18px',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  presentFiles: Common.File[];
  inProgress: boolean;
  folderPath: string;
  onCreate: (
    values: { datasetName: string },
    lowImages: TempFile[],
    highImages: TempFile[],
  ) => void;
}
//#endregion

const CreateDataset: React.FunctionComponent<Props> = ({
  classes,
  presentFiles,
  inProgress,
  onCreate,
}) => {
  const [lowImages, setLowImages] = useState<TempFile[]>([]);
  const [highImages, setHighImages] = useState<TempFile[]>([]);

  const hasDifferentAmountOfImages = () =>
    !!(
      lowImages.length &&
      highImages.length &&
      lowImages.length !== highImages.length
    );

  const hasWrongFileExtension = () => {
    const checkExtension = (files: TempFile[]) => {
      return !!files.find((file: TempFile) => {
        const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
        return ext !== 'dcm';
      });
    };

    return !!(
      lowImages.length &&
      highImages.length &&
      (checkExtension(lowImages) || checkExtension(highImages))
    );
  };

  const fileNames = presentFiles.map(
    (file: Common.File) =>
      file.path
        .split('/')
        .pop()!
        .trim()
        .toLowerCase()
        .split('.')[0],
  );

  const handleLowImages = (files: TempFile[]) => setLowImages(files);

  const handleHighImages = (files: TempFile[]) => setHighImages(files);

  return (
    <Formik
      initialValues={{
        datasetName: '',
      }}
      onSubmit={values => {
        onCreate(values, lowImages, highImages);
      }}
      validationSchema={Yup.object().shape({
        datasetName: Yup.string()
          .trim()
          .required('Field is required')
          .matches(/^[0-9a-zA-Z -]+$/, 'Name contains invalid characters')
          .lowercase()
          .notOneOf(fileNames, 'File, folder or dataset already exist'),
      })}
    >
      {({ values, handleChange, handleSubmit, errors }) => (
        <div className={classes.root}>
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              id="datasetName"
              name="datasetName"
              autoFocus
              required
              margin="normal"
              fullWidth
              label="CTImages set name"
              error={!!errors.datasetName}
              helperText={errors.datasetName || ''}
              onChange={handleChange}
              value={values.datasetName}
            />

            <Typography
              variant="subtitle1"
              component="p"
              className={classes.inputLabel}
            >
              Upload low energy images
            </Typography>
            <DraggableFileUpload
              idAttr="low-images"
              onAreaDrop={handleLowImages}
              filesAmount={lowImages.length}
            />

            <Typography
              variant="subtitle1"
              component="p"
              className={classes.inputLabel}
            >
              Upload high energy images
            </Typography>
            <DraggableFileUpload
              idAttr="high-images"
              onAreaDrop={handleHighImages}
              filesAmount={highImages.length}
            />

            {hasWrongFileExtension() && (
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.warning}
              >
                <WarningIcon classes={{ root: classes.warningIcon }} /> Warning:
                Energies have files with wrong extension
              </Typography>
            )}

            {hasDifferentAmountOfImages() && (
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.warning}
              >
                <WarningIcon classes={{ root: classes.warningIcon }} /> Warning:
                Energies have a different number of images
              </Typography>
            )}

            <Button
              type="submit"
              disabled={inProgress}
              className={classes.button}
            >
              {inProgress ? 'Creating dataset...' : 'Create'}
            </Button>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default withStyles(styles)(CreateDataset);
