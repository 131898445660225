import React, { useState, memo, useEffect } from 'react';
import classNames from 'classnames';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';

import ContextMenu from './tableRow/ContextMenu';
import GroupMenu from './tableRow/GroupMenu';

import { User, Group, GroupType } from '../../../stores/usersStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 70,
    },
    userName: {
      fontSize: '.9rem',
      fontWeight: 'bold',
      display: 'block',
    },
    userEmail: {
      display: 'block',
      fontSize: '.62rem',
      opacity: 0.5,
    },
    tableCell: {
      borderBottom: '2px solid #f3f3f3',
      fontSize: '.76rem',
      paddingLeft: 16,
      '&:first-child': {
        paddingLeft: 45,
      },
      '&:last-child': {
        paddingRight: 30,
      },
    },
    groupMore: {
      fontWeight: 'bold',
    },
    statusCell: {
      display: 'inline-flex',
      textAlign: 'center',
      color: theme.palette.primary.main,
      background: '#D4ECE6',
      padding: '0 20px',
      height: 24,
      borderRadius: 4,
      alignItems: 'center',
    },
    statusPendingCell: {
      background: '#C6CACB',
      color: theme.palette.common.white,
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  onHandleContextMenu: (target: EventTarget & Element, menuId: string) => void;
  user: User;
  contextMenuOpened: boolean;
  isLabAdmin: boolean;
  labView?: boolean;
  chosenLab?: string;
}
//#endregion

const getTime = (time: string) => {
  const date = new Date(time);
  const today = new Date();
  const isToday = today.toDateString() === date.toDateString();
  const hour = date.getHours();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const minutes = date.getMinutes();

  if (isToday) {
    return `${hour}:${minutes < 10 ? '0' + minutes : minutes} ${
      hour < 12 ? 'AM' : 'PM'
    }`;
  } else {
    return `${day < 10 ? '0' + day : day}-${
      month < 10 ? '0' + month : month
    }-${date.getFullYear()}`;
  }
};

const UserTableRow: React.FunctionComponent<Props> = ({
  user,
  onHandleContextMenu,
  contextMenuOpened,
  isLabAdmin,
  labView,
  classes,
  chosenLab,
}: Props) => {
  const [moreAnchorEl, setMoreAnchorEl] = useState<
    (EventTarget & Element) | null
  >(null);
  const [moreMenuOpened, setMoreMenuOpened] = useState<boolean>(false);
  const [labMenuOpened, setLabMenuOpened] = useState<boolean>(false);
  const [departmentMenuOpened, setDepartmentMenuOpened] = useState<boolean>(
    false,
  );

  useEffect(() => {
    if (!contextMenuOpened) {
      moreMenuOpened && setMoreMenuOpened(false);
      labMenuOpened && setLabMenuOpened(false);
      departmentMenuOpened && setDepartmentMenuOpened(false);
    }
  }, [contextMenuOpened, moreMenuOpened, labMenuOpened, departmentMenuOpened]);

  const handleContextMenu = (target: EventTarget & Element, type: string) => {
    setMoreAnchorEl(target);
    onHandleContextMenu(target, `${user.id}-${type}`);
  };

  const handleLabMenu = (event: React.MouseEvent) => {
    moreMenuOpened && setMoreMenuOpened(false);
    departmentMenuOpened && setDepartmentMenuOpened(false);
    setLabMenuOpened(!labMenuOpened);
    handleContextMenu(event.currentTarget, 'lab');
  };

  const handleDepartmentMenu = (event: React.MouseEvent) => {
    moreMenuOpened && setMoreMenuOpened(false);
    labMenuOpened && setLabMenuOpened(false);
    setDepartmentMenuOpened(!departmentMenuOpened);
    handleContextMenu(event.currentTarget, 'department');
  };

  const handleMoreMenu = (event: React.MouseEvent) => {
    labMenuOpened && setLabMenuOpened(false);
    departmentMenuOpened && setDepartmentMenuOpened(false);
    setMoreMenuOpened(!moreMenuOpened);
    handleContextMenu(event.currentTarget, 'more');
  };

  const isUserLabAdmin = user.lab_admins && !!user.lab_admins.length;

  const getGroupFromUser = (groupType: GroupType) => {
    const groups = user.groups ? user.groups : [];

    if (user.pending) {
      return groupType === 'department'
        ? user.departments
          ? user.departments
          : []
        : user.lab
        ? [user.lab]
        : [];
    }

    if (!groups.length && !user.lab_admins.length) {
      return [];
    }

    if (groupType === 'lab' && user.lab_admins.length) {
      return user.lab_admins.map((group: any) => group.full_lab_name);
    }

    return groups
      .filter((group: Group) => {
        if (chosenLab) {
          return group.group_type === groupType && group.lab_name === chosenLab;
        }
        return group.group_type === groupType;
      })
      .map((group: Group) =>
        groupType === 'department' ? group.department_name : group.lab_name,
      );
  };

  const formatGroup = (
    groups?: (string | undefined)[],
    isCurrentLabAdmin: boolean = false,
  ) => {
    if (!groups || !groups.length) {
      return isCurrentLabAdmin ? 'Not applicable' : 'None';
    }

    const more = groups.length > 1 ? ` and ${groups.length - 1} more` : '';

    return (
      <span>
        {groups[0]}
        <span className={classes.groupMore}>{more}</span>
      </span>
    );
  };

  const laboratories = getGroupFromUser('lab');
  const departments = getGroupFromUser('department');

  return (
    <TableRow className={classes.root}>
      <TableCell padding="none" className={classes.tableCell}>
        <span className={classes.userName}>{user.full_name}</span>
        <span className={classes.userEmail}>{user.email}</span>
      </TableCell>
      {chosenLab && (
        <TableCell align="left" padding="none" className={classes.tableCell}>
          {chosenLab}
        </TableCell>
      )}
      {!chosenLab && (
        <TableCell align="left" padding="none" className={classes.tableCell}>
          {laboratories && laboratories.length > 1 ? (
            <>
              <GroupMenu
                open={contextMenuOpened && labMenuOpened}
                anchorEl={moreAnchorEl}
                groups={laboratories}
                handlePopup={handleLabMenu}
                formattedGroups={formatGroup(laboratories)}
              />
            </>
          ) : (
            <>{formatGroup(laboratories)}</>
          )}
        </TableCell>
      )}
      <TableCell align="left" padding="none" className={classes.tableCell}>
        {departments && departments.length > 1 ? (
          <>
            <GroupMenu
              open={contextMenuOpened && departmentMenuOpened}
              anchorEl={moreAnchorEl}
              groups={departments}
              handlePopup={handleDepartmentMenu}
              formattedGroups={formatGroup(departments)}
            />
          </>
        ) : (
          <>{formatGroup(departments, isUserLabAdmin)}</>
        )}
      </TableCell>
      {!user.pending && (
        <TableCell align="left" padding="none" className={classes.tableCell}>
          {user.last_active ? getTime(user.last_active) : '-'}
        </TableCell>
      )}
      <TableCell align="left" padding="none" className={classes.tableCell}>
        <span
          className={classNames(classes.statusCell, {
            [classes.statusPendingCell]: user.pending,
          })}
        >
          {user.pending ? 'Pending' : 'Active'}
        </span>
      </TableCell>
      <TableCell align="right" padding="none" className={classes.tableCell}>
        <Grid container alignItems="center" justify="flex-end" spacing={16}>
          <Grid item>
            {!(isLabAdmin && isUserLabAdmin) && (
              <ContextMenu
                open={contextMenuOpened && moreMenuOpened}
                anchorEl={moreAnchorEl}
                pending={user.pending}
                handleMoreMenu={handleMoreMenu}
                userId={user.id}
                lab={
                  laboratories && laboratories.length ? laboratories[0] : null
                }
                labView={labView}
              />
            )}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(memo(UserTableRow));
