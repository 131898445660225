import React from 'react';
import classNames from 'classnames';
import { Link, navigate } from '@reach/router';
import { inject, observer } from 'mobx-react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { AuthStore } from 'stores/authStore';
import logoImage from 'assets/images/logo_petricloud_@2x.png';
import Navigation from './Navigation';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 68,
      width: '100%',
      backgroundColor: theme.palette.common.white,
      boxShadow: '3px 0 6px 0 rgba(201, 206, 213, 0.32)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 40px',
      zIndex: theme.zIndex.drawer + 1,
      position: 'fixed',
      top: 0,
    },
    rootLoggedIn: {
      justifyContent: 'flex-start',
    },
    spacer: {
      flex: 1,
    },
    logo: {
      width: 125,
      position: 'relative',
      top: 3,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {}

interface InjectedProps extends Props {
  authStore: AuthStore;
}

interface State {
  anchorEl: HTMLElement | null;
}
//#endregion

@inject('authStore')
@observer
class Navbar extends React.Component<Props, State> {
  state = {
    anchorEl: null,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleLogout = () => {
    this.injected.authStore.logOut(() => {
      navigate('/');
    });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const {
      authStore: { isLoggedIn, isAdmin, isLabAdmin, user },
    } = this.injected;

    return (
      <header
        className={classNames(classes.root, {
          [classes.rootLoggedIn]: isLoggedIn,
        })}
      >
        <Link to="/">
          <img className={classes.logo} src={logoImage} alt="Petricloud logo" />
        </Link>

        {isLoggedIn && (
          <>
            <Navigation isAuthorized={isAdmin || isLabAdmin} />
            <div className={classes.spacer} />
            <Typography variant="subtitle1" component="span">
              Hi! {user.fullName}
            </Typography>
            <IconButton onClick={this.handleOpenMenu}>
              <ArrowDropDownIcon />
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={this.handleCloseMenu}
            >
              <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </header>
    );
  }
}

export default withStyles(styles)(Navbar);
