import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      borderRight: `1px dashed ${theme.palette.primary.main}`,
      minHeight: 'calc(100vh - 68px)',
      minWidth: 115,
      position: 'relative',
    },
    optionSection: {
      height: 97,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: `1px dashed ${theme.palette.primary.main}`,
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {}
//#endregion

const Navigation: React.FunctionComponent<Props> = ({ classes, children }) => (
  <aside className={classes.root}>{children}</aside>
);

export default withStyles(styles)(Navigation);
