import React, { Component } from 'react';
import { Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, navigate } from '@reach/router';
import * as Yup from 'yup';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '../components/ui/Button';
import SnackbarMessage from '../components/ui/SnackbarMessage';

import { AuthStore } from '../stores/authStore';
import { UsersStore } from '../stores/usersStore';
import { AppStore } from '../stores/appStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 68px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loginCard: {
      height: '100%',
      width: 498,
      boxShadow: theme.shadows[1],
    },
    cardContent: {
      padding: '34px 90px 0 !important',
    },
    loginForm: {
      marginBottom: 40,
      paddingTop: 10,
    },
    loginButton: {
      marginTop: 40,
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    errorIcon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing.unit,
    },
    errorMessage: {
      display: 'flex',
      alignItems: 'center',
    },
  });
//#endregion

//#region Types
interface LoginPayload {
  email: string;
  password: string;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

interface InjectedProps extends Props {
  authStore: AuthStore;
  usersStore: UsersStore;
  appStore: AppStore;
}
//#endregion

//#region Component
@inject('authStore', 'usersStore', 'appStore')
@observer
class AcceptInvite extends Component<Props> {
  params = new URLSearchParams(window.location.search.replace(/\+/gi, '%2B'));

  get injected() {
    return this.props as InjectedProps;
  }

  goToDashboard = () => {
    navigate('/');
  };

  async componentDidMount() {
    const token = this.params.get('token')!;
    try {
      await this.injected.authStore.invitationExist(token);
    } catch (e) {
      navigate('/');
    }
  }

  render() {
    const { classes } = this.props;
    const { isLoading, hasError, isLoggedIn } = this.injected.authStore;
    const email = this.params.get('email')!;
    const token = this.params.get('token')!;

    return (
      <div className={classes.root}>
        <SnackbarMessage
          opened={hasError}
          variant="error"
          message="Wrong credentials"
          onClose={this.injected.authStore.clearError}
        />
        {this.injected.authStore.isInvited ? (
          email ? (
            <Card className={classes.loginCard}>
              <CardContent classes={{ root: classes.cardContent }}>
                <Typography variant="h1" align="center" gutterBottom>
                  Welcome to Petricloud
                </Typography>
                {isLoggedIn ? (
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{
                      paddingTop: 24,
                      paddingBottom: 40,
                    }}
                  >
                    <Grid item>
                      <Typography variant="h6">
                        You are now logged in
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        type="button"
                        className={classes.loginButton}
                        width={300}
                        onClick={this.goToDashboard}
                      >
                        Go to dashboard
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Formik
                    initialValues={{
                      password: '',
                      passwordConfirm: '',
                    }}
                    onSubmit={values => {
                      this.injected.usersStore.createUser({
                        email,
                        token,
                        password: values.password,
                      });
                    }}
                    validationSchema={Yup.object({
                      password: Yup.string().required('Password is required'),
                      passwordConfirm: Yup.string()
                        .oneOf(
                          [Yup.ref('password'), null],
                          'Passwords must match',
                        )
                        .required('Password confirm is required'),
                    })}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                    }) => (
                      <form
                        className={classes.loginForm}
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          disabled
                          fullWidth
                          margin="normal"
                          label="Email"
                          id="email"
                          type="email"
                          value={email}
                        />

                        <TextField
                          disabled={isLoading}
                          required
                          fullWidth
                          margin="normal"
                          label="Password"
                          id="password"
                          type="password"
                          error={!!(errors.password && touched.password)}
                          helperText={
                            errors.password && touched.password
                              ? errors.password
                              : ''
                          }
                          onChange={handleChange}
                          value={values.password}
                        />

                        <TextField
                          disabled={isLoading}
                          required
                          fullWidth
                          margin="normal"
                          label="Re-type password"
                          id="passwordConfirm"
                          type="password"
                          error={
                            !!(
                              errors.passwordConfirm && touched.passwordConfirm
                            )
                          }
                          helperText={
                            errors.passwordConfirm && touched.passwordConfirm
                              ? errors.passwordConfirm
                              : ''
                          }
                          onChange={handleChange}
                          value={values.passwordConfirm}
                        />

                        <Button
                          type="submit"
                          disabled={isLoading}
                          className={classes.loginButton}
                          width="100%"
                        >
                          {isLoading ? 'Joining in...' : 'Join'}
                        </Button>
                      </form>
                    )}
                  </Formik>
                )}
              </CardContent>
            </Card>
          ) : (
            <Typography variant="h4">Email not provided</Typography>
          )
        ) : null}
      </div>
    );
  }
}
//#endregion

export default withStyles(styles)(AcceptInvite);
