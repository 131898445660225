import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from './Button';

interface Props {
  title: string;
  question: string;
  opened: boolean;
  inProgress?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

class ConfirmationModal extends Component<Props> {
  render() {
    const {
      opened,
      inProgress = false,
      title,
      question,
      onConfirm,
      onCancel,
    } = this.props;

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={opened}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{question}</Typography>
        </DialogContent>
        <DialogActions>
          {onCancel && (
            <Button
              width={100}
              onClick={onCancel}
              color="primary"
              disabled={inProgress}
            >
              Cancel
            </Button>
          )}
          {onConfirm && (
            <Button
              width={100}
              onClick={onConfirm}
              color="primary"
              disabled={inProgress}
            >
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmationModal;
