import React, { useState, useEffect, useRef, memo } from 'react';
import classNames from 'classnames';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import ImageScale from './canvasViewer/ImageScale';

// utils
import { MAX_HEIGHT_SCREEN_PX } from '../../../../utils/sizingConstants';

// cornerstone library
const cornerstone = (window as any).cornerstone;

//#region Styles
const styles = () =>
  createStyles({
    root: {
      position: 'relative',
      border: '1px solid #aaa',
      touchAction: 'none',
      width: 335,
    },
    canvas: {
      willChange: 'filter',
    },
    '@keyframes blur': {
      '0%': { filter: 'blur(0)' },
      '50%': { filter: 'blur(4px)' },
      '100%': { filter: 'blur(0)' },
    },
    loadingCanvas: {
      animationName: 'blur',
      animationDuration: '2s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translate3d(0, -50%, 0)',
      zIndex: 999,
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  image?: any;
  viewportSettings?: any;
  loading: boolean;
  firstRender?: boolean;
  width: number;
  height: number;
  displayScales?: boolean;
}
//#endregion

const CanvasRadialViewer: React.FunctionComponent<Props> = ({
  classes,
  image,
  viewportSettings,
  loading,
  firstRender,
  width,
  height,
  displayScales,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const canvas = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = canvas.current;
    cornerstone.disable(container);

    if (image) {
      setIsLoading(true);
      cornerstone.enable(container);
      const viewport = cornerstone.getDefaultViewportForImage(container, image);
      viewport.scale = width / image.width;

      if (viewportSettings) {
        viewport.voi = {
          windowWidth: viewportSettings.width,
          windowCenter: viewportSettings.center,
        };
      }

      cornerstone.displayImage(container, image, viewport);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, viewportSettings]);

  return (
    <div className={classes.root}>
      {firstRender && !image && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={75} />
        </div>
      )}
      <div
        ref={canvas}
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
        className={classNames(classes.canvas, {
          [classes.loadingCanvas]: loading || isLoading,
        })}
      />
      {image && displayScales && (
        <>
          <ImageScale
            nativeSize={Math.min(
              image.height,
              (MAX_HEIGHT_SCREEN_PX * (image.width / width)) /
                (image.rowPixelSpacing / image.columnPixelSpacing),
            )}
            nativeOffset={0}
          />
          <ImageScale isBottom nativeSize={image.width} nativeOffset={0} />
        </>
      )}
    </div>
  );
};

export default memo(withStyles(styles)(CanvasRadialViewer));
