import React, { memo, useState } from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import CTViewerTabs from '../../../../components/ui/CTViewerTabs';
import CTViewerTab from '../../../../components/ui/ctViewerTabs/Tab';
import LowImage from './dualEnergy/LowEnergy';
import HightImage from './dualEnergy/HighEnergy';
import Results from './dualEnergy/Results';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '95%',
      margin: '0 auto',
      backgroundColor: theme.palette.common.white,
    },
    pane: {
      border: '1px solid rgb(224, 224, 224)',
      borderTop: 'none',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {}
//#endregion

const DualEnergy: React.FunctionComponent<Props> = ({ classes }) => {
  let tabContent;
  const [tabValue, setTabValue] = useState<string>('lowImage');

  const viewerTabs = [
    {
      label: 'Low Energy Image',
      value: 'lowImage',
    },
    {
      label: 'High Energy Image',
      value: 'highImage',
    },
    {
      label: 'Results',
      value: 'results',
    },
  ];

  const onHandleTabChange = (
    event: React.ChangeEvent<{}>,
    tabValue: string,
  ) => {
    setTabValue(tabValue);
  };

  if (tabValue === 'lowImage') {
    tabContent = <LowImage />;
  } else if (tabValue === 'highImage') {
    tabContent = <HightImage />;
  } else {
    tabContent = <Results />;
  }

  return (
    <div className={classes.root}>
      <CTViewerTabs tabValue={tabValue} onTabChange={onHandleTabChange}>
        {viewerTabs.map((tab: any) => (
          <CTViewerTab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </CTViewerTabs>
      <div className={classes.pane}>{tabContent}</div>
    </div>
  );
};

export default memo(withStyles(styles)(DualEnergy));
