const piecewiseLinear = (
  index: number,
  length: number,
  table: number[],
  resultScale: number,
) => {
  const segmentLength = length / (table.length - 1); // will be integer

  const segmentIndex = Math.floor(index / segmentLength);
  const segmentOffset = index % segmentLength;

  const segmentStartValue = table[segmentIndex];
  const segmentEndValue = table[segmentIndex + 1];
  const segmentPosition = segmentOffset / segmentLength;

  const unscaledResult =
    segmentStartValue * (1 - segmentPosition) +
    segmentEndValue * segmentPosition;
  const result = Math.floor(unscaledResult * resultScale);

  const max = resultScale - 1;
  if (result > max) return max;
  return result;
};

const generatePaletteFromSpec = (
  length: number,
  rSpec: number[],
  gSpec: number[],
  bSpec: number[],
) => {
  let paletteEntries = [];

  for (let i = 0; i < length; i++) {
    paletteEntries.push([
      piecewiseLinear(i, length, rSpec, 256),
      piecewiseLinear(i, length, gSpec, 256),
      piecewiseLinear(i, length, bSpec, 256),
      255,
    ]);
  }

  return paletteEntries;
};

export default generatePaletteFromSpec;
