import React, { memo, useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import ResultsImages from './generatedResults/ResultsImages';

import { CTViewerStore } from '../../../../../stores/ctViewerStore';
import convertArrayToImage from '../../../../../utils/convertArrayToImage';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      content: '""',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      overflowY: 'auto',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      padding: '32px 20px',
      transform: 'translate3d(100%, 0, 0)',
      '-webkit-overflow-scrolling': 'touch',
      zIndex: 9,
      background: '#F8FAFA',
      transition: 'transform .2s ease-in-out',
    },
    rootVisible: {
      transform: 'translate3d(0, 0, 0)',
    },
    resultsButtonBox: {
      width: '100%',
      margin: '0 auto',
    },
    resultsButton: {
      marginBottom: 10,
    },
    resultsWrapper: {
      margin: '0 auto',
      maxWidth: '100%',
      minWidth: '100%',
      backgroundColor: '#fff',
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '100px 0',
    },
    processing: {
      marginTop: '20px',
      borderRadius: '6px',
      padding: '4px 14px',
      background: '#e8e8e8',
    },
    processingText: {
      fontSize: '1rem',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
}
//#endregion

let axialArray: any[] = [];

const GeneratedResults: React.FunctionComponent<Props> = ({
  classes,
  ctViewerStore,
}) => {
  const [sagittalData, setSagittalData] = useState<any>(undefined);
  const [coronalData, setCoronalData] = useState<any>(undefined);
  const [unrolledData, setUnrolledData] = useState<any>(undefined);
  const [metaData, setMetaData] = useState<any>(undefined);
  const [axialData, setAxialData] = useState<any>([]);
  const [slabData, setSlabData] = useState<any>(undefined);
  const [firstRender, setFirstRender] = useState<boolean>(false);

  const goToImages = () => {
    ctViewerStore!.toggleResults();
  };

  useEffect(() => {
    if (ctViewerStore!.processingResults) {
      axialArray = [];
      setFirstRender(false);
      setCoronalData(undefined);
      setSagittalData(undefined);
      setUnrolledData(undefined);
      setMetaData(undefined);
      setSlabData(undefined);
      setAxialData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctViewerStore!.processingResults]);

  useEffect(() => {
    if (
      !firstRender &&
      sagittalData &&
      coronalData &&
      unrolledData &&
      metaData &&
      axialData.length === ctViewerStore!.axialSlicesToGenerate &&
      (ctViewerStore!.customSlice ? slabData : true)
    ) {
      setFirstRender(true);
      ctViewerStore!.setResultsViewportSettings({
        center: coronalData.params.windowCenter,
        width: coronalData.params.windowWidth,
      });
      ctViewerStore!.toggleProcessingResultsLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sagittalData, coronalData, unrolledData, metaData, axialData, slabData]);

  useEffect(() => {
    const { socket } = ctViewerStore!;

    if (!ctViewerStore!.customSlice) {
      setSlabData(false);
    }

    socket.on(
      'rawResultsSingleResponse',
      (
        infoFileContent: any,
        requestParams: any,
        metadata: any,
        params: any,
      ) => {
        if (requestParams.name === 'coronal') {
          setCoronalData({
            image: convertArrayToImage(new Int16Array(infoFileContent), params),
            requestParams,
            metadata,
            params,
          });
        } else if (requestParams.name === 'sagittal') {
          setSagittalData({
            image: convertArrayToImage(new Int16Array(infoFileContent), params),
            requestParams,
            metadata,
            params,
          });
        } else if (requestParams.name === 'unrolled') {
          setUnrolledData({
            image: convertArrayToImage(new Int16Array(infoFileContent), params),
            requestParams,
            metadata,
            params,
          });
        } else if (requestParams.name === 'slab') {
          setSlabData({
            image: convertArrayToImage(new Int16Array(infoFileContent), params),
            requestParams,
            metadata,
            params,
          });
        } else if (requestParams.name.startsWith('axial')) {
          axialArray.push({
            image: convertArrayToImage(new Int16Array(infoFileContent), params),
            requestParams,
            metadata,
            params,
          });

          if (axialArray.length === ctViewerStore!.axialSlicesToGenerate) {
            setAxialData(axialArray);
          }
        } else if (requestParams.name === 'metadata') {
          setMetaData(infoFileContent);
        }
      },
    );

    socket.on('rawResultsSingleError', (error: any) => {
      console.log('Microservice: SingleViewer results error', error);
      ctViewerStore!.setSingleViewerDataLoader(false);
      ctViewerStore!.showSingleViewerCalculationError(
        'Single Viewer results error',
      );
      ctViewerStore!.setSingleViewerResults(false);
      goToImages();
      axialArray = [];
    });

    socket.on('saveResultImageSingleCompleted', (obj: any) => {
      console.log('Microservice: Saving results completed!', obj);
      ctViewerStore!.decreaseExportingValue(obj.length);

      if (
        ctViewerStore!.resultsExporting === ctViewerStore!.resultsExportingCopy
      ) {
        ctViewerStore!.showSingleViewerCalculationSuccess(
          'Data exported successfully!',
        );
        ctViewerStore!.clearExportingValues();
      }
    });

    socket.on('saveResultImageSingleError', (error: any, obj: any) => {
      console.log('Microservice: Problem with saving results!', error);
      ctViewerStore!.decreaseExportingValue(obj.length);
      ctViewerStore!.showSingleViewerCalculationError(
        'Problem with exporting object',
      );

      if (
        ctViewerStore!.resultsExporting === ctViewerStore!.resultsExportingCopy
      ) {
        ctViewerStore!.clearExportingValues();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootVisible]: ctViewerStore!.showingResults,
      })}
    >
      <div className={classes.resultsButtonBox}>
        <Button onClick={goToImages} classes={{ root: classes.resultsButton }}>
          &lt;&lt; Back to the images
        </Button>
      </div>

      {ctViewerStore!.processingResults ? (
        <div className={classes.loadingContainer}>
          <CircularProgress size={50} />
          <div className={classes.processing}>
            <Typography
              variant="subtitle1"
              component="strong"
              className={classes.processingText}
            >
              Loading results...
            </Typography>
          </div>
        </div>
      ) : (
        <div className={classes.resultsWrapper}>
          <ResultsImages
            coronalData={coronalData}
            sagittalData={sagittalData}
            unrolledData={unrolledData}
            metaData={metaData}
            axialData={axialData}
            slabData={slabData}
          />
        </div>
      )}
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(GeneratedResults))),
);
