import React from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import is from 'is_js';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';

import { UsersStore } from '../../../stores/usersStore';
import ListItem from './usersAndDepartments/ListItem';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRight: `1px dashed ${theme.palette.primary.main}`,
      width: 350,
      padding: '40px 20px 0 32px',
    },
    section: {
      marginBottom: 30,
    },
    sectionTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      marginBottom: 16,
    },
    sectionTitleCanEdit: {
      cursor: 'pointer',
    },
    usersList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      width: is.ie() ? '100%' : 300,
    },
    searchField: {
      marginBottom: 30,
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  onSelectUser: (id: number) => void;
  onSelectDepartment: (name: string) => void;
  canEdit: boolean;
}

interface InjectedProps extends Props {
  usersStore: UsersStore;
}
//#endregion

@inject('usersStore')
@observer
class UsersAndDepartments extends React.Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { classes, onSelectUser, onSelectDepartment, canEdit } = this.props;
    const {
      fetching,
      fetchingDepartments,
      saving,
      selectedUser,
      userSearchValue,
      setSearchValue,
      clearSearch,
      filteredDepartments,
      searchActive,
    } = this.injected.usersStore;

    return (
      <div className={classes.root}>
        {fetching || fetchingDepartments || saving ? (
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            {searchActive && (
              <TextField
                autoFocus
                id="search"
                label="Search"
                fullWidth
                className={classes.searchField}
                value={userSearchValue}
                onChange={event => setSearchValue(event.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Clear search field">
                        <IconButton
                          aria-label="Clear search"
                          onClick={clearSearch}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {filteredDepartments.length >= 1 ? (
              <>
                {filteredDepartments.map(department => (
                  <section key={department.id} className={classes.section}>
                    <Typography
                      className={classNames(classes.sectionTitle, {
                        [classes.sectionTitleCanEdit]:
                          canEdit &&
                          department.id !== 9999999 &&
                          department.id !== -9999999 &&
                          department.id !== -1,
                      })}
                      onClick={() => {
                        if (
                          canEdit &&
                          department.id !== 9999999 &&
                          department.id !== -9999999 &&
                          department.id !== -1
                        ) {
                          onSelectDepartment(department.name);
                        }
                      }}
                    >
                      {department.department_name}
                    </Typography>

                    {department.users.length ? (
                      <ul className={classes.usersList}>
                        {department.users.map(user => (
                          <ListItem
                            key={user.id}
                            name={user.full_name}
                            selected={
                              selectedUser ? user.id === selectedUser.id : false
                            }
                            onClick={() => onSelectUser(user.id)}
                          />
                        ))}
                      </ul>
                    ) : (
                      <Typography variant="subtitle1">
                        No users added yet
                      </Typography>
                    )}
                  </section>
                ))}
              </>
            ) : (
              <Grid container justify="center">
                <Grid item>
                  <Typography variant="subtitle1">
                    No departments added yet
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(UsersAndDepartments);
