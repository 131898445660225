import React, { PureComponent } from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import withDrag from '../drag/withDrag';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      strokeWidth: 1,
      stroke: '#000',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  radius: number;
  onMouseDown: any;
  hasResizeDrag: boolean;
  registerOnDrag: any;
  registerSetDragInitialState: any;
  scaling: number;
  onDrag: any;
}
//#endregion

class ArrowIcon extends PureComponent<Props> {
  componentDidMount() {
    const { registerOnDrag, registerSetDragInitialState } = this.props;
    registerOnDrag(this.handleMouseMove);
    registerSetDragInitialState(this.setDragInitialState);
  }

  handleMouseMove = (
    unscaledXDiff: number,
    unscaledYDiff: number,
    initialState: any,
  ) => {
    const { initialRadius } = initialState;
    const { scaling, onDrag } = this.props;

    const newRadius = initialRadius - unscaledYDiff / scaling;

    if (onDrag) {
      onDrag(newRadius > 0 ? newRadius : 0);
    }
  };

  handleMouseDown = (ev: React.MouseEvent) => {
    const { onMouseDown, hasResizeDrag } = this.props;
    if (hasResizeDrag) {
      onMouseDown(ev);
    }
  };

  setDragInitialState = () => {
    const { radius } = this.props;
    return {
      initialRadius: radius,
    };
  };

  render() {
    const { classes } = this.props;

    return (
      <svg width={12} height={12} onMouseDown={this.handleMouseDown}>
        <line x1={6} y1={0} x2={6} y2={12} className={classes.root} />
        <line x1={0} y1={6} x2={12} y2={6} className={classes.root} />
      </svg>
    );
  }
}

export default withStyles(styles)(withDrag(ArrowIcon));
