import React from 'react';
import { observer, inject } from 'mobx-react';

import classNames from 'classnames';
import is from 'is_js';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';

import { UsersStore } from '../../../stores/usersStore';
import { ProjectsStore } from '../../../stores/projectsStore';
import ListItem from './usersList/ListItem';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRight: `1px dashed ${theme.palette.primary.main}`,
      width: 350,
      padding: '40px 20px 0 32px',
    },
    section: {
      marginBottom: 30,
    },
    sectionTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      marginBottom: 16,
    },
    sectionTitleCanEdit: {
      cursor: 'pointer',
    },
    usersList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      width: is.ie() ? '100%' : 300,
    },
    searchField: {
      marginBottom: 30,
    },
  });
//#endregion

//#region types

interface User {
  id: number;
  full_name: string;
}

interface Props extends WithStyles<typeof styles> {
  onSelectUser: (id: number, role: string) => void;
  canEdit: boolean;
}

interface InjectedProps extends Props {
  usersStore: UsersStore;
  projectsStore: ProjectsStore;
}
//#endregion

@inject('usersStore', 'projectsStore')
@observer
class UsersAndDepartments extends React.Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { classes, onSelectUser } = this.props;
    const { selectedUser, fetching } = this.injected.usersStore;
    const {
      requestInProgress,
      userSearchValue,
      setSearchValue,
      clearSearch,
      searchActive,
      filteredManagers,
      filteredTechnicians,
      filteredClients,
    } = this.injected.projectsStore;

    return (
      <div className={classes.root}>
        {requestInProgress || fetching ? (
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            {searchActive && (
              <TextField
                autoFocus
                id="search"
                label="Search"
                fullWidth
                className={classes.searchField}
                value={userSearchValue}
                onChange={event => setSearchValue(event.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Clear search field">
                        <IconButton
                          aria-label="Clear search"
                          onClick={clearSearch}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <section key="projectManagers" className={classes.section}>
              <Typography className={classNames(classes.sectionTitle)}>
                Project Managers
              </Typography>
              {filteredManagers && filteredManagers.length > 0 ? (
                <ul className={classes.usersList}>
                  {filteredManagers.map(user => (
                    <ListItem
                      key={user.id}
                      name={user.full_name}
                      selected={
                        selectedUser ? user.id === selectedUser.id : false
                      }
                      onClick={() => onSelectUser(user.id, 'projectManager')}
                    />
                  ))}
                </ul>
              ) : (
                <Typography variant="subtitle1">No users added yet</Typography>
              )}
            </section>

            <section key="technicians" className={classes.section}>
              <Typography className={classNames(classes.sectionTitle)}>
                Technicians
              </Typography>
              {filteredTechnicians && filteredTechnicians.length > 0 ? (
                <ul className={classes.usersList}>
                  {filteredTechnicians.map(user => (
                    <ListItem
                      key={user.id}
                      name={user.full_name}
                      selected={
                        selectedUser ? user.id === selectedUser.id : false
                      }
                      onClick={() => onSelectUser(user.id, 'technician')}
                    />
                  ))}
                </ul>
              ) : (
                <Typography variant="subtitle1">No users added yet</Typography>
              )}
            </section>

            <section key="clients" className={classes.section}>
              <Typography className={classNames(classes.sectionTitle)}>
                Clients
              </Typography>
              {filteredClients && filteredClients.length > 0 ? (
                <ul className={classes.usersList}>
                  {filteredClients.map(user => (
                    <ListItem
                      key={user.id}
                      name={user.full_name}
                      selected={
                        selectedUser ? user.id === selectedUser.id : false
                      }
                      onClick={() => onSelectUser(user.id, 'client')}
                    />
                  ))}
                </ul>
              ) : (
                <Typography variant="subtitle1">No users added yet</Typography>
              )}
            </section>
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(UsersAndDepartments);
