import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRight: `1px dashed ${theme.palette.primary.main}`,
      minWidth: 350,
      padding: '40px 20px 0 20px',
    },
    section: {
      marginBottom: 30,
    },
    sectionTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      marginBottom: 16,
    },
    usersList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    addButton: {
      paddingLeft: 0,
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {}
//#endregion

const GroupList: React.FunctionComponent<Props> = ({ classes, children }) => {
  return (
    <div className={classes.root}>
      <section className={classes.section}>{children}</section>
    </div>
  );
};

export default withStyles(styles)(GroupList);
