import React, { FC } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Button from '../../components/ui/Button';

//#region Styles
const styles = () =>
  createStyles({
    root: {},
    createLabButton: {
      marginTop: 40,
    },
    selectWrapper: {
      width: '100%',
      marginTop: '1rem',
    },
  });
//#endregion

//#region Types
export interface CreateProjectData {
  projectName: string;
  companyName: string;
  clientName: string;
  labName: string;
}

interface Props extends WithStyles<typeof styles> {
  allLabs: Common.Lab[];
  inProgress: boolean;
  onCreateProject: (data: CreateProjectData) => void;
}
//#endregion

// Component
const CreateProject: FC<Props> = ({
  allLabs,
  inProgress,
  classes,
  onCreateProject,
}) => {
  const labs = allLabs.map(lab => lab.path);

  return (
    <Formik
      initialValues={{
        projectName: '',
        companyName: '',
        clientName: '',
        labName: '',
      }}
      onSubmit={values => {
        onCreateProject(values);
      }}
      validationSchema={Yup.object().shape({
        labName: Yup.string()
          .matches(/^[0-9a-zA-Z ]+$/, 'Name contains invalid characters')
          .required('Field is required'),
      })}
    >
      {({ values, handleChange, handleSubmit, errors }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            margin="normal"
            fullWidth
            label="Project Name"
            id="projectName"
            error={!!errors.projectName}
            helperText={errors.projectName || ''}
            onChange={handleChange}
            value={values.projectName}
          />

          <TextField
            required
            margin="normal"
            fullWidth
            label="Company Name"
            id="companyName"
            error={!!errors.companyName}
            helperText={errors.companyName || ''}
            onChange={handleChange}
            value={values.companyName}
          />

          <TextField
            required
            margin="normal"
            fullWidth
            label="Client Name"
            id="clientName"
            error={!!errors.clientName}
            helperText={errors.clientName || ''}
            onChange={handleChange}
            value={values.clientName}
          />

          <FormControl className={classes.selectWrapper}>
            <InputLabel htmlFor="labName">Lab</InputLabel>
            <Select
              required
              fullWidth
              inputProps={{
                id: 'labName',
                name: 'labName',
              }}
              error={!!errors.labName}
              onChange={handleChange}
              value={values.labName}
            >
              {labs.map(lab => (
                <MenuItem key={lab} value={lab}>
                  {lab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            disabled={inProgress}
            type="submit"
            className={classes.createLabButton}
          >
            {inProgress ? 'Creating...' : 'Create'}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(CreateProject);
