import './addCustomPalettes';
import extractFirstLastGradientColor from './extractFirstLastGradientColor';

const gradientMinLookup = {
  gray: '#000',
  hot: '#000',
  hotIron: extractFirstLastGradientColor('hotIron', false),
  pet: extractFirstLastGradientColor('pet', false),
  hotMetalBlue: extractFirstLastGradientColor('hotMetalBlue', false),
  pet20Step: extractFirstLastGradientColor('pet20Step', false),
  customSpectral: extractFirstLastGradientColor('customSpectral', false),
};

export default gradientMinLookup;
