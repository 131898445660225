import httpClient from '../utils/api/httpClient';
import { observable, action, computed } from 'mobx';
import orderBy from 'lodash-es/orderBy';

const MY_FILES_PREFIX = 'My Files';

export class LabsStore {
  private endpoints = {
    files: 'api/v1/resources/files',
    createLab: 'api/v1/labs',
    groups: 'api/v1/groups',
    addAdminToGroup: 'api/v1/groups/add_sub_admin',
    getGroupAdmins: 'api/v1/groups/get_sub_admins',
    getSubAdmins: 'api/v1/groups/get_sub_admins?group_name=',
    removeSubAdmins: 'api/v1/groups/delete_sub_admin',
    removeLab: 'api/v1/lab_managers/remove_lab',
  };

  @observable
  isLoading: boolean = false;

  @observable
  labs: Common.Lab[] = [];

  @observable
  requestInProgress: boolean = false;

  @observable
  fetchingResources: boolean = false;

  @observable
  requestError: boolean = false;

  @computed
  get labsList() {
    return this.labs.filter(lab => lab.lab === true);
  }

  @computed
  get sharedFilesAndFolders() {
    const filteredResources = this.labs.filter(lab => lab.lab === false);
    return orderBy(filteredResources, ['type'], ['desc']);
  }

  @computed
  get myFilesSize() {
    const myFiles = this.labs.find(lab => lab.path === MY_FILES_PREFIX);

    if (myFiles) {
      return myFiles.size;
    }

    return 0;
  }

  @action.bound
  clearLabs() {
    this.labs = [];
  }

  async createLab(name: string) {
    return await httpClient.post(this.endpoints.createLab, {
      lab_name: name,
    });
  }

  async createGroup(groupName: string) {
    return await httpClient.post(this.endpoints.groups, {
      group_name: groupName,
    });
  }

  async getSubAdmins(groupName: string) {
    return await httpClient.get(`${this.endpoints.getSubAdmins}${groupName}`);
  }

  async removeSubAdmins(groupName: string, username: string) {
    const params = {
      group_name: groupName,
      username,
    };

    return await httpClient.put(`${this.endpoints.removeSubAdmins}`, params);
  }

  async removeLab(labName: string) {
    const params = {
      lab_name: labName,
    };

    return await httpClient.put(`${this.endpoints.removeLab}`, params);
  }

  async addAdminToGroup(payload: { groupName: string; userName: string }) {
    return await httpClient.post(this.endpoints.addAdminToGroup, {
      group_name: payload.groupName,
      username: payload.userName,
    });
  }

  async getGroupAdmins(payload: { groupName: string; userName: string }) {
    return await httpClient.post(this.endpoints.addAdminToGroup, {
      group_name: payload.groupName,
      username: payload.userName,
    });
  }

  @action.bound
  setRequestInProgress(value: boolean) {
    this.requestInProgress = value;
  }

  @action
  async getLabsList(): Promise<Common.Lab[]> {
    this.isLoading = true;
    const { data }: { data: Common.File[] } = await httpClient.get(
      this.endpoints.files,
      {
        params: {
          folder_path: '/',
          root_path: true,
        },
      },
    );

    const labs = data.map(file => ({
      path: file.path,
      size: file.size,
      lab: file.lab,
      type: file.type,
      uri: file.uri,
    }));

    this.labs = labs;
    this.isLoading = false;

    return labs;
  }
}

export default new LabsStore();
