import React, { memo } from 'react';
import { inject, observer } from 'mobx-react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  BH_CROP_RADIUS,
  CROP_CENTER_POSITION_X,
  CROP_CENTER_POSITION_Y,
} from '../../../../utils/sizingConstants';
import { CTViewerStore } from '../../../../stores/ctViewerStore';

//#region Styles
const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      fontSize: '1rem',
      lineHeight: 1.75,
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
}
//#endregion

const BeamHardeningCheckbox: React.FunctionComponent<Props> = ({
  classes,
  ctViewerStore,
}) => {
  if (!ctViewerStore!.axialImage) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    ctViewerStore!.toggleBeamHardening(event.target.checked);

    if (ctViewerStore!.beamHardeningX === undefined) {
      ctViewerStore!.setBeamHardeningX(CROP_CENTER_POSITION_X);
      ctViewerStore!.setBeamHardeningY(CROP_CENTER_POSITION_Y);
      ctViewerStore!.setBeamHardeningRadius(BH_CROP_RADIUS);
    }
  };

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            checked={ctViewerStore!.beamHardening}
            color="primary"
          />
        }
        label="Beam hardening"
        classes={{
          label: classes.label,
        }}
      />
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(BeamHardeningCheckbox))),
);
