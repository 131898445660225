import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '../../components/ui/Button';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginBottom: 40,
      paddingTop: 10,
    },
    submitButton: {
      marginTop: 40,
    },
    linkSent: {
      padding: '30px 0',
      color: '#69C3AC',
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  isLoading: boolean;
  onSend: (email: string) => void;
  passwordResetSent: boolean;
}
//#endregion

const Step1: React.FunctionComponent<Props> = ({
  classes,
  isLoading,
  onSend,
  passwordResetSent,
}) => {
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={values => {
        onSend(values.email);
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email()
          .required('Password is required'),
      })}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <>
          {!passwordResetSent && (
            <form
              className={classes.form}
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              <TextField
                required
                fullWidth
                margin="normal"
                label="Email"
                id="email"
                error={!!(errors.email && touched.email)}
                helperText={errors.email && touched.email ? errors.email : ''}
                onChange={handleChange}
                value={values.email}
              />

              <Button
                type="submit"
                disabled={isLoading}
                className={classes.submitButton}
                width="100%"
              >
                {isLoading ? 'Sending...' : 'Send'}
              </Button>
            </form>
          )}
          {passwordResetSent && (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.linkSent}
            >
              <Grid item>
                <Typography variant="h6" color="inherit">
                  Please check your email
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Formik>
  );
};

export default withStyles(styles)(Step1);
