import * as React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid #69C3AC`,
        },
        '&:before': {
          borderBottom: `2px solid #E1F3EE`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid #69C3AC`,
          '@media (hover: none)': {
            borderBottom: `1px solid #69C3AC`,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#E1F3EE',
      main: '#69C3AC',
      dark: '#566063',
    },
    text: {
      primary: '#1E1F24',
    },
  },
  typography: {
    fontFamily: 'acumin-pro, sans-serif',
    fontWeightMedium: 700,
    useNextVariants: true,
    h1: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    button: {
      fontWeight: 'bold',
      textTransform: 'initial',
    },
  },
  shape: {
    borderRadius: 10,
  },
});

function withRoot<P>(Component: React.ComponentType<P>) {
  function WithRoot(props: P) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
