import React, { PureComponent } from 'react';
import cloneDeep from 'lodash-es/cloneDeep';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

import TableHead from './userTable/TableHead';
import UserTableRow from './userTable/TableRow';

import { User } from '../../stores/usersStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      padding: 0,
    },
    tableWrapper: {
      overflow: 'hidden',
      overflowX: 'auto',
    },
    table: {
      marginTop: 38,
      overflow: 'hidden',
    },
    rootPagination: {
      display: 'flex',
      justifyContent: 'center',
    },
    paginationActions: {
      margin: 0,
    },
    emptyRows: {
      textAlign: 'center',
      borderBottom: '2px solid #f3f3f3',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  users: Array<User>;
  labView?: boolean;
  chosenLab?: string;
  isLabAdmin: boolean;
  tabValue: string;
}

interface State {
  phrase?: string;
  data: Array<User>;
  searched: Array<User>;
  page: number;
  rowsPerPage: number;
  userContextMenu: string | null;
}
//#endregion

class UserTable extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      phrase: '',
      searched: [],
      data: cloneDeep(props.users),
      page: 0,
      rowsPerPage: 10,
      userContextMenu: null,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.users !== prevProps.users) {
      this.setState({
        data: cloneDeep(this.props.users),
        phrase: '',
        searched: [],
        page: 0,
        userContextMenu: null,
      });
    }
  }

  handleChangePage = (event: any, page: number) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleSearch = (val: string) => {
    const { data } = this.state;
    const people = data.filter((user: User) => {
      const regex = new RegExp(val, 'gi');
      return user.full_name.match(regex);
    });

    this.setState({
      phrase: val,
      searched: people,
    });
  };

  handleContextMenu = (target: EventTarget & Element, menuId: string) => {
    const { userContextMenu } = this.state;
    this.setState({
      userContextMenu: userContextMenu === menuId ? null : menuId,
    });
  };

  render() {
    const { classes, labView, isLabAdmin, chosenLab, tabValue } = this.props;
    const {
      data,
      searched,
      rowsPerPage,
      page,
      phrase,
      userContextMenu,
    } = this.state;

    const list = phrase ? searched : data;

    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="users table">
            <TableHead
              onSearch={this.handleSearch}
              phrase={phrase}
              tabValue={tabValue}
            />

            <TableBody>
              {list
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user: User) => {
                  const isMenuOpened = userContextMenu
                    ? userContextMenu.indexOf(`${user.id}`) !== -1
                    : false;
                  return (
                    <UserTableRow
                      key={user.id}
                      user={user}
                      onHandleContextMenu={this.handleContextMenu}
                      contextMenuOpened={isMenuOpened}
                      labView={labView}
                      isLabAdmin={isLabAdmin}
                      chosenLab={chosenLab}
                    />
                  );
                })}

              {!list.length && (
                <TableRow>
                  <TableCell colSpan={8} className={classes.emptyRows}>
                    No users found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {list.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            classes={{
              root: classes.rootPagination,
              actions: classes.paginationActions,
            }}
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            labelDisplayedRows={() => ''}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(UserTable);
