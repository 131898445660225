import React, { memo } from 'react';
import { inject, observer } from 'mobx-react';
//@ts-ignore
import ReactSlider from 'react-slider';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Typography from '@material-ui/core/Typography';

import { CTViewerStore } from '../../../../stores/ctViewerStore';

//#region Styles
const styles = () =>
  createStyles({
    root: {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    slider: {
      width: '180px',
      height: '20px',
      margin: '0 12px',
    },
    thumb: {
      position: 'relative',
      backgroundColor: 'rgb(110, 206, 178)',
      width: '19px',
      height: '19px',
      borderRadius: '50%',
      border: '1px solid rgb(10, 166, 148)',
      transform: 'translateY(-5px)',

      '&:focus': {
        outline: 'none',
      },
    },
    track: {
      height: '4px',
      top: '3px',
      borderRadius: '6px',
      background: '#e9e9e9',

      '&-0': {
        background: '#e9e9e9',
      },
      '&-1': {
        background: '#e9e9e9',
      },
    },
    title: {
      display: 'block',
      marginBottom: 10,
    },
    input: {
      fontSize: 16,
      textAlign: 'center',
      borderRadius: '7px',
      border: '1px solid #DFE3ED',
      height: 33,
      width: 60,
      marginTop: '-9px',
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
}
//#endregion

const BHSlider: React.FunctionComponent<Props> = ({
  classes,
  ctViewerStore,
}) => {
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value.trim()) {
      ctViewerStore!.setBeamHardeningRange(parseFloat(ev.target.value));
    }
  };

  const handleChangeSlider = (value: number) => {
    ctViewerStore!.setBeamHardeningRange(value);
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="subtitle1"
        component="span"
        className={classes.title}
      >
        BH range:
      </Typography>
      <ReactSlider
        min={0.5}
        max={1}
        value={[ctViewerStore!.beamHardeningRange]}
        step={0.01}
        withBars
        onChange={handleChangeSlider}
        className={classes.slider}
        thumbClassName={classes.thumb}
        trackClassName={classes.track}
      />
      <input
        type="number"
        onChange={handleChange}
        min={0.5}
        max={1}
        value={ctViewerStore!.beamHardeningRange}
        step={0.01}
        className={classes.input}
      />
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(BHSlider))),
);
