import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import classNames from 'classnames';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      fontWeight: 'bold',
      padding: '0 0 2px 0',
      border: '1px solid #C6CACB',
      color: '#C6CACB',
      backgroundColor: 'transparent',
      minWidth: 35,
      maxWidth: 35,
      height: 22,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 3,
      order: 2,
      marginLeft: 8,
      fontSize: '.9rem',
    },
    selected: {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    content: {
      display: 'inline-block',
      width: '95%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  selected?: boolean;
}
//#endregion Types

const Indicator: React.FunctionComponent<Props> = ({
  selected,
  classes,
  children,
}) => (
  <div
    className={classNames(classes.root, {
      [classes.selected]: selected,
    })}
  >
    <span className={classes.content}>{children}</span>
  </div>
);

export default withStyles(styles)(Indicator);
