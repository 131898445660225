import React, { Component } from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import CircularProgress from '@material-ui/core/CircularProgress';

import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import LeftMenu from './project/LeftMenu';
import Settings from './project/Settings';
import Users from './project/Users';
import DirectoryListing from './project/DirectoryListing';
import { ProjectsStore } from '../stores/projectsStore';
import { inject, observer } from 'mobx-react';

//#region Styles
const styles = () =>
  createStyles({
    root: {
      paddingLeft: 240,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
      marginLeft: '-120px',
    },
  });
//#endregion

//#region Types
interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  id: string;
}

interface InjectedProps extends Props {
  projectsStore: ProjectsStore;
}

//#endregion

@inject('projectsStore')
@observer
class Project extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    const { id } = this.props;
    await this.injected.projectsStore.getProjectsList();
    await this.injected.projectsStore.getSingleProject(id);
  }

  async componentDidUpdate(prevProps: Props) {
    const { id } = this.props;

    if (id !== prevProps.id) {
      await this.injected.projectsStore.getSingleProject(id);
    }
  }

  async componentWillUnmount() {
    this.injected.projectsStore.clearCurrentProject();
  }

  render() {
    const pathname = (this.props.location || { pathname: '' }).pathname;
    const { classes, id } = this.props;

    const { requestInProgress, currentProject } = this.injected.projectsStore;

    return (
      <div className={classes.root}>
        {!requestInProgress && currentProject ? (
          <>
            <aside>
              <LeftMenu
                projectId={currentProject.id}
                projectName={currentProject.name || ''}
                labName={currentProject.lab.lab_name || ''}
              />
            </aside>
            <div>
              <Router>
                <DirectoryListing id={id} fullPath={pathname} path="/*" />
                <Settings
                  id={currentProject.id}
                  path="settings"
                  projectName={currentProject.name}
                  defaultSettings={{
                    projectName: currentProject.name,
                    companyName: currentProject.company_name,
                    clientName: currentProject.client_name,
                    labName: currentProject.lab.lab_name,
                  }}
                />
                <Users
                  id={currentProject.id}
                  labName={currentProject.lab.lab_name}
                  path="users"
                />
              </Router>
            </div>
          </>
        ) : (
          <div className={classes.loadingContainer}>
            <CircularProgress size={75} />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Project);
