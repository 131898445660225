import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import ButtonWithIcon from '../components/ui/ButtonWithIcon';
import Modal from '../components/ui/Modal';
import Dashboard from './labs/Dashboard';
import CreateLab, { CreateLabData } from './labs/CreateLab';
import CreateProject, { CreateProjectData } from './labs/CreateProject';
import CustomLabs from './labs/CustomLabs';

import { LabsStore } from '../stores/labsStore';
import { AuthStore } from '../stores/authStore';
import projectsStore, { ProjectsStore } from '../stores/projectsStore';
import appStore, { AppStore } from './../stores/appStore';

//#region Styles

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    mainContainer: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      padding: '48px 33px 0',
    },
    topPanel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      paddingLeft: 10,
    },
    formContainer: {},
    createLabButton: {
      marginTop: 40,
    },
    sectionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      paddingBottom: 30,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {}

interface InjectedProps extends Props {
  labsStore: LabsStore;
  projectsStore: ProjectsStore;
  appStore: AppStore;
  authStore: AuthStore;
}
//#endregion

@inject('appStore', 'labsStore', 'authStore', 'projectsStore')
@observer
class Labs extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    this.injected.labsStore.getLabsList();
    this.injected.projectsStore.getProjectsList();
  }

  createLab = async (data: CreateLabData) => {
    const { labsStore, appStore } = this.injected;

    try {
      labsStore.setRequestInProgress(true);
      await labsStore.createLab(data.labName);

      appStore.showSuccessToaster('Lab created');
      appStore.toggleModal('createLabOpened', false);

      labsStore.setRequestInProgress(false);
      await labsStore.getLabsList();
    } catch (error) {
      labsStore.setRequestInProgress(false);
      appStore.showErrorToaster('Failed to create lab');
    }
  };

  createProject = async (data: CreateProjectData) => {
    const { appStore, projectsStore } = this.injected;

    try {
      projectsStore.setRequestInProgress(true);
      await projectsStore.createProject(data);
      appStore.showSuccessToaster('Project created');
      appStore.toggleModal('createProjectOpened', false);

      projectsStore.setRequestInProgress(false);
      await projectsStore.getProjectsList();
    } catch (error) {
      projectsStore.setRequestInProgress(false);
      appStore.showErrorToaster('Failed to create project');
    }
  };

  isCurrentUserPM = () => {
    const { userData } = this.injected.authStore;

    if (!userData.pm_for_labs) {
      return false;
    }

    return !!userData.pm_for_labs.length;
  };

  render() {
    const { classes } = this.props;
    const { labsStore } = this.injected;
    const { labsList, isLoading, requestInProgress } = labsStore;
    const { isAdmin, isLabAdmin } = this.injected.authStore;

    const isPM = this.isCurrentUserPM();

    return (
      <div className={classes.root}>
        <Modal
          open={appStore.modals.createLabOpened}
          closeModal={() => appStore.toggleModal('createLabOpened', false)}
          title="Lab creation"
        >
          <CreateLab
            allLabs={labsList}
            onCreateLab={this.createLab}
            inProgress={requestInProgress}
          />
        </Modal>

        <Modal
          open={appStore.modals.createProjectOpened}
          closeModal={() => appStore.toggleModal('createProjectOpened', false)}
          title="Create a project"
        >
          <CreateProject
            allLabs={labsList}
            onCreateProject={this.createProject}
            inProgress={requestInProgress}
          />
        </Modal>

        <div className={classes.mainContainer}>
          {!isLoading && !projectsStore.requestInProgress ? (
            <>
              {(labsList.length > 0 || isAdmin) && (
                <>
                  <div className={classes.topPanel}>
                    <Typography className={classes.sectionTitle} component="h1">
                      Labs
                    </Typography>

                    <div>
                      {isAdmin && (
                        <ButtonWithIcon
                          onClick={() =>
                            appStore.toggleModal('createLabOpened', true)
                          }
                          icon={<LocationCityIcon />}
                        >
                          Add a lab
                        </ButtonWithIcon>
                      )}
                    </div>
                  </div>

                  <Dashboard type="lab" labs={labsList} />
                </>
              )}

              {(projectsStore.projectsList.length > 0 ||
                isAdmin ||
                isLabAdmin ||
                isPM) && (
                <>
                  <div className={classes.topPanel}>
                    <Typography className={classes.sectionTitle} component="h1">
                      Projects
                    </Typography>

                    <div>
                      {(isAdmin || isLabAdmin || isPM) && (
                        <ButtonWithIcon
                          onClick={() =>
                            appStore.toggleModal('createProjectOpened', true)
                          }
                          icon={<LocationCityIcon />}
                        >
                          Add a project
                        </ButtonWithIcon>
                      )}
                    </div>
                  </div>

                  <Dashboard type="project" labs={projectsStore.projectsList} />
                </>
              )}

              <div className={classes.topPanel}>
                <Typography className={classes.sectionTitle} component="h1">
                  Files
                </Typography>
              </div>

              <CustomLabs />
            </>
          ) : (
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <CircularProgress size={75} />
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Labs);
