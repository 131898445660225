import React, { memo } from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      borderRadius: '50%',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  centerX: number;
  centerY: number;
  radius: number;
  scaling: number;
  borderColor: string;
  backgroundColor: string;
  onMouseDown: ((e: React.MouseEvent) => void) | undefined;
}
//#endregion

const Marker: React.FunctionComponent<Props> = ({
  centerX,
  centerY,
  radius,
  scaling,
  borderColor,
  backgroundColor,
  onMouseDown,
  classes,
  children,
}) => {
  if (
    typeof centerX === 'undefined' ||
    typeof centerY === 'undefined' ||
    typeof radius === 'undefined'
  ) {
    return null;
  }

  const left = Math.round((centerX - radius) * scaling);
  const top = Math.round((centerY - radius) * scaling);
  const width = Math.round(2 * radius * scaling);
  const height = Math.round(2 * radius * scaling);

  return (
    <div
      className={classes.root}
      style={{
        left: left,
        top: top,
        width: width,
        height: height,
        border: `1px solid ${borderColor}`,
        backgroundColor: backgroundColor,
      }}
      onMouseDown={onMouseDown}
    >
      {children}
    </div>
  );
};

export default memo(withStyles(styles)(Marker));
