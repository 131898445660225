import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Tabs from '@material-ui/core/Tabs';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    tabIndicator: {
      display: 'none',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  tabValue: string;
  onTabChange: (event: React.ChangeEvent<{}>, tabValue: string) => void;
}
//#endregion

// Component
const CTViewerTabs: React.FunctionComponent<Props> = ({
  children,
  classes,
  tabValue,
  onTabChange,
}) => (
  <Tabs
    variant="fullWidth"
    value={tabValue}
    onChange={onTabChange}
    classes={{
      indicator: classes.tabIndicator,
    }}
  >
    {children}
  </Tabs>
);

export default withStyles(styles)(CTViewerTabs);
