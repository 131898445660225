import React, { Component, ChangeEvent } from 'react';
import { observer, inject } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';

import Base from './userBase/Base';
import { LabsStore } from '../../stores/labsStore';
import { FilesStore } from '../../stores/files/filesStore';
import { UsersStore, User, Group } from '../../stores/usersStore';
import { AuthStore } from '../../stores/authStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 25,
    },
    tabContainer: {
      margin: '0 32px',
    },
    tabs: {
      overflow: 'auto',
    },
    flexContainer: {
      flexWrap: 'wrap',
    },
    tabIndicator: {
      display: 'none',
    },
    tab: {
      background: 'transparent',
      color: theme.palette.common.black,
      borderRadius: 16,
      fontSize: '.9rem',
      margin: 6,
      padding: 0,
      minWidth: 0,
      minHeight: 30,
      fontWeight: 600,
    },
    selectedTab: {
      background: '#D4ECE6',
      color: theme.palette.primary.main,
    },
    tabLabel: {
      padding: '6px 20px',
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {}

interface InjectedProps extends Props {
  labsStore: LabsStore;
  usersStore: UsersStore;
  authStore: AuthStore;
  filesStore: FilesStore;
}

interface State {
  chosenLab: string;
}
//#endregion

@inject('labsStore', 'usersStore', 'authStore', 'filesStore')
@observer
class Labs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      chosenLab: '',
    };
  }

  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    const { usersStore, labsStore } = this.injected;

    if (usersStore.users.length === 0) {
      await usersStore.getUsersList();
    }

    if (usersStore.pendingUsers.length === 0) {
      await usersStore.getPendingUsersList();
    }

    if (labsStore.labs.length === 0) {
      await labsStore.getLabsList();
    }
  }

  componentWillUnmount() {
    const { usersStore, filesStore } = this.injected;

    filesStore.clear();
    usersStore.cleanUp();
  }

  handleLabChange = (event: ChangeEvent<{}>, value: string) => {
    this.setState({
      chosenLab: value,
    });

    this.injected.usersStore.getLabDepartments(value);
  };

  getUsers = (list: Array<User>, lab: string) => {
    const newList = list.filter(
      (user: User) =>
        (user.groups &&
          user.groups.find(
            (group: Group) =>
              group.group_type === 'lab' && group.lab_name === lab,
          )) ||
        (user.lab_admins &&
          user.lab_admins.find(
            (group: Common.LabAdmin) => group.full_lab_name === lab,
          )),
    );

    return newList;
  };

  getPendingUsers = (list: Array<User>, lab: string) => {
    const newList = list.filter((user: User) => user.lab && user.lab === lab);

    return newList;
  };

  render() {
    const { classes } = this.props;
    const { chosenLab } = this.state;

    const {
      usersStore: { fetching, saving, listOfUsers, listOfPendingUsers },
      labsStore: { isLoading, labsList },
      authStore: { isAdmin, userLabs },
    } = this.injected;

    if (fetching || saving || isLoading) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress size={75} />
        </div>
      );
    }

    let laboratories = [];
    if (isAdmin) {
      laboratories = labsList.map((lab: Common.Lab) => lab.path);
    } else {
      laboratories = userLabs || [];
    }

    const chosenLaboratory = chosenLab || laboratories[0];
    const users = this.getUsers(listOfUsers, chosenLaboratory);
    const pendingUsers = this.getPendingUsers(
      listOfPendingUsers,
      chosenLaboratory,
    );

    return (
      <div className={classes.root}>
        {laboratories.length ? (
          <>
            <div className={classes.tabContainer}>
              <Tabs
                value={chosenLaboratory}
                onChange={this.handleLabChange}
                classes={{
                  root: classes.tabs,
                  flexContainer: classes.flexContainer,
                  indicator: classes.tabIndicator,
                }}
              >
                {laboratories.map((lab: string) => {
                  return (
                    <Tab
                      key={lab}
                      label={lab}
                      value={lab}
                      classes={{
                        root: classes.tab,
                        labelContainer: classes.tabLabel,
                        selected: classes.selectedTab,
                      }}
                    />
                  );
                })}
              </Tabs>
            </div>

            <div>
              <Base
                listOfUsers={users}
                listOfPendingUsers={pendingUsers}
                labsList={laboratories}
                chosenLab={chosenLaboratory}
                labView
              />
            </div>
          </>
        ) : (
          <span>No labs found</span>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Labs);
