import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import NProgress from 'nprogress';

import authStore from '../../stores/authStore';
import appStore from '../../stores/appStore';

function onResponseSuccess(response: AxiosResponse) {
  appStore.setLoading(false);
  NProgress.done();

  return response;
}

function onResponseError(error: AxiosError) {
  appStore.setLoading(false);
  NProgress.done();

  if (error.response && error.response.status === 401) {
    authStore.logOut();
  }

  return Promise.reject(error.response);
}

function onRequest(config: AxiosRequestConfig) {
  NProgress.start();
  appStore.setLoading(true);

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const token = user.token || '';

  if (!config.headers.Authorization) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return config;
}

const httpClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_BASE_URL || 'http://35.239.189.121',
    method: 'get',
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(onRequest);
  instance.interceptors.response.use(onResponseSuccess, onResponseError);

  return instance;
};

export default httpClient();
