import { observable, computed } from 'mobx';
import { action, toJS } from 'mobx';

import httpClient from 'utils/api/httpClient';

export class SelectedFilesStore {
  private endpoints = {
    move: 'api/v1/resources/move',
    delete: 'api/v1/resources/delete',
  };

  @observable
  checkedFiles: Map<string, Common.File> = new Map();

  @observable
  clipBoard: string[] = [];

  @observable
  requestInProgress: boolean = false;

  @computed
  get isClipboardEmpty() {
    return !this.clipBoard.length;
  }

  @computed
  get numberOfCheckedFiles(): number {
    return this.checkedFiles.size;
  }

  @computed
  get checkedFilesMap(): Array<Common.File> {
    const newArray: Common.File[] = [];
    this.checkedFiles.forEach(value => {
      newArray.push(value);
    });
    return newArray;
  }

  @action
  clearCheckedList = () => {
    this.checkedFiles.clear();
    this.clipBoard = [];
  };

  @action
  checkFile = (entity: Common.File) => {
    const labFilePath = this.getFilePath(entity.uri);
    this.checkedFiles.set(labFilePath, entity);
  };

  @action
  uncheckFile = (entity: Common.File) => {
    const labFilePath = this.getFilePath(entity.uri);
    this.checkedFiles.delete(labFilePath);
  };

  @action
  setClipboard = () => {
    this.clipBoard = Object.keys(toJS(this.checkedFiles));
  };

  @action
  toggleFile = (entity: Common.File) => {
    const labFilePath = this.getFilePath(entity.uri);
    const checked = this.checkedFiles.has(labFilePath);

    if (checked) {
      this.checkedFiles.delete(labFilePath);
    } else {
      this.checkedFiles.set(labFilePath, entity);
    }
  };

  getFilePath(uri: string) {
    return decodeURIComponent(uri.split('/webdav/')[1]);
  }

  isChecked = (entity: Common.File) => {
    const labFilePath = this.getFilePath(entity.uri);
    return !!this.checkedFiles.has(labFilePath);
  };

  isCut = (entity: Common.File) => {
    const labFilePath = this.getFilePath(entity.uri);
    return this.clipBoard.includes(labFilePath);
  };

  async pasteFile(from: string, to: string) {
    const formData = new FormData();
    formData.append('source_path', from);
    formData.append('destination_path', to);

    return await httpClient.put(this.endpoints.move, formData);
  }

  async deleteFile(uri: string) {
    const params = {
      file_path: this.getFilePath(uri),
    };

    return await httpClient.put(this.endpoints.delete, params);
  }

  deleteAll = async () => {
    const files = Object.keys(toJS(this.checkedFiles));
    this.requestInProgress = true;

    while (!!files.length) {
      const fileUri = files.pop() || '';
      const file = this.checkedFiles.get(fileUri);
      await this.deleteFile(file!.uri);
    }

    this.clearCheckedList();
    this.requestInProgress = false;
  };
}

export default new SelectedFilesStore();
