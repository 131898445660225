import React, { useState, useCallback, useEffect, memo } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import debounce from 'lodash-es/debounce';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import CTViewerTabs from '../../../../components/ui/CTViewerTabs';
import CTViewerTab from '../../../../components/ui/ctViewerTabs/Tab';
import ImageViewer from './singleViewer/ImageViewer';
import Results from './singleViewer/Results';
import CropDetails from './singleViewer/CropDetails';

import findViewerSliceImages from '../../../../utils/findViewerSliceImages';
import { CTViewerStore } from '../../../../stores/ctViewerStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '95%',
      minWidth: '95%',
      margin: '0 auto',
      backgroundColor: theme.palette.common.white,
    },
    pane: {
      border: '1px solid rgb(224, 224, 224)',
      borderTop: 'none',
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '100px 0',
    },
    processing: {
      marginTop: '20px',
      borderRadius: '6px',
      padding: '4px 14px',
      background: '#e8e8e8',
    },
    processingText: {
      fontSize: '1rem',
    },
    processingError: {
      background: '#da6060',
    },
    processingTextError: {
      color: '#fff',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
  slicesGenerated: boolean;
}
//#endregion

const SingleViewer: React.FunctionComponent<Props> = ({
  classes,
  slicesGenerated,
  ctViewerStore,
}) => {
  const [tabValue, setTabValue] = useState<string>('image');
  const [firstRender, setFirstRender] = useState<boolean>(false);
  const [axialLoading, setAxialLoading] = useState<boolean>(false);
  const [corSagLoading, setCorSagLoading] = useState<boolean>(false);
  const [contrastSwitcher, setContrastSwitcher] = useState<boolean>(false);
  const [axialPreviousData, setAxialPreviousData] = useState<any>(undefined);
  const [imageAxialData, setImageAxialData] = useState<any>(undefined);
  const [imageAxialParams, setImageAxialParams] = useState<any>(undefined);
  const [imageSagittalData, setImageSagittalData] = useState<any>(undefined);
  const [imageSagittalParams, setImageSagittalParams] = useState<any>(
    undefined,
  );
  const [imageCoronalData, setImageCoronalData] = useState<any>(undefined);
  const [imageCoronalParams, setImageCoronalParams] = useState<any>(undefined);

  let changeTimeout: NodeJS.Timeout;

  const axialDataEmpty =
    imageAxialData === undefined && imageAxialParams === undefined;
  const sagittalDataEmpty =
    imageSagittalData === undefined && imageSagittalParams === undefined;
  const coronalDataEmpty =
    imageCoronalData === undefined && imageCoronalParams === undefined;

  const viewerTabs = [
    {
      label: 'Image',
      value: 'image',
    },
    {
      label: 'Results',
      value: 'results',
    },
  ];

  const onHandleTabChange = (
    event: React.ChangeEvent<{}>,
    tabValue: string,
  ) => {
    setTabValue(tabValue);
  };

  const waitForResponse = () => {
    const { socket } = ctViewerStore!;
    socket.on('dataResponse', (buffer: any, type: string) => {
      const resultArray = new Int16Array(buffer);

      if (type === 'axial') {
        setImageAxialData(resultArray);
      } else if (type === 'sagittal') {
        setImageSagittalData(resultArray);
      } else {
        setImageCoronalData(resultArray);
      }
    });

    socket.on('paramsResponse', (data: any, type: string) => {
      if (type === 'axial') {
        setImageAxialParams(data);
      } else if (type === 'sagittal') {
        setImageSagittalParams(data);
      } else {
        setImageCoronalParams(data);
      }
    });
  };

  const getAllData = (connection: boolean) => {
    if (connection) {
      if (
        !firstRender &&
        axialDataEmpty &&
        sagittalDataEmpty &&
        coronalDataEmpty
      ) {
        setFirstRender(true);
      }
      setAxialLoading(true);
      setCorSagLoading(true);

      clearTimeout(changeTimeout);
      changeTimeout = setTimeout(() => {
        const { socket } = ctViewerStore!;
        const axialSlice = Math.floor(ctViewerStore!.resultLength / 2);
        socket.emit('imageRequest', {
          type: 'axial',
          index: axialSlice > 0 ? axialSlice : 1,
          path: ctViewerStore!.pathToImages,
        });
        socket.emit('imageRequest', {
          type: 'coronal',
          index: 1,
          path: ctViewerStore!.pathToImages,
        });
        socket.emit('imageRequest', {
          type: 'sagittal',
          index: 1,
          path: ctViewerStore!.pathToImages,
        });
      }, 400);
    }
  };

  const getCoronalAndSagittalData = (connection: boolean) => {
    if (connection) {
      setCorSagLoading(true);

      clearTimeout(changeTimeout);
      changeTimeout = setTimeout(() => {
        const { socket } = ctViewerStore!;
        const { xIndex, yIndex } = findViewerSliceImages(
          ctViewerStore!.coronalImage!.width,
          ctViewerStore!.cropCenterX!,
          ctViewerStore!.cropCenterY!,
        );
        socket.emit('imageRequest', {
          type: 'coronal',
          index: Math.min(yIndex, ctViewerStore!.resultLength),
          path: ctViewerStore!.pathToImages,
        });
        socket.emit('imageRequest', {
          type: 'sagittal',
          index: Math.min(xIndex, ctViewerStore!.resultLength),
          path: ctViewerStore!.pathToImages,
        });
      }, 400);
    }
  };

  const getAxialData = (connection: boolean) => {
    if (connection) {
      setAxialLoading(true);

      clearTimeout(changeTimeout);
      changeTimeout = setTimeout(() => {
        const { socket } = ctViewerStore!;
        socket.emit('imageRequest', {
          type: 'axial',
          index: Math.min(
            ctViewerStore!.displayedSliceHeight!,
            ctViewerStore!.resultLength,
          ),
          path: ctViewerStore!.pathToImages,
        });
      }, 400);
    }
  };

  const debounceGetCoronalAndSagittalData = useCallback(
    debounce(
      () => getCoronalAndSagittalData(ctViewerStore!.serviceConnection),
      200,
    ),
    [ctViewerStore!.serviceConnection],
  );

  const debounceGetAxialData = useCallback(
    debounce(() => getAxialData(ctViewerStore!.serviceConnection), 200),
    [ctViewerStore!.serviceConnection],
  );

  useEffect(() => {
    if (ctViewerStore!.cropCenterX && ctViewerStore!.cropCenterY) {
      debounceGetCoronalAndSagittalData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctViewerStore!.cropCenterX, ctViewerStore!.cropCenterY]);

  useEffect(() => {
    if (
      ctViewerStore!.serviceConnection &&
      !ctViewerStore!.serviceInitialData &&
      slicesGenerated
    ) {
      ctViewerStore!.setServiceInitialData(true);
      waitForResponse();
      setTabValue('image');
      getAllData(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slicesGenerated]);

  useEffect(() => {
    if (!ctViewerStore!.serviceConnection) {
      setAxialLoading(false);
      setCorSagLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctViewerStore!.serviceConnection]);

  useEffect(() => {
    if (axialPreviousData) {
      debounceGetAxialData();
    }
    setAxialPreviousData(ctViewerStore!.displayedSliceHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctViewerStore!.displayedSliceHeight]);

  useEffect(() => {
    if (
      imageAxialData &&
      imageAxialParams &&
      imageSagittalData &&
      imageSagittalParams &&
      imageCoronalData &&
      imageCoronalParams
    ) {
      setAxialLoading(false);
      setCorSagLoading(false);
      firstRender && setFirstRender(false);
      !contrastSwitcher && setContrastSwitcher(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    imageAxialData,
    imageAxialParams,
    imageSagittalData,
    imageSagittalParams,
    imageCoronalData,
    imageCoronalParams,
  ]);

  return (
    <div className={classes.root}>
      <CTViewerTabs tabValue={tabValue} onTabChange={onHandleTabChange}>
        {viewerTabs.map((tab: any) => (
          <CTViewerTab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </CTViewerTabs>
      <div className={classes.pane}>
        {ctViewerStore!.processingError ? (
          <div className={classes.loadingContainer}>
            <div
              className={classNames(
                classes.processing,
                classes.processingError,
              )}
            >
              <Typography
                variant="subtitle1"
                component="strong"
                className={classNames(
                  classes.processingText,
                  classes.processingTextError,
                )}
              >
                Assets generation failure
              </Typography>
            </div>
          </div>
        ) : (
          <>
            {ctViewerStore!.serviceInitialData ? (
              <>
                {tabValue === 'image' ? (
                  <>
                    <ImageViewer
                      imageCoronalData={imageCoronalData}
                      imageCoronalParams={imageCoronalParams}
                      imageSagittalData={imageSagittalData}
                      imageSagittalParams={imageSagittalParams}
                      imageAxialData={imageAxialData}
                      imageAxialParams={imageAxialParams}
                      axialLoading={axialLoading}
                      corSagLoading={corSagLoading}
                      firstRender={firstRender}
                      contrastSwitcher={contrastSwitcher}
                    />
                    {contrastSwitcher && <CropDetails />}
                  </>
                ) : (
                  <Results />
                )}
              </>
            ) : (
              <div className={classes.loadingContainer}>
                <CircularProgress size={50} />
                <div className={classes.processing}>
                  <Typography
                    variant="subtitle1"
                    component="strong"
                    className={classes.processingText}
                  >
                    Generating assets...
                  </Typography>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(
  withStyles(styles)(inject('ctViewerStore')(observer(SingleViewer))),
);
