import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 20,
      boxShadow: '-1px 3px 6px 0 rgba(183, 190, 198, 0.36)',
      backgroundColor: theme.palette.primary.main,

      color: theme.palette.common.white,

      border: `1px solid ${theme.palette.primary.main}`,
      padding: '8px 12px',

      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
      },
    },
    outlined: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,

      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
    disabled: {
      backgroundColor: '#c7c7c8',
      color: theme.palette.common.white,
    },
    buttonText: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  width?: string | number;
  isLoading?: boolean;
  variant?: string;
}
//#endregion

const Button: React.FunctionComponent<Props & ButtonBaseProps> = ({
  children,
  width = '100%',
  isLoading = false,
  variant = 'button',
  classes,
  ...rest
}) => {
  return (
    <ButtonBase
      classes={{
        root: classNames(classes.root, {
          [classes.outlined]: variant === 'outlined',
        }),
        disabled: classes.disabled,
      }}
      style={{
        minWidth: width,
      }}
      {...rest}
    >
      {isLoading && (
        <CircularProgress variant="indeterminate" color="primary" size={20} />
      )}

      {!isLoading && (
        <Typography variant="button" color="inherit">
          {children}
        </Typography>
      )}
    </ButtonBase>
  );
};

export default withStyles(styles)(Button);
