import React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    button: {
      margin: 0,
      minWidth: 0,
      fontWeight: 'normal',
      fontSize: 13,
      paddingLeft: 2,
      paddingRight: 2,
    },
    groups: {
      padding: 6,
    },
    groupLabel: {
      marginTop: 2,
      marginRight: 4,
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
    groupLabelSpan: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 240,
      display: 'inline-block',
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  open: boolean;
  anchorEl: (EventTarget & Element) | null;
  handlePopup: (event: React.MouseEvent) => void;
  groups: (string | undefined)[];
  formattedGroups: string | JSX.Element;
}
//#endregion

const ContextMenu: React.FunctionComponent<Props> = ({
  open,
  anchorEl,
  handlePopup,
  groups,
  formattedGroups,
  classes,
}: Props) => (
  <>
    <Button className={classes.button} onClick={handlePopup}>
      {formattedGroups}
    </Button>
    <Popper
      open={open}
      placement="bottom"
      disablePortal={false}
      anchorEl={anchorEl}
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
        arrow: {
          enabled: true,
        },
      }}
    >
      <Paper className={classes.groups}>
        {groups && groups.length
          ? groups.map((group: string | undefined) => (
              <Chip
                key={group}
                className={classes.groupLabel}
                label={group}
                classes={{
                  label: classes.groupLabelSpan,
                }}
              />
            ))
          : 'None'}
      </Paper>
    </Popper>
  </>
);

export default withStyles(styles)(ContextMenu);
