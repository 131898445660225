import sort from 'fast-sort';
import axios from 'axios';
import { action, computed, flow, observable } from 'mobx';
import get from 'lodash-es/get';
import httpClient from 'utils/api/httpClient';

export const SHARED_WITH_ME_PREFIX = 'Shared with me';
export const MY_FILES_PREFIX = 'My Files';
export const FILES_PARALLEL = 5;

export interface TempFile {
  file: File;
  name: string;
  path?: string;
  exist: boolean;
  uploadProgress: number;
  uploaded: boolean;
}

export class FilesStore {
  private endpoints = {
    createFolder: 'api/v1/resources/create_folder',
    files: 'api/v1/resources/files',
    uploadFile: 'api/v1/resources/upload_file',
    resourceShares: 'api/v1/resources/get_shares',
    getUserShares: 'api/v1/resources/get_user_shares',
    shareResource: 'api/v1/resources/share',
    shareResourceGroup: 'api/v1/resources/share_with_group',
    removeShare: 'api/v1/resources/delete_share',
  };

  // true if we are browsing "virtual lab" - files shared with us
  // use to trim out prefix SHARED_WITH_ME_PREFIX
  @observable
  sharedFilesContext = false;

  @observable
  myFilesContext = false;

  @observable
  viewType = '';

  @observable
  pathToUpload = '';

  @observable
  currentPath = '';

  @observable
  files: Common.File[] = [];

  @observable
  isLoading = false;

  @observable
  uploadingModal = false;

  @observable
  requestInProgress = false;

  @observable
  fetchingResources = false;

  @observable
  requestError = false;

  @observable
  filesToUpload: TempFile[] = [];

  @observable
  uploadingFiles = false;

  @observable
  fileUploadSuccessAction: Function = () => {};

  @observable
  fileUploadFailedAction: Function = () => {};

  @observable
  fileUploadAborted = false;

  @observable
  uploadWarning = '';

  @observable
  datasetName = '';

  @computed
  get hasToRetryUpload(): boolean {
    return !this.uploadingFiles && !!this.filesToRetryUpload.length;
  }

  @computed
  get filesToRetryUpload(): TempFile[] {
    return this.filesToUpload.filter((file: TempFile) => !file.uploaded);
  }

  @computed
  get filesToUploadAmount(): number {
    return this.filesToUpload.length;
  }

  @computed
  get uploadedFilesAmount(): number {
    return this.filesToUpload.filter((file: TempFile) => file.uploaded).length;
  }

  @computed
  get filesUploadProgress(): number {
    const overallProgress =
      (this.uploadedFilesAmount / this.filesToUploadAmount) * 100 || 0;

    return Math.round(overallProgress);
  }

  @computed
  get uploaded(): boolean {
    return this.filesUploadProgress === 100;
  }

  getRealPath = (path: string) => {
    const newPath = this.viewType === 'project' ? `Projects/${path}` : path;

    if (this.sharedFilesContext) {
      return newPath.slice(SHARED_WITH_ME_PREFIX.length + 1);
    }

    return newPath;
  };

  getRealPathForUpload = (path: string) => {
    if (this.sharedFilesContext) {
      return path.slice(SHARED_WITH_ME_PREFIX.length + 1);
    }

    return path;
  };

  async createFolder(folderName: string) {
    return await httpClient.post(this.endpoints.createFolder, {
      folder_path: this.getRealPath(folderName.trim()),
    });
  }

  @action
  setDatasetName = (name: string) => {
    this.datasetName = name;
  };

  @action
  uploadFiles = flow(function*(
    this: FilesStore,
    files: TempFile[],
    onUploadSuccess: Function = () => {},
    onUploadFailed: Function = () => {},
  ) {
    this.filesToUpload = files;
    this.uploadingFiles = true;
    this.fileUploadSuccessAction = onUploadSuccess;
    this.fileUploadFailedAction = onUploadFailed;

    let promisesBuffer = [];
    const chunks: TempFile[][] = this.chunkFiles(files, FILES_PARALLEL);

    for (let i = 0; i < chunks.length; i++) {
      if (this.fileUploadAborted) {
        break;
      }

      const tempFiles = chunks[i];

      for (let j = 0; j < tempFiles.length; j++) {
        if (this.fileUploadAborted) {
          break;
        }

        const tempFile: TempFile = tempFiles[j];
        promisesBuffer.push(this.getFilePromise(tempFile));
      }

      try {
        yield Promise.all(promisesBuffer);
      } catch (error) {}

      promisesBuffer = [];
    }

    if (this.fileUploadAborted) {
      this.clearAfterUpload();
    } else {
      if (this.hasToRetryUpload) {
        onUploadFailed();
      } else {
        onUploadSuccess();
        this.closeFileUpload();
      }
      this.refresh();
    }
  });

  chunkFiles = (files: TempFile[], chunkMaxLength: number) => {
    let filesToUploadArray: TempFile[][] = [];
    let chunk: TempFile[] = [];

    files.forEach((file: TempFile) => {
      chunk.push(file);

      if (chunk.length === chunkMaxLength) {
        filesToUploadArray.push(chunk);
        chunk = [];
      }
    });

    if (chunk.length) {
      filesToUploadArray.push(chunk);
    }

    return filesToUploadArray;
  };

  async getFilePromise(file: TempFile) {
    const formData = this.setFormData(file);
    this.setFileProgress(file, 0);

    return httpClient
      .post(this.endpoints.uploadFile, formData, {
        onUploadProgress: progressEvent => {
          const progress = progressEvent.loaded / progressEvent.total;
          if (progress < 1) {
            this.setFileProgress(file, progress);
          }
        },
      })
      .then(() => this.setFileProgress(file, 1))
      .catch(e => e);
  }

  setFormData = (tempFile: TempFile): FormData => {
    const formData = new FormData();
    const file: File = tempFile.file;
    const path = tempFile.path || this.getRealPathForUpload(this.pathToUpload);

    formData.append('folder_path', path);
    formData.append('file_name', file.name);
    formData.append('file', file);

    return formData;
  };

  setFileProgress = (file: TempFile, value: number) => {
    const index = this.filesToUpload.findIndex(
      (f: TempFile) => f.name === file.name && f.path === file.path,
    );

    const tempFile = this.filesToUpload[index];
    tempFile.uploadProgress = value * 100;

    if (value === 1) {
      tempFile.uploaded = true;
    }

    this.filesToUpload[index] = tempFile;
  };

  clearAfterUpload() {
    this.setUploadPath('');
    this.setDatasetName('');
    this.filesToUpload = [];
    this.uploadingFiles = false;
    this.fileUploadAborted = false;
  }

  @action
  retryUpload = () => {
    this.uploadFiles(
      this.filesToRetryUpload,
      this.fileUploadSuccessAction,
      this.fileUploadFailedAction,
    );
  };

  async getResourceShares(this: FilesStore, path: string) {
    try {
      this.fetchingResources = true;
      const response = await httpClient.get(this.endpoints.resourceShares, {
        params: {
          path: this.getRealPath(path),
        },
      });

      const {
        data: {
          ocs: { data: sharesData },
        },
      } = response;

      // remove lab prefix from shared groups
      const noPrefixData = sharesData.map((record: any) => {
        const isGroup = record.share_type === 1;
        const labName = record.path.split('/')[1];
        const sliceFactor =
          record.share_with.lastIndexOf(`${labName}:`, 0) === 0
            ? labName.length + 1
            : labName.length;

        if (isGroup) {
          return {
            ...record,
            share_with: record.share_with.slice(sliceFactor),
          };
        }

        return record;
      });

      this.fetchingResources = false;
      return { success: true, data: noPrefixData };
    } catch (error) {
      this.fetchingResources = false;
      return { success: false };
    }
  }

  @action.bound
  setRequestInProgress(value: boolean) {
    this.requestInProgress = value;
  }

  @action.bound
  closeFileUpload() {
    this.fileUploadAborted = true;

    if (this.hasToRetryUpload) {
      this.clearAfterUpload();
    } else if (this.uploaded) {
      this.clearAfterUpload();
      this.fileUploadSuccessAction();
    }
  }

  @action.bound
  setViewType(value: string) {
    this.viewType = value;
  }

  @action.bound
  setUploadPath(value: string) {
    this.pathToUpload = value;
  }

  @action
  refresh = async () => {
    this.getAllFiles(this.currentPath);
  };

  @action
  clear = () => {
    this.files = [];
    this.viewType = '';
  };

  @action
  setFiles = (files: Common.File[]) => {
    this.files = files;
  };

  @action
  getAllFiles = async (path: string) => {
    const sharedWithMeFiles = path.startsWith(SHARED_WITH_ME_PREFIX);
    this.requestError = false;
    this.currentPath = path;
    this.sharedFilesContext = sharedWithMeFiles;
    this.myFilesContext = path.startsWith(MY_FILES_PREFIX);
    this.requestInProgress = true;

    try {
      const { data } = await httpClient.get(this.endpoints.files, {
        params: {
          folder_path: this.getRealPath(path),
          ...(sharedWithMeFiles && { root_path: true }),
        },
        // @ts-ignore
        transformResponse: axios.defaults.transformResponse.concat(function(
          data: any,
        ) {
          if (sharedWithMeFiles) {
            return data.filter(
              (entity: Common.File) =>
                !entity.lab &&
                entity.path !== 'My Files' &&
                entity.path !== 'Projects',
            );
          }

          return data;
        }),
      });

      if (this.sharedFilesContext) {
        this.appendFileSharedBy();
      }

      this.setFiles(sort(data).asc(['type', 'path']));
      this.requestInProgress = false;
    } catch {
      this.requestError = true;
      this.requestInProgress = false;
    }
  };

  @action
  getFilesStructureFromPath = async (path: string) => {
    const { data } = await httpClient.get(this.endpoints.files, {
      params: {
        folder_path: this.getRealPath(path),
      },
      // @ts-ignore
      transformResponse: axios.defaults.transformResponse.concat(function(
        data: any,
      ) {
        return data;
      }),
    });

    return data;
  };

  @action
  appendFileSharedBy = async () => {
    const { data } = await httpClient.get(this.endpoints.getUserShares);

    const getFileSharedByValue = (filepath: string) => {
      const sharedInfo = data.ocs.data.find(
        (record: any) => record.file_target === `/${filepath}`,
      );
      return sharedInfo ? sharedInfo.displayname_owner : '';
    };

    this.files = this.files.map((file: Common.File) => {
      return {
        ...file,
        sharedBy: getFileSharedByValue(file.path),
      };
    });
  };

  async setResourceShare(
    this: FilesStore,
    path: string,
    target: string,
    group: boolean = false,
  ) {
    const url = group
      ? this.endpoints.shareResourceGroup
      : this.endpoints.shareResource;
    const groupPrefix = path.split('/')[0];

    const preffixedTarget = group ? `${groupPrefix}:${target}` : target;

    const params = {
      path,
      [group ? 'group' : 'username']: preffixedTarget,
    };

    try {
      const response = await httpClient.put(url, params);

      const statuscode = get(response, 'data.meta.statuscode', 404);
      const success = statuscode === 100 || statuscode === 200;

      return { success };
    } catch (e) {
      return { success: false };
    }
  }

  @action
  removeResourceShare = flow(function*(
    this: FilesStore,
    path: string,
    value: string,
    isGroup: boolean,
  ) {
    try {
      const labName = path.split('/')[0];
      const preffixedValue = isGroup ? `${labName}:${value}` : value;

      const params = {
        path,
        [isGroup ? 'group' : 'username']: preffixedValue,
      };

      yield httpClient.put(this.endpoints.removeShare, params);
    } catch (error) {
      return { success: false };
    }
  });
}

export default new FilesStore();
