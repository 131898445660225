import React, { Component } from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Button from '../../../components/ui/Button';

//#region Styles
const styles = () =>
  createStyles({
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
    },
    actions: {
      justifyContent: 'flex-start',
      padding: '0 24px 24px',
      marginLeft: 0,
      marginRight: 0,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  selectedUser: Common.UserData | null;
  opened: boolean;
  inProgress?: boolean;
  fetching?: boolean;
  invitation?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}
//#endregion

class ConfirmationModal extends Component<Props> {
  render() {
    const {
      opened,
      inProgress = false,
      selectedUser,
      onConfirm,
      onCancel,
      fetching,
      invitation,
      classes,
    } = this.props;

    let title = `Remove ${
      selectedUser ? selectedUser.full_name : 'selected user'
    }`;
    let content = `Following action will remove ${
      selectedUser ? selectedUser.full_name : 'selected user'
    }, continue?`;

    if (invitation) {
      title = `Remove invitation for ${
        selectedUser ? selectedUser.full_name : 'selected user'
      }`;
      content = `Following action will remove an invitation for ${
        selectedUser ? selectedUser.full_name : 'selected user'
      }, continue?`;
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={opened}
      >
        {fetching ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={75} />
          </div>
        ) : (
          <>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <Typography>{content}</Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button
                width={100}
                onClick={onCancel}
                color="primary"
                disabled={inProgress}
              >
                Cancel
              </Button>
              <Button
                width={100}
                onClick={onConfirm}
                color="primary"
                disabled={inProgress}
              >
                Ok
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmationModal);
