import * as React from 'react';
import { Provider, observer } from 'mobx-react';
import { Router, Redirect } from '@reach/router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';

import withRoot from './utils/withRoot';
import Labs from './pages/Labs';
import Lab from './pages/Lab';
import Project from './pages/Project';
import Users from './pages/Users';
import AcceptInvite from './pages/AcceptInvite';
import PasswordReset from './pages/PasswordReset';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/layout/Layout';
import SnackbarMessage from './components/ui/SnackbarMessage';

import appStore from './stores/appStore';

import projectsStore from './stores/projectsStore';
import filesStore from './stores/files/filesStore';
import authStore from './stores/authStore';
import selectedFilesStore from './stores/files/selectedFilesStore';
import labsStore from './stores/labsStore';
import groupsStore from './stores/groupsStore';
import usersStore from './stores/usersStore';
import ctViewerStore from './stores/ctViewerStore';

import './assets/styles/nprogress.css';
import downloadStore from './stores/files/downloadStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      body: {
        margin: 0,
        padding: 0,
      },
      '#root': {
        padding: '0',
        width: '100%',
        backgroundColor: '#F8FAFA',
      },
      '#nprogress': {
        position: 'absolute',
        zIndex: 10000,
      },
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    spinnerContainer: {
      height: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {}
//#endregion

const stores = {
  appStore,
  authStore,
  selectedFilesStore,
  labsStore,
  groupsStore,
  usersStore,
  projectsStore,
  filesStore,
  downloadStore,
  ctViewerStore,
};

@observer
class App extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    const { isLoading } = authStore;

    return (
      <Provider {...stores}>
        <div className={classes.root}>
          {isLoading ? (
            <div className={classes.spinnerContainer}>
              <Typography variant="h1">Logging In...</Typography>
            </div>
          ) : (
            <Layout>
              <Router>
                <Redirect noThrow from="/" to="/labs" />
                <AcceptInvite path="/accept-invite" />
                <PasswordReset path="/password-reset" />
                <PrivateRoute as={Labs} default path="/labs" />
                <PrivateRoute as={Lab} path="/labs/:id/*" />
                <PrivateRoute as={Users} adminOnly path="/users" />
                <PrivateRoute as={Project} path="/projects/:id/*" />
              </Router>
            </Layout>
          )}

          <SnackbarMessage
            opened={appStore.toaster.opened}
            variant={appStore.toaster.variang}
            message={appStore.toaster.message}
            onClose={appStore.hideToaster}
          />
        </div>
      </Provider>
    );
  }
}

export default withRoot(withStyles(styles)(App));
