import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import is from 'is_js';
import { toJS } from 'mobx';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '../../../components/ui/Button';

//#region Styles
const styles = () =>
  createStyles({
    root: {},
    createLabButton: {
      marginTop: 40,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 30,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  onSave: (values: Common.UserPayload) => void;
  handleLabChange: (value: string) => void;
  inProgress: boolean;
  fetching: boolean;
  departmentsList: string[];
  selectedUser?: Common.UserData | null;
  labs: string[];
  isLabAdmin?: boolean;
  chosenLab?: string;
}
//#endregion

// Component
const InviteUser: React.FunctionComponent<Props> = ({
  onSave,
  inProgress,
  departmentsList,
  labs,
  selectedUser,
  fetching,
  handleLabChange,
  isLabAdmin,
  chosenLab,
  classes,
}) => {
  const isIE = is.ie();
  const departments = selectedUser ? toJS(selectedUser.departments) : undefined;
  const lab = chosenLab
    ? chosenLab
    : selectedUser && selectedUser.lab
    ? selectedUser.lab
    : '';

  return (
    <Formik
      initialValues={{
        fullName: selectedUser ? selectedUser.full_name : '',
        email: selectedUser ? selectedUser.email : '',
        lab: lab || '',
        departments: departments || [],
      }}
      onSubmit={(values: Common.UserPayload) => onSave(values)}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required('Invalid e-mail format'),
        fullName: Yup.string().required('Full name is required'),
        lab: Yup.string(),
        departments: Yup.array(Yup.string()),
      })}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              required
              margin="normal"
              fullWidth
              label="Full name"
              id="fullName"
              name="fullName"
              error={!!errors.fullName}
              helperText={errors.fullName || ''}
              onChange={handleChange}
              value={values.fullName}
            />

            <TextField
              required
              margin="normal"
              fullWidth
              label="E-mail"
              id="email"
              name="email"
              error={!!errors.email}
              helperText={errors.email || ''}
              onChange={handleChange}
              value={values.email}
            />

            {isIE === false ? (
              <>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!(errors.lab && touched.lab)}
                  disabled={isLabAdmin}
                >
                  <InputLabel htmlFor="select-labs">Lab Name</InputLabel>
                  <Select
                    fullWidth
                    value={values.lab}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      handleLabChange(event.target.value);
                      return handleChange(event);
                    }}
                    input={<Input name="lab" id="select-labs" />}
                  >
                    {labs.map(lab => (
                      <MenuItem key={lab} value={lab}>
                        {lab}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.lab && <FormHelperText>{errors.lab}</FormHelperText>}
                </FormControl>

                {fetching ? (
                  <div className={classes.loadingContainer}>
                    <CircularProgress size={50} />
                  </div>
                ) : (
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={!!(errors.departments && touched.departments)}
                  >
                    <InputLabel htmlFor="select-departments">
                      Departments
                    </InputLabel>
                    <Select
                      multiple
                      fullWidth
                      disabled={!departmentsList.length}
                      value={values.departments}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                        handleChange(event)
                      }
                      input={
                        <Input name="departments" id="select-departments" />
                      }
                    >
                      {departmentsList.map(department => (
                        <MenuItem key={department} value={department}>
                          {department}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.departments && (
                      <FormHelperText>{errors.departments}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </>
            ) : (
              <Typography variant="body1" style={{ paddingTop: 16 }}>
                Departments and labs assign not supported for IE 11
              </Typography>
            )}

            <Button
              disabled={inProgress}
              type="submit"
              className={classes.createLabButton}
            >
              {inProgress ? 'Sending...' : 'Confirm'}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(styles)(InviteUser);
