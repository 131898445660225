export const topMarkerHeight = 45;
export const leftMarkerWidth = 60;
export const bottomMarkerHeight = 40;
export const bottomLineHeight = 32;
export const rightPadding = 55;

export const leftTextOffset = 5;
export const sampleNameOffset = 50;
export const topTextOffset = 4;
export const topTextOffsetSecondLine = 20;
export const rulerLeftLineLength = 13;
export const markerTextOffset = 2;

// Ruler line sizes:
export const largeLineWidth = 20;
export const mediumLineWidth = 10;
export const smallLineWidth = 5;
export const markerTextVerticalOffset = 2;
export const rulerOffset = 2;

export const orientationMarkerHeight = 30;
