import React, { Component } from 'react';
import { navigate, Location } from '@reach/router';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Drawer from '@material-ui/core/Drawer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudIcon from '@material-ui/icons/Cloud';
import BuildIcon from '@material-ui/icons/Build';
import LocationIcon from '@material-ui/icons/LocationCity';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AuthStore } from '../../stores/authStore';
import { LabsStore } from '../../stores/labsStore';
import MenuOption from './leftMenu/MenuOption';
import Typography from '@material-ui/core/Typography';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 237,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 237,
      paddingTop: 30,
      paddingLeft: 30,
      borderRight: `1px dashed ${theme.palette.primary.main}`,
    },
    toolbar: {
      height: 68,
    },
    menu: {
      padding: 0,
      margin: 0,
    },
    myFileHeader: {
      marginTop: 25,
      marginBottom: 10,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  labName: string;
}

interface InjectedProps extends Props {
  authStore: AuthStore;
  labsStore: LabsStore;
}

interface State {
  isRootLab: boolean;
  hasError: boolean;
}
//#endregion

@inject('authStore', 'labsStore')
@observer
class LeftMenu extends Component<Props, State> {
  state = {
    isRootLab: false,
    hasError: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  mounted = false;

  async componentDidMount() {
    this.mounted = true;
    if (this.injected.labsStore.labs.length === 0) {
      try {
        await this.injected.labsStore.getLabsList();
        this.setIsLabInfo();
      } catch {
        this.hideMenuWhenErrorAppears();
      }
    } else {
      this.setIsLabInfo();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  hideMenuWhenErrorAppears() {
    if (this.mounted) {
      this.setState({
        hasError: true,
      });
    }
  }

  setIsLabInfo() {
    const isLab = Boolean(
      this.injected.labsStore.labsList.find(
        lab => lab.path === this.props.labName,
      ),
    );

    if (this.mounted) {
      this.setState({
        isRootLab: isLab,
      });
    }
  }

  goBack = () => {
    navigate('/labs');
  };

  navigateTo = (url: string) => {
    if (url.length > 0) {
      navigate(`/labs/${this.props.labName}/${url}`);
    } else {
      navigate(`/labs/${this.props.labName}`);
    }
  };

  isActive = (url: string) => {
    const pathName = window.location.pathname.replace('%20', ' ');
    const sections = ['settings', 'users', 'groups'];

    if (url.length > 0) {
      return pathName === `/labs/${this.props.labName}/${url}`;
    }

    const isSection = sections.some(section => pathName.endsWith(section));

    if (!isSection) {
      return true;
    }
  };

  render() {
    const { classes, labName } = this.props;
    const { isRootLab, hasError } = this.state;
    const { isAdmin, user } = this.injected.authStore;
    const { isLoading, labsList } = this.injected.labsStore;

    const isSharedWithMeContext = 'Shared with me' === this.props.labName;
    const isMyFilesContext = 'My Files' === this.props.labName;

    const isCustomLab = isSharedWithMeContext || isMyFilesContext;
    const isCurrentLabAdmin = (user.lab_admin_labs_full || []).includes(
      labName,
    );

    return (
      <Drawer
        variant="permanent"
        className={classes.root}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />

        {isLoading && !hasError ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={75} />
          </div>
        ) : (
          <nav className={classes.menu}>
            <Location>
              {() => (
                <>
                  {!hasError && !isSharedWithMeContext && !isMyFilesContext && (
                    <>
                      <MenuOption
                        icon={<ArrowBackIcon />}
                        onClicked={this.goBack}
                        isFirst
                      >
                        Lab List
                      </MenuOption>

                      {!isCustomLab && (
                        <MenuOption
                          icon={<CloudIcon />}
                          onClicked={() => this.navigateTo('')}
                          isActive={this.isActive('')}
                        >
                          {labName}
                        </MenuOption>
                      )}

                      {isAdmin && isRootLab && (
                        <MenuOption
                          icon={<BuildIcon />}
                          onClicked={() => this.navigateTo('settings')}
                          isActive={this.isActive('settings')}
                        >
                          Lab Settings
                        </MenuOption>
                      )}

                      {(isAdmin || isCurrentLabAdmin) && isRootLab && (
                        <MenuOption
                          icon={<AccountBoxIcon />}
                          onClicked={() => this.navigateTo('users')}
                          isActive={this.isActive('users')}
                        >
                          {isAdmin || isCurrentLabAdmin ? 'Users' : 'Contacts'}
                        </MenuOption>
                      )}

                      {isRootLab && (
                        <MenuOption
                          icon={<SupervisorAccountIcon />}
                          onClicked={() => this.navigateTo('groups')}
                          isActive={this.isActive('groups')}
                        >
                          My Groups
                        </MenuOption>
                      )}
                    </>
                  )}

                  <Typography
                    variant="subtitle1"
                    className={classes.myFileHeader}
                  >
                    Files
                  </Typography>

                  <MenuOption
                    icon={<CloudIcon />}
                    onClicked={() => navigate('/labs/My Files')}
                    isActive={isMyFilesContext}
                    variant="MY_FILES"
                  >
                    My Files
                  </MenuOption>
                  <MenuOption
                    icon={<ReplyAllIcon />}
                    onClicked={() => navigate('/labs/Shared with me')}
                    isActive={isSharedWithMeContext}
                    variant="SHARED"
                  >
                    Shared with me
                  </MenuOption>

                  {labsList.length > 0 && (
                    <>
                      <Typography
                        variant="subtitle1"
                        className={classes.myFileHeader}
                      >
                        My Labs
                      </Typography>

                      {labsList.map(lab => (
                        <MenuOption
                          key={lab.path}
                          icon={<LocationIcon />}
                          onClicked={() => navigate(`/labs/${lab.path}`)}
                          isActive={isMyFilesContext}
                        >
                          {lab.path}
                        </MenuOption>
                      ))}
                    </>
                  )}
                </>
              )}
            </Location>
          </nav>
        )}
      </Drawer>
    );
  }
}

export default withStyles(styles)(LeftMenu);
