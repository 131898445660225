import React, { Component } from 'react';
import classNames from 'classnames';
import { Link, RouteComponentProps } from '@reach/router';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { AppStore } from '../../stores/appStore';
import { LabsStore } from '../../stores/labsStore';
import { UsersStore } from '../../stores/usersStore';
import { SelectedFilesStore } from '../../stores/files/selectedFilesStore';
import { FilesStore } from '../../stores/files/filesStore';

import ButtonWithIcon from '../../components/ui/ButtonWithIcon';
import Modal from '../../components/ui/Modal';
import AddFolder from '../../components/ui/addFolder/AddFolder';
import FileUpload from '../../components/ui/FileUpload';
import TableHead from './directoryListing/TableHead';
import TableRow from './directoryListing/TableRow';
import ConfirmationModal from './../../components/ui/ConfirmationModal';
import { supportedFileExtensions } from '../../components/ui/lightbox/fileTypesSupport';
import Lightbox from '../../components/ui/lightbox/Lightbox';
import SelectedFilesActions from '../../components/SelectedFilesActions/SelectedFilesActions';
import { DownloadStore } from '../../stores/files/downloadStore';
import { getFileExtension } from 'utils/directoryUtils';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 104px)',
      position: 'relative',
      padding: '36px 36px 0',
    },
    actions: {
      marginTop: 24,
    },
    actionsItemsContainer: {
      minHeight: 80,
    },
    table: {
      marginTop: 30,
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 60,
    },
    noFiles: {
      paddingTop: '15px',
      paddingBottom: '15px',
    },
    breadCrumbs: {},
    breadCrumbsSeparator: {
      color: '#a7a7a7',
      fontSize: theme.typography.pxToRem(26),
      position: 'relative',
      top: 2,
    },
    pathPathLink: {
      color: theme.palette.text.primary,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
    },
    firstPathLink: {
      color: '#a7a7a7',
    },
    noFilesInFolder: {
      marginTop: 20,
    },
    fadeEnter: {
      opacity: 0.01,
    },
    fadeEnterActive: {
      opacity: 1,
      transition: 'opacity 500ms ease-in',
    },
    fadeExit: {
      opacity: 1,
    },
    fadeExitActive: {
      opacity: 0.01,
      transition: 'opacity 500ms ease-in',
    },
    rootPagination: {
      display: 'flex',
      justifyContent: 'center',
    },
    paginationActions: {
      margin: 0,
    },
  });
//#endregion

//#region Types
interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  id?: string; // comes from Router
  fullPath?: string;
}

interface State {
  page: number;
  rowsPerPage: number;
  selectedUri: string;
}

interface InjectedProps extends Props {
  appStore: AppStore;
  labsStore: LabsStore;
  usersStore: UsersStore;
  selectedFilesStore: SelectedFilesStore;
  filesStore: FilesStore;
  downloadStore: DownloadStore;
}
//#endregion

@inject(
  'appStore',
  'labsStore',
  'usersStore',
  'selectedFilesStore',
  'filesStore',
  'downloadStore',
)
@observer
class DirectoryListing extends Component<Props, State> {
  mounted: boolean = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 10,
      selectedUri: '',
    };
  }

  get injected() {
    return this.props as InjectedProps;
  }

  get fullPath() {
    const { location } = this.props;
    const fullPath = location ? location.pathname.split('/labs/')[1] : '';

    return decodeURIComponent(fullPath);
  }

  isEditable = () => {
    const isRootPath = this.isRootPath();
    const isMyFiles = this.fullPath === 'My Files';
    const {
      filesStore: { sharedFilesContext },
    } = this.injected;

    return !isRootPath || isMyFiles || sharedFilesContext;
  };

  isRootPath = () => !this.fullPath.includes('/');

  componentDidUpdate(prevProps: Props) {
    const fullPathChanged = prevProps.fullPath !== this.props.fullPath;

    if (fullPathChanged) {
      this.refresh();
      this.setState({ page: 0 });
    }
  }

  async componentDidMount() {
    const { usersStore, filesStore, selectedFilesStore } = this.injected;

    this.mounted = true;

    filesStore.clear();
    filesStore.setViewType('lab');
    selectedFilesStore.clearCheckedList();
    this.refresh();

    if (usersStore.users.length === 0) {
      await usersStore.getUsersList();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  refresh = () => {
    this.injected.filesStore.getAllFiles(this.fullPath);
  };

  renderPath() {
    const { classes } = this.props;
    const { currentPath } = this.injected.filesStore;
    const links: string[] = [];

    const getLast = () => (links.length ? links[links.length - 1] : '');
    const getCurrentFile = (path: string) => path.split('/').pop();

    currentPath.split('/').forEach(path => {
      const last = getLast();
      links.push(last ? `${last}/${path}` : path);
    });

    return (
      <Grid container alignItems="center" className={classes.breadCrumbs}>
        {links.map((path: string, i: number) => (
          <React.Fragment key={i}>
            <Grid item>
              <Link
                to={`/labs/${path}`}
                className={classNames(classes.pathPathLink, {
                  [classes.firstPathLink]: i === 0,
                })}
              >
                {i === 0 ? <HomeIcon /> : getCurrentFile(path)}
              </Link>
            </Grid>
            <Grid item>
              <KeyboardArrowRightIcon
                className={classes.breadCrumbsSeparator}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  }

  onDelete = async () => {
    const { selectedFilesStore } = this.injected;

    try {
      await selectedFilesStore.deleteAll();
      this.onActionComplete();
    } catch (error) {
      this.onActionError(error.data.message);
    }

    this.toggleDeleteConfirmation();
  };

  getConflictedFiles = () => {
    const { selectedFilesStore, filesStore } = this.injected;
    const { clipBoard } = selectedFilesStore;

    const currentDirectoryFileNames = filesStore.files.map(
      (file: Common.File) => file.path.split('/').pop() || '',
    );

    const isFileNameConflicted = (fileName: string) =>
      currentDirectoryFileNames.find((fn: string) => fn === fileName);

    return clipBoard
      .map(fileName => fileName.split('/').pop() || '')
      .filter((fileName: string) => isFileNameConflicted(fileName));
  };

  invokePaste = async () => {
    const { selectedFilesStore, filesStore } = this.injected;
    const { currentPath } = filesStore;

    const { clipBoard } = selectedFilesStore;

    this.toggleOverwriteConfimation(false);

    try {
      while (!!clipBoard.length) {
        const labFilePath = clipBoard.pop() || '';
        const filename = selectedFilesStore.checkedFiles.get(labFilePath)!.path;

        await selectedFilesStore.pasteFile(
          labFilePath,
          `${currentPath}/${filename}`,
        );
      }

      selectedFilesStore.checkedFiles.clear();

      this.onActionComplete();
    } catch (error) {
      this.onActionError(error.data.message);
    }
  };

  onPaste = () => {
    const conflictedFiles = this.getConflictedFiles();

    if (!!conflictedFiles.length) {
      this.toggleOverwriteConfimation(true);
      return;
    }

    this.invokePaste();
  };

  onActionComplete = async (overall = true) => {
    try {
      if (this.mounted) {
        this.injected.filesStore.refresh();
      }
      if (overall) {
        this.injected.appStore.showSuccessToaster();
      }
    } catch (error) {}
  };

  onActionError = (msg?: string) => {
    this.injected.appStore.showErrorToaster(msg);
  };

  toggleCreateFolder = () => {
    const opened = this.injected.appStore.modals.createFolderOpened;
    this.injected.appStore.modals.createFolderOpened = !opened;
  };

  toggleDeleteConfirmation = () => {
    const opened = this.injected.appStore.modals.deleteFolderOpened;
    this.injected.appStore.modals.deleteFolderOpened = !opened;
  };

  handleDownloadSelectedFiles = () => {
    const {
      selectedFilesStore: { checkedFilesMap },
    } = this.injected;

    this.injected.downloadStore.download(checkedFilesMap);
  };

  toggleOverwriteConfimation = (value: boolean) => {
    this.injected.appStore.toggleModal('conflictedPath', value);
  };

  toggleLightbox = async (file?: Common.File) => {
    const { appStore } = this.injected;
    const opened = appStore.modals.lightboxOpened;
    if (file && file.type === 'file') {
      const fileExtension = getFileExtension(file);

      if (!supportedFileExtensions.includes(fileExtension)) {
        return;
      }

      this.setState({
        selectedUri: file.uri,
      });
    }
    appStore.modals.lightboxOpened = !opened;
  };

  handleCreateFolder = async (folderData: { folderName: string }) => {
    const { filesStore } = this.injected;
    const { currentPath } = filesStore;
    const path = `/${currentPath}/${folderData.folderName}`;

    try {
      filesStore.setRequestInProgress(true);

      await filesStore.createFolder(path);

      filesStore.setRequestInProgress(false);

      await filesStore.refresh();

      this.toggleCreateFolder();
    } catch (error) {
      filesStore.setRequestInProgress(false);
      this.toggleCreateFolder();
    }
  };

  handleChangePage = (event: any, page: number) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { page, rowsPerPage } = this.state;
    const {
      selectedFilesStore,
      appStore: {
        modals: {
          createFolderOpened,
          deleteFolderOpened,
          conflictedPath,
          lightboxOpened,
        },
      },
      filesStore: {
        files,
        currentPath,
        requestInProgress,
        sharedFilesContext,
        requestError,
        uploadingFiles,
      },
      usersStore: { fetching },
    } = this.injected;

    const conflictedFilesListed = this.getConflictedFiles().join(', ');
    let differentItems = false;
    let lastItemType = '';
    let itemsType = 'item';

    selectedFilesStore.checkedFilesMap.forEach((file, index) => {
      if (index === 0) {
        lastItemType = file.type;
      } else {
        if (lastItemType !== file.type) {
          differentItems = true;
        }
      }
    });

    if (!differentItems) {
      if (lastItemType === 'directory') {
        itemsType = 'folder';
      } else {
        itemsType = lastItemType;
      }
    }

    if (requestError) {
      return (
        <div className={classes.root}>
          <Grid container spacing={32} justify="center" alignItems="center">
            <Grid item>
              <Typography variant="subtitle2">
                Problem with listing files from {currentPath}
              </Typography>
            </Grid>
          </Grid>
        </div>
      );
    }

    let directoryFiles = files.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );

    let lightBoxFiles = directoryFiles.filter(file => {
      const fileExtension = getFileExtension(file);
      return supportedFileExtensions.includes(fileExtension);
    });

    return (
      <div className={classes.root}>
        <Modal
          open={createFolderOpened}
          closeModal={this.toggleCreateFolder}
          title="Create folder"
        >
          <AddFolder
            presentFiles={files}
            inProgress={requestInProgress}
            onCreateFolder={this.handleCreateFolder}
          />
        </Modal>

        <ConfirmationModal
          opened={deleteFolderOpened}
          title="Files removal"
          question={`Following action will remove selected ${itemsType}(s), continue?`}
          onCancel={this.toggleDeleteConfirmation}
          onConfirm={this.onDelete}
          inProgress={selectedFilesStore.requestInProgress}
        />

        <ConfirmationModal
          opened={conflictedPath}
          title="Files overwrite?"
          question={`Following action will overwrite following file(s): ${conflictedFilesListed}, continue?`}
          onCancel={() => this.toggleOverwriteConfimation(false)}
          onConfirm={this.invokePaste}
        />

        {lightboxOpened && (
          <Lightbox
            open={lightboxOpened}
            fileUri={this.state.selectedUri}
            files={lightBoxFiles}
            closeModal={this.toggleLightbox}
          />
        )}

        <Typography variant="h1">{this.renderPath()}</Typography>

        {this.isEditable() && (
          <section className={classes.actions}>
            <Grid
              container
              spacing={32}
              justify="space-between"
              alignItems="center"
              className={classes.actionsItemsContainer}
            >
              <Grid item>
                {selectedFilesStore.numberOfCheckedFiles > 0 && (
                  <SelectedFilesActions
                    sharedFilesContext={sharedFilesContext}
                    isClipboardEmpty={selectedFilesStore.isClipboardEmpty}
                    disableDownload={uploadingFiles}
                    onDownload={this.handleDownloadSelectedFiles}
                    onMove={selectedFilesStore.setClipboard}
                    onPaste={this.onPaste}
                    onDelete={this.toggleDeleteConfirmation}
                  />
                )}
              </Grid>
              <Grid item>
                {!sharedFilesContext && (
                  <Grid container spacing={32} wrap="nowrap">
                    <Grid item>
                      <FileUpload folderPath={currentPath} context="lab" />
                    </Grid>
                    <Grid item>
                      <ButtonWithIcon
                        onClick={this.toggleCreateFolder}
                        icon={<CreateNewFolderIcon />}
                      >
                        Add Folder
                      </ButtonWithIcon>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </section>
        )}

        {requestInProgress || fetching ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={75} />
          </div>
        ) : (
          <>
            <Table className={classes.table}>
              <TableHead
                readonly={!this.isEditable()}
                sharedBy={sharedFilesContext}
              />

              <TableBody>
                {files.length > 0 && (
                  <>
                    {directoryFiles.map(entity => (
                      <TableRow
                        key={entity.path}
                        currentPath={currentPath}
                        isRootPath={this.isRootPath()}
                        entity={entity}
                        isCut={selectedFilesStore.isCut(entity)}
                        isChecked={selectedFilesStore.isChecked(entity)}
                        readonly={!this.isEditable()}
                        onCheck={() => selectedFilesStore.toggleFile(entity)}
                        onToggleLightBox={this.toggleLightbox}
                      />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>

            {files.length > 5 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                classes={{
                  root: classes.rootPagination,
                  actions: classes.paginationActions,
                }}
                count={files.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                labelDisplayedRows={() => ''}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            )}

            {files.length === 0 && (
              <Grid
                container
                justify="center"
                className={classes.noFilesInFolder}
              >
                <Grid item>
                  <Typography>You have no files in this folder</Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DirectoryListing);
