import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

import { AppStore } from '../../../../stores/appStore';
import { UsersStore } from '../../../../stores/usersStore';
import { AuthStore } from '../../../../stores/authStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.primary.dark,
    },
    list: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    listItem: {
      color: theme.palette.common.white,
      fontSize: '.9rem',
    },
    divider: {
      backgroundColor: theme.palette.common.white,
      opacity: 0.33,
    },
    arrow: {
      display: 'inline-block',
    },
  });
//#endregion Styles

//#region Types
interface Props extends WithStyles<typeof styles> {
  open: boolean;
  pending?: boolean;
  anchorEl: (EventTarget & Element) | null;
  lab: string | undefined | null;
  handleMoreMenu: (event: React.MouseEvent) => void;
  userId: number;
  labView?: boolean;
}

interface InjectedProps extends Props {
  appStore: AppStore;
  usersStore: UsersStore;
  authStore: AuthStore;
}
//#endregion

@inject('appStore', 'authStore', 'usersStore')
@observer
class ContextMenu extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleResendModal = () => {
    const { appStore, usersStore } = this.injected;
    const { userId, lab } = this.props;

    usersStore.setUserPendingInvitation(userId);
    lab && usersStore.getLabDepartments(lab);
    appStore.toggleModal('reInviteUserOpened', true);
  };

  handleRemoveFromAppModal = () => {
    const { appStore, usersStore } = this.injected;
    const { userId } = this.props;

    usersStore.setUserToRemove(userId);
    appStore.toggleModal('removeUserFromAppOpened', true);
  };

  handleRemoveFromLabModal = () => {
    const { appStore, usersStore } = this.injected;
    const { userId } = this.props;

    usersStore.setUserToRemove(userId);
    appStore.toggleModal('removeUserFromLabOpened', true);
  };

  handleRemoveInvitationModal = () => {
    const { appStore, usersStore } = this.injected;
    const { userId } = this.props;

    usersStore.setUserToRemove(userId, true);
    appStore.toggleModal('removeUserInvitationOpened', true);
  };

  render() {
    const {
      open,
      anchorEl,
      pending,
      handleMoreMenu,
      classes,
      labView,
    } = this.props;
    const { isAdmin } = this.injected.authStore;

    return (
      <>
        <IconButton aria-label="More" onClick={handleMoreMenu}>
          <MoreVertIcon />
        </IconButton>
        <Popper
          open={open}
          placement="left-start"
          disablePortal={false}
          anchorEl={anchorEl}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
            arrow: {
              enabled: true,
            },
          }}
        >
          <Paper className={classes.root}>
            <List component="nav" className={classes.list}>
              {pending && (
                <>
                  <ListItem button onClick={this.handleResendModal}>
                    <ListItemText
                      classes={{ primary: classes.listItem }}
                      primary="Resend invitation"
                    />
                  </ListItem>
                  <Divider className={classes.divider} />
                  <ListItem button onClick={this.handleRemoveInvitationModal}>
                    <ListItemText
                      classes={{ primary: classes.listItem }}
                      primary="Remove invitation"
                    />
                  </ListItem>
                </>
              )}
              {!pending && labView && (
                <ListItem button onClick={this.handleRemoveFromLabModal}>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary="Remove user from lab"
                  />
                </ListItem>
              )}
              {!pending && isAdmin && (
                <ListItem button onClick={this.handleRemoveFromAppModal}>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary="Remove user from the App"
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Popper>
      </>
    );
  }
}

export default withStyles(styles)(ContextMenu);
