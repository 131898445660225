import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Divider from '@material-ui/core/Divider';

import ButtonWithIcon from '../../../components/ui/ButtonWithIcon';
import Modal from '../../../components/ui/Modal';
import InviteUser from './InviteUser';
import ResendInvitation from './ResendInvitation';
import UserTable from '../UserTable';
import UserTabs from '../UserTabs';
import RemoveUser from './RemoveUser';

import { AppStore } from '../../../stores/appStore';
import { AuthStore } from '../../../stores/authStore';
import { UsersStore, User, Group } from '../../../stores/usersStore';

//#region Styles

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '32px 0',
    },
    topPanel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '36px 45px 0 45px',
    },
    divider: {
      opacity: 0.5,
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  listOfPendingUsers: User[];
  listOfUsers: User[];
  labsList: string[];
  labView?: boolean;
  chosenLab?: string;
}

interface InjectedProps extends Props {
  appStore: AppStore;
  usersStore: UsersStore;
  authStore: AuthStore;
}

interface State {
  tabValue: string;
}
//#endregion

@inject('appStore', 'usersStore', 'authStore')
@observer
class Base extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    if (props.labView && props.chosenLab) {
      this.injected.usersStore.getLabDepartments(props.chosenLab);
    }

    this.state = {
      tabValue: 'all',
    };
  }

  get injected() {
    return this.props as InjectedProps;
  }

  handleTabChange = (value: string) => {
    this.setState({
      tabValue: value,
    });
  };

  getLabAdmins = (list: Array<User>) => {
    const newList = list.filter((user: User) => {
      if (this.props.chosenLab) {
        return (
          user.lab_admins &&
          user.lab_admins.filter(
            admin => admin.full_lab_name === this.props.chosenLab,
          ).length
        );
      }
      return user.lab_admins.length;
    });

    return newList;
  };

  getUsersWithLab = (list: Array<User>) => {
    const newList = list.filter((user: User) => {
      if (this.props.chosenLab) {
        return (
          user.groups &&
          user.groups.some(
            (group: Group) =>
              group.group_type === 'lab' &&
              group.lab_name === this.props.chosenLab,
          )
        );
      }
      return (
        user.groups &&
        user.groups.some((group: Group) => group.group_type === 'lab')
      );
    });

    return newList;
  };

  getUsersWithoutLab = (list: Array<User>) => {
    const newList = list.filter(
      (user: User) =>
        (!user.groups ||
          !(
            user.groups &&
            user.groups.find((group: Group) => group.group_type === 'lab')
          )) &&
        !user.lab_admins.length,
    );

    return newList;
  };

  getUsers = (list: Array<User>) => {
    const { tabValue } = this.state;
    let newList;

    switch (tabValue) {
      case 'lab-admin':
        newList = this.getLabAdmins(list);
        break;
      case 'lab-user':
        newList = this.getUsersWithLab(list);
        break;
      case 'no-lab':
        newList = this.getUsersWithoutLab(list);
        break;
      case 'all':
      default:
        newList = list;
        break;
    }

    return newList;
  };

  reInviteUser = (payload: Common.UserPayload) => {
    this.injected.usersStore.sendInvitation(payload, true);
    this.toggleResendInvitationModal();
  };

  inviteUserToLab = (payload: Common.UserPayload) => {
    this.injected.usersStore.sendInvitation(payload);
    this.toggleSendInvitationToLabModal();
  };

  handleLabChange = (labName: string) => {
    this.injected.usersStore.getLabDepartments(labName);
  };

  toggleDeleteUserConfimation = () => {
    const opened = this.injected.appStore.modals.removeUserFromAppOpened;
    this.injected.appStore.modals.removeUserFromAppOpened = !opened;
  };

  toggleDeleteUserFromLabConfimation = () => {
    const opened = this.injected.appStore.modals.removeUserFromLabOpened;
    this.injected.appStore.modals.removeUserFromLabOpened = !opened;
  };

  toggleDeleteInvitationConfimation = () => {
    const opened = this.injected.appStore.modals.removeUserInvitationOpened;
    this.injected.appStore.modals.removeUserInvitationOpened = !opened;
  };

  toggleResendInvitationModal = () => {
    const opened = this.injected.appStore.modals.reInviteUserOpened;
    this.injected.appStore.modals.reInviteUserOpened = !opened;
  };

  toggleSendInvitationToLabModal = () => {
    const opened = this.injected.appStore.modals.inviteUserToLabOpened;
    this.injected.appStore.modals.inviteUserToLabOpened = !opened;
  };

  onUserDelete = () => {
    this.injected.usersStore.deleteUserFromSystem();
    this.toggleDeleteUserConfimation();
  };

  onUserDeleteFromLab = () => {
    this.injected.usersStore.deleteUserFromLab();
    this.toggleDeleteUserFromLabConfimation();
  };

  onUserInvitationDelete = () => {
    this.injected.usersStore.deleteInvitation();
    this.toggleDeleteInvitationConfimation();
  };

  render() {
    const {
      classes,
      listOfUsers,
      listOfPendingUsers,
      labsList,
      labView,
      chosenLab,
    } = this.props;
    const { tabValue } = this.state;

    const {
      appStore: {
        modals: {
          inviteUserOpened,
          inviteUserToLabOpened,
          reInviteUserOpened,
          removeUserFromAppOpened,
          removeUserInvitationOpened,
          removeUserFromLabOpened,
        },
        toggleModal,
      },
      usersStore: {
        fetchingDepartments,
        saving,
        inviteUser,
        selectedUser,
        departmentNames: departmentsList,
      },
      authStore: { isLabAdmin },
    } = this.injected;

    const users =
      tabValue === 'pending' ? listOfPendingUsers : this.getUsers(listOfUsers);

    const tabs = [
      {
        id: 1,
        label: 'All Users',
        value: 'all',
        people: listOfUsers.length,
      },
      {
        id: 2,
        label: 'No-Lab Users',
        value: 'no-lab',
        people: this.getUsersWithoutLab(listOfUsers).length,
      },
      {
        id: 3,
        label: "Lab's User",
        value: 'lab-user',
        people: this.getUsersWithLab(listOfUsers).length,
      },
      {
        id: 4,
        label: "Lab's Admin",
        value: 'lab-admin',
        people: this.getLabAdmins(listOfUsers).length,
      },
      {
        id: 5,
        label: 'Pending request',
        value: 'pending',
        people: listOfPendingUsers.length,
      },
    ];

    users.sort((a, b) =>
      a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase()),
    );

    return (
      <div className={classes.mainContainer}>
        <Modal
          open={inviteUserOpened}
          closeModal={() => toggleModal('inviteUserOpened', false)}
          title="Invite to App"
        >
          <InviteUser onSave={inviteUser} inProgress={saving} />
        </Modal>

        <Modal
          open={inviteUserToLabOpened}
          closeModal={() => toggleModal('inviteUserToLabOpened', false)}
          title="Invite to Lab"
        >
          <ResendInvitation
            onSave={this.inviteUserToLab}
            handleLabChange={this.handleLabChange}
            inProgress={saving}
            fetching={fetchingDepartments}
            departmentsList={departmentsList}
            labs={labsList}
            chosenLab={chosenLab}
            isLabAdmin={isLabAdmin}
          />
        </Modal>

        <Modal
          open={reInviteUserOpened}
          closeModal={this.toggleResendInvitationModal}
          title="Pending invitation request"
        >
          <ResendInvitation
            onSave={this.reInviteUser}
            handleLabChange={this.handleLabChange}
            inProgress={saving}
            fetching={fetchingDepartments}
            departmentsList={departmentsList}
            selectedUser={selectedUser}
            labs={labsList}
            isLabAdmin={isLabAdmin}
          />
        </Modal>

        <RemoveUser
          opened={removeUserFromAppOpened}
          selectedUser={selectedUser}
          onCancel={this.toggleDeleteUserConfimation}
          onConfirm={this.onUserDelete}
          fetching={fetchingDepartments}
        />

        <RemoveUser
          opened={removeUserFromLabOpened}
          selectedUser={selectedUser}
          onCancel={this.toggleDeleteUserFromLabConfimation}
          onConfirm={this.onUserDeleteFromLab}
          fetching={fetchingDepartments}
        />

        <RemoveUser
          opened={removeUserInvitationOpened}
          selectedUser={selectedUser}
          onCancel={this.toggleDeleteInvitationConfimation}
          onConfirm={this.onUserInvitationDelete}
          invitation
          fetching={fetchingDepartments}
        />

        <Divider className={classes.divider} />
        <div className={classes.topPanel}>
          <UserTabs
            value={tabValue}
            tabs={tabs}
            handleTabChange={this.handleTabChange}
            hiddenTabs={labView ? ['no-lab'] : []}
          />

          <div>
            {!labView ? (
              <ButtonWithIcon
                onClick={() => toggleModal('inviteUserOpened', true)}
                icon={<PersonAddIcon />}
              >
                Invite to App
              </ButtonWithIcon>
            ) : (
              <ButtonWithIcon
                onClick={() => toggleModal('inviteUserToLabOpened', true)}
                icon={<PersonAddIcon />}
              >
                Invite to Lab
              </ButtonWithIcon>
            )}
          </div>
        </div>

        <UserTable
          users={users}
          labView={labView}
          isLabAdmin={isLabAdmin}
          chosenLab={chosenLab}
          tabValue={tabValue}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Base);
