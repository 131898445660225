import React, { Component } from 'react';
import { difference } from 'lodash-es';
import produce from 'immer';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import AppButton from '../../../components/ui/Button';
import { GroupObject } from '../Groups';
import { GroupObjectUser } from './../Groups';
import { GroupsStore } from './../../../stores/groupsStore';
import { AppStore } from './../../../stores/appStore';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '40px 20px 0 32px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    listWrapper: {
      backgroundColor: '#f8fafa',
      borderRadius: 10,
      padding: 15,
      maxHeight: 300,
      overflow: 'hidden',
      overflowY: 'auto',
    },
    title: {
      color: theme.palette.secondary.light,
      fontWeight: 100,
    },
    userName: {
      fontSize: 17,
      fontWeight: 100,
      cursor: 'pointer',
    },
    submitButton: {
      marginTop: 20,
    },
  });
//#endregion

//#region types
interface State {
  selectedUsers: GroupObjectUser[];
}

interface Props extends WithStyles<typeof styles> {
  group: GroupObject;
  labName: string;
  labUsers: GroupObjectUser[];
  closeModal: () => void;
  onUpdate: () => void;
}

interface InjectedProps extends Props {
  groupsStore: GroupsStore;
  appStore: AppStore;
}
//#endregion

@inject('groupsStore', 'appStore')
@observer
class GroupMembers extends Component<Props, State> {
  state = {
    selectedUsers: [],
  };

  componentDidMount() {
    const { users } = this.props.group;
    this.setState({ selectedUsers: [...users] });
  }

  get injected() {
    return this.props as InjectedProps;
  }

  isUserInGroup = (user: GroupObjectUser) => {
    const { selectedUsers } = this.state;

    return selectedUsers.find(
      (selectedUser: GroupObjectUser) => selectedUser.id === user.id,
    );
  };

  toggleUser = (user: GroupObjectUser) => {
    this.setState(
      produce<State>(draft => {
        if (!this.isUserInGroup(user)) {
          draft.selectedUsers.push(user);
        } else {
          draft.selectedUsers = draft.selectedUsers.filter(
            (u: GroupObjectUser) => u.id !== user.id,
          );
        }
      }),
    );
  };

  onSubmit = async () => {
    const { group, labUsers, onUpdate, closeModal, labName } = this.props;
    const { selectedUsers } = this.state;
    const { groupsStore, appStore } = this.injected;

    if (!labUsers.length) {
      closeModal();
    }

    const prefixedGroupName = `${labName}:${group.name}`;

    const savedUserNames = group.users.map((u: GroupObjectUser) => u.username);
    const selectedUserNames = Array.from(new Set(selectedUsers)).map(
      (u: GroupObjectUser) => u.username,
    );

    const removed = difference(savedUserNames, selectedUserNames);
    const added = difference(selectedUserNames, savedUserNames);

    const toAdd = added.map((username: string) =>
      groupsStore.addUserGroup(username, prefixedGroupName),
    );
    const toRemove = removed.map((username: string) =>
      groupsStore.removeUser(username, prefixedGroupName),
    );

    appStore.setLoading(true);

    try {
      await Promise.all([...toAdd, ...toRemove]);
      appStore.showSuccessToaster();
    } catch (error) {
      appStore.showErrorToaster();
    }

    onUpdate();
    closeModal();

    appStore.setLoading(false);
  };

  render() {
    const { labUsers, classes } = this.props;
    const noUsers = !labUsers.length;

    return (
      <div>
        <div className={classes.listWrapper}>
          {!noUsers &&
            labUsers.map((user: GroupObjectUser) => (
              <div key={user.id}>
                <Typography
                  className={classes.userName}
                  onClick={() => this.toggleUser(user)}
                  variant="h6"
                >
                  <Checkbox
                    color="primary"
                    checked={!!this.isUserInGroup(user)}
                    onChange={() => this.toggleUser(user)}
                    disableRipple={true}
                  />
                  {user.full_name}
                </Typography>
              </div>
            ))}

          {noUsers && (
            <Typography variant="body1">
              There are no users in the lab
            </Typography>
          )}
        </div>

        {!noUsers && (
          <AppButton className={classes.submitButton} onClick={this.onSubmit}>
            Save
          </AppButton>
        )}

        {noUsers && (
          <AppButton
            className={classes.submitButton}
            onClick={this.props.closeModal}
          >
            Close
          </AppButton>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(GroupMembers);
