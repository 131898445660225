import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';

export const styles = (theme: Theme) =>
  createStyles({
    zipList: {
      position: 'relative',
      overflow: 'auto',
      height: 50,
      '&:before': {
        content: '',
        position: 'absolute',
        bottom: 0,
        left: 0,
        background:
          'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)',
      },
      '&.scroll': {
        height: 100,
      },
    },
    uploadInfo: {
      position: 'relative',
      padding: '14px 17px 15px 17px',
      minHeight: '50px',
    },
    canCancel: {
      '&:hover $cancelButton': {
        display: 'block',
      },
      '&:hover $progressContainer': {
        display: 'none',
      },
      '&:hover $errorIcon': {
        display: 'none',
      },
    },
    cancelled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },

    fileName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginRight: '10px',
    },
    uploadedFiles: {
      color: '#b6babb',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    reUploadButton: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      right: 36,
      bottom: 0,
      margin: 'auto',
      height: 44,
    },
    fileTypeIcon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '5px',
      '& svg': {
        fontSize: '18px',
      },
    },
    icon: {
      fontSize: '20px',
    },
    errorIcon: {
      fontSize: '20px',
      color: '#d15051',
    },
    folderIcon: {
      color: '#1880FF',
    },
    fileIcon: {
      color: theme.palette.primary.main,
    },
    statusContainer: {
      display: 'flex',
    },
    fileElement: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    markIcon: {
      fontSize: '20px',
      color: theme.palette.primary.main,
      position: 'relative',
    },
    progressContainer: {
      display: 'flex',
      width: 20,
      height: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
    cancelButton: {
      display: 'none',
      position: 'absolute',
      top: 0,
      right: 5,
      bottom: 0,
      margin: 'auto',
      height: 44,
    },
  });
