import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 90,
      border: '1px dashed #E5E5E5',
      borderRadius: '3px',
      backgroundColor: '#F8FAFA',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all .2s ease-in-out',
    },
    onDragHover: {
      backgroundColor: '#ddd',
    },
    fileInput: {
      height: 0,
      width: 0,
      visibility: 'hidden',
    },
    label: {
      color: theme.palette.primary.main,
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    labelAlt: {
      padding: '10px 15px',
    },
    areaText: {
      fontSize: '13px',
    },
    rootAlternative: {
      display: 'flex',
      alignItems: 'center',
    },
    chip: {
      padding: '0 5px',
      background: '#E1F3EE',

      '&:focus': {
        background: '#E1F3EE',
      },
    },
    chipLabel: {
      fontSize: '16px',
      letterSpacing: '0.15px',
      color: '#1E1F24',
      position: 'relative',
      top: '-1px',
    },
    deleteIcon: {
      fontSize: '16px',
      color: theme.palette.primary.main,

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    buttonText: {
      fontSize: '10px',
      fontWeight: 600,
      letterSpacing: '0.1px',
      lineHeight: '12px',
      color: 'inherit',
      cursor: 'pointer',
    },
  });
