import { observable, action, computed } from 'mobx';
import sleep from '../utils/sleep';

import appStore from './appStore';

export interface CTImage {
  imageId: string;
  minPixelValue: number;
  maxPixelValue: number;
  slope: number;
  intercept: number;
  windowWidth: number;
  windowCenter: number;
  rows: number;
  columns: number;
  width: number;
  height: number;
  color: string;
  flatPixels: Int16Array;
  getPixelData: () => Int16Array;
  sizeInBytes: number;
  columnPixelSpacing: number;
  rowPixelSpacing: number;
}

interface ProcessData {
  inputPath: string;
  resultsPath: string;
  cropCenterX: number | undefined;
  cropCenterY: number | undefined;
  cropRadius: number | undefined;
  cropTop: number | undefined;
  cropBottom: number | undefined;
  tiltAngle: number;
  sampleName: string;
  coreTopDepthMeters: number;
  depthUnit: string;
  axialSlicesToGenerate: number;
  slab?: any;
  beamHardening?: any;
}

export class CTViewerStore {
  @observable
  generatedResult: any = {
    length: 5,
  };

  @observable
  singleViewerResults: boolean = false;

  @observable
  lastResultsId: string = '';

  @observable
  axialSliceLoading: boolean = false;

  @observable
  showingResults: boolean = false;

  @observable
  processSingleViewerLoading: boolean = false;

  @observable
  processSingleViewerProgress: number = 0;

  @observable
  processingResults: boolean = false;

  @observable
  processingError: boolean = false;

  @observable
  socket: any = null;

  @observable
  serviceConnection: boolean = false;

  @observable
  serviceInitialData: boolean = false;

  @observable
  colormap: string = 'gray';

  @observable
  chosenEnergy: string | null = null;

  @observable
  pathToImages: string = '';

  @observable
  pathToProject: string = '';

  @observable
  projectName: string = '';

  @observable
  sampleName: string = '';

  @observable
  generatingInProgress: boolean = false;

  @observable
  generatingError: boolean = false;

  @observable
  viewportOffset: number = 0; // offset in native px from the top, if image is taller than viewport

  @observable
  coronalImage: CTImage | null = null;

  @observable
  sagittalImage: CTImage | null = null;

  @observable
  axialImage: CTImage | null = null;

  @observable
  displayedSliceHeight: number | undefined = undefined; // XY slice height, in native px

  // Selected area
  @observable
  cropRadius: number | undefined = undefined;

  @observable
  cropTop: number | undefined = undefined;

  @observable
  cropBottom: number | undefined = undefined;

  @observable
  cropCenterX: number | undefined = undefined;

  @observable
  cropCenterY: number | undefined = undefined;

  @observable
  depth: number = 0; // always stored in m; converted to the  during displaying

  @observable
  depthUnit: string = 'm'; // 'm' / 'ft'

  @observable
  axialSlicesToGenerate: number = 5;

  @observable
  customSlice: any = null; // format: {x1: 10, y1: 10, x2: 200, y2: 200}

  @observable
  brightnessRange: any = null;

  @observable
  results: any = null;

  @observable
  shouldApplyHardeningCorrection: boolean = false;

  @observable
  beamHardening: boolean = false;

  @observable
  beamHardeningX: number | undefined = undefined;

  @observable
  beamHardeningY: number | undefined = undefined;

  @observable
  beamHardeningRadius: number | undefined = undefined;

  @observable
  tiltAngle: number = 0;

  @observable
  beamHardeningRange: number = 0.9;

  @observable
  viewportSettings: any = {
    center: 1500,
    width: 3000,
  };

  // generated results
  @observable
  outputWindow: any = {
    min: 0,
    max: 3000,
  };

  @observable
  resultsExporting: number = 0;

  @observable
  resultsExportingCopy: number = 0;

  @observable
  resultsExported: boolean = false;

  @computed
  get resultLength() {
    return this.generatedResult.length;
  }

  @action
  setResultsViewportSettings = (props: any) => {
    this.outputWindow = {
      ...this.outputWindow,
      ...props,
    };
  };

  @action
  toggleResults = () => {
    this.showingResults = !this.showingResults;
  };

  @action
  setAxialSliceLoading = (value: boolean) => {
    this.axialSliceLoading = value;
  };

  @action
  setSingleViewerDataProgress = (value: number) => {
    this.processSingleViewerProgress = value;
  };

  @action
  setSingleViewerDataLoader = (value: boolean) => {
    this.processSingleViewerLoading = value;
  };

  @action
  setSingleViewerResultsId = (uuid: string) => {
    this.lastResultsId = uuid;
  };

  @action
  setServiceInitialData = (value: boolean) => {
    this.serviceInitialData = value;
  };

  @action
  setProcessingError = (value: boolean) => {
    this.processingError = value;
  };

  @action
  setSocket = (value: any) => {
    this.socket = value;
  };

  @action
  setServiceConnection = (value: boolean) => {
    this.serviceConnection = value;
  };

  @action
  setEnergy = (value: string) => {
    this.chosenEnergy = value;
  };

  @action
  setPathToProject = (value: string) => {
    this.pathToProject = value;
  };

  @action
  setPathToImages = (value: string) => {
    this.pathToImages = value;
  };

  @action
  setProjectName = (value: string) => {
    this.projectName = value;
  };

  @action
  setViewportOffset = (value: number) => {
    this.viewportOffset = value;
  };

  @action
  setCoronalImage = (value: CTImage) => {
    this.coronalImage = value;
  };

  @action
  setSagittalImage = (value: CTImage) => {
    this.sagittalImage = value;
  };

  @action
  setAxialImage = (value: CTImage) => {
    this.axialImage = value;
  };

  @action
  setViewportSettings = (value: any) => {
    this.viewportSettings = value;
  };

  @action
  setCropParameters = (value: any) => {
    let xSize = 512; // default
    let ySize = 512; // default

    if (this.coronalImage) {
      xSize = this.coronalImage.width;
    }

    if (this.sagittalImage) {
      ySize = this.sagittalImage.width;
    }

    if (typeof value.cropCenterX !== 'undefined') {
      if (this.cropRadius && value.cropCenterX < this.cropRadius) {
        value.cropCenterX = this.cropRadius;
      }

      if (this.cropRadius && value.cropCenterX > xSize - this.cropRadius) {
        value.cropCenterX = xSize - this.cropRadius;
      }

      this.cropCenterX = value.cropCenterX;
    }

    if (typeof value.cropCenterY !== 'undefined') {
      if (this.cropRadius && value.cropCenterY < this.cropRadius) {
        value.cropCenterY = this.cropRadius;
      }

      if (this.cropRadius && value.cropCenterY > ySize - this.cropRadius) {
        value.cropCenterY = ySize - this.cropRadius;
      }

      this.cropCenterY = value.cropCenterY;
    }

    if (typeof value.cropTop !== 'undefined') {
      this.cropTop = value.cropTop;
    }

    if (typeof value.cropBottom !== 'undefined') {
      this.cropBottom = value.cropBottom;
    }

    if (typeof value.cropRadius !== 'undefined') {
      if (this.cropCenterX && value.cropRadius > this.cropCenterX) {
        value.cropRadius = this.cropCenterX;
      }
      if (this.cropCenterY && value.cropRadius > this.cropCenterY) {
        value.cropRadius = this.cropCenterY;
      }

      if (this.cropCenterX && value.cropRadius + this.cropCenterX > xSize) {
        value.cropRadius = xSize - this.cropCenterX;
      }
      if (this.cropCenterY && value.cropRadius + this.cropCenterY > ySize) {
        value.cropRadius = ySize - this.cropCenterY;
      }

      this.cropRadius = value.cropRadius;
    }

    if (typeof value.displayedSliceHeight !== 'undefined') {
      this.displayedSliceHeight = value.displayedSliceHeight;
    }
  };

  @action
  singleSetCustomSlice = (values: any) => {
    this.customSlice = values;
  };

  @action
  changeTilt = (value: number) => {
    this.tiltAngle = value;
  };

  @action
  toggleBeamHardening = (value: boolean) => {
    this.beamHardening = value;
  };

  @action
  setBeamHardeningX = (value: number) => {
    this.beamHardeningX = value;
  };

  @action
  setBeamHardeningY = (value: number) => {
    this.beamHardeningY = value;
  };

  @action
  setBeamHardeningRadius = (value: number) => {
    this.beamHardeningRadius = value;
  };

  @action
  setBeamHardeningRange = (value: number) => {
    this.beamHardeningRange = value;
  };

  @action
  setBrightnessRange = (min: number, max: number) => {
    this.brightnessRange = {
      min,
      max,
    };
  };

  @action
  setDepthUnit = (value: string) => {
    this.depthUnit = value;
  };

  @action
  setDepth = (value: number) => {
    this.depth = value;
  };

  @action
  setSampleName = (value: string) => {
    this.sampleName = value;
  };

  @action
  setSlicesToGenerate = (value: number) => {
    this.axialSlicesToGenerate = value;
  };

  @action
  finishGeneratingInProgress = (data: any) => {
    this.generatedResult = data;
    this.generatingInProgress = false;
    this.generatingError = false;

    if (!data.alreadyConverted) {
      appStore.showInfoToaster('Starting to generate assets');
    }
  };

  @action
  stopGeneratingSlices = () => {
    this.generatingInProgress = false;
    this.generatingError = true;
    appStore.showErrorToaster('Processing failure');
  };

  @action
  generateAssets = () => {
    if (this.socket && this.socket.connected) {
      this.generatingInProgress = true;
      this.generatingError = false;

      this.socket.emit('convertSlices', {
        forceRefresh: false,
        path: this.pathToImages,
      });
    }
  };

  @action
  processData = () => {
    if (this.socket && this.socket.connected) {
      const data: ProcessData = {
        inputPath: this.pathToImages,
        resultsPath: this.pathToProject,
        cropCenterX: this.cropCenterX,
        cropCenterY: this.cropCenterY,
        cropRadius: this.cropRadius,
        cropTop: this.cropTop,
        cropBottom: this.cropBottom,
        tiltAngle: this.tiltAngle,
        sampleName: this.sampleName,
        coreTopDepthMeters: this.depth,
        depthUnit: this.depthUnit,
        axialSlicesToGenerate: this.axialSlicesToGenerate,
      };

      if (this.customSlice) {
        data.slab = this.customSlice;
      }

      if (this.beamHardening) {
        data.beamHardening = {
          centerX: this.beamHardeningX,
          centerY: this.beamHardeningY,
          radius: this.beamHardeningRadius,
          range: this.beamHardeningRange,
          referenceHeight: Math.min(
            this.displayedSliceHeight!,
            this.resultLength,
          ),
        };
      }

      this.processSingleViewerLoading = true;
      console.log('SingleEnergy data processing: ', JSON.stringify(data));
      this.socket.emit('processSingle', data);
    }
  };

  @action
  setSingleViewerResults = (value: boolean) => {
    this.singleViewerResults = value;
  };

  @action
  getRawResultsSingle = async () => {
    if (this.socket && this.socket.connected) {
      this.singleViewerResults = true;
      this.showingResults = true;
      this.processingResults = true;

      const path = `${this.pathToProject}/${this.lastResultsId}`;

      this.socket.emit('getRawResultsSingle', {
        path,
        name: 'coronal',
      });

      this.socket.emit('getRawResultsSingle', {
        path,
        name: 'sagittal',
      });

      this.socket.emit('getRawResultsSingle', {
        path,
        name: 'unrolled',
      });

      this.socket.emit('getRawResultsSingle', {
        path,
        name: 'metadata',
      });

      if (this.customSlice) {
        this.socket.emit('getRawResultsSingle', {
          path,
          name: 'slab',
        });
      }

      for (let i = 0; i < this.axialSlicesToGenerate; i++) {
        await sleep(100);
        this.socket.emit('getRawResultsSingle', {
          path,
          name: `axial${i}`,
        });
      }
    }
  };

  @action
  getRawResultsSingleForAxialSlice = (index: number) => {
    if (this.socket && this.socket.connected) {
      this.axialSliceLoading = true;
      const path = `${this.pathToProject}/${this.lastResultsId}`;

      this.socket.emit('getRawResultsSingle', {
        path,
        name: `axial${index}`,
      });
    }
  };

  @action
  saveSingleResult = async (arrayToSend: any[], axialSlicesToSend: any[]) => {
    if (this.socket && this.socket.connected) {
      this.resultsExporting = arrayToSend.length + axialSlicesToSend.length;
      this.socket.emit('saveResultImageSingle', arrayToSend);

      await sleep(2000);
      this.socket.emit('saveResultImageSingle', axialSlicesToSend);
    }
  };

  @action
  toggleProcessingResultsLoader = () => {
    this.processingResults = !this.processingResults;
  };

  @action
  showSingleViewerCalculationError = (msg: string) => {
    appStore.showErrorToaster(msg);
  };

  @action
  showSingleViewerCalculationSuccess = (msg: string) => {
    appStore.showSuccessToaster(msg);
  };

  @action
  selectColorMap = (color: string) => {
    this.colormap = color;
  };

  @action
  decreaseExportingValue = (val: number) => {
    this.resultsExportingCopy += val;
  };

  @action
  clearExportingValues = () => {
    this.resultsExporting = 0;
    this.resultsExportingCopy = 0;
    this.resultsExported = true;
  };

  @action
  clearData = () => {
    this.generatedResult = {
      length: 5,
    };
    this.processingError = false;
    this.generatingInProgress = false;
    this.chosenEnergy = null;
    this.serviceInitialData = false;
    this.sampleName = '';
    this.generatingError = false;
    this.viewportOffset = 0;
    this.coronalImage = null;
    this.sagittalImage = null;
    this.axialImage = null;
    this.viewportSettings = {
      center: 1500,
      width: 3000,
    };
    this.customSlice = null;
    this.displayedSliceHeight = undefined;
    this.cropRadius = undefined;
    this.cropTop = undefined;
    this.cropBottom = undefined;
    this.cropCenterX = undefined;
    this.cropCenterY = undefined;
    this.depth = 0;
    this.beamHardening = false;
    this.beamHardeningX = undefined;
    this.beamHardeningY = undefined;
    this.beamHardeningRadius = undefined;
    this.beamHardeningRange = 0.9;
    this.tiltAngle = 0;
    this.singleViewerResults = false;

    // generated results
    this.clearProcessingData();
  };

  @action
  clearProcessingData = () => {
    // generated results
    this.resultsExporting = 0;
    this.resultsExportingCopy = 0;
    this.resultsExported = false;
    this.outputWindow = {
      min: 0,
      max: 3000,
    };
    this.colormap = 'gray';
    this.processSingleViewerProgress = 0;
    this.processSingleViewerLoading = false;
    this.showingResults = false;
    this.processingResults = false;
  };
}

export default new CTViewerStore();
