export default (
  context: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  textAlign?: string,
  textBaseline?: string,
  colorStr?: string,
) => {
  context.fillStyle = colorStr ? colorStr : '#fff';
  context.font = '18px';
  context.textAlign = (textAlign ? textAlign : 'left') as CanvasTextAlign;
  context.textBaseline = (textBaseline
    ? textBaseline
    : 'top') as CanvasTextBaseline;

  context.fillText(text, x, y);
};
