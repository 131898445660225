import drawText from './drawText';
import convertToUnit from './convertToUnit';
import {
  leftTextOffset,
  topTextOffsetSecondLine,
  topTextOffset,
} from './spacings';

export default (
  context: CanvasRenderingContext2D,
  width: number,
  sampleName: string,
  sliceName: string,
  topDepthM: number,
  depthUnit: string,
  decimalDigits: number,
) => {
  const topDepth = convertToUnit(topDepthM, depthUnit);
  const depthText = `${topDepth.toFixed(decimalDigits)} ${depthUnit}`;

  drawText(context, sampleName, width / 2, topTextOffset, 'center', 'top');
  drawText(
    context,
    depthText,
    leftTextOffset,
    topTextOffsetSecondLine,
    'left',
    'top',
  );
  drawText(
    context,
    sliceName,
    width - leftTextOffset,
    topTextOffsetSecondLine,
    'right',
    'top',
  );
};
