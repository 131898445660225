import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Formik, FormikActions } from 'formik';
import * as Yup from 'yup';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';

import AppButton from '../../../components/ui/Button';
import { GroupObject } from '../Groups';
import { GroupsStore } from '../../../stores/groupsStore';
import { AppStore } from './../../../stores/appStore';
import { getPrefixedGroupName } from '../../../stores/groupsStore';

//#region styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '40px 20px 0 32px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    sectionTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      marginBottom: 16,
    },
    usersList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    addButton: {
      paddingLeft: 0,
    },
    removeButton: {
      padding: '10px 20px 10px 20px',
      color: '#D15151',
      float: 'left',
    },
    removeButtonIcon: {
      marginRight: 9,
      fontSize: 16,
    },
    bottomOptions: {
      borderTop: `2px dashed ${theme.palette.primary.main}`,
      height: 112,
      marginTop: 100,
      paddingTop: 20,
      textAlign: 'right',
      width: '100%',
    },
    groupNameInput: {
      flex: 1,
      display: 'flex',
      alignItems: 'left',
      textAlign: 'right',
      justifyContent: 'space-between',
    },
  });
//#endregion

//#region types
interface Props extends WithStyles<typeof styles> {
  allGroups: GroupObject[];
  labName: string;
  editable: boolean;
  group?: GroupObject | null;
  onUpdate: () => void;
  close: () => void;
}

interface FormProps {
  groupName: string;
}

interface InjectedProps extends Props {
  groupsStore: GroupsStore;
  appStore: AppStore;
}
//#endregion

@inject('groupsStore', 'appStore')
@observer
class GroupEdit extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  isNew = () => !this.props.group;

  onSubmit = async (
    { groupName }: FormProps,
    actions: FormikActions<FormProps>,
  ) => {
    const { groupsStore, appStore } = this.injected;
    const { labName, group, onUpdate, close } = this.props;

    const isNew = this.isNew();
    const preffixed = getPrefixedGroupName(labName, groupName);

    try {
      if (isNew) {
        await groupsStore.createGroup(preffixed, labName);
      } else {
        const preffixedOld = `${labName}:${group!.name}`;
        await groupsStore.renameGroup(preffixedOld, preffixed);
      }
      onUpdate();
      close();
      appStore.showSuccessToaster();
    } catch (error) {
      appStore.showErrorToaster();
    }

    actions.setSubmitting(false);
  };

  onRemove = async () => {
    const { groupsStore, appStore } = this.injected;
    const { labName, group, onUpdate, close } = this.props;
    const groupName = group ? group.name : '';
    const preffixedGroupName = `${labName}:${groupName}`;

    try {
      await groupsStore.deleteGroup(preffixedGroupName);
      onUpdate();
      close();
      appStore.showSuccessToaster();
    } catch (error) {
      appStore.showErrorToaster();
    }
  };

  render() {
    const { allGroups, classes, editable, group, close } = this.props;
    const groupName = group ? group.name : '';
    const isSaved = !!group;

    const alreadyCreated = allGroups.map((group: GroupObject) => group.name);

    const validationSchema = Yup.object().shape({
      groupName: Yup.string()
        .min(1)
        .notOneOf(alreadyCreated, 'Given group name already exist'),
    });

    return (
      <div className={classes.root} key={groupName}>
        <Typography className={classes.sectionTitle}>
          {this.isNew() && 'Add new group'}
          {!this.isNew() && 'Edit group'}
        </Typography>

        <Formik
          initialValues={{
            groupName,
          }}
          onSubmit={this.onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, handleSubmit, errors, isSubmitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    id="groupName"
                    margin="normal"
                    className={classes.groupNameInput}
                    name="groupName"
                    disabled={!editable}
                    value={values.groupName}
                    onChange={handleChange}
                    error={!!errors.groupName}
                    helperText={!!errors.groupName ? errors.groupName : ''}
                  />
                </Grid>
              </Grid>

              <div className={classes.bottomOptions}>
                <div>
                  {isSaved && (
                    <Button
                      className={classes.removeButton}
                      onClick={this.onRemove}
                      disabled={!editable}
                      tabIndex={3}
                    >
                      <DeleteIcon className={classes.removeButtonIcon} />
                      Remove group
                    </Button>
                  )}

                  <Button tabIndex={4} onClick={close}>
                    Cancel
                  </Button>

                  <AppButton
                    type="submit"
                    width={170}
                    disabled={isSubmitting || !editable}
                    isLoading={isSubmitting}
                    tabIndex={0}
                  >
                    {this.isNew() && 'Save new group'}
                    {!this.isNew() && 'Save Changes'}
                  </AppButton>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withStyles(styles)(GroupEdit);
