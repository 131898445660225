import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      minHeight: 45,
      width: 330,
      borderRadius: 10,
      zIndex: 999,
      boxShadow: '0 4px 12px -4px #D1D4D5',
      overflow: 'hidden',
      transition: 'all .2s ease',
      margin: '0 15px',
    },
    rootMinimized: {
      transform: 'translate3d(0, calc(100% - 19px), 0)',
    },
    header: {
      backgroundColor: '#586063',
      padding: '10px 18px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerText: {
      color: '#fff',
    },
    uploadingText: {
      fontSize: '.9rem',
    },
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      padding: '0',
      background: '#fff',
      maxHeight: '265px',
      overflowX: 'hidden',
      overflowY: 'auto',

      '& > div > div > div:not(:first-child)': {
        borderTop: '1px solid rgba(86,96,99,0.07)',
      },
    },
    progress: {
      backgroundColor: '#586063',
    },
    buttonContainer: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      outline: 'none',
      width: '25px',
      height: '25px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'transparent',
      color: '#fff',
      borderRadius: '50%',
      border: '1px solid #7C8689',
      transition: 'all .2s ease-in-out',
      cursor: 'pointer',

      '&:first-child': {
        marginRight: '7px',
      },

      '&:hover': {
        background: '#7C8689',
      },

      '& > svg': {
        fontSize: '17px',
      },
    },
    abortOverlay: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      textAlign: 'center',
      zIndex: 999,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(255,255,266,0.8)',
    },
    abortingText: {
      fontSize: '1.1rem',
    },
  });
