import React, { memo, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
//@ts-ignore
import ReactSlider from 'react-slider';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import NumericInput from '../../../../../../../components/ui/NumericInput';

import { CTViewerStore } from '../../../../../../../stores/ctViewerStore';
import gradientLookup from '../../../../../../../utils/gradientLookup';
import gradientMinLookup from '../../../../../../../utils/gradientMinLookup';
import gradientMaxLookup from '../../../../../../../utils/gradientMaxLookup';

const step = 1;

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '250px',
      margin: '20px auto 0 auto',
      paddingBottom: '10px',
    },
    slider: {
      width: '250px',
      height: '20px',
    },
    thumb: {
      position: 'relative',
      width: '12px',
      height: '17px',
      background: '#505f88',
      transform: 'translateY(-6px)',

      '&:focus': {
        outline: 'none',
      },

      '&-0, &-1': {
        '&:after': {
          content: '""',
          position: 'absolute',
          left: 0,
          width: 0,
          height: 0,
          bottom: '-6px',
          borderTop: '6px solid #505f88',
          borderLeft: '6px solid transparent',
          borderRight: '6px solid transparent',
        },
      },
    },
    track: {
      '&-0': {
        border: '1px solid #aaa',
      },
      '&-1': {
        border: '1px solid #aaa',
      },
      '&-2': {
        border: '1px solid #aaa',
      },
    },
    trackGray: {
      height: '10px',
      borderRadius: '5px',

      '&-0': {
        background: gradientMinLookup['gray'],
        border: '1px solid #aaa',
      },
      '&-1': {
        background: gradientLookup['gray'],
        border: '1px solid #aaa',
      },
      '&-2': {
        background: gradientMaxLookup['gray'],
        border: '1px solid #aaa',
      },
    },
    trackPet: {
      height: '10px',
      borderRadius: '5px',

      '&-0': {
        background: gradientMinLookup['pet'],
        border: '1px solid #aaa',
      },
      '&-1': {
        background: gradientLookup['pet'],
        border: '1px solid #aaa',
      },
      '&-2': {
        background: gradientMaxLookup['pet'],
        border: '1px solid #aaa',
      },
    },
    trackHot: {
      height: '10px',
      borderRadius: '5px',

      '&-0': {
        background: gradientMinLookup['hot'],
        border: '1px solid #aaa',
      },
      '&-1': {
        background: gradientLookup['hot'],
        border: '1px solid #aaa',
      },
      '&-2': {
        background: gradientMaxLookup['hot'],
        border: '1px solid #aaa',
      },
    },
    trackSpectral: {
      height: '10px',
      borderRadius: '5px',

      '&-0': {
        background: gradientMinLookup['customSpectral'],
      },
      '&-1': {
        background: gradientLookup['customSpectral'],
      },
      '&-2': {
        background: gradientMaxLookup['customSpectral'],
      },
    },
    inputBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    buttonContainer: {
      width: '100%',
      textAlign: 'center',
      marginTop: '16px',
    },
    button: {
      color: '#fff',
      boxShadow: 'none',
      backgroundColor: '#69C3AC',
      padding: '6px 16px',
      lineHeight: 1.75,
      minWidth: '64px',
      fontSize: '0.9375rem',
      fontFamily: 'acumin-pro, sans-serif',
      fontWeight: 'bold',
      borderRadius: '10px',
      textTransform: 'initial',
      border: 0,
      margin: 0,
      cursor: 'pointer',
      display: 'inline-flex',
      position: 'relative',
      alignItems: 'center',
      userSelect: 'none',
      verticalAlign: 'middle',
      justifyContent: 'center',
      outline: 'none',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  ctViewerStore?: CTViewerStore;
  lowestDensityValue: number;
  highestDensityValue: number;
}
//#endregion

const DensityColorWindowSettings: React.FunctionComponent<Props> = ({
  classes,
  lowestDensityValue,
  highestDensityValue,
  ctViewerStore,
}) => {
  const [localMinValue, setLocalMinValue] = useState<string | undefined>(
    undefined,
  );
  const [localMaxValue, setLocalMaxValue] = useState<string | undefined>(
    undefined,
  );

  const handleChangeIntensityWindow = (newValue: number, isMax: boolean) => {
    ctViewerStore!.setResultsViewportSettings({
      [isMax ? 'max' : 'min']: Math.round(newValue),
    });
  };

  const windowMin = () => {
    const { outputWindow } = ctViewerStore!;
    if (outputWindow.min !== undefined) return outputWindow.min;
    return outputWindow.center - outputWindow.width / 2;
  };

  const windowMax = () => {
    const { outputWindow } = ctViewerStore!;
    if (outputWindow.max !== undefined) return outputWindow.max;
    return outputWindow.center + outputWindow.width / 2;
  };

  const sliderMin = () => {
    setLocalMinValue(undefined);
    return Math.max(windowMin(), lowestDensityValue);
  };

  const sliderMax = () => {
    setLocalMaxValue(undefined);
    return Math.min(windowMax(), highestDensityValue);
  };

  const handleChangeMin = (newMin: number) => {
    if (newMin === null) {
      setLocalMinValue(newMin);
    } else {
      setLocalMinValue(undefined);
    }

    if (newMin > windowMax()) return;

    handleChangeIntensityWindow(newMin, false);
  };

  const handleChangeMax = (newMax: number) => {
    if (newMax === null) {
      setLocalMaxValue(newMax);
    } else {
      setLocalMaxValue(undefined);
    }

    if (newMax < windowMin()) return;

    handleChangeIntensityWindow(newMax, true);
  };

  const handleChangeMinMaxArray = (newValues: any) => {
    const min = sliderMin();
    const max = sliderMax();

    if (newValues[0] !== min) handleChangeMin(newValues[0]);
    if (newValues[1] !== max) handleChangeMax(newValues[1]);
  };

  const resetToDefault = () => {
    const { viewportSettings } = ctViewerStore!;
    const windowMin = viewportSettings.center - viewportSettings.width / 2;
    const windowMax = viewportSettings.center + viewportSettings.width / 2;
    handleChangeMin(
      windowMin < lowestDensityValue ? lowestDensityValue : windowMin,
    );
    handleChangeMax(
      windowMax > highestDensityValue ? highestDensityValue : windowMax,
    );
  };

  useEffect(() => {
    resetToDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    outputWindow: { center, width, min, max },
    colormap,
  } = ctViewerStore!;

  if (center !== undefined && Number.isNaN(center)) return null;
  if (width !== undefined && Number.isNaN(width)) return null;
  if (min !== undefined && Number.isNaN(min)) return null;
  if (max !== undefined && Number.isNaN(max)) return null;

  const colorKey = colormap || 'gray';

  return (
    <div className={classes.root}>
      <ReactSlider
        min={lowestDensityValue}
        max={highestDensityValue}
        value={[min, max]}
        onChange={handleChangeMinMaxArray}
        withBars
        className={classes.slider}
        thumbClassName={classes.thumb}
        trackClassName={classNames({
          [classes.trackGray]: colorKey === 'gray',
          [classes.trackPet]: colorKey === 'pet',
          [classes.trackHot]: colorKey === 'hot',
          [classes.trackSpectral]: colorKey === 'customSpectral',
        })}
      />
      <div className={classes.inputBox}>
        <NumericInput
          value={localMinValue !== undefined ? localMinValue : windowMin()}
          step={step}
          onChange={handleChangeMin}
        />
        <NumericInput
          value={localMaxValue !== undefined ? localMaxValue : windowMax()}
          step={step}
          onChange={handleChangeMax}
        />
      </div>
      <div className={classes.buttonContainer}>
        <button className={classes.button} onClick={resetToDefault}>
          Reset to default
        </button>
      </div>
    </div>
  );
};

export default memo(
  withStyles(styles)(
    inject('ctViewerStore')(observer(DensityColorWindowSettings)),
  ),
);
