import React from 'react';
import { Formik, FormikActions } from 'formik';
import some from 'lodash-es/some';
import { observer, inject } from 'mobx-react';
import * as Yup from 'yup';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';

import AutoCompleter from '../../../../components/ui/AutoCompleter';

import { GroupObject } from '../../Groups';
import { GroupsStore } from './../../../../stores/groupsStore';
import { UsersStore } from '../../../../stores/usersStore';
import { AuthStore } from '../../../../stores/authStore';

//#region Styles
const styles = () =>
  createStyles({
    chip: {
      margin: 5,

      '&:first-child ': {
        marginLeft: 0,
      },
    },
    content: {
      overflowY: 'visible',
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  onClose: () => void;
  onAddShare: (email: string, group: boolean) => void;
  onRemoveShare: (id: string, group: boolean) => void;
  onUpdateShares: () => void;
  hideGroups: boolean;
  opened: boolean;
  path: string;
  emails: Email[];
  groups: GroupObject[];
  requestInProgress: boolean;
  [key: string]: any;
}

interface InjectedProps extends Props {
  groupsStore: GroupsStore;
  usersStore: UsersStore;
  authStore: AuthStore;
}

interface FormValues {
  email: string;
  group: string;
  [key: string]: string;
}

interface Email {
  value: string;
  id: string;
}
//#endregion

@inject('groupsStore', 'usersStore', 'authStore')
@observer
class ShareEntityDialog extends React.Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleAdd = (values: FormValues, actions: FormikActions<FormValues>) => {
    if (values.email) {
      this.handleAddEmail(values, actions);
    }

    if (values.group) {
      this.handleAddGroup(values, actions);
    }
  };

  handleAddEmail = (
    values: FormValues,
    { resetForm, setFieldError }: FormikActions<FormValues>,
  ) => {
    if (some(this.props.emails, ['value', values.email])) {
      setFieldError('email', 'Email already added');
      return;
    }

    this.props.onAddShare(values.email, false);
    resetForm();
  };

  handleAddGroup = (
    values: FormValues,
    { resetForm, setFieldError }: FormikActions<FormValues>,
  ) => {
    this.setState({ selectedGroup: values.group });

    if (some(this.props.groups, ['value', values.group])) {
      setFieldError('group', 'Group already added');
      return;
    }

    this.props.onAddShare(values.group, true);
    resetForm();
  };

  render() {
    const {
      classes,
      opened,
      onClose,
      onRemoveShare,
      emails,
      groups,
      path,
    } = this.props;

    const labName = path.split('/')[0];
    const hideGroups = labName === 'My Files';

    const {
      groupsStore: { labGroups },
      usersStore: { getUserByProperty, listOfUsers },
      authStore: { userData },
    } = this.injected;
    const labGroupAvailable = !!labGroups.length;

    const usedEmails = emails.map((email: any) => email.value);
    const usedGroups = groups.map((group: any) => +group.id);

    const users = listOfUsers
      .map(user => ({
        value: user.email,
        label: user.full_name,
      }))
      .filter(user => user.value !== userData.email);

    users.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
    );
    labGroups.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );

    return (
      <Dialog
        open={opened}
        maxWidth="xs"
        fullWidth
        classes={{
          paper: classes.content,
        }}
      >
        <DialogTitle>Share with</DialogTitle>
        <DialogContent className={classes.content}>
          {emails.map((email: Email) => (
            <Chip
              className={classes.chip}
              key={email.id}
              label={getUserByProperty('email', email.value)[0].full_name}
              onDelete={() => onRemoveShare(email.value, false)}
            />
          ))}

          {groups.map((group: any) => (
            <Chip
              className={classes.chip}
              key={group.id}
              label={group.value}
              onDelete={() => onRemoveShare(group.value, true)}
            />
          ))}

          <Formik
            initialValues={{
              email: '',
              group: '',
            }}
            validationSchema={Yup.object().shape({
              group: Yup.string()
                .min(1)
                .notOneOf(usedGroups, 'Given group is already used'),
              email: Yup.string()
                .min(1)
                .notOneOf(usedEmails, 'Given email is already used'),
            })}
            onSubmit={this.handleAdd}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors,
              setFieldValue,
              setFieldTouched,
            }) => {
              const user = getUserByProperty('email', values.email)[0];
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={8} alignItems="flex-end">
                    <Grid item xs={10}>
                      <AutoCompleter
                        autoFocus
                        options={users}
                        label="User name"
                        error={!!errors.email}
                        helperText={errors.email || ''}
                        value={{
                          value: values.email,
                          label: user ? user.full_name : '',
                        }}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        id="email"
                        name="email"
                        noOptionsMessage="No users found"
                        isClearable
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton type="submit">
                        <AddCircleIcon fontSize="small" />
                      </IconButton>
                    </Grid>

                    {!hideGroups && (
                      <Grid item xs={10}>
                        {labGroupAvailable && (
                          <TextField
                            select
                            margin="normal"
                            id="group"
                            name="group"
                            label="Group"
                            fullWidth
                            error={!!errors.group}
                            helperText={errors.group || ''}
                            onChange={handleChange}
                            value={values.group}
                          >
                            {labGroups
                              .filter(
                                (group: GroupObject) =>
                                  !usedGroups.includes(+group.id),
                              )
                              .map((group: GroupObject) => (
                                <MenuItem key={group.id} value={group.name}>
                                  {group.group_type === 'department'
                                    ? group.department_name
                                    : group.name}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}

                        {!labGroupAvailable && (
                          <Typography variant="body1">
                            There are no groups defined
                          </Typography>
                        )}
                      </Grid>
                    )}

                    {!hideGroups && (
                      <Grid item xs={2}>
                        {labGroupAvailable && (
                          <IconButton type="submit">
                            <AddCircleIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>

        <DialogActions>
          {this.props.requestInProgress ? (
            <CircularProgress />
          ) : (
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ShareEntityDialog);
