import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 30,
      right: 30,
      zIndex: 999,
      display: 'flex',
      margin: '0 -15px',
      alignItems: 'flex-end',
    },
  });
