import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import { checkAll, areAllChecked } from '../../../utils/directoryUtils';

interface Props {
  sharedBy: boolean;
  readonly: boolean;
}

const FilesTableHead: React.FunctionComponent<Props> = ({
  readonly,
  sharedBy,
}: Props) => {
  return (
    <TableHead>
      <TableRow>
        {!readonly && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={areAllChecked()}
              onChange={() => checkAll(false)}
            />
          </TableCell>
        )}
        <TableCell>Type</TableCell>
        {!sharedBy && <TableCell>Shared with</TableCell>}
        {sharedBy && <TableCell />}
        <TableCell>Size</TableCell>
        {sharedBy && <TableCell>Shared by</TableCell>}
        <TableCell>Last Modified</TableCell>
        {!readonly && <TableCell align="right">Actions</TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default FilesTableHead;
