import React from 'react';
import classNames from 'classnames';
import { WithStyles } from '@material-ui/core/styles';

import { styles } from './styles';
import withStyles from '@material-ui/core/styles/withStyles';

interface FileStatusFloatBoxContainerProps extends WithStyles<typeof styles> {}

const FileProcessPopupContainer: React.FC<FileStatusFloatBoxContainerProps> = ({
  classes,
  children,
}) => {
  return <div className={classNames(classes.root)}>{children}</div>;
};

export default withStyles(styles)(FileProcessPopupContainer);
