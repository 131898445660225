import React from 'react';
import NumericInput from 'react-numeric-input';

const DetailsNumberInput = (props: any) => (
  <NumericInput
    {...props}
    style={{
      input: { width: '120px', padding: '0.5rem', borderColor: '#DFE3ED' },
      btn: { background: '#DFE3ED', right: 0, boxShadow: 'none' },
      'btn:active': { background: '#DFE3ED' },
      'btn:hover': { background: '#DFE3ED' },
      btnUp: { top: 0 },
      btnDown: { bottom: 0 },

      arrowUp: {
        position: 'absolute',
        top: '75%',
        left: '55%',
        width: '7px',
        height: '7px',
        borderWidth: '2px 2px 0.2ex 0.2ex',
        background: 'transparent',
        borderColor: 'black',
        borderTop: '4px',
        borderRight: '4px',
        transform: 'translate3d(0,-50%,0) rotate(135deg)',
      },
      arrowDown: {
        position: 'absolute',
        top: '60%',
        left: '55%',
        width: '7px',
        height: '7px',
        borderWidth: '2px 2px 0.2ex 0.2ex',
        background: 'transparent',
        borderColor: 'black',
        borderTop: '4px',
        borderRight: '4px',
        transform: 'translate3d(0,-50%,0) rotate(315deg)',
      },
    }}
  />
);

export default DetailsNumberInput;
