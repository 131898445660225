import httpClient from '../utils/api/httpClient';
import { AxiosResponse } from 'axios';
import { GroupObject } from '../pages/lab/Groups';
import { observable, action } from 'mobx';

export function getPrefixedGroupName(labName: string, groupName: string) {
  return `${labName}:${groupName}`;
}

export function removePrefixFromGroupName(labName: string, groupName: string) {
  const prefixLen = labName.length + 1;
  return groupName.slice(prefixLen);
}

export class GroupsStore {
  private ENDPOINTS = {
    delete: '/api/v1/groups/delete',
    addUser: '/api/v1/groups/add_to_group',
    create: '/api/v1/groups/create_custom_group',
    getGroups: '/api/v1/groups/get_user_groups?username=',
    rename: '/api/v1/groups/change_name',
    getLabGroups: '/api/v1/groups/lab_groups?lab_name=',
    getLabUsers: '/api/v1/labs/users?lab_name=',
    deleteUserFromGroup: '/api/v1/groups/delete_from_group?group_name=',
  };

  @observable labName: string = '';
  @observable labGroups: GroupObject[] = [];

  @action
  setLabName = (labName: string) => {
    this.labName = labName;
  };

  @action
  setGroupList = (groupList: GroupObject[]) => {
    const { labName } = this;

    const strippedNames = groupList
      // filter out main group including all users
      .filter((group: GroupObject) => group.name !== labName)
      .map((group: GroupObject) => ({
        ...group,
        name: removePrefixFromGroupName(labName, group.name),
      }));

    this.labGroups = strippedNames;
  };

  async createGroup(groupName: string, labName: string) {
    return httpClient.post(this.ENDPOINTS.create, {
      group_name: groupName,
      lab_name: labName,
    });
  }

  async renameGroup(groupName: string, newName: string) {
    return httpClient.put(this.ENDPOINTS.rename, {
      old_name: groupName,
      name: newName,
    });
  }

  async deleteGroup(groupName: string) {
    return httpClient.put(this.ENDPOINTS.delete, {
      group_name: groupName,
    });
  }

  async addUserGroup(username: string, groupName: string) {
    return httpClient.post(this.ENDPOINTS.addUser, {
      group_name: groupName,
      username,
    });
  }

  async removeUser(userName: string, groupName: string) {
    const url = `${
      this.ENDPOINTS.deleteUserFromGroup
    }${groupName}&username=${userName}`;
    return httpClient.put(url);
  }

  async getUserGruop(userName: string) {
    return await httpClient.post(`${this.ENDPOINTS.getGroups}${userName}`);
  }

  async getLabUsers(labName: string) {
    return await httpClient
      .get(`${this.ENDPOINTS.getLabUsers}${labName}`)
      .then((response: AxiosResponse) => response.data);
  }

  async getLabGroups(labName: string) {
    this.setLabName(labName);
    this.setGroupList([]);

    return await httpClient
      .get(`${this.ENDPOINTS.getLabGroups}${labName}`)
      .then((response: AxiosResponse) => response.data)
      .then(this.setGroupList);
  }
}

export default new GroupsStore();
