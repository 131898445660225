import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

import { styles } from './styles';
import classNames from 'classnames';

interface FileProcessesProps extends WithStyles<typeof styles> {
  amount: number;
}

const FileProcesses: React.FC<FileProcessesProps> = ({
  amount,
  classes,
  children,
}) => {
  return (
    <div className={classNames(classes.zipList, { scroll: amount > 1 })}>
      {children}
    </div>
  );
};

export default withStyles(styles)(FileProcesses);
