import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';

import Button from 'components/ui/Button';

interface UserEditRemoveDialog {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const UserEditRemoveDialog: React.FunctionComponent<UserEditRemoveDialog> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={isOpen}
    >
      <DialogTitle>User removal</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to remove this user from lab?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button width={100} onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button width={100} onClick={onConfirm} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEditRemoveDialog;
