// adding custom palettes according to:
// https://github.com/cornerstonejs/cornerstone/blob/master/example/falseColorMapping/index.html
import generatePaletteFromSpec from './generatePaletteFromSpec';
// @ts-ignore
const customSpectral = window.cornerstone.colors.getColormap('customSpectral');

const customSpectralPalette = generatePaletteFromSpec(
  256,
  [0, 0, 0, 1, 1],
  [0, 0, 1, 1, 0],
  [0, 1, 0, 0, 0],
);

customSpectral.setNumberOfColors(256);
for (let i = 0; i < customSpectralPalette.length; i++) {
  customSpectral.insertColor(i, customSpectralPalette[i]);
}
