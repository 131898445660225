// see Cornerstone library colors/colormap.js:
// https://github.com/cornerstonejs/cornerstone/blob/master/src/colors/colormap.js

import './addCustomPalettes';
import convertToLinearGradient from './convertToLinearGradient';

const gradientLookup = {
  gray: 'linear-gradient(90deg, #000, #fff)',
  hot: 'linear-gradient(90deg, #000, #f00, #ff0, #fff)',
  hotIron: convertToLinearGradient('hotIron'),
  pet: convertToLinearGradient('pet'),
  hotMetalBlue: convertToLinearGradient('hotMetalBlue'),
  pet20Step: convertToLinearGradient('pet20Step'),
  customSpectral: convertToLinearGradient('customSpectral'),
};

export default gradientLookup;
