import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import withDrag from '../drag/withDrag';
import ArrowSVG from '../icons/ArrowIcon';

import {
  MAX_HEIGHT_SCREEN_PX,
  VERTICAL_LINE_ADDED_HEIGHT_PX,
  FULL_LINE_WIDTH_SCREEN_PX,
} from '../../../../../utils/sizingConstants';
import { CTViewerStore } from '../../../../../stores/ctViewerStore';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      cursor: 'pointer',
      userSelect: 'none',
      transform: 'translate3d(0, 0, 0)',
      transition: 'opacity .2s ease-in-out',

      '&.fadeOut': {
        opacity: 0.5,
      },

      '&.fadeOut:hover': {
        opacity: 1,
      },

      '& .markerHolder': {
        position: 'absolute',
        width: 24,
        height: 24,
        background: '#fff',
        borderRadius: '50%',
        border: '1px solid #000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      '&.horizontal': {
        top: 0,
        left: '4px',
        height: '1.5px',
      },

      '&.horizontal .markerHolder': {
        transform: 'rotate(90deg)',
      },

      '&.horizontal .markerHolder.first': {
        top: -12,
        left: 9,
      },

      '&.horizontal .markerHolder.second': {
        top: -12,
        right: 9,
      },

      '&.vertical': {
        top: '42px',
        left: 0,
        width: '1.5px',
      },

      '&.vertical .markerHolder.first': {
        left: -12,
        top: 9,
      },

      '&.vertical .markerHolder.second': {
        left: -12,
        bottom: 9,
      },

      '& .label': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        border: '1px solid #000',
        background: '#fff',
        padding: '3px 7px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '.9rem',
        color: '#1E1F24',
      },
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  position?: number;
  isVertical?: boolean;
  offset: number;
  scaling: number;
  imageHeight: number;
  viewportOffset?: number;
  color: string;
  label: string;
  onMouseDown: ((e: React.MouseEvent) => void) | undefined;
  onChange: any;
  registerOnDrag: any;
  registerSetDragInitialState: any;
  ctViewerStore?: CTViewerStore;
}
//#endregion

const LineMarker: React.FunctionComponent<Props> = ({
  classes,
  position,
  isVertical,
  offset,
  scaling,
  imageHeight,
  viewportOffset,
  color,
  label,
  onMouseDown,
  onChange,
  registerOnDrag,
  registerSetDragInitialState,
  ctViewerStore,
}) => {
  const newPosition = useRef<number | undefined>();
  const isOutOfBottomRange = (positionWithSnap: number) => {
    if (positionWithSnap * scaling > MAX_HEIGHT_SCREEN_PX) {
      return true;
    }
    return false;
  };

  const handleMouseMove = (
    unscaledXDiff: number,
    unscaledYDiff: number,
    initialDragPosition: any,
  ) => {
    const screenPxDiff = isVertical ? unscaledXDiff : unscaledYDiff;
    let newPosition = 0;

    if (!isVertical) {
      if (
        (initialDragPosition - ctViewerStore!.viewportOffset) * scaling >
        MAX_HEIGHT_SCREEN_PX
      ) {
        const nativePxDiff = screenPxDiff / scaling;
        const maxHeightNativePx = MAX_HEIGHT_SCREEN_PX / scaling;
        newPosition =
          maxHeightNativePx + ctViewerStore!.viewportOffset + nativePxDiff;
      } else if (initialDragPosition - ctViewerStore!.viewportOffset < 0) {
        newPosition = ctViewerStore!.viewportOffset + screenPxDiff / scaling;
      } else {
        newPosition = initialDragPosition + screenPxDiff / scaling;
      }
    } else {
      newPosition = initialDragPosition + screenPxDiff / scaling;
    }

    if (onChange) {
      onChange(Math.round(newPosition));
    }
  };

  const returnPosition = () => newPosition.current;

  useEffect(() => {
    registerOnDrag(handleMouseMove);
    registerSetDragInitialState(returnPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    newPosition.current = position;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  if (position === null || position === undefined) return null;

  let positionWithSnap = position;
  let isOutOfRange = false;
  if (viewportOffset !== undefined && !isVertical) {
    positionWithSnap = position - viewportOffset;
  }
  if (positionWithSnap < 0) {
    positionWithSnap = 0;
    isOutOfRange = true;
  }
  if (isOutOfBottomRange(positionWithSnap)) {
    positionWithSnap = MAX_HEIGHT_SCREEN_PX / scaling;
    isOutOfRange = true;
  }

  const positionPx = Math.round(positionWithSnap * scaling + offset);

  let cssClass = '';
  const styleElem: any = {
    background: color,
  };

  if (isVertical) {
    const addedHeight = VERTICAL_LINE_ADDED_HEIGHT_PX;
    const maxMarkerHeightScreenPx = MAX_HEIGHT_SCREEN_PX + addedHeight;

    let fullHeightPx = Math.round(imageHeight * scaling + addedHeight);
    if (fullHeightPx > maxMarkerHeightScreenPx) {
      fullHeightPx = maxMarkerHeightScreenPx;
    }

    styleElem.transform = `translate3d(${positionPx}px, 0, 0)`;
    styleElem.height = `${fullHeightPx}px`;
    cssClass = 'vertical';
  } else {
    styleElem.transform = `translate3d(0, ${positionPx}px, 0)`;
    styleElem.width = `${FULL_LINE_WIDTH_SCREEN_PX}px`;
    cssClass = 'horizontal';
  }

  const borderTextStyleElem = {
    borderColor: color,
  };

  return (
    <div
      className={classNames(classes.root, cssClass, { fadeOut: isOutOfRange })}
      style={styleElem}
      onMouseDown={onMouseDown}
    >
      <div className="markerHolder first" style={borderTextStyleElem}>
        <ArrowSVG />
      </div>
      <div className="markerHolder second" style={borderTextStyleElem}>
        <ArrowSVG />
      </div>
      {label && (
        <div className="label" style={borderTextStyleElem}>
          {label}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(
  withDrag(inject('ctViewerStore')(observer(LineMarker))),
);
