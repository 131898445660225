import { CTImage } from '../stores/ctViewerStore';

function regenerateImageWithColorMap(
  oldImage: CTImage,
  colormap: any,
  newID: any,
) {
  const flatPixels = oldImage.getPixelData();

  return {
    imageId: newID || oldImage.imageId,
    minPixelValue: oldImage.minPixelValue,
    maxPixelValue: oldImage.maxPixelValue,
    slope: oldImage.slope,
    intercept: oldImage.intercept,
    windowWidth: oldImage.windowWidth,
    windowCenter: oldImage.windowCenter,
    rows: oldImage.rows,
    columns: oldImage.columns,
    width: oldImage.width,
    height: oldImage.height,
    color: false,
    colormap,

    flatPixels,
    getPixelData() {
      return this.flatPixels;
    },
    sizeInBytes: flatPixels.length, // assume 32 bit storage
    columnPixelSpacing: oldImage.columnPixelSpacing, // horizontal spacing
    rowPixelSpacing: oldImage.rowPixelSpacing, // vertical spacing
  };
}

export default regenerateImageWithColorMap;
