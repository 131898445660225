import React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

//#region Styles
const styles = (theme: Theme) =>
  createStyles({
    menuLink: {
      textDecoration: 'none',
      display: 'block',
      textAlign: 'center',
      color: '#C6CACB',
      padding: '0 50px 0 0',
      cursor: 'pointer',

      '&:hover': {
        color: '#566063',
      },
    },
    menuLinkActive: {
      color: '#566063',
      '& > *': {
        fontWeight: 'bold',
      },
    },
  });
//#endregion

//#region Types
interface Props extends WithStyles<typeof styles> {
  to: string;
}
//#endregion

const NavLink: React.FunctionComponent<Props> = ({ classes, ...props }) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: classNames(classes.menuLink, {
          [classes.menuLinkActive]: isCurrent,
        }),
      };
    }}
  />
);

export default withStyles(styles)(NavLink);
