import { MAX_HEIGHT_SCREEN_PX, WIDTH_SCREEN_PX } from './sizingConstants';
import { CTImage } from '../stores/ctViewerStore';

function calculateHeightScreenPx(image: CTImage | null) {
  if (!image) return MAX_HEIGHT_SCREEN_PX;

  const nativeHeight = image.height;
  const nativeWidth = image.width;
  const verticalPixelRatio = image.rowPixelSpacing / image.columnPixelSpacing;

  let screenHeight =
    nativeHeight * (WIDTH_SCREEN_PX / nativeWidth) * verticalPixelRatio;
  if (screenHeight > MAX_HEIGHT_SCREEN_PX) {
    screenHeight = MAX_HEIGHT_SCREEN_PX;
  }

  return Math.round(screenHeight);
}

export default calculateHeightScreenPx;
